import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Ranking from './Ranking';
import TabelaAndamento from './TabelaAndamento';
import TabelaAditivos from './TabelaAditivos';

const InicioCpl = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches

    const estilo = {
        divCaixa: {
            width: mediaWeb ? "46vw" : "94vw", height: mediaWeb ? "55vh" : "60vh", marginTop: "2vh",
            display: "inline-block", verticalAlign: "top", boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
        }
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{
                    width: "94vw",
                    height: mediaWeb ? "30vh" : "60vh",
                    marginTop: "1vh",
                    display: "inline-block",
                    verticalAlign: "top",
                    border: '1px solid #ddd',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)'
                }}>
                    <Ranking />
                </div>
            </div>
            <div style={{ display: mediaWeb ? 'flex' : "block", justifyContent: 'space-between', width: "94vw", margin: "auto" }}>
                <div style={estilo.divCaixa}>
                    <TabelaAndamento />
                </div>
                <div style={{ ...estilo.divCaixa, marginBottom: mediaWeb ? "0vh" : "10vh" }}>
                    <TabelaAditivos />
                </div>
            </div>
        </>
    );
};

export default InicioCpl;
