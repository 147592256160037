import { useState, useEffect, useContext } from 'react'
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios'

const TabelasPorAreas = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabela, setTabela] = useState()

    useEffect(() => {
        axios.get('/api/orcamento/itensSemValorEstimado')
            .then(res => {
                setTabela(res.data)
            })
            .catch(error => alert(error))
    }, [])

    const updateField = (area, objeto, index, value, nameField) => {
        setTabela(prevState => {
            const newArray = [...prevState]
            newArray[index][nameField] = value
            return newArray
        })
    }

    const saveItem = (area, objeto, index) => {
        const indexObjeto = tabela.findIndex(el => el.area === area && el.objeto === objeto)
        axios.post("/api/orcamento/addValorEstimado", tabela[indexObjeto])
            .then(res => alert(res.data))
            .catch(error => alert("O item não pôde ser salvo, contate o suporte."))
    }

    const estilo = {
        table: {
            width: mediaWeb ? "98vw" : "100%", margin: "auto", marginTop: "2vh", marginBottom: "2vh",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: mediaWeb ? 'fixed' : null
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        },
        input: {
            border: "none", outline: "none", backgroundColor: "#00000000",
            color: myState === "light" ? 'black' : "#FFFF", width: "96%",
            resize: "none", overflow: "hidden"
        }
    }

    return (
        <>
            <table style={{ ...estilo.table }}>
                <thead>
                    <tr>
                        <th style={{ ...estilo.th, width: "15vw" }}>OBJETO</th>
                        <th style={{ ...estilo.th, width: "10vw" }}>DESCRIÇÃO</th>
                        <th style={{ ...estilo.th, width: "10vw" }}>UNIDADE</th>
                        <th style={{ ...estilo.th, width: "10vw" }}>VALOR ESTIMADO</th>
                        <th style={{ ...estilo.th, width: "10vw" }}>SALVAR</th>
                    </tr>
                </thead>
                <tbody>
                    {RenderItens(tabela)}
                </tbody>
            </table>
        </>
    )

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function parseCurrency(value) {
        const cleanedValue = value.replace(/\D/g, '')
        return parseFloat(cleanedValue) / 100
    }

    function RenderItens(array) {
        let rows = []
        if (array && array.length > 0) {
            array.map((el, index) => {
                rows.push(
                    <tr>
                        <td style={estilo.td}>{el.objeto}</td>
                        <td style={estilo.td}>
                            <input id={`inputValor-${el.objeto}`} style={estilo.input}
                                name="descricao" type="text"
                                onChange={e => updateField(el.area, el.objeto, index, e.target.value, e.target.name)}
                                value={formatCurrency(tabela[index]?.descricao || '')} />
                        </td>
                        <td style={estilo.td}>
                            <input id={`inputValor-${el.objeto}`} style={estilo.input}
                                name="und" type="text"
                                onChange={e => updateField(el.area, el.objeto, index, e.target.value, e.target.name)}
                                value={formatCurrency(tabela[index]?.und || '')} />
                        </td>
                        <td style={estilo.td}>
                            <input id={`inputValor-${el.objeto}`} style={estilo.input}
                                name="valorEstimado"
                                onChange={e => updateField(el.area, el.objeto, index, parseCurrency(e.target.value), e.target.name)}
                                value={formatCurrency(tabela[index]?.valorEstimado || '')} />
                        </td>
                        <td style={{ ...estilo.td, textAlign: "center" }}>
                            <button style={{
                                border: "none", outline: "none", borderRadius: '8px', width: "4vw",
                                backgroundColor: "#09792d", color: "#FFFF", padding: "5px"
                            }}
                                onClick={e => saveItem(el.area, el.objeto, index)} >Salvar</button>
                        </td>
                    </tr>
                )
            })
        }
        return rows
    }
}

export default TabelasPorAreas