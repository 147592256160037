import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "./MyContext"

const Textarea = (props) => {
    const { myState } = useContext(MyContext);
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const width = mediaWeb ? props.widthWeb : props.widthMobile

    const estilo = {
        divInput: {
            display: props.display || "inline-grid", textAlign: "center", width: width || "80vw",
            margin: "auto", marginBottom: props.marginB || "1vh", 
            marginRight: props.marginR || "2vw", marginLeft: props.marginL
        },
        label: {
            textAlign: "left", fontWeight: 600, fontSize: props.fontS || 15,
            color: myState === "light" ? "black" : "#dbdbdb"
        },
        input: {
            width: width || "80vw", border: myState === "light" ? "1px solid #00000029" : "1px solid rgb(0,0,0, 0.35)", 
            borderRadius: "5px", color: myState === "light" ? "black" : "#dbdbdb",
            minHeight: "20vh", fontSize: props.fontS || 15, backgroundColor: "#00000000"
        }
    }

    return (
        <div style={estilo.divInput}>
            <div style={estilo.label}>{props.texto}</div>
            {RenderInput()}
        </div>
    )

    function RenderInput() {
        const handleChange = (e) => {
            if (props.funcao) {
                props.funcao(e, props.additionalArgs)
            }
        }

        if (props.modo === "fixed") {
            var data = props.valor
            return (
                <div style={{ ...estilo.input, textAlign: "left" }}>
                    {data}
                </div>
            )
        }
        else {
            return (
                <textarea style={estilo.input} type={props.tipo}
                    onChange={handleChange} name={props.nome}
                    placeholder={props.placeholder} defaultValue={props.default}
                />
            )
        }
    }
}

export default Textarea