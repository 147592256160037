import { useState, useContext, useRef, useEffect } from 'react'
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios'

const ChatAI = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [dados, setDados] = useState({
        query: "",
        idConversation: sessionStorage.idConversation
    })
    const [messages, setMessages] = useState([])
    const [loadedMessages, setLoadedMessages] = useState(false)

    const scrollRef = useRef(null)
    useEffect(() => {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }, [messages])

    useEffect(() => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let hash = '';

        if (!sessionStorage.idConversation) {
            for (let i = 0; i < 24; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                hash += characters.charAt(randomIndex);
            }
            sessionStorage.idConversation = hash
            setDados(prevState => ({
                ...prevState,
                idConversation: hash
            }))
        }
    }, [])
    
    useEffect(() => {
        let id = window.location.pathname.slice(8)
        if (id.length > 0 && !loadedMessages) {
            axios.post("/api/conversation/getConversation", { id })
                .then(res => {
                    setMessages(res.data.conversationHistory)
                    setDados((prevDados) => ({ ...prevDados, idConversation: res.data._id }))
                    setLoadedMessages(true)
                })
                .catch(error => {
                    alert("Azar Militar, alguma coisa deu errado!")
                })
        }
    }, [])

    const enviar = () => {
        const formattedTimeHumano = new Date().toLocaleString('pt-BR', { hour: '2-digit', minute: '2-digit' })
        insertMessage({
            tipo: "humano", mensagem: dados.query,
            hora: formattedTimeHumano
        })
        setDados((prevDados) => ({ ...prevDados, query: "" }))
        // const url = "https://iacbmpb.herokuapp.com/pandasAgent/funesbom"
        // const url = "http://localhost:5001/pandasAgent/funesbom"
        // const url = "http://localhost:5001/ai/horarios"
        // const url = "http://localhost:5001/customAgent/agent"
        // const url = "http://localhost:5001/ownerAgent/agent"
        const url = "http://localhost:5001/crefitoAgent/agent"
        axios.post(url, dados)
            .then(res => {
                setDados((prevDados) => ({ ...prevDados, idConversation: res.data.idConversation }))

                const formattedTime = new Date().toLocaleString('pt-BR', { hour: '2-digit', minute: '2-digit' })
                insertMessage({
                    tipo: "ai", mensagem: res.data.analise,
                    hora: formattedTime
                })
            })
            .catch(error => {
                alert(error)
                console.log(error)
            })
    }

    const updateField = (e) => {
        const { name, value } = e.target
        let capitalValue = value.charAt(0).toUpperCase() + value.slice(1)
        let tempState = { ...dados }
        tempState[name] = capitalValue
        setDados(tempState)
    }

    const insertMessage = (object) => {
        setMessages((prevMessages) => [...prevMessages, object])
    }

    return (
        <div style={{ display: "flex", flex: "1", flexDirection: "column" }}>
            <div ref={scrollRef} style={{
                height: mediaWeb ? "80vh" : "84vh",
                overflow: "scroll",
                overflowX: "hidden"
            }}>
                {RenderMessages()}
            </div>
            <div style={{
                display: "flex",
                marginTop: "auto", marginLeft: "auto", marginRight: "auto", marginBottom: "1vh",
                width: "96vw", height: "6vh",
                border: "1px solid #ddd", borderRadius: "10px"
            }}>
                <input value={dados.query} onChange={e => updateField(e)} name="query"
                    onKeyDown={e => e.key === "Enter" && enviar()}
                    style={{
                        width: "84vw", border: "none", outline: "none",
                        backgroundColor: "#00000000",
                        color: myState === "light" ? 'black' : "#FFFF"
                    }} />
                <i className="bi bi-send" onClick={() => enviar()}
                    style={{
                        alignSelf: "center", marginLeft: "auto", marginRight: "4vw",
                        fontSize: "20px", backgroundColor: "#00000000",
                        color: myState === "light" ? 'black' : "#cccc"
                    }}></i>
            </div>
        </div>
    )

    function RenderMessages() {
        let mensagens = []
        messages.map(el => {
            if (el.mensagem) {
                mensagens.push(
                    <div style={{
                        width: "70vw", textAlign: "justify",
                        backgroundColor: el.tipo === "ai" ? "#ff6666" : "#d9fdd3",
                        color: el.tipo === "ai" ? "#FFFF" : "black",
                        borderRadius: "8px", margin: "2vw", padding: "8px",
                        marginLeft: el.tipo === "humano" ? "auto" : "2vw",
                        marginRight: el.tipo === "ai" ? "auto" : "2vw",
                    }}>
                        {el.mensagem}
                        <div style={{ textAlign: "right" }}>{el.hora}</div>
                    </div>
                )
            } else if (el.input) {
                mensagens.push(
                    <div style={{
                        width: "70vw", textAlign: "justify",
                        backgroundColor: "#d9fdd3", color: "black",
                        borderRadius: "8px", margin: "2vw", padding: "8px",
                        marginLeft: "auto", marginRight: "2vw",
                    }}>
                        {el.input}
                        <div style={{ textAlign: "right" }}>{el.hora}</div>
                    </div>
                )
            } else {
                mensagens.push(
                    <div style={{
                        width: "70vw", textAlign: "justify",
                        backgroundColor: "#ff6666", color: "#FFFF",
                        borderRadius: "8px", margin: "2vw", padding: "8px",
                        marginLeft: "2vw", marginRight: "auto",
                    }}>
                        {el.output}
                        <div style={{ textAlign: "right" }}>{el.hora}</div>
                    </div>
                )
            }
        })
        return mensagens
    }
}

export default ChatAI