import React, { useState } from 'react'
import axios from 'axios'

const InsertVectors = () => {
    const [dados, setDados] = useState({})
    const [disabled, setDisabled] = useState(false)

    const estilo = {
        principal: {
            width: "40vw", marginLeft: "2vw",
            textAlign: "left", color: "#484848", marginTop: "2vh"
        },
        input: {
            width: "40vw", backgroundColor: "#00000000", border: "1px solid #00000029", borderRadius: "5px",
            minHeight: "5px"
        },
        area: {
            backgroundColor: "#00000000", border: "1px solid #00000029", borderRadius: "5px",
            textAlign: "left", width: "90vw", whiteSpace: "pre-line"
        }
    }

    const updateField = (e) => {
        const {name, value} = e.target
        let newState = {...dados}
        newState[name] = value
        setDados(newState)
    }

    const send = () => {
        // setDisabled(true)
        axios.post('http://localhost:5001/vectorsCbm/insertVectors', dados)
        // axios.post('https://iacbmpb.herokuapp.com/vectorsCbm/insertVectors', dados)
            .then(res => {
                alert(res.data)
                setDisabled(false)
                window.location = '/InsertVectors'
            })
            .catch(error => alert(error))
    }

    return (
        <>
            <div style={estilo.principal}>
                <div>URL</div>
                <input style={estilo.input} type="text" name="url"
                onChange={e => updateField(e)} />
                <div>Documento</div>
                <input style={estilo.input} type="text" name="documento"
                onChange={e => updateField(e)} />
                <div>Texto</div>
                <textarea style={estilo.area} name="texto" rows="10"
                onChange={e => updateField(e)} ></textarea>
            </div>
            <div style={{textAlign: "center"}}>
                <button style={{
                    width: "8vw", border: "none", backgroundColor: "#09792d",
                    outline: "none", borderRadius: "8px", color: "#FFFF",
                    padding: "3px", marginTop: "2vh"
                }} disabled={disabled} onClick={e => send()}>Enviar</button>
            </div>
        </>
    )
}

export default InsertVectors