const cnae = [
    { codigo: "0111-3/01", descricao: "Cultivo de arroz"},
{ codigo: "0111-3/02", descricao: "Cultivo de milho"},
{ codigo: "0111-3/03", descricao: "Cultivo de trigo"},
{ codigo: "0111-3/99", descricao: "Cultivo de outros cereais não especificados anteriormente"},
{ codigo: "0112-1/01", descricao: "Cultivo de algodão herbáceo"},
{ codigo: "0112-1/02", descricao: "Cultivo de juta"},
{ codigo: "0112-1/99", descricao: "Cultivo de outras fibras de lavoura temporária não especificadas anteriormente"},
{ codigo: "0113-0/00", descricao: "Cultivo de cana-de-açúcar"},
{ codigo: "0114-8/00", descricao: "Cultivo de fumo"},
{ codigo: "0115-6/00", descricao: "Cultivo de soja"},
{ codigo: "0116-4/01", descricao: "Cultivo de amendoim"},
{ codigo: "0116-4/02", descricao: "Cultivo de girassol"},
{ codigo: "0116-4/03", descricao: "Cultivo de mamona"},
{ codigo: "0116-4/99", descricao: "Cultivo de outras oleaginosas de lavoura temporária não especificadas anteriormente"},
{ codigo: "0119-9/01", descricao: "Cultivo de abacaxi"},
{ codigo: "0119-9/02", descricao: "Cultivo de alho"},
{ codigo: "0119-9/03", descricao: "Cultivo de batata-inglesa"},
{ codigo: "0119-9/04", descricao: "Cultivo de cebola"},
{ codigo: "0119-9/05", descricao: "Cultivo de feijão"},
{ codigo: "0119-9/06", descricao: "Cultivo de mandioca"},
{ codigo: "0119-9/07", descricao: "Cultivo de melão"},
{ codigo: "0119-9/08", descricao: "Cultivo de melancia"},
{ codigo: "0119-9/09", descricao: "Cultivo de tomate rasteiro"},
{ codigo: "0119-9/99", descricao: "Cultivo de outras plantas de lavoura temporária não especificadas anteriormente"},
{ codigo: "0121-1/01", descricao: "Horticultura, exceto morango"},
{ codigo: "0121-1/02", descricao: "Cultivo de morango"},
{ codigo: "0122-9/00", descricao: "Cultivo de flores e plantas ornamentais"},
{ codigo: "0131-8/00", descricao: "Cultivo de laranja"},
{ codigo: "0132-6/00", descricao: "Cultivo de uva"},
{ codigo: "0133-4/01", descricao: "Cultivo de açaí"},
{ codigo: "0133-4/02", descricao: "Cultivo de banana"},
{ codigo: "0133-4/03", descricao: "Cultivo de caju"},
{ codigo: "0133-4/04", descricao: "Cultivo de cítricos, exceto laranja"},
{ codigo: "0133-4/05", descricao: "Cultivo de coco-da-baía"},
{ codigo: "0133-4/06", descricao: "Cultivo de guaraná"},
{ codigo: "0133-4/07", descricao: "Cultivo de maçã"},
{ codigo: "0133-4/08", descricao: "Cultivo de mamão"},
{ codigo: "0133-4/09", descricao: "Cultivo de maracujá"},
{ codigo: "0133-4/10", descricao: "Cultivo de manga"},
{ codigo: "0133-4/11", descricao: "Cultivo de pêssego"},
{ codigo: "0133-4/99", descricao: "Cultivo de frutas de lavoura permanente não especificadas anteriormente"},
{ codigo: "0134-2/00", descricao: "Cultivo de café"},
{ codigo: "0135-1/00", descricao: "Cultivo de cacau"},
{ codigo: "0139-3/01", descricao: "Cultivo de chá-da-índia"},
{ codigo: "0139-3/02", descricao: "Cultivo de erva-mate"},
{ codigo: "0139-3/03", descricao: "Cultivo de pimenta-do-reino"},
{ codigo: "0139-3/04", descricao: "Cultivo de plantas para condimento, exceto pimenta-do-reino"},
{ codigo: "0139-3/05", descricao: "Cultivo de dendê"},
{ codigo: "0139-3/06", descricao: "Cultivo de seringueira"},
{ codigo: "0139-3/99", descricao: "Cultivo de outras plantas de lavoura permanente não especificadas anteriormente"},
{ codigo: "0141-5/01", descricao: "Produção de sementes certificadas, exceto de forrageiras para pasto"},
{ codigo: "0141-5/02", descricao: "Produção de sementes certificadas de forrageiras para formação de pasto"},
{ codigo: "0142-3/00", descricao: "Produção de mudas e outras formas de propagação vegetal, certificadas"},
{ codigo: "0151-2/01", descricao: "Criação de bovinos para corte"},
{ codigo: "0151-2/02", descricao: "Criação de bovinos para leite"},
{ codigo: "0151-2/03", descricao: "Criação de bovinos, exceto para corte e leite"},
{ codigo: "0152-1/01", descricao: "Criação de bufalinos"},
{ codigo: "0152-1/02", descricao: "Criação de equinos"},
{ codigo: "0152-1/03", descricao: "Criação de asininos e muares"},
{ codigo: "0153-9/01", descricao: "Criação de caprinos"},
{ codigo: "0153-9/02", descricao: "Criação de ovinos, inclusive para produção de lã"},
{ codigo: "0154-7/00", descricao: "Criação de suínos"},
{ codigo: "0155-5/01", descricao: "Criação de frangos para corte"},
{ codigo: "0155-5/02", descricao: "Produção de pintos de um dia"},
{ codigo: "0155-5/03", descricao: "Criação de outros galináceos, exceto para corte"},
{ codigo: "0155-5/04", descricao: "Criação de aves, exceto galináceos"},
{ codigo: "0155-5/05", descricao: "Produção de ovos"},
{ codigo: "0159-8/01", descricao: "Apicultura"},
{ codigo: "0159-8/02", descricao: "Criação de animais de estimação"},
{ codigo: "0159-8/03", descricao: "Criação de escargô"},
{ codigo: "0159-8/04", descricao: "Criação de bicho-da-seda"},
{ codigo: "0159-8/99", descricao: "Criação de outros animais não especificados anteriormente"},
{ codigo: "0161-0/01", descricao: "Serviço de pulverização e controle de pragas agrícolas"},
{ codigo: "0161-0/02", descricao: "Serviço de poda de árvores para lavouras"},
{ codigo: "0161-0/03", descricao: "Serviço de preparação de terreno, cultivo e colheita"},
{ codigo: "0161-0/99", descricao: "Atividades de apoio à agricultura não especificadas anteriormente"},
{ codigo: "0162-8/01", descricao: "Serviço de inseminação artificial em animais"},
{ codigo: "0162-8/02", descricao: "Serviço de tosquiamento de ovinos"},
{ codigo: "0162-8/03", descricao: "Serviço de manejo de animais"},
{ codigo: "0162-8/99", descricao: "Atividades de apoio à pecuária não especificadas anteriormente"},
{ codigo: "0163-6/00", descricao: "Atividades de pós-colheita"},
{ codigo: "0170-9/00", descricao: "Caça e serviços relacionados"},
{ codigo: "0210-1/01", descricao: "Cultivo de eucalipto"},
{ codigo: "0210-1/02", descricao: "Cultivo de acácia-negra"},
{ codigo: "0210-1/03", descricao: "Cultivo de pinus"},
{ codigo: "0210-1/04", descricao: "Cultivo de teca"},
{ codigo: "0210-1/05", descricao: "Cultivo de espécies madeireiras, exceto eucalipto, acácia-negra, pinus e teca"},
{ codigo: "0210-1/06", descricao: "Cultivo de mudas em viveiros florestais"},
{ codigo: "0210-1/07", descricao: "Extração de madeira em florestas plantadas"},
{ codigo: "0210-1/08", descricao: "Produção de carvão vegetal - florestas plantadas"},
{ codigo: "0210-1/09", descricao: "Produção de casca de acácia-negra - florestas plantadas"},
{ codigo: "0210-1/99", descricao: "Produção de produtos não madeireiros não especificados anteriormente em florestas plantadas"},
{ codigo: "0220-9/01", descricao: "Extração de madeira em florestas nativas"},
{ codigo: "0220-9/02", descricao: "Produção de carvão vegetal - florestas nativas"},
{ codigo: "0220-9/03", descricao: "Coleta de castanha-do-pará em florestas nativas"},
{ codigo: "0220-9/04", descricao: "Coleta de látex em florestas nativas"},
{ codigo: "0220-9/05", descricao: "Coleta de palmito em florestas nativas"},
{ codigo: "0220-9/06", descricao: "Conservação de florestas nativas"},
{ codigo: "0220-9/99", descricao: "Coleta de produtos não madeireiros não especificados anteriormente em florestas nativas"},
{ codigo: "0230-6/00", descricao: "Atividades de apoio à produção florestal"},
{ codigo: "0311-6/01", descricao: "Pesca de peixes em água salgada"},
{ codigo: "0311-6/02", descricao: "Pesca de crustáceos e moluscos em água salgada"},
{ codigo: "0311-6/03", descricao: "Coleta de outros produtos marinhos"},
{ codigo: "0311-6/04", descricao: "Atividades de apoio à pesca em água salgada"},
{ codigo: "0312-4/01", descricao: "Pesca de peixes em água doce"},
{ codigo: "0312-4/02", descricao: "Pesca de crustáceos e moluscos em água doce"},
{ codigo: "0312-4/03", descricao: "Coleta de outros produtos aquáticos de água doce"},
{ codigo: "0312-4/04", descricao: "Atividades de apoio à pesca em água doce"},
{ codigo: "0321-3/01", descricao: "Criação de peixes em água salgada e salobra"},
{ codigo: "0321-3/02", descricao: "Criação de camarões em água salgada e salobra"},
{ codigo: "0321-3/03", descricao: "Criação de ostras e mexilhões em água salgada e salobra"},
{ codigo: "0321-3/04", descricao: "Criação de peixes ornamentais em água salgada e salobra"},
{ codigo: "0321-3/05", descricao: "Atividades de apoio à aquicultura em água salgada e salobra"},
{ codigo: "0321-3/99", descricao: "Cultivos e semicultivos da aquicultura em água salgada e salobra não especificados anteriormente"},
{ codigo: "0322-1/01", descricao: "Criação de peixes em água doce"},
{ codigo: "0322-1/02", descricao: "Criação de camarões em água doce"},
{ codigo: "0322-1/03", descricao: "Criação de ostras e mexilhões em água doce"},
{ codigo: "0322-1/04", descricao: "Criação de peixes ornamentais em água doce"},
{ codigo: "0322-1/05", descricao: "Ranicultura"},
{ codigo: "0322-1/06", descricao: "Criação de jacaré"},
{ codigo: "0322-1/07", descricao: "Atividades de apoio à aquicultura em água doce"},
{ codigo: "0322-1/99", descricao: "Cultivos e semicultivos da aquicultura em água doce não especificados anteriormente"},
{ codigo: "0500-3/01", descricao: "Extração de carvão mineral"},
{ codigo: "0500-3/02", descricao: "Beneficiamento de carvão mineral"},
{ codigo: "0600-0/01", descricao: "Extração de petróleo e gás natural"},
{ codigo: "0600-0/02", descricao: "Extração e beneficiamento de xisto"},
{ codigo: "0600-0/03", descricao: "Extração e beneficiamento de areias betuminosas"},
{ codigo: "0710-3/01", descricao: "Extração de minério de ferro"},
{ codigo: "0710-3/02", descricao: "Pelotização, sinterização e outros beneficiamentos de minério de ferro"},
{ codigo: "0721-9/01", descricao: "Extração de minério de alumínio"},
{ codigo: "0721-9/02", descricao: "Beneficiamento de minério de alumínio"},
{ codigo: "0722-7/01", descricao: "Extração de minério de estanho"},
{ codigo: "0722-7/02", descricao: "Beneficiamento de minério de estanho"},
{ codigo: "0723-5/01", descricao: "Extração de minério de manganês"},
{ codigo: "0723-5/02", descricao: "Beneficiamento de minério de manganês"},
{ codigo: "0724-3/01", descricao: "Extração de minério de metais preciosos"},
{ codigo: "0724-3/02", descricao: "Beneficiamento de minério de metais preciosos"},
{ codigo: "0725-1/00", descricao: "Extração de minerais radioativos"},
{ codigo: "0729-4/01", descricao: "Extração de minérios de nióbio e titânio"},
{ codigo: "0729-4/02", descricao: "Extração de minério de tungstênio"},
{ codigo: "0729-4/03", descricao: "Extração de minério de níquel"},
{ codigo: "0729-4/04", descricao: "Extração de minérios de cobre, chumbo, zinco e outros minerais metálicos não ferrosos não especificados anteriormente"},
{ codigo: "0729-4/05", descricao: "Beneficiamento de minérios de cobre, chumbo, zinco e outros minerais metálicos não ferrosos não especificados anteriormente"},
{ codigo: "0810-0/01", descricao: "Extração de ardósia e beneficiamento associado"},
{ codigo: "0810-0/02", descricao: "Extração de granito e beneficiamento associado"},
{ codigo: "0810-0/03", descricao: "Extração de mármore e beneficiamento associado"},
{ codigo: "0810-0/04", descricao: "Extração de calcário e dolomita e beneficiamento associado"},
{ codigo: "0810-0/05", descricao: "Extração de gesso e caulim"},
{ codigo: "0810-0/06", descricao: "Extração de areia, cascalho ou pedregulho e beneficiamento associado"},
{ codigo: "0810-0/07", descricao: "Extração de argila e beneficiamento associado"},
{ codigo: "0810-0/08", descricao: "Extração de saibro e beneficiamento associado"},
{ codigo: "0810-0/09", descricao: "Extração de basalto e beneficiamento associado"},
{ codigo: "0810-0/10", descricao: "Beneficiamento de gesso e caulim associado à extração"},
{ codigo: "0810-0/99", descricao: "Extração e britamento de pedras e outros materiais para construção e beneficiamento associado"},
{ codigo: "0891-6/00", descricao: "Extração de minerais para fabricação de adubos, fertilizantes e outros produtos químicos"},
{ codigo: "0892-4/01", descricao: "Extração de sal marinho"},
{ codigo: "0892-4/02", descricao: "Extração de sal-gema"},
{ codigo: "0892-4/03", descricao: "Refino e outros tratamentos do sal"},
{ codigo: "0893-2/00", descricao: "Extração de gemas (pedras preciosas e semipreciosas)"},
{ codigo: "0899-1/01", descricao: "Extração de grafita"},
{ codigo: "0899-1/02", descricao: "Extração de quartzo"},
{ codigo: "0899-1/03", descricao: "Extração de amianto"},
{ codigo: "0899-1/99", descricao: "Extração de outros minerais não metálicos não especificados anteriormente"},
{ codigo: "0910-6/00", descricao: "Atividades de apoio à extração de petróleo e gás natural"},
{ codigo: "0990-4/01", descricao: "Atividades de apoio à extração de minério de ferro"},
{ codigo: "0990-4/02", descricao: "Atividades de apoio à extração de minerais metálicos não ferrosos"},
{ codigo: "0990-4/03", descricao: "Atividades de apoio à extração de minerais não metálicos"},
{ codigo: "1011-2/01", descricao: "Frigorífico - abate de bovinos"},
{ codigo: "1011-2/02", descricao: "Frigorífico - abate de equinos"},
{ codigo: "1011-2/03", descricao: "Frigorífico - abate de ovinos e caprinos"},
{ codigo: "1011-2/04", descricao: "Frigorífico - abate de bufalinos"},
{ codigo: "1011-2/05", descricao: "Matadouro - abate de reses sob contrato, exceto abate de suínos"},
{ codigo: "1012-1/01", descricao: "Abate de aves"},
{ codigo: "1012-1/02", descricao: "Abate de pequenos animais"},
{ codigo: "1012-1/03", descricao: "Frigorífico - abate de suínos"},
{ codigo: "1012-1/04", descricao: "Matadouro - abate de suínos sob contrato"},
{ codigo: "1013-9/01", descricao: "Fabricação de produtos de carne"},
{ codigo: "1013-9/02", descricao: "Preparação de subprodutos do abate"},
{ codigo: "1020-1/01", descricao: "Preservação de peixes, crustáceos e moluscos"},
{ codigo: "1020-1/02", descricao: "Fabricação de conservas de peixes, crustáceos e moluscos"},
{ codigo: "1031-7/00", descricao: "Fabricação de conservas de frutas"},
{ codigo: "1032-5/01", descricao: "Fabricação de conservas de palmito"},
{ codigo: "1032-5/99", descricao: "Fabricação de conservas de legumes e outros vegetais, exceto palmito"},
{ codigo: "1033-3/01", descricao: "Fabricação de sucos concentrados de frutas, hortaliças e legumes"},
{ codigo: "1033-3/02", descricao: "Fabricação de sucos de frutas, hortaliças e legumes, exceto concentrados"},
{ codigo: "1041-4/00", descricao: "Fabricação de óleos vegetais em bruto, exceto óleo de milho"},
{ codigo: "1042-2/00", descricao: "Fabricação de óleos vegetais refinados, exceto óleo de milho"},
{ codigo: "1043-1/00", descricao: "Fabricação de margarina e outras gorduras vegetais e de óleos não comestíveis de animais"},
{ codigo: "1051-1/00", descricao: "Preparação do leite"},
{ codigo: "1052-0/00", descricao: "Fabricação de laticínios"},
{ codigo: "1053-8/00", descricao: "Fabricação de sorvetes e outros gelados comestíveis"},
{ codigo: "1061-9/01", descricao: "Beneficiamento de arroz"},
{ codigo: "1061-9/02", descricao: "Fabricação de produtos do arroz"},
{ codigo: "1062-7/00", descricao: "Moagem de trigo e fabricação de derivados"},
{ codigo: "1063-5/00", descricao: "Fabricação de farinha de mandioca e derivados"},
{ codigo: "1064-3/00", descricao: "Fabricação de farinha de milho e derivados, exceto óleos de milho"},
{ codigo: "1065-1/01", descricao: "Fabricação de amidos e féculas de vegetais"},
{ codigo: "1065-1/02", descricao: "Fabricação de óleo de milho em bruto"},
{ codigo: "1065-1/03", descricao: "Fabricação de óleo de milho refinado"},
{ codigo: "1066-0/00", descricao: "Fabricação de alimentos para animais"},
{ codigo: "1069-4/00", descricao: "Moagem e fabricação de produtos de origem vegetal não especificados anteriormente"},
{ codigo: "1071-6/00", descricao: "Fabricação de açúcar em bruto"},
{ codigo: "1072-4/01", descricao: "Fabricação de açúcar de cana refinado"},
{ codigo: "1072-4/02", descricao: "Fabricação de açúcar de cereais (dextrose) e de beterraba"},
{ codigo: "1081-3/01", descricao: "Beneficiamento de café"},
{ codigo: "1081-3/02", descricao: "Torrefação e moagem de café"},
{ codigo: "1082-1/00", descricao: "Fabricação de produtos à base de café"},
{ codigo: "1091-1/01", descricao: "Fabricação de produtos de panificação industrial"},
{ codigo: "1091-1/02", descricao: "Fabricação de produtos de padaria e confeitaria com predominância de produção própria"},
{ codigo: "1092-9/00", descricao: "Fabricação de biscoitos e bolachas"},
{ codigo: "1093-7/01", descricao: "Fabricação de produtos derivados do cacau e de chocolates"},
{ codigo: "1093-7/02", descricao: "Fabricação de frutas cristalizadas, balas e semelhantes"},
{ codigo: "1094-5/00", descricao: "Fabricação de massas alimentícias"},
{ codigo: "1095-3/00", descricao: "Fabricação de especiarias, molhos, temperos e condimentos"},
{ codigo: "1096-1/00", descricao: "Fabricação de alimentos e pratos prontos"},
{ codigo: "1099-6/01", descricao: "Fabricação de vinagres"},
{ codigo: "1099-6/02", descricao: "Fabricação de pós-alimentícios"},
{ codigo: "1099-6/03", descricao: "Fabricação de fermentos e leveduras"},
{ codigo: "1099-6/04", descricao: "Fabricação de gelo comum"},
{ codigo: "1099-6/05", descricao: "Fabricação de produtos para infusão (chá, mate, etc.)"},
{ codigo: "1099-6/06", descricao: "Fabricação de adoçantes naturais e artificiais"},
{ codigo: "1099-6/07", descricao: "Fabricação de alimentos dietéticos e complementos alimentares"},
{ codigo: "1099-6/99", descricao: "Fabricação de outros produtos alimentícios não especificados anteriormente"},
{ codigo: "1111-9/01", descricao: "Fabricação de aguardente de cana-de-açúcar"},
{ codigo: "1111-9/02", descricao: "Fabricação de outras aguardentes e bebidas destiladas"},
{ codigo: "1112-7/00", descricao: "Fabricação de vinho"},
{ codigo: "1113-5/01", descricao: "Fabricação de malte, inclusive malte uísque"},
{ codigo: "1113-5/02", descricao: "Fabricação de cervejas e chopes"},
{ codigo: "1121-6/00", descricao: "Fabricação de águas envasadas"},
{ codigo: "1122-4/01", descricao: "Fabricação de refrigerantes"},
{ codigo: "1122-4/02", descricao: "Fabricação de chá mate e outros chás prontos para consumo"},
{ codigo: "1122-4/03", descricao: "Fabricação de refrescos, xaropes e pós para refrescos, exceto refrescos de frutas"},
{ codigo: "1122-4/04", descricao: "Fabricação de bebidas isotônicas"},
{ codigo: "1122-4/99", descricao: "Fabricação de outras bebidas não alcoólicas não especificadas anteriormente"},
{ codigo: "1210-7/00", descricao: "Processamento industrial do fumo"},
{ codigo: "1220-4/01", descricao: "Fabricação de cigarros"},
{ codigo: "1220-4/02", descricao: "Fabricação de cigarrilhas e charutos"},
{ codigo: "1220-4/03", descricao: "Fabricação de filtros para cigarros"},
{ codigo: "1220-4/99", descricao: "Fabricação de outros produtos do fumo, exceto cigarros, cigarrilhas e charutos"},
{ codigo: "1311-1/00", descricao: "Preparação e fiação de fibras de algodão"},
{ codigo: "1312-0/00", descricao: "Preparação e fiação de fibras têxteis naturais, exceto algodão"},
{ codigo: "1313-8/00", descricao: "Fiação de fibras artificiais e sintéticas"},
{ codigo: "1314-6/00", descricao: "Fabricação de linhas para costurar e bordar"},
{ codigo: "1321-9/00", descricao: "Tecelagem de fios de algodão"},
{ codigo: "1322-7/00", descricao: "Tecelagem de fios de fibras têxteis naturais, exceto algodão"},
{ codigo: "1323-5/00", descricao: "Tecelagem de fios de fibras artificiais e sintéticas"},
{ codigo: "1330-8/00", descricao: "Fabricação de tecidos de malha"},
{ codigo: "1340-5/01", descricao: "Estamparia e texturização em fios, tecidos, artefatos têxteis e peças do vestuário"},
{ codigo: "1340-5/02", descricao: "Alvejamento, tingimento e torção em fios, tecidos, artefatos têxteis e peças do vestuário"},
{ codigo: "1340-5/99", descricao: "Outros serviços de acabamento em fios, tecidos, artefatos têxteis e peças do vestuário"},
{ codigo: "1351-1/00", descricao: "Fabricação de artefatos têxteis para uso doméstico"},
{ codigo: "1352-9/00", descricao: "Fabricação de artefatos de tapeçaria"},
{ codigo: "1353-7/00", descricao: "Fabricação de artefatos de cordoaria"},
{ codigo: "1354-5/00", descricao: "Fabricação de tecidos especiais, inclusive artefatos"},
{ codigo: "1359-6/00", descricao: "Fabricação de outros produtos têxteis não especificados anteriormente"},
{ codigo: "1411-8/01", descricao: "Confecção de roupas íntimas"},
{ codigo: "1411-8/02", descricao: "Facção de roupas íntimas"},
{ codigo: "1412-6/01", descricao: "Confecção de peças do vestuário, exceto roupas íntimas e as confeccionadas sob medida"},
{ codigo: "1412-6/02", descricao: "Confecção, sob medida, de peças do vestuário, exceto roupas íntimas"},
{ codigo: "1412-6/03", descricao: "Facção de peças do vestuário, exceto roupas íntimas"},
{ codigo: "1413-4/01", descricao: "Confecção de roupas profissionais, exceto sob medida"},
{ codigo: "1413-4/02", descricao: "Confecção, sob medida, de roupas profissionais"},
{ codigo: "1413-4/03", descricao: "Facção de roupas profissionais"},
{ codigo: "1414-2/00", descricao: "Fabricação de acessórios do vestuário, exceto para segurança e proteção"},
{ codigo: "1421-5/00", descricao: "Fabricação de meias"},
{ codigo: "1422-3/00", descricao: "Fabricação de artigos do vestuário, produzidos em malharias e tricotagens, exceto meias"},
{ codigo: "1510-6/00", descricao: "Curtimento e outras preparações de couro"},
{ codigo: "1521-1/00", descricao: "Fabricação de artigos para viagem, bolsas e semelhantes de qualquer material"},
{ codigo: "1529-7/00", descricao: "Fabricação de artefatos de couro não especificados anteriormente"},
{ codigo: "1531-9/01", descricao: "Fabricação de calçados de couro"},
{ codigo: "1531-9/02", descricao: "Acabamento de calçados de couro sob contrato"},
{ codigo: "1532-7/00", descricao: "Fabricação de tênis de qualquer material"},
{ codigo: "1533-5/00", descricao: "Fabricação de calçados de material sintético"},
{ codigo: "1539-4/00", descricao: "Fabricação de calçados de materiais não especificados anteriormente"},
{ codigo: "1540-8/00", descricao: "Fabricação de partes para calçados, de qualquer material"},
{ codigo: "1610-2/03", descricao: "Serrarias com desdobramento de madeira em bruto"},
{ codigo: "1610-2/04", descricao: "Serrarias sem desdobramento de madeira em bruto - Resseragem"},
{ codigo: "1610-2/05", descricao: "Serviço de tratamento de madeira realizado sob contrato"},
{ codigo: "1621-8/00", descricao: "Fabricação de madeira laminada e de chapas de madeira compensada, prensada e aglomerada"},
{ codigo: "1622-6/01", descricao: "Fabricação de casas de madeira pré-fabricadas"},
{ codigo: "1622-6/02", descricao: "Fabricação de esquadrias de madeira e de peças de madeira para instalações industriais e comerciais"},
{ codigo: "1622-6/99", descricao: "Fabricação de outros artigos de carpintaria para construção"},
{ codigo: "1623-4/00", descricao: "Fabricação de artefatos de tanoaria e de embalagens de madeira"},
{ codigo: "1629-3/01", descricao: "Fabricação de artefatos diversos de madeira, exceto móveis"},
{ codigo: "1629-3/02", descricao: "Fabricação de artefatos diversos de cortiça, bambu, palha, vime e outros materiais trançados, exceto móveis"},
{ codigo: "1710-9/00", descricao: "Fabricação de celulose e outras pastas para a fabricação de papel"},
{ codigo: "1721-4/00", descricao: "Fabricação de papel"},
{ codigo: "1722-2/00", descricao: "Fabricação de cartolina e papel-cartão"},
{ codigo: "1731-1/00", descricao: "Fabricação de embalagens de papel"},
{ codigo: "1732-0/00", descricao: "Fabricação de embalagens de cartolina e papel-cartão"},
{ codigo: "1733-8/00", descricao: "Fabricação de chapas e de embalagens de papelão ondulado"},
{ codigo: "1741-9/01", descricao: "Fabricação de formulários contínuos"},
{ codigo: "1741-9/02", descricao: "Fabricação de produtos de papel, cartolina, papel-cartão e papelão ondulado para uso comercial e de escritório"},
{ codigo: "1742-7/01", descricao: "Fabricação de fraldas descartáveis"},
{ codigo: "1742-7/02", descricao: "Fabricação de absorventes higiênicos"},
{ codigo: "1742-7/99", descricao: "Fabricação de produtos de papel para uso doméstico e higiênico-sanitário não especificados anteriormente"},
{ codigo: "1749-4/00", descricao: "Fabricação de produtos de pastas celulósicas, papel, cartolina, papel-cartão e papelão ondulado não especificados anteriormente"},
{ codigo: "1811-3/01", descricao: "Impressão de jornais"},
{ codigo: "1811-3/02", descricao: "Impressão de livros, revistas e outras publicações periódicas"},
{ codigo: "1812-1/00", descricao: "Impressão de material de segurança"},
{ codigo: "1813-0/01", descricao: "Impressão de material para uso publicitário"},
{ codigo: "1813-0/99", descricao: "Impressão de material para outros usos"},
{ codigo: "1821-1/00", descricao: "Serviços de pré-impressão"},
{ codigo: "1822-9/01", descricao: "Serviços de encadernação e plastificação"},
{ codigo: "1822-9/99", descricao: "Serviços de acabamentos gráficos, exceto encadernação e plastificação"},
{ codigo: "1830-0/01", descricao: "Reprodução de som em qualquer suporte"},
{ codigo: "1830-0/02", descricao: "Reprodução de vídeo em qualquer suporte"},
{ codigo: "1830-0/03", descricao: "Reprodução de software em qualquer suporte"},
{ codigo: "1910-1/00", descricao: "Coquerias"},
{ codigo: "1921-7/00", descricao: "Fabricação de produtos do refino de petróleo"},
{ codigo: "1922-5/01", descricao: "Formulação de combustíveis"},
{ codigo: "1922-5/02", descricao: "Rerrefino de óleos lubrificantes"},
{ codigo: "1922-5/99", descricao: "Fabricação de outros produtos derivados do petróleo, exceto produtos do refino"},
{ codigo: "1931-4/00", descricao: "Fabricação de álcool"},
{ codigo: "1932-2/00", descricao: "Fabricação de biocombustíveis, exceto álcool"},
{ codigo: "2011-8/00", descricao: "Fabricação de cloro e álcalis"},
{ codigo: "2012-6/00", descricao: "Fabricação de intermediários para fertilizantes"},
{ codigo: "2013-4/01", descricao: "Fabricação de adubos e fertilizantes organo-minerais"},
{ codigo: "2013-4/02", descricao: "Fabricação de adubos e fertilizantes, exceto organo-minerais"},
{ codigo: "2014-2/00", descricao: "Fabricação de gases industriais"},
{ codigo: "2019-3/01", descricao: "Elaboração de combustíveis nucleares"},
{ codigo: "2019-3/99", descricao: "Fabricação de outros produtos químicos inorgânicos não especificados anteriormente"},
{ codigo: "2021-5/00", descricao: "Fabricação de produtos petroquímicos básicos"},
{ codigo: "2022-3/00", descricao: "Fabricação de intermediários para plastificantes, resinas e fibras"},
{ codigo: "2029-1/00", descricao: "Fabricação de produtos químicos orgânicos não especificados anteriormente"},
{ codigo: "2031-2/00", descricao: "Fabricação de resinas termoplásticas"},
{ codigo: "2032-1/00", descricao: "Fabricação de resinas termofixas"},
{ codigo: "2033-9/00", descricao: "Fabricação de elastômeros"},
{ codigo: "2040-1/00", descricao: "Fabricação de fibras artificiais e sintéticas"},
{ codigo: "2051-7/00", descricao: "Fabricação de defensivos agrícolas"},
{ codigo: "2052-5/00", descricao: "Fabricação de desinfestantes domissanitários"},
{ codigo: "2061-4/00", descricao: "Fabricação de sabões e detergentes sintéticos"},
{ codigo: "2062-2/00", descricao: "Fabricação de produtos de limpeza e polimento"},
{ codigo: "2063-1/00", descricao: "Fabricação de cosméticos, produtos de perfumaria e de higiene pessoal"},
{ codigo: "2071-1/00", descricao: "Fabricação de tintas, vernizes, esmaltes e lacas"},
{ codigo: "2072-0/00", descricao: "Fabricação de tintas de impressão"},
{ codigo: "2073-8/00", descricao: "Fabricação de impermeabilizantes, solventes e produtos afins"},
{ codigo: "2091-6/00", descricao: "Fabricação de adesivos e selantes"},
{ codigo: "2092-4/01", descricao: "Fabricação de pólvoras, explosivos e detonantes"},
{ codigo: "2092-4/02", descricao: "Fabricação de artigos pirotécnicos"},
{ codigo: "2092-4/03", descricao: "Fabricação de fósforos de segurança"},
{ codigo: "2093-2/00", descricao: "Fabricação de aditivos de uso industrial"},
{ codigo: "2094-1/00", descricao: "Fabricação de catalisadores"},
{ codigo: "2099-1/01", descricao: "Fabricação de chapas, filmes, papéis e outros materiais e produtos químicos para fotografia"},
{ codigo: "2099-1/99", descricao: "Fabricação de outros produtos químicos não especificados anteriormente"},
{ codigo: "2110-6/00", descricao: "Fabricação de produtos farmoquímicos"},
{ codigo: "2121-1/01", descricao: "Fabricação de medicamentos alopáticos para uso humano"},
{ codigo: "2121-1/02", descricao: "Fabricação de medicamentos homeopáticos para uso humano"},
{ codigo: "2121-1/03", descricao: "Fabricação de medicamentos fitoterápicos para uso humano"},
{ codigo: "2122-0/00", descricao: "Fabricação de medicamentos para uso veterinário"},
{ codigo: "2123-8/00", descricao: "Fabricação de preparações farmacêuticas"},
{ codigo: "2211-1/00", descricao: "Fabricação de pneumáticos e de câmaras-de-ar"},
{ codigo: "2212-9/00", descricao: "Reforma de pneumáticos usados"},
{ codigo: "2219-6/00", descricao: "Fabricação de artefatos de borracha não especificados anteriormente"},
{ codigo: "2221-8/00", descricao: "Fabricação de laminados planos e tubulares de material plástico"},
{ codigo: "2222-6/00", descricao: "Fabricação de embalagens de material plástico"},
{ codigo: "2223-4/00", descricao: "Fabricação de tubos e acessórios de material plástico para uso na construção"},
{ codigo: "2229-3/01", descricao: "Fabricação de artefatos de material plástico para uso pessoal e doméstico"},
{ codigo: "2229-3/02", descricao: "Fabricação de artefatos de material plástico para usos industriais"},
{ codigo: "2229-3/03", descricao: "Fabricação de artefatos de material plástico para uso na construção, exceto tubos e acessórios"},
{ codigo: "2229-3/99", descricao: "Fabricação de artefatos de material plástico para outros usos não especificados anteriormente"},
{ codigo: "2311-7/00", descricao: "Fabricação de vidro plano e de segurança"},
{ codigo: "2312-5/00", descricao: "Fabricação de embalagens de vidro"},
{ codigo: "2319-2/00", descricao: "Fabricação de artigos de vidro"},
{ codigo: "2320-6/00", descricao: "Fabricação de cimento"},
{ codigo: "2330-3/01", descricao: "Fabricação de estruturas pré-moldadas de concreto armado, em série e sob encomenda"},
{ codigo: "2330-3/02", descricao: "Fabricação de artefatos de cimento para uso na construção"},
{ codigo: "2330-3/03", descricao: "Fabricação de artefatos de fibrocimento para uso na construção"},
{ codigo: "2330-3/04", descricao: "Fabricação de casas pré-moldadas de concreto"},
{ codigo: "2330-3/05", descricao: "Preparação de massa de concreto e argamassa para construção"},
{ codigo: "2330-3/99", descricao: "Fabricação de outros artefatos e produtos de concreto, cimento, fibrocimento, gesso e materiais semelhantes"},
{ codigo: "2341-9/00", descricao: "Fabricação de produtos cerâmicos refratários"},
{ codigo: "2342-7/01", descricao: "Fabricação de azulejos e pisos"},
{ codigo: "2342-7/02", descricao: "Fabricação de artefatos de cerâmica e barro cozido para uso na construção, exceto azulejos e pisos"},
{ codigo: "2349-4/01", descricao: "Fabricação de material sanitário de cerâmica"},
{ codigo: "2349-4/99", descricao: "Fabricação de produtos cerâmicos não refratários não especificados anteriormente"},
{ codigo: "2391-5/01", descricao: "Britamento de pedras, exceto associado à extração"},
{ codigo: "2391-5/02", descricao: "Aparelhamento de pedras para construção, exceto associado à extração"},
{ codigo: "2391-5/03", descricao: "Aparelhamento de placas e execução de trabalhos em mármore, granito, ardósia e outras pedras"},
{ codigo: "2392-3/00", descricao: "Fabricação de cal e gesso"},
{ codigo: "2399-1/01", descricao: "Decoração, lapidação, gravação, vitrificação e outros trabalhos em cerâmica, louça, vidro e cristal"},
{ codigo: "2399-1/02", descricao: "Fabricação de abrasivos"},
{ codigo: "2399-1/99", descricao: "Fabricação de outros produtos de minerais não metálicos não especificados anteriormente"},
{ codigo: "2411-3/00", descricao: "Produção de ferro-gusa"},
{ codigo: "2412-1/00", descricao: "Produção de ferroligas"},
{ codigo: "2421-1/00", descricao: "Produção de semiacabados de aço"},
{ codigo: "2422-9/01", descricao: "Produção de laminados planos de aço ao carbono, revestidos ou não"},
{ codigo: "2422-9/02", descricao: "Produção de laminados planos de aços especiais"},
{ codigo: "2423-7/01", descricao: "Produção de tubos de aço sem costura"},
{ codigo: "2423-7/02", descricao: "Produção de laminados longos de aço, exceto tubos"},
{ codigo: "2424-5/01", descricao: "Produção de arames de aço"},
{ codigo: "2424-5/02", descricao: "Produção de relaminados, trefilados e perfilados de aço, exceto arames"},
{ codigo: "2431-8/00", descricao: "Produção de tubos de aço com costura"},
{ codigo: "2439-3/00", descricao: "Produção de outros tubos de ferro e aço"},
{ codigo: "2441-5/01", descricao: "Produção de alumínio e suas ligas em formas primárias"},
{ codigo: "2441-5/02", descricao: "Produção de laminados de alumínio"},
{ codigo: "2442-3/00", descricao: "Metalurgia dos metais preciosos"},
{ codigo: "2443-1/00", descricao: "Metalurgia do cobre"},
{ codigo: "2449-1/01", descricao: "Produção de zinco em formas primárias"},
{ codigo: "2449-1/02", descricao: "Produção de laminados de zinco"},
{ codigo: "2449-1/03", descricao: "Fabricação de ânodos para galvanoplastia"},
{ codigo: "2449-1/99", descricao: "Metalurgia de outros metais não ferrosos e suas ligas não especificados anteriormente"},
{ codigo: "2451-2/00", descricao: "Fundição de ferro e aço"},
{ codigo: "2452-1/00", descricao: "Fundição de metais não ferrosos e suas ligas"},
{ codigo: "2511-0/00", descricao: "Fabricação de estruturas metálicas"},
{ codigo: "2512-8/00", descricao: "Fabricação de esquadrias de metal"},
{ codigo: "2513-6/00", descricao: "Fabricação de obras de caldeiraria pesada"},
{ codigo: "2521-7/00", descricao: "Fabricação de tanques, reservatórios metálicos e caldeiras para aquecimento central"},
{ codigo: "2522-5/00", descricao: "Fabricação de caldeiras geradoras de vapor, exceto para aquecimento central e para veículos"},
{ codigo: "2531-4/01", descricao: "Produção de forjados de aço"},
{ codigo: "2531-4/02", descricao: "Produção de forjados de metais não ferrosos e suas ligas"},
{ codigo: "2532-2/01", descricao: "Produção de artefatos estampados de metal"},
{ codigo: "2532-2/02", descricao: "Metalurgia do pó"},
{ codigo: "2539-0/01", descricao: "Serviços de usinagem, torneiria e solda"},
{ codigo: "2539-0/02", descricao: "Serviços de tratamento e revestimento em metais"},
{ codigo: "2541-1/00", descricao: "Fabricação de artigos de cutelaria"},
{ codigo: "2542-0/00", descricao: "Fabricação de artigos de serralheria, exceto esquadrias"},
{ codigo: "2543-8/00", descricao: "Fabricação de ferramentas"},
{ codigo: "2550-1/01", descricao: "Fabricação de equipamento bélico pesado, exceto veículos militares de combate"},
{ codigo: "2550-1/02", descricao: "Fabricação de armas de fogo, outras armas e munições"},
{ codigo: "2591-8/00", descricao: "Fabricação de embalagens metálicas"},
{ codigo: "2592-6/01", descricao: "Fabricação de produtos de trefilados de metal padronizados"},
{ codigo: "2592-6/02", descricao: "Fabricação de produtos de trefilados de metal, exceto padronizados"},
{ codigo: "2593-4/00", descricao: "Fabricação de artigos de metal para uso doméstico e pessoal"},
{ codigo: "2599-3/01", descricao: "Serviços de confecção de armações metálicas para a construção"},
{ codigo: "2599-3/02", descricao: "Serviço de corte e dobra de metais"},
{ codigo: "2599-3/99", descricao: "Fabricação de outros produtos de metal não especificados anteriormente"},
{ codigo: "2610-8/00", descricao: "Fabricação de componentes eletrônicos"},
{ codigo: "2621-3/00", descricao: "Fabricação de equipamentos de informática"},
{ codigo: "2622-1/00", descricao: "Fabricação de periféricos para equipamentos de informática"},
{ codigo: "2631-1/00", descricao: "Fabricação de equipamentos transmissores de comunicação, peças e acessórios"},
{ codigo: "2632-9/00", descricao: "Fabricação de aparelhos telefônicos e de outros equipamentos de comunicação, peças e acessórios"},
{ codigo: "2640-0/00", descricao: "Fabricação de aparelhos de recepção, reprodução, gravação e amplificação de áudio e vídeo"},
{ codigo: "2651-5/00", descricao: "Fabricação de aparelhos e equipamentos de medida, teste e controle"},
{ codigo: "2652-3/00", descricao: "Fabricação de cronômetros e relógios"},
{ codigo: "2660-4/00", descricao: "Fabricação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação"},
{ codigo: "2670-1/01", descricao: "Fabricação de equipamentos e instrumentos ópticos, peças e acessórios"},
{ codigo: "2670-1/02", descricao: "Fabricação de aparelhos fotográficos e cinematográficos, peças e acessórios"},
{ codigo: "2680-9/00", descricao: "Fabricação de mídias virgens, magnéticas e ópticas"},
{ codigo: "2710-4/01", descricao: "Fabricação de geradores de corrente contínua e alternada, peças e acessórios"},
{ codigo: "2710-4/02", descricao: "Fabricação de transformadores, indutores, conversores, sincronizadores e semelhantes, peças e acessórios"},
{ codigo: "2710-4/03", descricao: "Fabricação de motores elétricos, peças e acessórios"},
{ codigo: "2721-0/00", descricao: "Fabricação de pilhas, baterias e acumuladores elétricos, exceto para veículos automotores"},
{ codigo: "2722-8/01", descricao: "Fabricação de baterias e acumuladores para veículos automotores"},
{ codigo: "2722-8/02", descricao: "Recondicionamento de baterias e acumuladores para veículos automotores"},
{ codigo: "2731-7/00", descricao: "Fabricação de aparelhos e equipamentos para distribuição e controle de energia elétrica"},
{ codigo: "2732-5/00", descricao: "Fabricação de material elétrico para instalações em circuito de consumo"},
{ codigo: "2733-3/00", descricao: "Fabricação de fios, cabos e condutores elétricos isolados"},
{ codigo: "2740-6/01", descricao: "Fabricação de lâmpadas"},
{ codigo: "2740-6/02", descricao: "Fabricação de luminárias e outros equipamentos de iluminação"},
{ codigo: "2751-1/00", descricao: "Fabricação de fogões, refrigeradores e máquinas de lavar e secar para uso doméstico, peças e acessórios"},
{ codigo: "2759-7/01", descricao: "Fabricação de aparelhos elétricos de uso pessoal, peças e acessórios"},
{ codigo: "2759-7/99", descricao: "Fabricação de outros aparelhos eletrodomésticos não especificados anteriormente, peças e acessórios"},
{ codigo: "2790-2/01", descricao: "Fabricação de eletrodos, contatos e outros artigos de carvão e grafita para uso elétrico, eletroímãs e isoladores"},
{ codigo: "2790-2/02", descricao: "Fabricação de equipamentos para sinalização e alarme"},
{ codigo: "2790-2/99", descricao: "Fabricação de outros equipamentos e aparelhos elétricos não especificados anteriormente"},
{ codigo: "2811-9/00", descricao: "Fabricação de motores e turbinas, peças e acessórios, exceto para aviões e veículos rodoviários"},
{ codigo: "2812-7/00", descricao: "Fabricação de equipamentos hidráulicos e pneumáticos, peças e acessórios, exceto válvulas"},
{ codigo: "2813-5/00", descricao: "Fabricação de válvulas, registros e dispositivos semelhantes, peças e acessórios"},
{ codigo: "2814-3/01", descricao: "Fabricação de compressores para uso industrial, peças e acessórios"},
{ codigo: "2814-3/02", descricao: "Fabricação de compressores para uso não industrial, peças e acessórios"},
{ codigo: "2815-1/01", descricao: "Fabricação de rolamentos para fins industriais"},
{ codigo: "2815-1/02", descricao: "Fabricação de equipamentos de transmissão para fins industriais, exceto rolamentos"},
{ codigo: "2821-6/01", descricao: "Fabricação de fornos industriais, aparelhos e equipamentos não elétricos para instalações térmicas, peças e acessórios"},
{ codigo: "2821-6/02", descricao: "Fabricação de estufas e fornos elétricos para fins industriais, peças e acessórios"},
{ codigo: "2822-4/01", descricao: "Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de pessoas, peças e acessórios"},
{ codigo: "2822-4/02", descricao: "Fabricação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas, peças e acessórios"},
{ codigo: "2823-2/00", descricao: "Fabricação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial, peças e acessórios"},
{ codigo: "2824-1/01", descricao: "Fabricação de aparelhos e equipamentos de ar condicionado para uso industrial"},
{ codigo: "2824-1/02", descricao: "Fabricação de aparelhos e equipamentos de ar condicionado para uso não industrial"},
{ codigo: "2825-9/00", descricao: "Fabricação de máquinas e equipamentos para saneamento básico e ambiental, peças e acessórios"},
{ codigo: "2829-1/01", descricao: "Fabricação de máquinas de escrever, calcular e outros equipamentos não eletrônicos para escritório, peças e acessórios"},
{ codigo: "2829-1/99", descricao: "Fabricação de outras máquinas e equipamentos de uso geral não especificados anteriormente, peças e acessórios"},
{ codigo: "2831-3/00", descricao: "Fabricação de tratores agrícolas, peças e acessórios"},
{ codigo: "2832-1/00", descricao: "Fabricação de equipamentos para irrigação agrícola, peças e acessórios"},
{ codigo: "2833-0/00", descricao: "Fabricação de máquinas e equipamentos para a agricultura e pecuária, peças e acessórios, exceto para irrigação"},
{ codigo: "2840-2/00", descricao: "Fabricação de máquinas-ferramenta, peças e acessórios"},
{ codigo: "2851-8/00", descricao: "Fabricação de máquinas e equipamentos para a prospecção e extração de petróleo, peças e acessórios"},
{ codigo: "2852-6/00", descricao: "Fabricação de outras máquinas e equipamentos para uso na extração mineral, peças e acessórios, exceto na extração de petróleo"},
{ codigo: "2853-4/00", descricao: "Fabricação de tratores, peças e acessórios, exceto agrícolas"},
{ codigo: "2854-2/00", descricao: "Fabricação de máquinas e equipamentos para terraplenagem, pavimentação e construção, peças e acessórios, exceto tratores"},
{ codigo: "2861-5/00", descricao: "Fabricação de máquinas para a indústria metalúrgica, peças e acessórios, exceto máquinas-ferramenta"},
{ codigo: "2862-3/00", descricao: "Fabricação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo, peças e acessórios"},
{ codigo: "2863-1/00", descricao: "Fabricação de máquinas e equipamentos para a indústria têxtil, peças e acessórios"},
{ codigo: "2864-0/00", descricao: "Fabricação de máquinas e equipamentos para as indústrias do vestuário, do couro e de calçados, peças e acessórios"},
{ codigo: "2865-8/00", descricao: "Fabricação de máquinas e equipamentos para as indústrias de celulose, papel e papelão e artefatos, peças e acessórios"},
{ codigo: "2866-6/00", descricao: "Fabricação de máquinas e equipamentos para a indústria do plástico, peças e acessórios"},
{ codigo: "2869-1/00", descricao: "Fabricação de máquinas e equipamentos para uso industrial específico não especificados anteriormente, peças e acessórios"},
{ codigo: "2910-7/01", descricao: "Fabricação de automóveis, camionetas e utilitários"},
{ codigo: "2910-7/02", descricao: "Fabricação de chassis com motor para automóveis, camionetas e utilitários"},
{ codigo: "2910-7/03", descricao: "Fabricação de motores para automóveis, camionetas e utilitários"},
{ codigo: "2920-4/01", descricao: "Fabricação de caminhões e ônibus"},
{ codigo: "2920-4/02", descricao: "Fabricação de motores para caminhões e ônibus"},
{ codigo: "2930-1/01", descricao: "Fabricação de cabines, carrocerias e reboques para caminhões"},
{ codigo: "2930-1/02", descricao: "Fabricação de carrocerias para ônibus"},
{ codigo: "2930-1/03", descricao: "Fabricação de cabines, carrocerias e reboques para outros veículos automotores, exceto caminhões e ônibus"},
{ codigo: "2941-7/00", descricao: "Fabricação de peças e acessórios para o sistema motor de veículos automotores"},
{ codigo: "2942-5/00", descricao: "Fabricação de peças e acessórios para os sistemas de marcha e transmissão de veículos automotores"},
{ codigo: "2943-3/00", descricao: "Fabricação de peças e acessórios para o sistema de freios de veículos automotores"},
{ codigo: "2944-1/00", descricao: "Fabricação de peças e acessórios para o sistema de direção e suspensão de veículos automotores"},
{ codigo: "2945-0/00", descricao: "Fabricação de material elétrico e eletrônico para veículos automotores, exceto baterias"},
{ codigo: "2949-2/01", descricao: "Fabricação de bancos e estofados para veículos automotores"},
{ codigo: "2949-2/99", descricao: "Fabricação de outras peças e acessórios para veículos automotores não especificadas anteriormente"},
{ codigo: "2950-6/00", descricao: "Recondicionamento e recuperação de motores para veículos automotores"},
{ codigo: "3011-3/01", descricao: "Construção de embarcações de grande porte"},
{ codigo: "3011-3/02", descricao: "Construção de embarcações para uso comercial e para usos especiais, exceto de grande porte"},
{ codigo: "3012-1/00", descricao: "Construção de embarcações para esporte e lazer"},
{ codigo: "3031-8/00", descricao: "Fabricação de locomotivas, vagões e outros materiais rodantes"},
{ codigo: "3032-6/00", descricao: "Fabricação de peças e acessórios para veículos ferroviários"},
{ codigo: "3041-5/00", descricao: "Fabricação de aeronaves"},
{ codigo: "3042-3/00", descricao: "Fabricação de turbinas, motores e outros componentes e peças para aeronaves"},
{ codigo: "3050-4/00", descricao: "Fabricação de veículos militares de combate"},
{ codigo: "3091-1/01", descricao: "Fabricação de motocicletas"},
{ codigo: "3091-1/02", descricao: "Fabricação de peças e acessórios para motocicletas"},
{ codigo: "3092-0/00", descricao: "Fabricação de bicicletas e triciclos não motorizados, peças e acessórios"},
{ codigo: "3099-7/00", descricao: "Fabricação de equipamentos de transporte não especificados anteriormente"},
{ codigo: "3101-2/00", descricao: "Fabricação de móveis com predominância de madeira"},
{ codigo: "3102-1/00", descricao: "Fabricação de móveis com predominância de metal"},
{ codigo: "3103-9/00", descricao: "Fabricação de móveis de outros materiais, exceto madeira e metal"},
{ codigo: "3104-7/00", descricao: "Fabricação de colchões"},
{ codigo: "3211-6/01", descricao: "Lapidação de gemas"},
{ codigo: "3211-6/02", descricao: "Fabricação de artefatos de joalheria e ourivesaria"},
{ codigo: "3211-6/03", descricao: "Cunhagem de moedas e medalhas"},
{ codigo: "3212-4/00", descricao: "Fabricação de bijuterias e artefatos semelhantes"},
{ codigo: "3220-5/00", descricao: "Fabricação de instrumentos musicais, peças e acessórios"},
{ codigo: "3230-2/00", descricao: "Fabricação de artefatos para pesca e esporte"},
{ codigo: "3240-0/01", descricao: "Fabricação de jogos eletrônicos"},
{ codigo: "3240-0/02", descricao: "Fabricação de mesas de bilhar, de sinuca e acessórios não associada à locação"},
{ codigo: "3240-0/03", descricao: "Fabricação de mesas de bilhar, de sinuca e acessórios associada à locação"},
{ codigo: "3240-0/99", descricao: "Fabricação de outros brinquedos e jogos recreativos não especificados anteriormente"},
{ codigo: "3250-7/01", descricao: "Fabricação de instrumentos não eletrônicos e utensílios para uso médico, cirúrgico, odontológico e de laboratório"},
{ codigo: "3250-7/02", descricao: "Fabricação de mobiliário para uso médico, cirúrgico, odontológico e de laboratório"},
{ codigo: "3250-7/03", descricao: "Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral sob encomenda"},
{ codigo: "3250-7/04", descricao: "Fabricação de aparelhos e utensílios para correção de defeitos físicos e aparelhos ortopédicos em geral, exceto sob encomenda"},
{ codigo: "3250-7/05", descricao: "Fabricação de materiais para medicina e odontologia"},
{ codigo: "3250-7/06", descricao: "Serviços de prótese dentária"},
{ codigo: "3250-7/07", descricao: "Fabricação de artigos ópticos"},
{ codigo: "3250-7/09", descricao: "Serviço de laboratório óptico"},
{ codigo: "3291-4/00", descricao: "Fabricação de escovas, pincéis e vassouras"},
{ codigo: "3292-2/01", descricao: "Fabricação de roupas de proteção e segurança e resistentes a fogo"},
{ codigo: "3292-2/02", descricao: "Fabricação de equipamentos e acessórios para segurança pessoal e profissional"},
{ codigo: "3299-0/01", descricao: "Fabricação de guarda-chuvas e similares"},
{ codigo: "3299-0/02", descricao: "Fabricação de canetas, lápis e outros artigos para escritório"},
{ codigo: "3299-0/03", descricao: "Fabricação de letras, letreiros e placas de qualquer material, exceto luminosos"},
{ codigo: "3299-0/04", descricao: "Fabricação de painéis e letreiros luminosos"},
{ codigo: "3299-0/05", descricao: "Fabricação de aviamentos para costura"},
{ codigo: "3299-0/06", descricao: "Fabricação de velas, inclusive decorativas"},
{ codigo: "3299-0/99", descricao: "Fabricação de produtos diversos não especificados anteriormente"},
{ codigo: "3311-2/00", descricao: "Manutenção e reparação de tanques, reservatórios metálicos e caldeiras, exceto para veículos"},
{ codigo: "3312-1/02", descricao: "Manutenção e reparação de aparelhos e instrumentos de medida, teste e controle"},
{ codigo: "3312-1/03", descricao: "Manutenção e reparação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação"},
{ codigo: "3312-1/04", descricao: "Manutenção e reparação de equipamentos e instrumentos ópticos"},
{ codigo: "3313-9/01", descricao: "Manutenção e reparação de geradores, transformadores e motores elétricos"},
{ codigo: "3313-9/02", descricao: "Manutenção e reparação de baterias e acumuladores elétricos, exceto para veículos"},
{ codigo: "3313-9/99", descricao: "Manutenção e reparação de máquinas, aparelhos e materiais elétricos não especificados anteriormente"},
{ codigo: "3314-7/01", descricao: "Manutenção e reparação de máquinas motrizes não elétricas"},
{ codigo: "3314-7/02", descricao: "Manutenção e reparação de equipamentos hidráulicos e pneumáticos, exceto válvulas"},
{ codigo: "3314-7/03", descricao: "Manutenção e reparação de válvulas industriais"},
{ codigo: "3314-7/04", descricao: "Manutenção e reparação de compressores"},
{ codigo: "3314-7/05", descricao: "Manutenção e reparação de equipamentos de transmissão para fins industriais"},
{ codigo: "3314-7/06", descricao: "Manutenção e reparação de máquinas, aparelhos e equipamentos para instalações térmicas"},
{ codigo: "3314-7/07", descricao: "Manutenção e reparação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial"},
{ codigo: "3314-7/08", descricao: "Manutenção e reparação de máquinas, equipamentos e aparelhos para transporte e elevação de cargas"},
{ codigo: "3314-7/09", descricao: "Manutenção e reparação de máquinas de escrever, calcular e de outros equipamentos não eletrônicos para escritório"},
{ codigo: "3314-7/10", descricao: "Manutenção e reparação de máquinas e equipamentos para uso geral não especificados anteriormente"},
{ codigo: "3314-7/11", descricao: "Manutenção e reparação de máquinas e equipamentos para agricultura e pecuária"},
{ codigo: "3314-7/12", descricao: "Manutenção e reparação de tratores agrícolas"},
{ codigo: "3314-7/13", descricao: "Manutenção e reparação de máquinas-ferramenta"},
{ codigo: "3314-7/14", descricao: "Manutenção e reparação de máquinas e equipamentos para a prospecção e extração de petróleo"},
{ codigo: "3314-7/15", descricao: "Manutenção e reparação de máquinas e equipamentos para uso na extração mineral, exceto na extração de petróleo"},
{ codigo: "3314-7/16", descricao: "Manutenção e reparação de tratores, exceto agrícolas"},
{ codigo: "3314-7/17", descricao: "Manutenção e reparação de máquinas e equipamentos de terraplenagem, pavimentação e construção, exceto tratores"},
{ codigo: "3314-7/18", descricao: "Manutenção e reparação de máquinas para a indústria metalúrgica, exceto máquinas-ferramenta"},
{ codigo: "3314-7/19", descricao: "Manutenção e reparação de máquinas e equipamentos para as indústrias de alimentos, bebidas e fumo"},
{ codigo: "3314-7/20", descricao: "Manutenção e reparação de máquinas e equipamentos para a indústria têxtil, do vestuário, do couro e calçados"},
{ codigo: "3314-7/21", descricao: "Manutenção e reparação de máquinas e aparelhos para a indústria de celulose, papel e papelão e artefatos"},
{ codigo: "3314-7/22", descricao: "Manutenção e reparação de máquinas e aparelhos para a indústria do plástico"},
{ codigo: "3314-7/99", descricao: "Manutenção e reparação de outras máquinas e equipamentos para usos industriais não especificados anteriormente"},
{ codigo: "3315-5/00", descricao: "Manutenção e reparação de veículos ferroviários"},
{ codigo: "3316-3/01", descricao: "Manutenção e reparação de aeronaves, exceto a manutenção na pista"},
{ codigo: "3316-3/02", descricao: "Manutenção de aeronaves na pista"},
{ codigo: "3317-1/01", descricao: "Manutenção e reparação de embarcações e estruturas flutuantes"},
{ codigo: "3317-1/02", descricao: "Manutenção e reparação de embarcações para esporte e lazer"},
{ codigo: "3319-8/00", descricao: "Manutenção e reparação de equipamentos e produtos não especificados anteriormente"},
{ codigo: "3321-0/00", descricao: "Instalação de máquinas e equipamentos industriais"},
{ codigo: "3329-5/01", descricao: "Serviços de montagem de móveis de qualquer material"},
{ codigo: "3329-5/99", descricao: "Instalação de outros equipamentos não especificados anteriormente"},
{ codigo: "3511-5/01", descricao: "Geração de energia elétrica"},
{ codigo: "3511-5/02", descricao: "Atividades de coordenação e controle da operação da geração e transmissão de energia elétrica"},
{ codigo: "3512-3/00", descricao: "Transmissão de energia elétrica"},
{ codigo: "3513-1/00", descricao: "Comércio atacadista de energia elétrica"},
{ codigo: "3514-0/00", descricao: "Distribuição de energia elétrica"},
{ codigo: "3520-4/01", descricao: "Produção de gás; processamento de gás natural"},
{ codigo: "3520-4/02", descricao: "Distribuição de combustíveis gasosos por redes urbanas"},
{ codigo: "3530-1/00", descricao: "Produção e distribuição de vapor, água quente e ar condicionado"},
{ codigo: "3600-6/01", descricao: "Captação, tratamento e distribuição de água"},
{ codigo: "3600-6/02", descricao: "Distribuição de água por caminhões"},
{ codigo: "3701-1/00", descricao: "Gestão de redes de esgoto"},
{ codigo: "3702-9/00", descricao: "Atividades relacionadas a esgoto, exceto a gestão de redes"},
{ codigo: "3811-4/00", descricao: "Coleta de resíduos não perigosos"},
{ codigo: "3812-2/00", descricao: "Coleta de resíduos perigosos"},
{ codigo: "3821-1/00", descricao: "Tratamento e disposição de resíduos não perigosos"},
{ codigo: "3822-0/00", descricao: "Tratamento e disposição de resíduos perigosos"},
{ codigo: "3831-9/01", descricao: "Recuperação de sucatas de alumínio"},
{ codigo: "3831-9/99", descricao: "Recuperação de materiais metálicos, exceto alumínio"},
{ codigo: "3832-7/00", descricao: "Recuperação de materiais plásticos"},
{ codigo: "3839-4/01", descricao: "Usinas de compostagem"},
{ codigo: "3839-4/99", descricao: "Recuperação de materiais não especificados anteriormente"},
{ codigo: "3900-5/00", descricao: "Descontaminação e outros serviços de gestão de resíduos"},
{ codigo: "4110-7/00", descricao: "Incorporação de empreendimentos imobiliários"},
{ codigo: "4120-4/00", descricao: "Construção de edifícios"},
{ codigo: "4211-1/01", descricao: "Construção de rodovias e ferrovias"},
{ codigo: "4211-1/02", descricao: "Pintura para sinalização em pistas rodoviárias e aeroportos"},
{ codigo: "4212-0/00", descricao: "Construção de obras de arte especiais"},
{ codigo: "4213-8/00", descricao: "Obras de urbanização - ruas, praças e calçadas"},
{ codigo: "4221-9/01", descricao: "Construção de barragens e represas para geração de energia elétrica"},
{ codigo: "4221-9/02", descricao: "Construção de estações e redes de distribuição de energia elétrica"},
{ codigo: "4221-9/03", descricao: "Manutenção de redes de distribuição de energia elétrica"},
{ codigo: "4221-9/04", descricao: "Construção de estações e redes de telecomunicações"},
{ codigo: "4221-9/05", descricao: "Manutenção de estações e redes de telecomunicações"},
{ codigo: "4222-7/01", descricao: "Construção de redes de abastecimento de água, coleta de esgoto e construções correlatas, exceto obras de irrigação"},
{ codigo: "4222-7/02", descricao: "Obras de irrigação"},
{ codigo: "4223-5/00", descricao: "Construção de redes de transportes por dutos, exceto para água e esgoto"},
{ codigo: "4291-0/00", descricao: "Obras portuárias, marítimas e fluviais"},
{ codigo: "4292-8/01", descricao: "Montagem de estruturas metálicas"},
{ codigo: "4292-8/02", descricao: "Obras de montagem industrial"},
{ codigo: "4299-5/01", descricao: "Construção de instalações esportivas e recreativas"},
{ codigo: "4299-5/99", descricao: "Outras obras de engenharia civil não especificadas anteriormente"},
{ codigo: "4311-8/01", descricao: "Demolição de edifícios e outras estruturas"},
{ codigo: "4311-8/02", descricao: "Preparação de canteiro e limpeza de terreno"},
{ codigo: "4312-6/00", descricao: "Perfurações e sondagens"},
{ codigo: "4313-4/00", descricao: "Obras de terraplenagem"},
{ codigo: "4319-3/00", descricao: "Serviços de preparação do terreno não especificados anteriormente"},
{ codigo: "4321-5/00", descricao: "Instalação e manutenção elétrica"},
{ codigo: "4322-3/01", descricao: "Instalações hidráulicas, sanitárias e de gás"},
{ codigo: "4322-3/02", descricao: "Instalação e manutenção de sistemas centrais de ar condicionado, de ventilação e refrigeração"},
{ codigo: "4322-3/03", descricao: "Instalações de sistema de prevenção contra incêndio"},
{ codigo: "4329-1/01", descricao: "Instalação de painéis publicitários"},
{ codigo: "4329-1/02", descricao: "Instalação de equipamentos para orientação à navegação marítima, fluvial e lacustre"},
{ codigo: "4329-1/03", descricao: "Instalação, manutenção e reparação de elevadores, escadas e esteiras rolantes"},
{ codigo: "4329-1/04", descricao: "Montagem e instalação de sistemas e equipamentos de iluminação e sinalização em vias públicas, portos e aeroportos"},
{ codigo: "4329-1/05", descricao: "Tratamentos térmicos, acústicos ou de vibração"},
{ codigo: "4329-1/99", descricao: "Outras obras de instalações em construções não especificadas anteriormente"},
{ codigo: "4330-4/01", descricao: "Impermeabilização em obras de engenharia civil"},
{ codigo: "4330-4/02", descricao: "Instalação de portas, janelas, tetos, divisórias e armários embutidos de qualquer material"},
{ codigo: "4330-4/03", descricao: "Obras de acabamento em gesso e estuque"},
{ codigo: "4330-4/04", descricao: "Serviços de pintura de edifícios em geral"},
{ codigo: "4330-4/05", descricao: "Aplicação de revestimentos e de resinas em interiores e exteriores"},
{ codigo: "4330-4/99", descricao: "Outras obras de acabamento da construção"},
{ codigo: "4391-6/00", descricao: "Obras de fundações"},
{ codigo: "4399-1/01", descricao: "Administração de obras"},
{ codigo: "4399-1/02", descricao: "Montagem e desmontagem de andaimes e outras estruturas temporárias"},
{ codigo: "4399-1/03", descricao: "Obras de alvenaria"},
{ codigo: "4399-1/04", descricao: "Serviços de operação e fornecimento de equipamentos para transporte e elevação de cargas e pessoas para uso em obras"},
{ codigo: "4399-1/05", descricao: "Perfuração e construção de poços de água"},
{ codigo: "4399-1/99", descricao: "Serviços especializados para construção não especificados anteriormente"},
{ codigo: "4511-1/01", descricao: "Comércio a varejo de automóveis, camionetas e utilitários novos"},
{ codigo: "4511-1/02", descricao: "Comércio a varejo de automóveis, camionetas e utilitários usados"},
{ codigo: "4511-1/03", descricao: "Comércio por atacado de automóveis, camionetas e utilitários novos e usados"},
{ codigo: "4511-1/04", descricao: "Comércio por atacado de caminhões novos e usados"},
{ codigo: "4511-1/05", descricao: "Comércio por atacado de reboques e semireboques novos e usados"},
{ codigo: "4511-1/06", descricao: "Comércio por atacado de ônibus e micro-ônibus novos e usados"},
{ codigo: "4512-9/01", descricao: "Representantes comerciais e agentes do comércio de veículos automotores"},
{ codigo: "4512-9/02", descricao: "Comércio sob consignação de veículos automotores"},
{ codigo: "4520-0/01", descricao: "Serviços de manutenção e reparação mecânica de veículos automotores"},
{ codigo: "4520-0/02", descricao: "Serviços de lanternagem ou funilaria e pintura de veículos automotores"},
{ codigo: "4520-0/03", descricao: "Serviços de manutenção e reparação elétrica de veículos automotores"},
{ codigo: "4520-0/04", descricao: "Serviços de alinhamento e balanceamento de veículos automotores"},
{ codigo: "4520-0/05", descricao: "Serviços de lavagem, lubrificação e polimento de veículos automotores"},
{ codigo: "4520-0/06", descricao: "Serviços de borracharia para veículos automotores"},
{ codigo: "4520-0/07", descricao: "Serviços de instalação, manutenção e reparação de acessórios para veículos automotores"},
{ codigo: "4520-0/08", descricao: "Serviços de capotaria"},
{ codigo: "4530-7/01", descricao: "Comércio por atacado de peças e acessórios novos para veículos automotores"},
{ codigo: "4530-7/02", descricao: "Comércio por atacado de pneumáticos e câmaras-de-ar"},
{ codigo: "4530-7/03", descricao: "Comércio a varejo de peças e acessórios novos para veículos automotores"},
{ codigo: "4530-7/04", descricao: "Comércio a varejo de peças e acessórios usados para veículos automotores"},
{ codigo: "4530-7/05", descricao: "Comércio a varejo de pneumáticos e câmaras-de-ar"},
{ codigo: "4530-7/06", descricao: "Representantes comerciais e agentes do comércio de peças e acessórios novos e usados para veículos automotores"},
{ codigo: "4541-2/01", descricao: "Comércio por atacado de motocicletas e motonetas"},
{ codigo: "4541-2/02", descricao: "Comércio por atacado de peças e acessórios para motocicletas e motonetas"},
{ codigo: "4541-2/03", descricao: "Comércio a varejo de motocicletas e motonetas novas"},
{ codigo: "4541-2/04", descricao: "Comércio a varejo de motocicletas e motonetas usadas"},
{ codigo: "4541-2/06", descricao: "Comércio a varejo de peças e acessórios novos para motocicletas e motonetas"},
{ codigo: "4541-2/07", descricao: "Comércio a varejo de peças e acessórios usados para motocicletas e motonetas"},
{ codigo: "4542-1/01", descricao: "Representantes comerciais e agentes do comércio de motocicletas e motonetas, peças e acessórios"},
{ codigo: "4542-1/02", descricao: "Comércio sob consignação de motocicletas e motonetas"},
{ codigo: "4543-9/00", descricao: "Manutenção e reparação de motocicletas e motonetas"},
{ codigo: "4611-7/00", descricao: "Representantes comerciais e agentes do comércio de matérias-primas agrícolas e animais vivos"},
{ codigo: "4612-5/00", descricao: "Representantes comerciais e agentes do comércio de combustíveis, minerais, produtos siderúrgicos e químicos"},
{ codigo: "4613-3/00", descricao: "Representantes comerciais e agentes do comércio de madeira, material de construção e ferragens"},
{ codigo: "4614-1/00", descricao: "Representantes comerciais e agentes do comércio de máquinas, equipamentos, embarcações e aeronaves"},
{ codigo: "4615-0/00", descricao: "Representantes comerciais e agentes do comércio de eletrodomésticos, móveis e artigos de uso doméstico"},
{ codigo: "4616-8/00", descricao: "Representantes comerciais e agentes do comércio de têxteis, vestuário, calçados e artigos de viagem"},
{ codigo: "4617-6/00", descricao: "Representantes comerciais e agentes do comércio de produtos alimentícios, bebidas e fumo"},
{ codigo: "4618-4/01", descricao: "Representantes comerciais e agentes do comércio de medicamentos, cosméticos e produtos de perfumaria"},
{ codigo: "4618-4/02", descricao: "Representantes comerciais e agentes do comércio de instrumentos e materiais odonto-médico-hospitalares"},
{ codigo: "4618-4/03", descricao: "Representantes comerciais e agentes do comércio de jornais, revistas e outras publicações"},
{ codigo: "4618-4/99", descricao: "Outros representantes comerciais e agentes do comércio especializado em produtos não especificados anteriormente"},
{ codigo: "4619-2/00", descricao: "Representantes comerciais e agentes do comércio de mercadorias em geral não especializado"},
{ codigo: "4621-4/00", descricao: "Comércio atacadista de café em grão"},
{ codigo: "4622-2/00", descricao: "Comércio atacadista de soja"},
{ codigo: "4623-1/01", descricao: "Comércio atacadista de animais vivos"},
{ codigo: "4623-1/02", descricao: "Comércio atacadista de couros, lãs, peles e outros subprodutos não comestíveis de origem animal"},
{ codigo: "4623-1/03", descricao: "Comércio atacadista de algodão"},
{ codigo: "4623-1/04", descricao: "Comércio atacadista de fumo em folha não beneficiado"},
{ codigo: "4623-1/05", descricao: "Comércio atacadista de cacau"},
{ codigo: "4623-1/06", descricao: "Comércio atacadista de sementes, flores, plantas e gramas"},
{ codigo: "4623-1/07", descricao: "Comércio atacadista de sisal"},
{ codigo: "4623-1/08", descricao: "Comércio atacadista de matérias-primas agrícolas com atividade de fracionamento e acondicionamento associada"},
{ codigo: "4623-1/09", descricao: "Comércio atacadista de alimentos para animais"},
{ codigo: "4623-1/99", descricao: "Comércio atacadista de matérias-primas agrícolas não especificadas anteriormente"},
{ codigo: "4631-1/00", descricao: "Comércio atacadista de leite e laticínios"},
{ codigo: "4632-0/01", descricao: "Comércio atacadista de cereais e leguminosas beneficiados"},
{ codigo: "4632-0/02", descricao: "Comércio atacadista de farinhas, amidos e féculas"},
{ codigo: "4632-0/03", descricao: "Comércio atacadista de cereais e leguminosas beneficiados, farinhas, amidos e féculas, com atividade de fracionamento e acondicionamento associada"},
{ codigo: "4633-8/01", descricao: "Comércio atacadista de frutas, verduras, raízes, tubérculos, hortaliças e legumes frescos"},
{ codigo: "4633-8/02", descricao: "Comércio atacadista de aves vivas e ovos"},
{ codigo: "4633-8/03", descricao: "Comércio atacadista de coelhos e outros pequenos animais vivos para alimentação"},
{ codigo: "4634-6/01", descricao: "Comércio atacadista de carnes bovinas e suínas e derivados"},
{ codigo: "4634-6/02", descricao: "Comércio atacadista de aves abatidas e derivados"},
{ codigo: "4634-6/03", descricao: "Comércio atacadista de pescados e frutos do mar"},
{ codigo: "4634-6/99", descricao: "Comércio atacadista de carnes e derivados de outros animais"},
{ codigo: "4635-4/01", descricao: "Comércio atacadista de água mineral"},
{ codigo: "4635-4/02", descricao: "Comércio atacadista de cerveja, chope e refrigerante"},
{ codigo: "4635-4/03", descricao: "Comércio atacadista de bebidas com atividade de fracionamento e acondicionamento associada"},
{ codigo: "4635-4/99", descricao: "Comércio atacadista de bebidas não especificadas anteriormente"},
{ codigo: "4636-2/01", descricao: "Comércio atacadista de fumo beneficiado"},
{ codigo: "4636-2/02", descricao: "Comércio atacadista de cigarros, cigarrilhas e charutos"},
{ codigo: "4637-1/01", descricao: "Comércio atacadista de café torrado, moído e solúvel"},
{ codigo: "4637-1/02", descricao: "Comércio atacadista de açúcar"},
{ codigo: "4637-1/03", descricao: "Comércio atacadista de óleos e gorduras"},
{ codigo: "4637-1/04", descricao: "Comércio atacadista de pães, bolos, biscoitos e similares"},
{ codigo: "4637-1/05", descricao: "Comércio atacadista de massas alimentícias"},
{ codigo: "4637-1/06", descricao: "Comércio atacadista de sorvetes"},
{ codigo: "4637-1/07", descricao: "Comércio atacadista de chocolates, confeitos, balas, bombons e semelhantes"},
{ codigo: "4637-1/99", descricao: "Comércio atacadista especializado em outros produtos alimentícios não especificados anteriormente"},
{ codigo: "4639-7/01", descricao: "Comércio atacadista de produtos alimentícios em geral"},
{ codigo: "4639-7/02", descricao: "Comércio atacadista de produtos alimentícios em geral, com atividade de fracionamento e acondicionamento associada"},
{ codigo: "4641-9/01", descricao: "Comércio atacadista de tecidos"},
{ codigo: "4641-9/02", descricao: "Comércio atacadista de artigos de cama, mesa e banho"},
{ codigo: "4641-9/03", descricao: "Comércio atacadista de artigos de armarinho"},
{ codigo: "4642-7/01", descricao: "Comércio atacadista de artigos do vestuário e acessórios, exceto profissionais e de segurança"},
{ codigo: "4642-7/02", descricao: "Comércio atacadista de roupas e acessórios para uso profissional e de segurança do trabalho"},
{ codigo: "4643-5/01", descricao: "Comércio atacadista de calçados"},
{ codigo: "4643-5/02", descricao: "Comércio atacadista de bolsas, malas e artigos de viagem"},
{ codigo: "4644-3/01", descricao: "Comércio atacadista de medicamentos e drogas de uso humano"},
{ codigo: "4644-3/02", descricao: "Comércio atacadista de medicamentos e drogas de uso veterinário"},
{ codigo: "4645-1/01", descricao: "Comércio atacadista de instrumentos e materiais para uso médico, cirúrgico, hospitalar e de laboratórios"},
{ codigo: "4645-1/02", descricao: "Comércio atacadista de próteses e artigos de ortopedia"},
{ codigo: "4645-1/03", descricao: "Comércio atacadista de produtos odontológicos"},
{ codigo: "4646-0/01", descricao: "Comércio atacadista de cosméticos e produtos de perfumaria"},
{ codigo: "4646-0/02", descricao: "Comércio atacadista de produtos de higiene pessoal"},
{ codigo: "4647-8/01", descricao: "Comércio atacadista de artigos de escritório e de papelaria"},
{ codigo: "4647-8/02", descricao: "Comércio atacadista de livros, jornais e outras publicações"},
{ codigo: "4649-4/01", descricao: "Comércio atacadista de equipamentos elétricos de uso pessoal e doméstico"},
{ codigo: "4649-4/02", descricao: "Comércio atacadista de aparelhos eletrônicos de uso pessoal e doméstico"},
{ codigo: "4649-4/03", descricao: "Comércio atacadista de bicicletas, triciclos e outros veículos recreativos"},
{ codigo: "4649-4/04", descricao: "Comércio atacadista de móveis e artigos de colchoaria"},
{ codigo: "4649-4/05", descricao: "Comércio atacadista de artigos de tapeçaria; persianas e cortinas"},
{ codigo: "4649-4/06", descricao: "Comércio atacadista de lustres, luminárias e abajures"},
{ codigo: "4649-4/07", descricao: "Comércio atacadista de filmes, CDs, DVDs, fitas e discos"},
{ codigo: "4649-4/08", descricao: "Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar"},
{ codigo: "4649-4/09", descricao: "Comércio atacadista de produtos de higiene, limpeza e conservação domiciliar, com atividade de fracionamento e acondicionamento associada"},
{ codigo: "4649-4/10", descricao: "Comércio atacadista de jóias, relógios e bijuterias, inclusive pedras preciosas e semipreciosas lapidadas"},
{ codigo: "4649-4/99", descricao: "Comércio atacadista de outros equipamentos e artigos de uso pessoal e doméstico não especificados anteriormente"},
{ codigo: "4651-6/01", descricao: "Comércio atacadista de equipamentos de informática"},
{ codigo: "4651-6/02", descricao: "Comércio atacadista de suprimentos para informática"},
{ codigo: "4652-4/00", descricao: "Comércio atacadista de componentes eletrônicos e equipamentos de telefonia e comunicação"},
{ codigo: "4661-3/00", descricao: "Comércio atacadista de máquinas, aparelhos e equipamentos para uso agropecuário; partes e peças"},
{ codigo: "4662-1/00", descricao: "Comércio atacadista de máquinas, equipamentos para terraplenagem, mineração e construção; partes e peças"},
{ codigo: "4663-0/00", descricao: "Comércio atacadista de máquinas e equipamentos para uso industrial; partes e peças"},
{ codigo: "4664-8/00", descricao: "Comércio atacadista de máquinas, aparelhos e equipamentos para uso odonto-médico-hospitalar; partes e peças"},
{ codigo: "4665-6/00", descricao: "Comércio atacadista de máquinas e equipamentos para uso comercial; partes e peças"},
{ codigo: "4669-9/01", descricao: "Comércio atacadista de bombas e compressores; partes e peças"},
{ codigo: "4669-9/99", descricao: "Comércio atacadista de outras máquinas e equipamentos não especificados anteriormente; partes e peças"},
{ codigo: "4671-1/00", descricao: "Comércio atacadista de madeira e produtos derivados"},
{ codigo: "4672-9/00", descricao: "Comércio atacadista de ferragens e ferramentas"},
{ codigo: "4673-7/00", descricao: "Comércio atacadista de material elétrico"},
{ codigo: "4674-5/00", descricao: "Comércio atacadista de cimento"},
{ codigo: "4679-6/01", descricao: "Comércio atacadista de tintas, vernizes e similares"},
{ codigo: "4679-6/02", descricao: "Comércio atacadista de mármores e granitos"},
{ codigo: "4679-6/03", descricao: "Comércio atacadista de vidros, espelhos e vitrais"},
{ codigo: "4679-6/04", descricao: "Comércio atacadista especializado de materiais de construção não especificados anteriormente"},
{ codigo: "4679-6/99", descricao: "Comércio atacadista de materiais de construção em geral"},
{ codigo: "4681-8/01", descricao: "Comércio atacadista de álcool carburante, biodiesel, gasolina e demais derivados de petróleo, exceto lubrificantes, não realizado por transportador retalhista (TRR)"},
{ codigo: "4681-8/02", descricao: "Comércio atacadista de combustíveis realizado por transportador retalhista (TRR)"},
{ codigo: "4681-8/03", descricao: "Comércio atacadista de combustíveis de origem vegetal, exceto álcool carburante"},
{ codigo: "4681-8/04", descricao: "Comércio atacadista de combustíveis de origem mineral em bruto"},
{ codigo: "4681-8/05", descricao: "Comércio atacadista de lubrificantes"},
{ codigo: "4682-6/00", descricao: "Comércio atacadista de gás liquefeito de petróleo (GLP)"},
{ codigo: "4683-4/00", descricao: "Comércio atacadista de defensivos agrícolas, adubos, fertilizantes e corretivos do solo"},
{ codigo: "4684-2/01", descricao: "Comércio atacadista de resinas e elastômeros"},
{ codigo: "4684-2/02", descricao: "Comércio atacadista de solventes"},
{ codigo: "4684-2/99", descricao: "Comércio atacadista de outros produtos químicos e petroquímicos não especificados anteriormente"},
{ codigo: "4685-1/00", descricao: "Comércio atacadista de produtos siderúrgicos e metalúrgicos, exceto para construção"},
{ codigo: "4686-9/01", descricao: "Comércio atacadista de papel e papelão em bruto"},
{ codigo: "4686-9/02", descricao: "Comércio atacadista de embalagens"},
{ codigo: "4687-7/01", descricao: "Comércio atacadista de resíduos de papel e papelão"},
{ codigo: "4687-7/02", descricao: "Comércio atacadista de resíduos e sucatas não metálicos, exceto de papel e papelão"},
{ codigo: "4687-7/03", descricao: "Comércio atacadista de resíduos e sucatas metálicos"},
{ codigo: "4689-3/01", descricao: "Comércio atacadista de produtos da extração mineral, exceto combustíveis"},
{ codigo: "4689-3/02", descricao: "Comércio atacadista de fios e fibras beneficiados"},
{ codigo: "4689-3/99", descricao: "Comércio atacadista especializado em outros produtos intermediários não especificados anteriormente"},
{ codigo: "4691-5/00", descricao: "Comércio atacadista de mercadorias em geral, com predominância de produtos alimentícios"},
{ codigo: "4692-3/00", descricao: "Comércio atacadista de mercadorias em geral, com predominância de insumos agropecuários"},
{ codigo: "4693-1/00", descricao: "Comércio atacadista de mercadorias em geral, sem predominância de alimentos ou de insumos agropecuários"},
{ codigo: "4711-3/01", descricao: "Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - hipermercados"},
{ codigo: "4711-3/02", descricao: "Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - supermercados"},
{ codigo: "4712-1/00", descricao: "Comércio varejista de mercadorias em geral, com predominância de produtos alimentícios - minimercados, mercearias e armazéns"},
{ codigo: "4713-0/02", descricao: "Lojas de variedades, exceto lojas de departamentos ou magazines"},
{ codigo: "4713-0/04", descricao: "Lojas de departamentos ou magazines, exceto lojas francas (Duty free)"},
{ codigo: "4713-0/05", descricao: "Lojas francas (Duty Free) de aeroportos, portos e em fronteiras terrestres"},
{ codigo: "4721-1/02", descricao: "Padaria e confeitaria com predominância de revenda"},
{ codigo: "4721-1/03", descricao: "Comércio varejista de laticínios e frios"},
{ codigo: "4721-1/04", descricao: "Comércio varejista de doces, balas, bombons e semelhantes"},
{ codigo: "4722-9/01", descricao: "Comércio varejista de carnes - açougues"},
{ codigo: "4722-9/02", descricao: "Peixaria"},
{ codigo: "4723-7/00", descricao: "Comércio varejista de bebidas"},
{ codigo: "4724-5/00", descricao: "Comércio varejista de hortifrutigranjeiros"},
{ codigo: "4729-6/01", descricao: "Tabacaria"},
{ codigo: "4729-6/02", descricao: "Comércio varejista de mercadorias em lojas de conveniência"},
{ codigo: "4729-6/99", descricao: "Comércio varejista de produtos alimentícios em geral ou especializado em produtos alimentícios não especificados anteriormente"},
{ codigo: "4731-8/00", descricao: "Comércio varejista de combustíveis para veículos automotores"},
{ codigo: "4732-6/00", descricao: "Comércio varejista de lubrificantes"},
{ codigo: "4741-5/00", descricao: "Comércio varejista de tintas e materiais para pintura"},
{ codigo: "4742-3/00", descricao: "Comércio varejista de material elétrico"},
{ codigo: "4743-1/00", descricao: "Comércio varejista de vidros"},
{ codigo: "4744-0/01", descricao: "Comércio varejista de ferragens e ferramentas"},
{ codigo: "4744-0/02", descricao: "Comércio varejista de madeira e artefatos"},
{ codigo: "4744-0/03", descricao: "Comércio varejista de materiais hidráulicos"},
{ codigo: "4744-0/04", descricao: "Comércio varejista de cal, areia, pedra britada, tijolos e telhas"},
{ codigo: "4744-0/05", descricao: "Comércio varejista de materiais de construção não especificados anteriormente"},
{ codigo: "4744-0/06", descricao: "Comércio varejista de pedras para revestimento"},
{ codigo: "4744-0/99", descricao: "Comércio varejista de materiais de construção em geral"},
{ codigo: "4751-2/01", descricao: "Comércio varejista especializado de equipamentos e suprimentos de informática"},
{ codigo: "4751-2/02", descricao: "Recarga de cartuchos para equipamentos de informática"},
{ codigo: "4752-1/00", descricao: "Comércio varejista especializado de equipamentos de telefonia e comunicação"},
{ codigo: "4753-9/00", descricao: "Comércio varejista especializado de eletrodomésticos e equipamentos de áudio e vídeo"},
{ codigo: "4754-7/01", descricao: "Comércio varejista de móveis"},
{ codigo: "4754-7/02", descricao: "Comércio varejista de artigos de colchoaria"},
{ codigo: "4754-7/03", descricao: "Comércio varejista de artigos de iluminação"},
{ codigo: "4755-5/01", descricao: "Comércio varejista de tecidos"},
{ codigo: "4755-5/02", descricao: "Comercio varejista de artigos de armarinho"},
{ codigo: "4755-5/03", descricao: "Comercio varejista de artigos de cama, mesa e banho"},
{ codigo: "4756-3/00", descricao: "Comércio varejista especializado de instrumentos musicais e acessórios"},
{ codigo: "4757-1/00", descricao: "Comércio varejista especializado de peças e acessórios para aparelhos eletroeletrônicos para uso doméstico, exceto informática e comunicação"},
{ codigo: "4759-8/01", descricao: "Comércio varejista de artigos de tapeçaria, cortinas e persianas"},
{ codigo: "4759-8/99", descricao: "Comércio varejista de outros artigos de uso doméstico não especificados anteriormente"},
{ codigo: "4761-0/01", descricao: "Comércio varejista de livros"},
{ codigo: "4761-0/02", descricao: "Comércio varejista de jornais e revistas"},
{ codigo: "4761-0/03", descricao: "Comércio varejista de artigos de papelaria"},
{ codigo: "4762-8/00", descricao: "Comércio varejista de discos, CDs, DVDs e fitas"},
{ codigo: "4763-6/01", descricao: "Comércio varejista de brinquedos e artigos recreativos"},
{ codigo: "4763-6/02", descricao: "Comércio varejista de artigos esportivos"},
{ codigo: "4763-6/03", descricao: "Comércio varejista de bicicletas e triciclos; peças e acessórios"},
{ codigo: "4763-6/04", descricao: "Comércio varejista de artigos de caça, pesca e camping"},
{ codigo: "4763-6/05", descricao: "Comércio varejista de embarcações e outros veículos recreativos; peças e acessórios"},
{ codigo: "4771-7/01", descricao: "Comércio varejista de produtos farmacêuticos, sem manipulação de fórmulas"},
{ codigo: "4771-7/02", descricao: "Comércio varejista de produtos farmacêuticos, com manipulação de fórmulas"},
{ codigo: "4771-7/03", descricao: "Comércio varejista de produtos farmacêuticos homeopáticos"},
{ codigo: "4771-7/04", descricao: "Comércio varejista de medicamentos veterinários"},
{ codigo: "4772-5/00", descricao: "Comércio varejista de cosméticos, produtos de perfumaria e de higiene pessoal"},
{ codigo: "4773-3/00", descricao: "Comércio varejista de artigos médicos e ortopédicos"},
{ codigo: "4774-1/00", descricao: "Comércio varejista de artigos de óptica"},
{ codigo: "4781-4/00", descricao: "Comércio varejista de artigos do vestuário e acessórios"},
{ codigo: "4782-2/01", descricao: "Comércio varejista de calçados"},
{ codigo: "4782-2/02", descricao: "Comércio varejista de artigos de viagem"},
{ codigo: "4783-1/01", descricao: "Comércio varejista de artigos de joalheria"},
{ codigo: "4783-1/02", descricao: "Comércio varejista de artigos de relojoaria"},
{ codigo: "4784-9/00", descricao: "Comércio varejista de gás liqüefeito de petróleo (GLP)"},
{ codigo: "4785-7/01", descricao: "Comércio varejista de antiguidades"},
{ codigo: "4785-7/99", descricao: "Comércio varejista de outros artigos usados"},
{ codigo: "4789-0/01", descricao: "Comércio varejista de suvenires, bijuterias e artesanatos"},
{ codigo: "4789-0/02", descricao: "Comércio varejista de plantas e flores naturais"},
{ codigo: "4789-0/03", descricao: "Comércio varejista de objetos de arte"},
{ codigo: "4789-0/04", descricao: "Comércio varejista de animais vivos e de artigos e alimentos para animais de estimação"},
{ codigo: "4789-0/05", descricao: "Comércio varejista de produtos saneantes domissanitários"},
{ codigo: "4789-0/06", descricao: "Comércio varejista de fogos de artifício e artigos pirotécnicos"},
{ codigo: "4789-0/07", descricao: "Comércio varejista de equipamentos para escritório"},
{ codigo: "4789-0/08", descricao: "Comércio varejista de artigos fotográficos e para filmagem"},
{ codigo: "4789-0/09", descricao: "Comércio varejista de armas e munições"},
{ codigo: "4789-0/99", descricao: "Comércio varejista de outros produtos não especificados anteriormente"},
{ codigo: "4911-6/00", descricao: "Transporte ferroviário de carga"},
{ codigo: "4912-4/01", descricao: "Transporte ferroviário de passageiros intermunicipal e interestadual"},
{ codigo: "4912-4/02", descricao: "Transporte ferroviário de passageiros municipal e em região metropolitana"},
{ codigo: "4912-4/03", descricao: "Transporte metroviário"},
{ codigo: "4921-3/01", descricao: "Transporte rodoviário coletivo de passageiros, com itinerário fixo, municipal"},
{ codigo: "4921-3/02", descricao: "Transporte rodoviário coletivo de passageiros, com itinerário fixo, intermunicipal em região metropolitana"},
{ codigo: "4922-1/01", descricao: "Transporte rodoviário coletivo de passageiros, com itinerário fixo, intermunicipal, exceto em região metropolitana"},
{ codigo: "4922-1/02", descricao: "Transporte rodoviário coletivo de passageiros, com itinerário fixo, interestadual"},
{ codigo: "4922-1/03", descricao: "Transporte rodoviário coletivo de passageiros, com itinerário fixo, internacional"},
{ codigo: "4923-0/01", descricao: "Serviço de táxi"},
{ codigo: "4923-0/02", descricao: "Serviço de transporte de passageiros - locação de automóveis com motorista"},
{ codigo: "4924-8/00", descricao: "Transporte escolar"},
{ codigo: "4929-9/01", descricao: "Transporte rodoviário coletivo de passageiros, sob regime de fretamento, municipal"},
{ codigo: "4929-9/02", descricao: "Transporte rodoviário coletivo de passageiros, sob regime de fretamento, intermunicipal, interestadual e internacional"},
{ codigo: "4929-9/03", descricao: "Organização de excursões em veículos rodoviários próprios, municipal"},
{ codigo: "4929-9/04", descricao: "Organização de excursões em veículos rodoviários próprios, intermunicipal, interestadual e internacional"},
{ codigo: "4929-9/99", descricao: "Outros transportes rodoviários de passageiros não especificados anteriormente"},
{ codigo: "4930-2/01", descricao: "Transporte rodoviário de carga, exceto produtos perigosos e mudanças, municipal"},
{ codigo: "4930-2/02", descricao: "Transporte rodoviário de carga, exceto produtos perigosos e mudanças, intermunicipal, interestadual e internacional"},
{ codigo: "4930-2/03", descricao: "Transporte rodoviário de produtos perigosos"},
{ codigo: "4930-2/04", descricao: "Transporte rodoviário de mudanças"},
{ codigo: "4940-0/00", descricao: "Transporte dutoviário"},
{ codigo: "4950-7/00", descricao: "Trens turísticos, teleféricos e similares"},
{ codigo: "5011-4/01", descricao: "Transporte marítimo de cabotagem - Carga"},
{ codigo: "5011-4/02", descricao: "Transporte marítimo de cabotagem - Passageiros"},
{ codigo: "5012-2/01", descricao: "Transporte marítimo de longo curso - Carga"},
{ codigo: "5012-2/02", descricao: "Transporte marítimo de longo curso - Passageiros"},
{ codigo: "5021-1/01", descricao: "Transporte por navegação interior de carga, municipal, exceto travessia"},
{ codigo: "5021-1/02", descricao: "Transporte por navegação interior de carga, intermunicipal, interestadual e internacional, exceto travessia"},
{ codigo: "5022-0/01", descricao: "Transporte por navegação interior de passageiros em linhas regulares, municipal, exceto travessia"},
{ codigo: "5022-0/02", descricao: "Transporte por navegação interior de passageiros em linhas regulares, intermunicipal, interestadual e internacional, exceto travessia"},
{ codigo: "5030-1/01", descricao: "Navegação de apoio marítimo"},
{ codigo: "5030-1/02", descricao: "Navegação de apoio portuário"},
{ codigo: "5030-1/03", descricao: "Serviço de rebocadores e empurradores"},
{ codigo: "5091-2/01", descricao: "Transporte por navegação de travessia, municipal"},
{ codigo: "5091-2/02", descricao: "Transporte por navegação de travessia, intermunicipal, interestadual e internacional"},
{ codigo: "5099-8/01", descricao: "Transporte aquaviário para passeios turísticos"},
{ codigo: "5099-8/99", descricao: "Outros transportes aquaviários não especificados anteriormente"},
{ codigo: "5111-1/00", descricao: "Transporte aéreo de passageiros regular"},
{ codigo: "5112-9/01", descricao: "Serviço de táxi aéreo e locação de aeronaves com tripulação"},
{ codigo: "5112-9/99", descricao: "Outros serviços de transporte aéreo de passageiros não regular"},
{ codigo: "5120-0/00", descricao: "Transporte aéreo de carga"},
{ codigo: "5130-7/00", descricao: "Transporte espacial"},
{ codigo: "5211-7/01", descricao: "Armazéns gerais - emissão de warrant"},
{ codigo: "5211-7/02", descricao: "Guarda-móveis"},
{ codigo: "5211-7/99", descricao: "Depósitos de mercadorias para terceiros, exceto armazéns gerais e guarda-móveis"},
{ codigo: "5212-5/00", descricao: "Carga e descarga"},
{ codigo: "5221-4/00", descricao: "Concessionárias de rodovias, pontes, túneis e serviços relacionados"},
{ codigo: "5222-2/00", descricao: "Terminais rodoviários e ferroviários"},
{ codigo: "5223-1/00", descricao: "Estacionamento de veículos"},
{ codigo: "5229-0/01", descricao: "Serviços de apoio ao transporte por táxi, inclusive centrais de chamada"},
{ codigo: "5229-0/02", descricao: "Serviços de reboque de veículos"},
{ codigo: "5229-0/99", descricao: "Outras atividades auxiliares dos transportes terrestres não especificadas anteriormente"},
{ codigo: "5231-1/01", descricao: "Administração da infraestrutura portuária"},
{ codigo: "5231-1/02", descricao: "Atividades do Operador Portuário"},
{ codigo: "5231-1/03", descricao: "Gestão de terminais aquaviários"},
{ codigo: "5232-0/00", descricao: "Atividades de agenciamento marítimo"},
{ codigo: "5239-7/01", descricao: "Serviços de praticagem"},
{ codigo: "5239-7/99", descricao: "Atividades auxiliares dos transportes aquaviários não especificadas anteriormente"},
{ codigo: "5240-1/01", descricao: "Operação dos aeroportos e campos de aterrissagem"},
{ codigo: "5240-1/99", descricao: "Atividades auxiliares dos transportes aéreos, exceto operação dos aeroportos e campos de aterrissagem"},
{ codigo: "5250-8/01", descricao: "Comissaria de despachos"},
{ codigo: "5250-8/02", descricao: "Atividades de despachantes aduaneiros"},
{ codigo: "5250-8/03", descricao: "Agenciamento de cargas, exceto para o transporte marítimo"},
{ codigo: "5250-8/04", descricao: "Organização logística do transporte de carga"},
{ codigo: "5250-8/05", descricao: "Operador de transporte multimodal - OTM"},
{ codigo: "5310-5/01", descricao: "Atividades do Correio Nacional"},
{ codigo: "5310-5/02", descricao: "Atividades de franqueadas e permissionárias do Correio Nacional"},
{ codigo: "5320-2/01", descricao: "Serviços de malote não realizados pelo Correio Nacional"},
{ codigo: "5320-2/02", descricao: "Serviços de entrega rápida"},
{ codigo: "5510-8/01", descricao: "Hotéis"},
{ codigo: "5510-8/02", descricao: "Apart-hotéis"},
{ codigo: "5510-8/03", descricao: "Motéis"},
{ codigo: "5590-6/01", descricao: "Albergues, exceto assistenciais"},
{ codigo: "5590-6/02", descricao: "Campings"},
{ codigo: "5590-6/03", descricao: "Pensões (alojamento)"},
{ codigo: "5590-6/99", descricao: "Outros alojamentos não especificados anteriormente"},
{ codigo: "5611-2/01", descricao: "Restaurantes e similares"},
{ codigo: "5611-2/03", descricao: "Lanchonetes, casas de chá, de sucos e similares"},
{ codigo: "5611-2/04", descricao: "Bares e outros estabelecimentos especializados em servir bebidas, sem entretenimento"},
{ codigo: "5611-2/05", descricao: "Bares e outros estabelecimentos especializados em servir bebidas, com entretenimento"},
{ codigo: "5612-1/00", descricao: "Serviços ambulantes de alimentação"},
{ codigo: "5620-1/01", descricao: "Fornecimento de alimentos preparados preponderantemente para empresas"},
{ codigo: "5620-1/02", descricao: "Serviços de alimentação para eventos e recepções - bufê"},
{ codigo: "5620-1/03", descricao: "Cantinas - serviços de alimentação privativos"},
{ codigo: "5620-1/04", descricao: "Fornecimento de alimentos preparados preponderantemente para consumo domiciliar"},
{ codigo: "5811-5/00", descricao: "Edição de livros"},
{ codigo: "5812-3/01", descricao: "Edição de jornais diários"},
{ codigo: "5812-3/02", descricao: "Edição de jornais não diários"},
{ codigo: "5813-1/00", descricao: "Edição de revistas"},
{ codigo: "5819-1/00", descricao: "Edição de cadastros, listas e outros produtos gráficos"},
{ codigo: "5821-2/00", descricao: "Edição integrada à impressão de livros"},
{ codigo: "5822-1/01", descricao: "Edição integrada à impressão de jornais diários"},
{ codigo: "5822-1/02", descricao: "Edição integrada à impressão de jornais não diários"},
{ codigo: "5823-9/00", descricao: "Edição integrada à impressão de revistas"},
{ codigo: "5829-8/00", descricao: "Edição integrada à impressão de cadastros, listas e outros produtos gráficos"},
{ codigo: "5911-1/01", descricao: "Estúdios cinematográficos"},
{ codigo: "5911-1/02", descricao: "Produção de filmes para publicidade"},
{ codigo: "5911-1/99", descricao: "Atividades de produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente"},
{ codigo: "5912-0/01", descricao: "Serviços de dublagem"},
{ codigo: "5912-0/02", descricao: "Serviços de mixagem sonora em produção audiovisual"},
{ codigo: "5912-0/99", descricao: "Atividades de pós-produção cinematográfica, de vídeos e de programas de televisão não especificadas anteriormente"},
{ codigo: "5913-8/00", descricao: "Distribuição cinematográfica, de vídeo e de programas de televisão"},
{ codigo: "5914-6/00", descricao: "Atividades de exibição cinematográfica"},
{ codigo: "5920-1/00", descricao: "Atividades de gravação de som e de edição de música"},
{ codigo: "6010-1/00", descricao: "Atividades de rádio"},
{ codigo: "6021-7/00", descricao: "Atividades de televisão aberta"},
{ codigo: "6022-5/01", descricao: "Programadoras"},
{ codigo: "6022-5/02", descricao: "Atividades relacionadas à televisão por assinatura, exceto programadoras"},
{ codigo: "6110-8/01", descricao: "Serviços de telefonia fixa comutada - STFC"},
{ codigo: "6110-8/02", descricao: "Serviços de redes de transporte de telecomunicações - SRTT"},
{ codigo: "6110-8/03", descricao: "Serviços de comunicação multimídia - SCM"},
{ codigo: "6110-8/99", descricao: "Serviços de telecomunicações por fio não especificados anteriormente"},
{ codigo: "6120-5/01", descricao: "Telefonia móvel celular"},
{ codigo: "6120-5/02", descricao: "Serviço móvel especializado - SME"},
{ codigo: "6120-5/99", descricao: "Serviços de telecomunicações sem fio não especificados anteriormente"},
{ codigo: "6130-2/00", descricao: "Telecomunicações por satélite"},
{ codigo: "6141-8/00", descricao: "Operadoras de televisão por assinatura por cabo"},
{ codigo: "6142-6/00", descricao: "Operadoras de televisão por assinatura por micro-ondas"},
{ codigo: "6143-4/00", descricao: "Operadoras de televisão por assinatura por satélite"},
{ codigo: "6190-6/01", descricao: "Provedores de acesso às redes de comunicações"},
{ codigo: "6190-6/02", descricao: "Provedores de voz sobre protocolo Internet - VOIP"},
{ codigo: "6190-6/99", descricao: "Outras atividades de telecomunicações não especificadas anteriormente"},
{ codigo: "6201-5/01", descricao: "Desenvolvimento de programas de computador sob encomenda"},
{ codigo: "6201-5/02", descricao: "Web desing"},
{ codigo: "6202-3/00", descricao: "Desenvolvimento e licenciamento de programas de computador customizáveis"},
{ codigo: "6203-1/00", descricao: "Desenvolvimento e licenciamento de programas de computador não customizáveis"},
{ codigo: "6204-0/00", descricao: "Consultoria em tecnologia da informação"},
{ codigo: "6209-1/00", descricao: "Suporte técnico, manutenção e outros serviços em tecnologia da informação"},
{ codigo: "6311-9/00", descricao: "Tratamento de dados, provedores de serviços de aplicação e serviços de hospedagem na Internet"},
{ codigo: "6319-4/00", descricao: "Portais, provedores de conteúdo e outros serviços de informação na Internet"},
{ codigo: "6391-7/00", descricao: "Agências de notícias"},
{ codigo: "6399-2/00", descricao: "Outras atividades de prestação de serviços de informação não especificadas anteriormente"},
{ codigo: "6410-7/00", descricao: "Banco Central"},
{ codigo: "6421-2/00", descricao: "Bancos comerciais"},
{ codigo: "6422-1/00", descricao: "Bancos múltiplos, com carteira comercial"},
{ codigo: "6423-9/00", descricao: "Caixas econômicas"},
{ codigo: "6424-7/01", descricao: "Bancos cooperativos"},
{ codigo: "6424-7/02", descricao: "Cooperativas centrais de crédito"},
{ codigo: "6424-7/03", descricao: "Cooperativas de crédito mútuo"},
{ codigo: "6424-7/04", descricao: "Cooperativas de crédito rural"},
{ codigo: "6431-0/00", descricao: "Bancos múltiplos, sem carteira comercial"},
{ codigo: "6432-8/00", descricao: "Bancos de investimento"},
{ codigo: "6433-6/00", descricao: "Bancos de desenvolvimento"},
{ codigo: "6434-4/00", descricao: "Agências de fomento"},
{ codigo: "6435-2/01", descricao: "Sociedades de crédito imobiliário"},
{ codigo: "6435-2/02", descricao: "Associações de poupança e empréstimo"},
{ codigo: "6435-2/03", descricao: "Companhias hipotecárias"},
{ codigo: "6436-1/00", descricao: "Sociedades de crédito, financiamento e investimento - financeiras"},
{ codigo: "6437-9/00", descricao: "Sociedades de crédito ao microempreendedor"},
{ codigo: "6438-7/01", descricao: "Bancos de câmbio"},
{ codigo: "6438-7/99", descricao: "Outras instituições de intermediação não monetária não especificadas anteriormente"},
{ codigo: "6440-9/00", descricao: "Arrendamento mercantil"},
{ codigo: "6450-6/00", descricao: "Sociedades de capitalização"},
{ codigo: "6461-1/00", descricao: "Holdings de instituições financeiras"},
{ codigo: "6462-0/00", descricao: "Holdings de instituições não financeiras"},
{ codigo: "6463-8/00", descricao: "Outras sociedades de participação, exceto holdings"},
{ codigo: "6470-1/01", descricao: "Fundos de investimento, exceto previdenciários e imobiliários"},
{ codigo: "6470-1/02", descricao: "Fundos de investimento previdenciários"},
{ codigo: "6470-1/03", descricao: "Fundos de investimento imobiliários"},
{ codigo: "6491-3/00", descricao: "Sociedades de fomento mercantil - factoring"},
{ codigo: "6492-1/00", descricao: "Securitização de créditos"},
{ codigo: "6493-0/00", descricao: "Administração de consórcios para aquisição de bens e direitos"},
{ codigo: "6499-9/01", descricao: "Clubes de investimento"},
{ codigo: "6499-9/02", descricao: "Sociedades de investimento"},
{ codigo: "6499-9/03", descricao: "Fundo garantidor de crédito"},
{ codigo: "6499-9/04", descricao: "Caixas de financiamento de corporações"},
{ codigo: "6499-9/05", descricao: "Concessão de crédito pelas OSCIP"},
{ codigo: "6499-9/99", descricao: "Outras atividades de serviços financeiros não especificadas anteriormente"},
{ codigo: "6511-1/01", descricao: "Sociedade seguradora de seguros vida"},
{ codigo: "6511-1/02", descricao: "Planos de auxílio-funeral"},
{ codigo: "6512-0/00", descricao: "Sociedade seguradora de seguros não vida"},
{ codigo: "6520-1/00", descricao: "Sociedade seguradora de seguros-saúde"},
{ codigo: "6530-8/00", descricao: "Resseguros"},
{ codigo: "6541-3/00", descricao: "Previdência complementar fechada"},
{ codigo: "6542-1/00", descricao: "Previdência complementar aberta"},
{ codigo: "6550-2/00", descricao: "Planos de saúde"},
{ codigo: "6611-8/01", descricao: "Bolsa de valores"},
{ codigo: "6611-8/02", descricao: "Bolsa de mercadorias"},
{ codigo: "6611-8/03", descricao: "Bolsa de mercadorias e futuros"},
{ codigo: "6611-8/04", descricao: "Administração de mercados de balcão organizados"},
{ codigo: "6612-6/01", descricao: "Corretoras de títulos e valores mobiliários"},
{ codigo: "6612-6/02", descricao: "Distribuidoras de títulos e valores mobiliários"},
{ codigo: "6612-6/03", descricao: "Corretoras de câmbio"},
{ codigo: "6612-6/04", descricao: "Corretoras de contratos de mercadorias"},
{ codigo: "6612-6/05", descricao: "Agentes de investimentos em aplicações financeiras"},
{ codigo: "6613-4/00", descricao: "Administração de cartões de crédito"},
{ codigo: "6619-3/01", descricao: "Serviços de liquidação e custódia"},
{ codigo: "6619-3/02", descricao: "Correspondentes de instituições financeiras"},
{ codigo: "6619-3/03", descricao: "Representações de bancos estrangeiros"},
{ codigo: "6619-3/04", descricao: "Caixas eletrônicos"},
{ codigo: "6619-3/05", descricao: "Operadoras de cartões de débito"},
{ codigo: "6619-3/99", descricao: "Outras atividades auxiliares dos serviços financeiros não especificadas anteriormente"},
{ codigo: "6621-5/01", descricao: "Peritos e avaliadores de seguros"},
{ codigo: "6621-5/02", descricao: "Auditoria e consultoria atuarial"},
{ codigo: "6622-3/00", descricao: "Corretores e agentes de seguros, de planos de previdência complementar e de saúde"},
{ codigo: "6629-1/00", descricao: "Atividades auxiliares dos seguros, da previdência complementar e dos planos de saúde não especificadas anteriormente"},
{ codigo: "6630-4/00", descricao: "Atividades de administração de fundos por contrato ou comissão"},
{ codigo: "6810-2/01", descricao: "Compra e venda de imóveis próprios"},
{ codigo: "6810-2/02", descricao: "Aluguel de imóveis próprios"},
{ codigo: "6810-2/03", descricao: "Loteamento de imóveis próprios"},
{ codigo: "6821-8/01", descricao: "Corretagem na compra e venda e avaliação de imóveis"},
{ codigo: "6821-8/02", descricao: "Corretagem no aluguel de imóveis"},
{ codigo: "6822-6/00", descricao: "Gestão e administração da propriedade imobiliária"},
{ codigo: "6911-7/01", descricao: "Serviços advocatícios"},
{ codigo: "6911-7/02", descricao: "Atividades auxiliares da justiça"},
{ codigo: "6911-7/03", descricao: "Agente de propriedade industrial"},
{ codigo: "6912-5/00", descricao: "Cartórios"},
{ codigo: "6920-6/01", descricao: "Atividades de contabilidade"},
{ codigo: "6920-6/02", descricao: "Atividades de consultoria e auditoria contábil e tributária"},
{ codigo: "7020-4/00", descricao: "Atividades de consultoria em gestão empresarial, exceto consultoria técnica específica"},
{ codigo: "7111-1/00", descricao: "Serviços de arquitetura"},
{ codigo: "7112-0/00", descricao: "Serviços de engenharia"},
{ codigo: "7119-7/01", descricao: "Serviços de cartografia, topografia e geodésia"},
{ codigo: "7119-7/02", descricao: "Atividades de estudos geológicos"},
{ codigo: "7119-7/03", descricao: "Serviços de desenho técnico relacionados à arquitetura e engenharia"},
{ codigo: "7119-7/04", descricao: "Serviços de perícia técnica relacionados à segurança do trabalho"},
{ codigo: "7119-7/99", descricao: "Atividades técnicas relacionadas à engenharia e arquitetura não especificadas anteriormente"},
{ codigo: "7120-1/00", descricao: "Testes e análises técnicas"},
{ codigo: "7210-0/00", descricao: "Pesquisa e desenvolvimento experimental em ciências físicas e naturais"},
{ codigo: "7220-7/00", descricao: "Pesquisa e desenvolvimento experimental em ciências sociais e humanas"},
{ codigo: "7311-4/00", descricao: "Agências de publicidade"},
{ codigo: "7312-2/00", descricao: "Agenciamento de espaços para publicidade, exceto em veículos de comunicação"},
{ codigo: "7319-0/01", descricao: "Criação de estandes para feiras e exposições"},
{ codigo: "7319-0/02", descricao: "Promoção de vendas"},
{ codigo: "7319-0/03", descricao: "Marketing direto"},
{ codigo: "7319-0/04", descricao: "Consultoria em publicidade"},
{ codigo: "7319-0/99", descricao: "Outras atividades de publicidade não especificadas anteriormente"},
{ codigo: "7320-3/00", descricao: "Pesquisas de mercado e de opinião pública"},
{ codigo: "7410-2/02", descricao: "Design de interiores"},
{ codigo: "7410-2/03", descricao: "Desing de produto"},
{ codigo: "7410-2/99", descricao: "Atividades de desing não especificadas anteriormente"},
{ codigo: "7420-0/01", descricao: "Atividades de produção de fotografias, exceto aérea e submarina"},
{ codigo: "7420-0/02", descricao: "Atividades de produção de fotografias aéreas e submarinas"},
{ codigo: "7420-0/03", descricao: "Laboratórios fotográficos"},
{ codigo: "7420-0/04", descricao: "Filmagem de festas e eventos"},
{ codigo: "7420-0/05", descricao: "Serviços de microfilmagem"},
{ codigo: "7490-1/01", descricao: "Serviços de tradução, interpretação e similares"},
{ codigo: "7490-1/02", descricao: "Escafandria e mergulho"},
{ codigo: "7490-1/03", descricao: "Serviços de agronomia e de consultoria às atividades agrícolas e pecuárias"},
{ codigo: "7490-1/04", descricao: "Atividades de intermediação e agenciamento de serviços e negócios em geral, exceto imobiliários"},
{ codigo: "7490-1/05", descricao: "Agenciamento de profissionais para atividades esportivas, culturais e artísticas"},
{ codigo: "7490-1/99", descricao: "Outras atividades profissionais, científicas e técnicas não especificadas anteriormente"},
{ codigo: "7500-1/00", descricao: "Atividades veterinárias"},
{ codigo: "7711-0/00", descricao: "Locação de automóveis sem condutor"},
{ codigo: "7719-5/01", descricao: "Locação de embarcações sem tripulação, exceto para fins recreativos"},
{ codigo: "7719-5/02", descricao: "Locação de aeronaves sem tripulação"},
{ codigo: "7719-5/99", descricao: "Locação de outros meios de transporte não especificados anteriormente, sem condutor"},
{ codigo: "7721-7/00", descricao: "Aluguel de equipamentos recreativos e esportivos"},
{ codigo: "7722-5/00", descricao: "Aluguel de fitas de vídeo, DVDs e similares"},
{ codigo: "7723-3/00", descricao: "Aluguel de objetos do vestuário, jóias e acessórios"},
{ codigo: "7729-2/01", descricao: "Aluguel de aparelhos de jogos eletrônicos"},
{ codigo: "7729-2/02", descricao: "Aluguel de móveis, utensílios e aparelhos de uso doméstico e pessoal; instrumentos musicais"},
{ codigo: "7729-2/03", descricao: "Aluguel de material médico"},
{ codigo: "7729-2/99", descricao: "Aluguel de outros objetos pessoais e domésticos não especificados anteriormente"},
{ codigo: "7731-4/00", descricao: "Aluguel de máquinas e equipamentos agrícolas sem operador"},
{ codigo: "7732-2/01", descricao: "Aluguel de máquinas e equipamentos para construção sem operador, exceto andaimes"},
{ codigo: "7732-2/02", descricao: "Aluguel de andaimes"},
{ codigo: "7733-1/00", descricao: "Aluguel de máquinas e equipamentos para escritório"},
{ codigo: "7739-0/01", descricao: "Aluguel de máquinas e equipamentos para extração de minérios e petróleo, sem operador"},
{ codigo: "7739-0/02", descricao: "Aluguel de equipamentos científicos, médicos e hospitalares, sem operador"},
{ codigo: "7739-0/03", descricao: "Aluguel de palcos, coberturas e outras estruturas de uso temporário, exceto andaimes"},
{ codigo: "7739-0/99", descricao: "Aluguel de outras máquinas e equipamentos comerciais e industriais não especificados anteriormente, sem operador"},
{ codigo: "7740-3/00", descricao: "Gestão de ativos intangíveis não financeiros"},
{ codigo: "7810-8/00", descricao: "Seleção e agenciamento de mão de obra"},
{ codigo: "7820-5/00", descricao: "Locação de mão de obra temporária"},
{ codigo: "7830-2/00", descricao: "Fornecimento e gestão de recursos humanos para terceiros"},
{ codigo: "7911-2/00", descricao: "Agências de viagens"},
{ codigo: "7912-1/00", descricao: "Operadores turísticos"},
{ codigo: "7990-2/00", descricao: "Serviços de reservas e outros serviços de turismo não especificados anteriormente"},
{ codigo: "8011-1/01", descricao: "Atividades de vigilância e segurança privada"},
{ codigo: "8011-1/02", descricao: "Serviços de adestramento de cães de guarda"},
{ codigo: "8012-9/00", descricao: "Atividades de transporte de valores"},
{ codigo: "8020-0/01", descricao: "Atividades de monitoramento de sistemas de segurança eletrônico"},
{ codigo: "8020-0/02", descricao: "Outras atividades de serviços de segurança"},
{ codigo: "8030-7/00", descricao: "Atividades de investigação particular"},
{ codigo: "8111-7/00", descricao: "Serviços combinados para apoio a edifícios, exceto condomínios prediais"},
{ codigo: "8112-5/00", descricao: "Condomínios prediais"},
{ codigo: "8121-4/00", descricao: "Limpeza em prédios e em domicílios"},
{ codigo: "8122-2/00", descricao: "Imunização e controle de pragas urbanas"},
{ codigo: "8129-0/00", descricao: "Atividades de limpeza não especificadas anteriormente"},
{ codigo: "8130-3/00", descricao: "Atividades paisagísticas"},
{ codigo: "8211-3/00", descricao: "Serviços combinados de escritório e apoio administrativo"},
{ codigo: "8219-9/01", descricao: "Fotocópias"},
{ codigo: "8219-9/99", descricao: "Preparação de documentos e serviços especializados de apoio administrativo não especificados anteriormente"},
{ codigo: "8220-2/00", descricao: "Atividades de teleatendimento"},
{ codigo: "8230-0/01", descricao: "Serviços de organização de feiras, congressos, exposições e festas"},
{ codigo: "8230-0/02", descricao: "Casas de festas e eventos"},
{ codigo: "8291-1/00", descricao: "Atividades de cobrança e informações cadastrais"},
{ codigo: "8292-0/00", descricao: "Envasamento e empacotamento sob contrato"},
{ codigo: "8299-7/01", descricao: "Medição de consumo de energia elétrica, gás e água"},
{ codigo: "8299-7/02", descricao: "Emissão de vales-alimentação, vales-transporte e similares"},
{ codigo: "8299-7/03", descricao: "Serviços de gravação de carimbos, exceto confecção"},
{ codigo: "8299-7/04", descricao: "Leiloeiros independentes"},
{ codigo: "8299-7/05", descricao: "Serviços de levantamento de fundos sob contrato"},
{ codigo: "8299-7/06", descricao: "Casas lotéricas"},
{ codigo: "8299-7/07", descricao: "Salas de acesso à Internet"},
{ codigo: "8299-7/99", descricao: "Outras atividades de serviços prestados principalmente às empresas não especificadas anteriormente"},
{ codigo: "8411-6/00", descricao: "Administração pública em geral"},
{ codigo: "8412-4/00", descricao: "Regulação das atividades de saúde, educação, serviços culturais e outros serviços sociais"},
{ codigo: "8413-2/00", descricao: "Regulação das atividades econômicas"},
{ codigo: "8421-3/00", descricao: "Relações exteriores"},
{ codigo: "8422-1/00", descricao: "Defesa"},
{ codigo: "8423-0/00", descricao: "Justiça"},
{ codigo: "8424-8/00", descricao: "Segurança e ordem pública"},
{ codigo: "8425-6/00", descricao: "Defesa Civil"},
{ codigo: "8430-2/00", descricao: "Seguridade social obrigatória"},
{ codigo: "8511-2/00", descricao: "Educação infantil - creche"},
{ codigo: "8512-1/00", descricao: "Educação infantil - pré-escola"},
{ codigo: "8513-9/00", descricao: "Ensino fundamental"},
{ codigo: "8520-1/00", descricao: "Ensino médio"},
{ codigo: "8531-7/00", descricao: "Educação superior - graduação"},
{ codigo: "8532-5/00", descricao: "Educação superior - graduação e pós-graduação"},
{ codigo: "8533-3/00", descricao: "Educação superior - pós-graduação e extensão"},
{ codigo: "8541-4/00", descricao: "Educação profissional de nível técnico"},
{ codigo: "8542-2/00", descricao: "Educação profissional de nível tecnológico"},
{ codigo: "8550-3/01", descricao: "Administração de caixas escolares"},
{ codigo: "8550-3/02", descricao: "Atividades de apoio à educação, exceto caixas escolares"},
{ codigo: "8591-1/00", descricao: "Ensino de esportes"},
{ codigo: "8592-9/01", descricao: "Ensino de dança"},
{ codigo: "8592-9/02", descricao: "Ensino de artes cênicas, exceto dança"},
{ codigo: "8592-9/03", descricao: "Ensino de música"},
{ codigo: "8592-9/99", descricao: "Ensino de arte e cultura não especificado anteriormente"},
{ codigo: "8593-7/00", descricao: "Ensino de idiomas"},
{ codigo: "8599-6/01", descricao: "Formação de condutores"},
{ codigo: "8599-6/02", descricao: "Cursos de pilotagem"},
{ codigo: "8599-6/03", descricao: "Treinamento em informática"},
{ codigo: "8599-6/04", descricao: "Treinamento em desenvolvimento profissional e gerencial"},
{ codigo: "8599-6/05", descricao: "Cursos preparatórios para concursos"},
{ codigo: "8599-6/99", descricao: "Outras atividades de ensino não especificadas anteriormente"},
{ codigo: "8610-1/01", descricao: "Atividades de atendimento hospitalar, exceto pronto-socorro e unidades para atendimento a urgências"},
{ codigo: "8610-1/02", descricao: "Atividades de atendimento em pronto-socorro e unidades hospitalares para atendimento a urgências"},
{ codigo: "8621-6/01", descricao: "UTI móvel"},
{ codigo: "8621-6/02", descricao: "Serviços móveis de atendimento a urgências, exceto por UTI móvel"},
{ codigo: "8622-4/00", descricao: "Serviços de remoção de pacientes, exceto os serviços móveis de atendimento a urgências"},
{ codigo: "8630-5/01", descricao: "Atividade médica ambulatorial com recursos para realização de procedimentos cirúrgicos"},
{ codigo: "8630-5/02", descricao: "Atividade médica ambulatorial com recursos para realização de exames complementares"},
{ codigo: "8630-5/03", descricao: "Atividade médica ambulatorial restrita a consultas"},
{ codigo: "8630-5/04", descricao: "Atividade odontológica"},
{ codigo: "8630-5/06", descricao: "Serviços de vacinação e imunização humana"},
{ codigo: "8630-5/07", descricao: "Atividades de reprodução humana assistida"},
{ codigo: "8630-5/99", descricao: "Atividades de atenção ambulatorial não especificadas anteriormente"},
{ codigo: "8640-2/01", descricao: "Laboratórios de anatomia patológica e citológica"},
{ codigo: "8640-2/02", descricao: "Laboratórios clínicos"},
{ codigo: "8640-2/03", descricao: "Serviços de diálise e nefrologia"},
{ codigo: "8640-2/04", descricao: "Serviços de tomografia"},
{ codigo: "8640-2/05", descricao: "Serviços de diagnóstico por imagem com uso de radiação ionizante, exceto tomografia"},
{ codigo: "8640-2/06", descricao: "Serviços de ressonância magnética"},
{ codigo: "8640-2/07", descricao: "Serviços de diagnóstico por imagem sem uso de radiação ionizante, exceto ressonância magnética"},
{ codigo: "8640-2/08", descricao: "Serviços de diagnóstico por registro gráfico - ECG, EEG e outros exames análogos"},
{ codigo: "8640-2/09", descricao: "Serviços de diagnóstico por métodos ópticos - endoscopia e outros exames análogos"},
{ codigo: "8640-2/10", descricao: "Serviços de quimioterapia"},
{ codigo: "8640-2/11", descricao: "Serviços de radioterapia"},
{ codigo: "8640-2/12", descricao: "Serviços de hemoterapia"},
{ codigo: "8640-2/13", descricao: "Serviços de litotripsia"},
{ codigo: "8640-2/14", descricao: "Serviços de bancos de células e tecidos humanos"},
{ codigo: "8640-2/99", descricao: "Atividades de serviços de complementação diagnóstica e terapêutica não especificadas anteriormente"},
{ codigo: "8650-0/01", descricao: "Atividades de enfermagem"},
{ codigo: "8650-0/02", descricao: "Atividades de profissionais da nutrição"},
{ codigo: "8650-0/03", descricao: "Atividades de psicologia e psicanálise"},
{ codigo: "8650-0/04", descricao: "Atividades de fisioterapia"},
{ codigo: "8650-0/05", descricao: "Atividades de terapia ocupacional"},
{ codigo: "8650-0/06", descricao: "Atividades de fonoaudiologia"},
{ codigo: "8650-0/07", descricao: "Atividades de terapia de nutrição enteral e parenteral"},
{ codigo: "8650-0/99", descricao: "Atividades de profissionais da área de saúde não especificadas anteriormente"},
{ codigo: "8660-7/00", descricao: "Atividades de apoio à gestão de saúde"},
{ codigo: "8690-9/01", descricao: "Atividades de práticas integrativas e complementares em saúde humana"},
{ codigo: "8690-9/02", descricao: "Atividades de bancos de leite humano"},
{ codigo: "8690-9/03", descricao: "Atividades de acupuntura"},
{ codigo: "8690-9/04", descricao: "Atividades de podologia"},
{ codigo: "8690-9/99", descricao: "Outras atividades de atenção à saúde humana não especificadas anteriormente"},
{ codigo: "8711-5/01", descricao: "Clínicas e residências geriátricas"},
{ codigo: "8711-5/02", descricao: "Instituições de longa permanência para idosos"},
{ codigo: "8711-5/03", descricao: "Atividades de assistência a deficientes físicos, imunodeprimidos e convalescentes"},
{ codigo: "8711-5/04", descricao: "Centros de apoio a pacientes com câncer e com AIDS"},
{ codigo: "8711-5/05", descricao: "Condomínios residenciais para idosos"},
{ codigo: "8712-3/00", descricao: "Atividades de fornecimento de infraestrutura de apoio e assistência a paciente no domicílio"},
{ codigo: "8720-4/01", descricao: "Atividades de centros de assistência psicossocial"},
{ codigo: "8720-4/99", descricao: "Atividades de assistência psicossocial e à saúde a portadores de distúrbios psíquicos, deficiência mental e dependência química e grupos similares não especificadas anteriormente"},
{ codigo: "8730-1/01", descricao: "Orfanatos"},
{ codigo: "8730-1/02", descricao: "Albergues assistenciais"},
{ codigo: "8730-1/99", descricao: "Atividades de assistência social prestadas em residências coletivas e particulares não especificadas anteriormente"},
{ codigo: "8800-6/00", descricao: "Serviços de assistência social sem alojamento"},
{ codigo: "9001-9/01", descricao: "Produção teatral"},
{ codigo: "9001-9/02", descricao: "Produção musical"},
{ codigo: "9001-9/03", descricao: "Produção de espetáculos de dança"},
{ codigo: "9001-9/04", descricao: "Produção de espetáculos circenses, de marionetes e similares"},
{ codigo: "9001-9/05", descricao: "Produção de espetáculos de rodeios, vaquejadas e similares"},
{ codigo: "9001-9/06", descricao: "Atividades de sonorização e de iluminação"},
{ codigo: "9001-9/99", descricao: "Artes cênicas, espetáculos e atividades complementares não especificados anteriormente"},
{ codigo: "9002-7/01", descricao: "Atividades de artistas plásticos, jornalistas independentes e escritores"},
{ codigo: "9002-7/02", descricao: "Restauração de obras de arte"},
{ codigo: "9003-5/00", descricao: "Gestão de espaços para artes cênicas, espetáculos e outras atividades artísticas"},
{ codigo: "9101-5/00", descricao: "Atividades de bibliotecas e arquivos"},
{ codigo: "9102-3/01", descricao: "Atividades de museus e de exploração de lugares e prédios históricos e atrações similares"},
{ codigo: "9102-3/02", descricao: "Restauração e conservação de lugares e prédios históricos"},
{ codigo: "9103-1/00", descricao: "Atividades de jardins botânicos, zoológicos, parques nacionais, reservas ecológicas e áreas de proteção ambiental"},
{ codigo: "9200-3/01", descricao: "Casas de bingo"},
{ codigo: "9200-3/02", descricao: "Exploração de apostas em corridas de cavalos"},
{ codigo: "9200-3/99", descricao: "Exploração de jogos de azar e apostas não especificados anteriormente"},
{ codigo: "9311-5/00", descricao: "Gestão de instalações de esportes"},
{ codigo: "9312-3/00", descricao: "Clubes sociais, esportivos e similares"},
{ codigo: "9313-1/00", descricao: "Atividades de condicionamento físico"},
{ codigo: "9319-1/01", descricao: "Produção e promoção de eventos esportivos"},
{ codigo: "9319-1/99", descricao: "Outras atividades esportivas não especificadas anteriormente"},
{ codigo: "9321-2/00", descricao: "Parques de diversão e parques temáticos"},
{ codigo: "9329-8/01", descricao: "Discotecas, danceterias, salões de dança e similares"},
{ codigo: "9329-8/02", descricao: "Exploração de boliches"},
{ codigo: "9329-8/03", descricao: "Exploração de jogos de sinuca, bilhar e similares"},
{ codigo: "9329-8/04", descricao: "Exploração de jogos eletrônicos recreativos"},
{ codigo: "9329-8/99", descricao: "Outras atividades de recreação e lazer não especificadas anteriormente"},
{ codigo: "9411-1/00", descricao: "Atividades de organizações associativas patronais e empresariais"},
{ codigo: "9412-0/01", descricao: "Atividades de fiscalização profissional"},
{ codigo: "9412-0/99", descricao: "Outras atividades associativas profissionais"},
{ codigo: "9420-1/00", descricao: "Atividades de organizações sindicais"},
{ codigo: "9430-8/00", descricao: "Atividades de associações de defesa de direitos sociais"},
{ codigo: "9491-0/00", descricao: "Atividades de organizações religiosas ou filosóficas"},
{ codigo: "9492-8/00", descricao: "Atividades de organizações políticas"},
{ codigo: "9493-6/00", descricao: "Atividades de organizações associativas ligadas à cultura e à arte"},
{ codigo: "9499-5/00", descricao: "Atividades associativas não especificadas anteriormente"},
{ codigo: "9511-8/00", descricao: "Reparação e manutenção de computadores e de equipamentos periféricos"},
{ codigo: "9512-6/00", descricao: "Reparação e manutenção de equipamentos de comunicação"},
{ codigo: "9521-5/00", descricao: "Reparação e manutenção de equipamentos eletroeletrônicos de uso pessoal e doméstico"},
{ codigo: "9529-1/01", descricao: "Reparação de calçados, bolsas e artigos de viagem"},
{ codigo: "9529-1/02", descricao: "Chaveiros"},
{ codigo: "9529-1/03", descricao: "Reparação de relógios"},
{ codigo: "9529-1/04", descricao: "Reparação de bicicletas, triciclos e outros veículos não motorizados"},
{ codigo: "9529-1/05", descricao: "Reparação de artigos do mobiliário"},
{ codigo: "9529-1/06", descricao: "Reparação de jóias"},
{ codigo: "9529-1/99", descricao: "Reparação e manutenção de outros objetos e equipamentos pessoais e domésticos não especificados anteriormente"},
{ codigo: "9601-7/01", descricao: "Lavanderias"},
{ codigo: "9601-7/02", descricao: "Tinturarias"},
{ codigo: "9601-7/03", descricao: "Toalheiros"},
{ codigo: "9602-5/01", descricao: "Cabeleireiros, manicure e pedicure"},
{ codigo: "9602-5/02", descricao: "Atividades de estética e outros serviços de cuidados com a beleza"},
{ codigo: "9603-3/01", descricao: "Gestão e manutenção de cemitérios"},
{ codigo: "9603-3/02", descricao: "Serviços de cremação"},
{ codigo: "9603-3/03", descricao: "Serviços de sepultamento"},
{ codigo: "9603-3/04", descricao: "Serviços de funerárias"},
{ codigo: "9603-3/05", descricao: "Serviços de somatoconservação"},
{ codigo: "9603-3/99", descricao: "Atividades funerárias e serviços relacionados não especificados anteriormente"},
{ codigo: "9609-2/02", descricao: "Agências matrimoniais"},
{ codigo: "9609-2/04", descricao: "Exploração de máquinas de serviços pessoais acionadas por moeda"},
{ codigo: "9609-2/05", descricao: "Atividades de sauna e banhos"},
{ codigo: "9609-2/06", descricao: "Serviços de tatuagem e colocação de piercing"},
{ codigo: "9609-2/07", descricao: "Alojamento de animais domésticos"},
{ codigo: "9609-2/08", descricao: "Higiene e embelezamento de animais domésticos"},
{ codigo: "9609-2/99", descricao: "Outras atividades de serviços pessoais não especificadas anteriormente"},
{ codigo: "9700-5/00", descricao: "Serviços domésticos"}]

const codigos = cnae.map(el => el.codigo).sort()
const descricoes = cnae.map(el => el.descricao)

export default { codigos, descricoes}