const CaixaFinalDfd = ({ fonte, cmd, regional }) => {
    return (
        <>
            {fonte === "FUNESBOM" ? (<>
                <div style={{
                    border: "1px solid black", width: "98vw", fontSize: "12px",
                    marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
                }}>
                    <div style={{
                        borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                        textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                    }}>
                        RESPONSABILIDADE PELA FORMALIZAÇÃO DA DEMANDA E CONTEÚDO DO DOCUMENTO
                    </div>
                    <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}><br />
                        Certifico que a formalização da demanda se faz necessária pelos motivos expostos nas
                        justificativas para cada área do presente documento em atendimento a Lei nº 14.133/2021 e
                        conforme previsto no bem como em atendimento ao DECRETO Nº 44.639, de 22 de dezembro de 2023.</div>
                    <br />
                    <div style={{ textAlign: "right", padding: "5px", fontWeight: "320" }}><br />
                        João Pessoa, datado via PBDOC.
                    </div><br />
                    <div style={{ textAlign: "center", marginTop: "8vh" }}><b>
                        <br />
                        {localStorage.nome} - {localStorage.posto}<br />
                        {localStorage.numeroMatricula} - {localStorage.regionalUser}
                    </b><br />
                        <b>Responsável pelo Planejamento</b>
                        <br />
                        Assinado e datado via PBDOC
                    </div>
                </div>
                <div style={{
                    border: "1px solid black", width: "98vw", fontSize: "12px",
                    marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
                }}>
                    <div style={{
                        borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                        textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                    }}>
                        COMANDANTE / CHEFE / RESPONSÁVEL PELA UNIDADE REQUISITANTE
                    </div>
                    <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}><br />
                        De acordo com a demanda e o quantitativo solicitado.
                    </div><br />
                    <div style={{ textAlign: "right", padding: "5px", fontWeight: "320" }}><br />
                        João Pessoa, datado via PBDOC.
                    </div><br />
                    <div style={{ textAlign: "center", padding: "5px", fontWeight: "420" }}><br />
                        {cmd !== undefined ? (
                            <div style={{ textAlign: "center", marginTop: "8vh" }}><b>
                                <br />
                                {cmd.nome} - {cmd.posto}<br />
                                {cmd.numeroMatricula} - {cmd.regional}
                            </b></div>
                        ) : (<></>)}
                        Assinado e datado via PBDOC
                    </div><br />
                </div>
            </>) : (<>
                <div style={{
                    border: "1px solid black", width: "98vw", fontSize: "12px",
                    marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
                }}>
                    <div style={{
                        borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                        textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                    }}>
                        RESPONSABILIDADE PELA FORMALIZAÇÃO DA DEMANDA E CONTEÚDO DO DOCUMENTO
                    </div>
                    <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}><br />
                        Certifico que as formalizações das demandas compiladas no presente documento se fazem
                        necessárias pelos motivos expostos nas justificativas para cada área do presente documento
                        baseadas nas reuniões e demandas {regional === "Núcleo Estratégico" ? 
                        ("dos setores estratégicos do CBMPB") : ("das Unidades Bombeiros Militar subordinadas a este CRBM")},
                        tudo em conformidade com o inciso VII do Art. 12 da Lei nº 14.133/2021 - "VII - a partir de
                        documentos de formalização de demandas, os órgãos responsáveis pelo planejamento de cada
                        ente federativo poderão, na forma de regulamento, elaborar plano de contratações anual,
                        com o objetivo de racionalizar as contratações dos órgãos e entidades sob sua competência,
                        garantir o alinhamento com o seu planejamento estratégico e subsidiar a elaboração das
                        respectivas leis orçamentárias.", bem como em atendimento ao DECRETO Nº 44.639, de 22 de
                        dezembro de 2023</div>
                    <br />
                    <div style={{ textAlign: "right", padding: "5px", fontWeight: "320" }}><br />
                        João Pessoa, datado via PBDOC.
                    </div><br />
                    <div style={{ textAlign: "center", marginTop: "8vh" }}><b>
                        <br />
                        {localStorage.nome} - {localStorage.posto}<br />
                        {localStorage.numeroMatricula} - {localStorage.regionalUser}
                    </b><br />
                        <b>Responsável pelo Planejamento</b>
                        <br />
                        Assinado e datado via PBDOC
                    </div>
                </div>
                <div style={{
                    border: "1px solid black", width: "98vw", fontSize: "12px",
                    marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
                }}>
                    <div style={{
                        borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                        textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                    }}>
                        ANUÊNCIA DO ORDENADOR DE DESPESAS
                    </div>
                    <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}><br />
                        <b style={{ fontWeight: "600" }}>RATIFICO</b> as demandas e os quantitativos solicitados,
                        bem como <b style={{ fontWeight: "600" }}>ATESTO</b> que o
                        PCA/CBM/2024/{cmd !== undefined ? cmd.regional : ""} proposto para execução no ano de 2025 está em conformidade com o
                        planejamento, elaboração e execução das peças orçamentárias aplicáveis e com o
                        planejamento estratégico 2024/2027 do Corpo de Bombeiros Militar da Paraíba.
                    </div><br />
                    <div style={{ textAlign: "right", padding: "5px", fontWeight: "320" }}><br />
                        João Pessoa, datado via PBDOC.
                    </div><br />
                    <div style={{ textAlign: "center", padding: "5px", fontWeight: "320" }}><br />
                        {cmd !== undefined ? (
                            <div style={{ textAlign: "center", marginTop: "8vh" }}><b>
                                <br />
                                {cmd.nome} - {cmd.posto}<br />
                                {cmd.numeroMatricula} - {cmd.regional === "Núcleo Estratégico" ? "Comandante Geral" : cmd.regional}
                            </b></div>
                        ) : (<></>)}
                        Assinado e datado via PBDOC
                    </div><br />
                </div>
            </>)}
        </>
    )
}

export default CaixaFinalDfd