import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios';

import calcularPrazo from "../Biblioteca/Funcoes/calculoPrazo"
import converterData from '../Biblioteca/Funcoes/converterData';

const TabelaAditivos = () => {
    const { myState } = useContext(MyContext);
    const [dados, setDados] = useState([]);

    useEffect(() => {
        axios("/api/cpl/getAditivos")
            .then(res => {
                setDados(res.data);
            })
            .catch(error => alert(error.response.data));
    }, []);

    const estilo = {
        th: {
            color: myState === "light" ? 'black' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '16px',
            border: '1px solid #ddd',
            fontWeight: 600, fontSize: 15
        },
        td: {
            padding: '16px', backgroundColor: myState === "light" ? '#fff' : "#00000000",
            border: '1px solid #ddd', fontSize: 14, color: myState === "light" ? 'black' : "#FFFF",
        }
    }

    return (
        <div
            style={{
                height: '100%',
                overflowY: 'auto',
            }}
        >
            <div style={{
                position: 'sticky',
                top: 0,
                backgroundColor: myState === "light" ? '#fff' : "#00000000",
                zIndex: 1,
                color: myState === "light" ? '#33348F' : "#c4c4ea",
                padding: '16px',
                border: '1px solid #ddd',
                fontWeight: 600,
                fontSize: 15,
                textAlign: "center"
            }}>
                ADITIVOS
            </div>
            <table
                style={{
                    width: '100%',
                    padding: '16px',
                    borderCollapse: 'collapse',
                    textAlign: "center",
                    borderRadius: '10px',
                    maxHeight: '28vh',
                    tableLayout: 'fixed',
                    wordWrap: 'break-word',
                }}
            >
                <thead>
                    <tr>
                        <th style={estilo.th}>CONTRATO</th>
                        <th style={estilo.th}>OBJETO</th>
                        <th style={estilo.th}>VIGÊNCIA</th>
                        <th style={estilo.th}>PRAZO</th>
                    </tr>
                </thead>
                <tbody>
                    {dados.map((item, index) => (
                        <tr key={index}>
                            <td style={estilo.td}>{item.numeroContrato}</td>
                            <td style={estilo.td}>{item.objeto}</td>
                            <td style={estilo.td}>{converterData(item.prazoPge.slice(0, 10))}</td>
                            <td style={estilo.td}>{calcularPrazo(item.prazoPge)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TabelaAditivos;
