import React, { useContext } from 'react';
import { MyContext } from "../Biblioteca/MyContext"

const NavDf = (props) => {
    const { myState } = useContext(MyContext);

    const linkStyle = {
        marginLeft: 'auto',
        marginRight: '1rem',
        color: myState === "light" ? 'black' : "#F9F9F9",
        fontWeight: 550,
        fontSize: "13px",
        cursor: 'pointer',
        textDecoration: 'none',
    };

    if (window.matchMedia('(min-width:600px)').matches) {
        return (
            <div style={{ textAlign: "center", width: '100%' }}>
                <a href="/dashboardDF" style={{ ...props.estilo, ...linkStyle }}>
                    DASHBOARD
                </a>
                <a href="/senha" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    SENHA
                </a>
            </div>
        );
    } else {
        return (
            <>
                <div className="mt-2">
                    <a href="/dashboardDF" style={{ ...props.estilo, ...linkStyle }}>
                        DASHBOARD
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/senha" style={{ ...props.estilo, ...linkStyle }}>
                        SENHA
                    </a>
                </div>
            </>
        );
    }
};

export default NavDf;
