import { useState } from "react"
import axios from 'axios'

import Caixa from "../../Biblioteca/Caixa"
import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import cnae from './cnae'
import enviar from '../../Biblioteca/Funcoes/enviar'

const NovoProcesso = () => {
    const [dados, setDados] = useState({ user: localStorage.nome })
    const [disabled, setDisabled] = useState(false)

    const updateField = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        if (name === "codCnae") {
            let index = cnae.codigos.indexOf(value)
            tempState["descricaoCnae"] = cnae.descricoes[index]
        }
        tempState[name] = value
        setDados(tempState)
    }

    return (
        <Caixa>
            <div>
                <Input texto="Nº da Solicitação" nome="numeroSolicitacao" widthWeb="14vw" widthMobile="80vw"
                    funcao={updateField} tipo="number" />
            </div>
            <Input texto="PBDOC" nome="pbdoc" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Select texto="Andamento" nome="andamento" funcao={updateField} widthWeb="14vw" widthMobile="80vw"
                options={["Abertura", "Pesquisa", "DF-RO", "Comitê Gestor", "Nota Técnica",
                    "PGE", "Ass. CMDG", "CGE", "DF-Empenho", "SEAD", "Cancelado", "QRX"]} />
            <Input texto="Data do Andamento" nome="dataAndamento" tipo="date"
                funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Select texto="Modalidade" nome="modalidade" funcao={updateField} widthWeb="14vw" widthMobile="80vw"
                options={["Adesão de Ata", "Compra Direta", "Dispensa de Licitação", "Dispensa Art. 75",
                    "Inexigibilidade de Licitação", "Pregão", "Registro de Preços", "Contrato",
                    "TED/Convênios", "Utilização de Ata"]} />
            <Input texto="Nº da Modalidade" nome="numeroModalidade" tipo="number"
                funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            {dados.modalidade === "Compra Direta" || dados.modalidade === "Dispensa Art. 75" ?
                (
                    <>
                        <Select texto="Código CNAE" nome="codCnae" funcao={updateField} widthWeb="14vw" widthMobile="80vw"
                            options={cnae.codigos} />
                        <Input texto="Descrição" nome="descricaoCnae" widthWeb="14vw" widthMobile="80vw"
                            valor={dados.descricaoCnae || null} funcao={() => console.log("")} />
                    </>
                ) : null
            }
            <Input texto="Valor Reservado" nome="valorReserva" tipo="moeda" dado={dados.valorReserva || null}
                funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Data da Reserva" nome="dataReserva" tipo="date"
                funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Objeto" nome="objeto" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Observações" nome="obs" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <div>
                <Botao texto="ENVIAR" funcao={() => enviar('/api/cpl/novoProcesso', dados, "/processosCpl")} 
                widthMobile="40vw" disabled={disabled} />
            </div>
        </Caixa>
    )
}

export default NovoProcesso