import React, { useContext, useState, useEffect } from "react";
import { MyContext } from "./MyContext"

const Tema = (props) => {
  const mediaWeb = window.matchMedia('(min-width:600px)').matches;
  const { myState, updateMyState } = useContext(MyContext);
  const [tema, setTema] = useState(localStorage.tema || "light")
  const [icon, setIcon] = useState()

  useEffect(() => {
    updateMyState(tema)
    tema === "light" ? setIcon("bi bi-moon-stars-fill") : setIcon("bi bi-brightness-high-fill")
  }, [])

  const handleContext = () => {
    tema === "light" ? updateMyState("dark") : updateMyState("light")
    tema === "light" ? setTema("dark") : setTema("light")
    tema === "light" ? localStorage.setItem("tema", "dark") : localStorage.setItem("tema", "light")
    tema === "light" ? setIcon("bi bi-brightness-high-fill") : setIcon("bi bi-moon-stars-fill")
  }

  return (
    <>
      <div style={{ position: 'absolute', left: "4vw" }}>
        <i
        onClick={() => handleContext()}
          className={icon}
          style={{
            color: myState === "light" ? 'black' : "#F9F9F9",
            marginLeft: mediaWeb ? '1rem' : "3rem",
            cursor: 'pointer',
            fontSize: '16px',
          }}
        ></i>
      </div>
    </>
  )
}

export default Tema