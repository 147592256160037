import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "../../Biblioteca/MyContext"

import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import enviar from '../../Biblioteca/Funcoes/enviar'

const Movimentacoes = (props) => {
    const { myState } = useContext(MyContext);
    const [dados, setDados] = useState({
        idProcesso: props.processo._id, user: localStorage.nome,
        movimentacoes: []
    })
    const [qtd, setQtd] = useState(0)

    const updateField = (index, campo, valor) => {
        setDados(prevState => {
            const movimentacoesAtualizadas = [...prevState.movimentacoes];
            movimentacoesAtualizadas[index] = {
                ...movimentacoesAtualizadas[index],
                [campo]: valor
            };
            return {
                ...prevState,
                movimentacoes: movimentacoesAtualizadas
            };
        });
    }

    const handleQtd = (e) => {
        const { name } = e.target
        if (name === "mais") {
            let tempQtd = qtd + 1
            setQtd(tempQtd)
        } else {
            if (qtd === 0) return null
            let tempQtd = qtd - 1
            setQtd(tempQtd)
            let tempDados = { ...dados }
            tempDados.movimentacoes.pop()
            setDados(tempDados)
        }
    }

    return (
        <>
            <div style={{
                color: myState === "light" ? "#33348F" : "#c4c4ea", marginTop: "3vh",
                marginBottom: "2vh", textAlign: "left"
            }}>
                <b>Movimentações </b>
                <>
                    <button onClick={e => handleQtd(e)} name="menos"
                        style={{
                            border: "none", backgroundColor: "#E71313", marginLeft: "1vw", width: "2vw",
                            color: "#FFFF", borderRadius: "5px", paddingTop: "0.5px", paddingBottom: "0.5px"
                        }}>-</button>
                    <div style={{
                        display: "inline-block", borderRadius: "5px", textAlign: "center",
                        marginLeft: "1vw"
                    }}>
                        {qtd}
                    </div>
                    <button onClick={e => handleQtd(e)} name="mais"
                        style={{
                            border: "none", backgroundColor: "#42a908", marginLeft: "1vw", width: "2vw",
                            color: "#FFFF", borderRadius: "5px", paddingTop: "0.5px", paddingBottom: "0.5px"
                        }}>+</button>
                </>
                {qtd > 0 ? (
                    <div>
                        <Botao texto="Enviar" display="inline-block" widthWeb="5vw" widthMobile="20vw" marginR="1vw"
                            funcao={() => enviar(`/api/cpl/${props.url}`, dados, `/${props.redirect}`)} />
                    </div>
                ) : null}
                {RenderMovimentacoes()}
            </div >
            {
                props.processo.andamentos.reverse().map(x => {
                    return (
                        <div style={{ textAlign: "left" }}>
                            <Input texto="Andamento" widthWeb="14vw" widthMobile="80vw" funcao={() => console.log()} modo="fixed" valor={x.andamento} />
                            <Input texto="Data do Andamento" widthWeb="14vw" widthMobile="80vw" funcao={() => console.log()} tipo="date"
                                modo="fixed" valor={x.dataAndamento} />
                            <Input texto="Responsavel" widthWeb="14vw" widthMobile="80vw" funcao={() => console.log()} modo="fixed" valor={x.responsavel} />
                        </div>
                    )
                })
            }
        </>
    )

    function RenderMovimentacoes() {
        let campos = []
        for (let n = 1; n <= qtd; n++) {
            campos.push(
                <div style={{ textAlign: "left" }}>
                    <Select texto="Andamento" nome="andamento" widthWeb="14vw" widthMobile="80vw"
                        funcao={(e) => updateField(n - 1, "andamento", e.target.value)}
                        options={["Abertura", "CPL", "Pesquisa", "DF-RO", "Comitê Gestor", "Nota Técnica",
                            "PGE", "Ass. CMDG", "CGE", "DF-Empenho", "SEAD", "Cancelado", "QRX"]} />
                    <Input texto="Data do Andamento" nome="dataAndamento" widthWeb="14vw" widthMobile="80vw"
                        funcao={(e) => updateField(n - 1, "dataAndamento", e.target.value)} tipo="date" />
                </div>
            )
        }
        return campos
    }
}

export default Movimentacoes