const Caixa = (props) => {
    const estilo = {
        caixa: {
            border: '1px solid #ddd', borderRadius: "5px",
            margin: "auto", width: "90vw", marginTop: "4vh", marginBottom: props.marginBottom,
            paddingLeft: "2vw", paddingRight: "2vw", paddingBottom: "4vh", paddingTop: "4vh",
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
        }
    }

    return (
        <div style={estilo.caixa}>
            {props.children}
        </div>
    )
}

export default Caixa