import { useState, useEffect } from 'react'
import axios from 'axios'

import Caixa from '../Biblioteca/Caixa'
import Input from '../Biblioteca/Input'
import Select from '../Biblioteca/Select'
import Botao from '../Biblioteca/Botao'

const User = () => {
    const [search, setSearch] = useState()
    const [usersList, setUsersList] = useState([])
    const [regionais, setRegionais] = useState([])
    const [editUser, setEditUser] = useState({})
    const [keyUser, setKey] = useState(0)

    useEffect(() => {
        axios('/api/user/getRegionais')
            .then(res => setRegionais(res.data))
            .catch(error => alert(error.response.data.message))
    }, [])

    const updateSearch = (e) => {
        const { value } = e.target
        setSearch(value)
    }

    const buscaItem = () => {
        axios.post('/api/user/searchUser', { search: search })
            .then(res => setUsersList(res.data))
            .catch(error => alert(error.response.data.message))
    }

    const loadUser = (usuario) => {
        setEditUser({})
        setEditUser(usuario)
        setKey(prevState => prevState + 1)
    }

    const updateUser = (e) => {
        const { name, value } = e.target
        let temp = { ...editUser }
        temp[name] = value
        setEditUser(temp)
    }

    const salvaUser = () => {
        axios.post('/api/user/saveUser', editUser)
            .then(res => {
                alert(res.data)
                window.location = '/user'
            })
            .catch(error => alert(error.response.data.message))
    }

    return (
        <>
            <Caixa>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "30vw auto auto", alignItems: "flex-end", justifyContent: "flex-start",
                    height: "100%", width: "auto", columnGap: "2vw"
                }}>
                    <Input texto="Buscar Usuário" nome="searchUser" widthWeb="30vw" widthMobile="80vw"
                        funcao={updateSearch} />
                    <Botao texto="BUSCAR" funcao={buscaItem}
                        align="left" widthMobile="40vw" display="inline-grid" />
                </div>
                {usersList.length > 0 ? (<>
                    <table style={{ margin: "2vh auto 2vh auto", width: "80vw" }}>
                        <thead>
                            <tr>
                                <th>NOME</th>
                                <th>POST</th>
                                <th>REGIONAL</th>
                                <th>FONTE</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersList.map(el => {
                                return (
                                    <tr key={el._id}>
                                        <td>{el.nome}</td>
                                        <td>{el.posto}</td>
                                        <td>{el.regional}</td>
                                        <td>{el.fonte}</td>
                                        <td>
                                            <Botao texto="Editar" widthWeb="8vw" funcao={() => loadUser(el)} />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </>) : (<></>)}
            </Caixa>
            <Caixa>
                <div style={{ textAlign: "center", fontWeight: 600, color: "#33348F" }}>NOVO/EDITAR USUÁRIO</div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} key={keyUser}>
                    <Input texto="Nome" nome="nome" widthWeb="30vw" widthMobile="80vw"
                        display="block" valor={editUser.nome} funcao={updateUser} />
                    <Input texto="Login" nome="matricula" widthWeb="30vw" widthMobile="80vw"
                        display="block" valor={editUser.matricula} funcao={updateUser} />
                    <Input texto="Matrícula" nome="numeroMatricula" widthWeb="30vw" widthMobile="80vw"
                        display="block" valor={editUser.numeroMatricula} funcao={updateUser} />
                    <Select texto="Posto" nome="posto" widthWeb="30vw" widthMobile="80vw"
                        display="block" valor={editUser.posto} funcao={updateUser}
                        options={["Soldado", "Cabo", "3º Sargento", "2º Sargento", "1º Sargento", "Subtenente",
                            "Aspirante", "2º Tenente", "1º Tenente", "Capitão", "Major", "Tenente Coronel", "Coronel"
                        ]} />
                    <Input texto="Contato" nome="contato" widthWeb="30vw" widthMobile="80vw"
                        display="block" valor={editUser.contato} funcao={updateUser} />
                    <Input texto="E-mail" nome="email" widthWeb="30vw" widthMobile="80vw"
                        display="block" valor={editUser.email} funcao={updateUser} />
                    <Select texto="Regional/Departamento" nome="regional" widthWeb="30vw" widthMobile="80vw"
                        display="block" valor={editUser.regional} funcao={updateUser}
                        options={regionais} />
                    <Select texto="Fonte" nome="fonte" widthWeb="30vw" widthMobile="80vw"
                        display="block" valor={editUser.fonte} funcao={updateUser}
                        options={["FUNESBOM", "CBM"]} />
                    <Select texto="Permissões" nome="tipo" widthWeb="30vw" widthMobile="80vw"
                        display="block" funcao={updateUser}
                        options={["Núcleo Estratégico", "CPL", "DFD para PCA", "DAL"]} />
                    <Input texto="Senha" nome="password" widthWeb="30vw" widthMobile="80vw" tipo="password"
                        display="block" funcao={updateUser} />
                    <Input texto="Repetir Senha" nome="repeatPassword" widthWeb="30vw" widthMobile="80vw" tipo="password"
                        display="block" funcao={updateUser} />
                    <Botao texto="SALVAR" funcao={salvaUser}
                        align="left" widthMobile="40vw" display="inline-grid" />
                </div>
            </Caixa>
        </>
    )
}

export default User