import { useState } from 'react'
import axios from 'axios'

import brasao from '../../assets/brasao.png'
import Input from '../Biblioteca/Input'
import Botao from '../Biblioteca/Botao'

const Login = () => {
    const [dados, setDados] = useState({})

    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const widthForm = mediaWeb ? "20vw" : "80vw"
    const widthBrasao = mediaWeb ? "8vw" : "30vw"

    const estilo = {
        container: {
            height: "100vh"
        },
        formulario: {
            width: widthForm,
            margin: "auto",
            textAlign: "center",
        },
        brasao: {
            marginTop: "20vh",
            marginBottom: "3vh",
            width: widthBrasao,
        }
    }

    const updateField = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }

    const login = () => {
        const url = '/api/auth'
        axios.post(url, dados)
            .then(res => {
                const { token, payload } = res.data
                localStorage.setItem('token', token)
                localStorage.setItem('id', payload.user.id)
                localStorage.setItem('nome', payload.user.nome)
                localStorage.setItem('matricula', payload.user.matricula)
                localStorage.setItem('tipo', payload.user.tipo)
                localStorage.setItem('area', payload.user.area)
                localStorage.setItem('regionalUser', payload.user.regionalUser)
                localStorage.setItem('numeroMatricula', payload.user.numeroMatricula)
                localStorage.setItem('email', payload.user.email)
                localStorage.setItem('contato', payload.user.contato)
                localStorage.setItem('posto', payload.user.posto)
                window.location = "/"
            })
            .catch(error => alert(error.response.data))
    }

    return (
        <div className="login" style={estilo.container}>
            <div style={estilo.formulario}>
                <img src={brasao} style={estilo.brasao} />
                <div>
                    <Input texto="Login" funcao={updateField} nome="matricula"
                        widthWeb="15vw" widthMobile="40vw" />
                </div>
                <Input texto="Senha" funcao={updateField} nome="password" tipo="password"
                    widthWeb="15vw" widthMobile="40vw" />
                <Botao texto="ENTRAR" widthMobile="20vw" funcao={login} />
            </div>
        </div>
    )
}


export default Login