import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const TestSocket = () => {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState('');

  useEffect(() => {
    const newSocket = io('http://localhost:5001');

    newSocket.on('connect', () => {
      newSocket.emit('conectado', { user: localStorage.nome, idConversation: '123' });
    });

    newSocket.on('response', (data) => {
      console.log(data)
      // setResponse(data);
    });

    // Definir o socket
    setSocket(newSocket);

    // Limpeza ao desmontar o componente
    return () => {
      newSocket.disconnect();
    };
  }, []);

  const handleSendMessage = () => {
    if (socket) {
      // Enviar mensagem para o servidor
      socket.emit('mensagem', { idConversation: '123', message });
    }
  };

  return (
    <div>
      <h1>Test SocketIO</h1>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button onClick={handleSendMessage}>Send</button>
      <p>Server Response: {response}</p>
    </div>
  );
};

export default TestSocket;
