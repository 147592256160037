import { useState } from "react"

import Caixa from "../../Biblioteca/Caixa"
import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import enviar from '../../Biblioteca/Funcoes/enviar'

const NovoDocumento = () => {
    const [dados, setDados] = useState({ user: localStorage.nome })
    const [disabled, setDisabled] = useState(false)

    const updateField = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }

    return (
        <>
            <Caixa>
                <Select texto="Concuído" nome="concluido" funcao={updateField} widthWeb="12vw" widthMobile="80vw"
                    options={["Sim", "Não"]} valor="Não" />
                <Input texto="PBDOC" nome="pbdoc" funcao={updateField} widthWeb="12vw" widthMobile="80vw" />
                <Input texto="Descrição" nome="descricao" funcao={updateField} widthWeb="12vw" widthMobile="80vw" />
                <Select texto="Remetente" nome="remetente" funcao={updateField} widthWeb="12vw" widthMobile="80vw"
                    options={["AJG", "DAL", "DF", "DP", "GABCMT", "CPL", "DAT", "EIPCP"]} />
                <Select texto="Destinatário" nome="destinatario" funcao={updateField} widthWeb="12vw" widthMobile="80vw"
                    options={["AJG", "DAL", "DF", "DP", "GABCMT", "CPL", "DAT", "PAULINO"]} />
                <Input texto="Observações" nome="obs" funcao={updateField} widthWeb="12vw" widthMobile="80vw" />
                <div>
                    <Botao texto="ENVIAR" funcao={() => enviar('/api/cpl/novoDocumento', dados, "/documentosCpl", setDisabled())} 
                    widthMobile="40vw" disabled={disabled} />
                </div>
            </Caixa>
        </>
    )
}

export default NovoDocumento