import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { MyContext } from "../Biblioteca/MyContext"

import brasao from '../../assets/brasao.png'
import "./ImpressaoDfd.css"

const ImpressaoDfd = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabelas, setTabelas] = useState([])
    const [cmd, setCmd] = useState()

    useEffect(() => {
        const url = '/api/orcamento/getPcaGeral'
        axios.post(url, { regional: localStorage.regionalUser })
            .then(res => setTabelas(res.data))
            .catch(error => alert(error.response.message))
    }, [])

    const estilo = {
        table: {
            width: "88vw", marginTop: "2vh", marginBottom: "2vh",
            marginLeft: "auto", marginRight: "auto",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: mediaWeb ? 'fixed' : null
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11,
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        }
    }

    return (
        <div>
            <div style={{ textAlign: "center", marginTop: "4vh", fontWeight: "600", marginBottom: "5vh" }}>
                <img src={brasao} style={{ width: "8vw" }} alt="Brasão" />
                <div>ESTADO DA PARAÍBA</div>
                <div>SECRETARIA DE SEGURANÇA E DEFESA SOCIAL</div>
                <div>CORPO DE BOMBEIROS MILITAR DA PARAÍBA</div>
            </div>
            <div style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", marginBottom: "4vh" }}>
                DOCUMENTO DE FORMALIZAÇÃO DE DEMANDA – DFD
            </div>
            <div style={{
                border: "1px solid black", width: "90vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#8db3e2", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    INFORMAÇÕES DO NÍVEL SETORIAL
                </div>
                <div style={{
                    borderBottom: "1px solid black",
                    padding: "5px 0 5px 5px"
                }}><b>NÍVEL SETORIAL: </b>Equipe Integrada de Planejamento de Compras e Projetos (EIPCP)</div>
                <div style={{ borderBottom: "1px solid black", display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>Bombeiro Militar responsável pelo Planejamento e esclarecimentos da Demanda: </b>
                        Maria Iolanda Ribeiro Mendes
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Matrícula CBMPB: </b> 523.369-1
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>E-mail: </b> maria.mendes@bombeiros.pb.gov.br
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Contato: </b> (83)98854-7384
                    </div>
                </div>
            </div>
            {renderTabelas()}
            <div style={{
                border: "1px solid black", width: "90vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#8db3e2", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    RESPONSABILIDADE PELA COMPILAÇÃO DA DEMANDA E CONTEÚDO DO DOCUMENTO
                </div>
                <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}><br />
                Certifico que as formalizações das demandas compiladas no presente documento se fazem necessárias pelos motivos expostos nas justificativas para cada área do presente documento, bem como nos DFDs em anexo, tudo em conformidade com o inciso VII do Art. 12 da Lei nº 14.133/2021 -  "VII - a partir de documentos de formalização de demandas, os órgãos responsáveis pelo planejamento de cada ente federativo poderão, na forma de regulamento, elaborar plano de contratações anual, com o objetivo de racionalizar as contratações dos órgãos e entidades sob sua competência, garantir o alinhamento com o seu planejamento estratégico e subsidiar a elaboração das respectivas leis orçamentárias."</div>
                <br />
            </div>
            <div style={{
                border: "1px solid black", width: "90vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#8db3e2", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    ANUÊNCIA DO ORDENADOR DE DESPESAS
                </div>
                <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}><br />
                    De acordo com a demanda e o quantitativo solicitado.
                </div><br />
                <div style={{ textAlign: "right", padding: "5px", fontWeight: "320" }}><br />
                    João Pessoa, 15 de março de 2024.
                </div><br />
                <div style={{ textAlign: "center", padding: "5px", fontWeight: "320" }}><br />
                    MARCELO AUGUSTO DE <b style={{fontWeight: "600"}}>ARAÚJO</b> BEZERRA - CEL QOBM <br />
                    Comandante Geral do CBMPB/Ordenador de Despesa<br/>
                    MAT. 516.508-3<br/>
                    Assinado via PBDOC
                </div><br />
            </div>
        </div>
    )

    function renderNivelSetorial() {
        if (["1º CRBM", "2º CRBM", "3º CRBM", "4º CRBM", "5º CRBM"].includes(localStorage.regionalUser)) {
            return localStorage.regionalUser
        } else return `Departamento (${localStorage.regionalUser})`
    }

    function RenderSomaTotal(items) {
        let somaTotal = items.reduce((acc, item) => {
            return acc + (item.valorEstimado * item.totalQtdEstimada)
        }, 0)
        return formatCurrency(somaTotal)
    }


    function renderTabelas() {
        if (tabelas && tabelas.length > 0) {
            return (
                <div style={{
                    border: "1px solid black", width: "90vw", fontSize: "12px",
                    marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
                }}>
                    <div style={{
                        borderBottom: "1px solid black", backgroundColor: "#8db3e2", fontSize: "16px",
                        textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                    }}>
                        INFORMAÇÕES DA NECESSIDADE DE CONTRATAÇÃO
                    </div>
                    <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                        <div style={{ textAlign: "center" }}><b>Descrição sucinta da solicitação:</b></div>
                        <br />
                        Formalização da Demanda para fins de Compilação do Planejamento do Plano de Contratação Anual 2024 das unidades requisitantes no âmbito da corporação.
                    </b></div>
                    <table style={estilo.table}>
                        <thead>
                            <tr>
                                <th style={estilo.th}>OBJETO</th>
                                <th style={estilo.th}>DESCRIÇÃO</th>
                                <th style={estilo.th}>UND. DE MEDIDA</th>
                                <th style={estilo.th}>2020</th>
                                <th style={estilo.th}>2021</th>
                                <th style={estilo.th}>2022</th>
                                <th style={estilo.th}>QTD. ESTIMADA</th>
                                <th style={estilo.th}>VALOR UNITÁRIO</th>
                                <th style={estilo.th}>VALOR TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderRows(tabelas)}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="7" style={{
                                    ...estilo.th, backgroundColor: "#8db3e2", color: "black", textAlign: "left"
                                }}>
                                    VALOR TOTAL DO DOCUMENTO DE FORMALIZAÇÃO DA DEMANDA
                                </th>
                                <th colSpan="2" style={{
                                    ...estilo.th, backgroundColor: "#8db3e2", color: "black", textAlign: "left"
                                }}>
                                    {RenderSomaTotal(tabelas)}
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            )
        }
    }

    function formatCurrency(value) {
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }

    function renderRows(items) {
        return items.map((item, index) => {
            const valorTotal = item.valorEstimado * item.totalQtdEstimada
            if (item.totalQtdEstimada > 0) {
                return (
                    <tr key={index}>
                        <td style={estilo.td}>{item.objeto}</td>
                        <td style={estilo.td}>{item.descricao}</td>
                        <td style={estilo.td}>{item.und}</td>
                        <td style={estilo.td}>{item.totalAno1}</td>
                        <td style={estilo.td}>{item.totalAno2}</td>
                        <td style={estilo.td}>{item.totalAno3}</td>
                        <td style={estilo.td}>{item.totalQtdEstimada}</td>
                        <td style={estilo.td}>{formatCurrency(item.valorEstimado)}</td>
                        <td style={estilo.td}>{formatCurrency(valorTotal)}</td>
                    </tr>
                )
            }
        })
    }
}

export default ImpressaoDfd
