import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { MyContext } from "../Biblioteca/MyContext"

import brasao from '../../assets/brasao.png'
import "./ImpressaoDfd.css"

const ImpressaoDfd = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabelas, setTabelas] = useState([])
    const [cmd, setCmd] = useState()

    useEffect(() => {
        const url = '/api/orcamento/getByRegional'
        axios.post(url, { regional: localStorage.regionalUser })
            .then(res => setTabelas(res.data))
            .catch(error => alert(error.response.message))
    }, [])
    
    useEffect(() => {
        const url = '/api/orcamento/getCmdAss'
        axios.post(url, { regional: localStorage.regionalUser })
            .then(res => setCmd(res.data))
            .catch(error => alert(error.response.message))
    }, [])

    const estilo = {
        table: {
            width: "88vw", marginTop: "2vh", marginBottom: "2vh",
            marginLeft: "auto", marginRight: "auto",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: mediaWeb ? 'fixed' : null
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11,
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        }
    }
    
    return (
        <div>
            <div style={{ textAlign: "center", marginTop: "4vh", fontWeight: "600", marginBottom: "5vh" }}>
                <img src={brasao} style={{ width: "8vw" }} />
                <div>ESTADO DA PARAÍBA</div>
                <div>SECRETARIA DE SEGURANÇA E DEFESA SOCIAL</div>
                <div>CORPO DE BOMBEIROS MILITAR DA PARAÍBA</div>
            </div>
            <div style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", marginBottom: "4vh" }}>
                DOCUMENTO DE FORMALIZAÇÃO DE DEMANDA – DFD
            </div>
            <div style={{
                border: "1px solid black", width: "90vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#8db3e2", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    INFORMAÇÕES DO NÍVEL SETORIAL
                </div>
                <div style={{
                    borderBottom: "1px solid black",
                    padding: "5px 0 5px 5px"
                }}><b>NÍVEL SETORIAL: </b>{RenderNivelSetorial()}</div>
                <div style={{ borderBottom: "1px solid black", display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>Bombeiro Militar  reponsável pelo Planejamento e esclarecimentos da Demanda: </b>
                        {localStorage.nome}
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Matrícula CBMPB: </b> {localStorage.numeroMatricula}
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>E-mail: </b> {localStorage.email}
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Contato: </b> {localStorage.contato}
                    </div>
                </div>
            </div>
            {RenderTabelas()}
            <div style={{
                border: "1px solid black", width: "90vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#8db3e2", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    RESPONSABILIDADE PELA FORMALIZAÇÃO DA DEMANDA E CONTEÚDO DO DOCUMENTO
                </div>
                <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}><br />
                    Certifico que a formalização da demanda se faz necessária pelos motivos expostos nas
                    justificativas para cada área do presente documento e atendimento a
                    Lei nº 14.133/2021.</div>
                <br />
            </div>
            <div style={{ textAlign: "center", marginTop: "8vh" }}><b>
                ______________________________________________________<br />
                {localStorage.nome} - {localStorage.posto}<br />
                {localStorage.numeroMatricula} - {localStorage.regionalUser}
            </b></div>
            {cmd !== undefined ? (
                <div style={{ textAlign: "center", marginTop: "8vh" }}><b>
                    ______________________________________________________<br />
                    {cmd.nome} - {cmd.posto}<br />
                    {cmd.numeroMatricula} - {cmd.regional}
                </b></div>
            ) : (<></>)}
        </div>
    )

    function RenderNivelSetorial() {
        if (localStorage.regionalUser === "1º CRBM" || localStorage.regionalUser === "2º CRBM" ||
            localStorage.regionalUser === "3º CRBM" || localStorage.regionalUser === "4º CRBM" ||
            localStorage.regionalUser === "5º CRBM") {
            return localStorage.regionalUser
        } else return `Departamento (${localStorage.regionalUser})`
    }

    function RenderTabelas() {
        let dfds = []
        if (tabelas !== undefined) {
            Object.keys(tabelas).map(obj => {
                var titulo = obj
                if (obj === "Ajudância") titulo = "Material Administrativo"
                if (obj === "DEFID") titulo = "Material Esportivo"
                dfds.push(
                    <div style={{
                        border: "1px solid black", width: "90vw", fontSize: "12px",
                        marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
                    }}>
                        <div style={{
                            borderBottom: "1px solid black", backgroundColor: "#8db3e2", fontSize: "16px",
                            textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                        }}>
                            INFORMAÇÕES DA NECESSIDADE DE CONTRATAÇÃO ({titulo})
                        </div>
                        <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                            <div style={{ textAlign: "center" }}><b>Descrição sucinta da solicitação:</b></div>
                            <br />
                            Formalização da Demanda para fins de Planejamento de Contratação Anual 2024 de objetos e
                            serviços comuns para desenvolvimento das ações e ativividades fins e meio no âmbito da corporação.
                        </b></div>
                        <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                            <div style={{ textAlign: "center" }}><b>Necessidade / Justificativa técnica da contratação para itens:</b></div>
                            <br />
                            {tabelas[obj].justificativa}
                        </b></div>
                        <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                            <div style={{ textAlign: "center" }}><b>Resultados Pretendidos:</b></div>
                            <br />
                            {tabelas[obj].resultados}
                        </b></div>
                        <table style={estilo.table}>
                            <thead>
                                <tr>
                                    <th rowSpan="2" style={{ ...estilo.th, width: "20vw" }}>OBJETO</th>
                                    <th rowSpan="2" style={{ ...estilo.th, width: "30vw" }}>DESCRIÇÃO BASE DE REFERÊNCIA</th>
                                    <th rowSpan="2" style={estilo.th}>UND. DE MEDIDA</th>
                                    <th className="thDfd" colSpan="3">QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                                    <th rowSpan="2" style={estilo.th}>QTD. ESTIMADA</th>
                                    <th rowSpan="2" style={estilo.th}>VALOR ESTIMADO UNITÁRIO</th>
                                    <th rowSpan="2" style={estilo.th}>VALOR TOTAL</th>
                                </tr>
                                <tr>
                                    <th className="thDfd">2021</th>
                                    <th className="thDfd">2022</th>
                                    <th className="thDfd">2023</th>
                                </tr>
                            </thead>
                            <tbody>
                                {RenderRows(tabelas[obj].items)}
                            </tbody>
                            <thead>
                                <tr>
                                    <th colSpan="5" style={{
                                        ...estilo.th, backgroundColor: "#8db3e2", color: "black", textAlign: "left"
                                    }}>
                                        VALOR TOTAL DO DOCUMENTO DE FORMALIZAÇÃO DA DEMANDA
                                    </th>
                                    <th colSpan="4" style={{
                                        ...estilo.th, backgroundColor: "#8db3e2", color: "black", textAlign: "left"
                                    }}>
                                        {RenderSomaTotal(tabelas[obj].items, obj)}
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                )
            })
        }
        return dfds
    }

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderSomaTotal(array, obj) {
        let somaTotal = 0
        array.map(el => {
            const indexRegional = el.regionais.findIndex(x => x.regional === localStorage.regionalUser)
            somaTotal += el.valorEstimado * el.regionais[indexRegional].qtdEstimada
        })
        return formatCurrency(somaTotal)
    }

    function RenderRows(array) {
        let rows = []
        array.map((el, index) => {
            const indexRegional = el.regionais.findIndex(x => x.regional === localStorage.regionalUser)
            const valorTotal = el.valorEstimado * el.regionais[indexRegional].qtdEstimada
            if (el.regionais[indexRegional].qtdEstimada !== 0) {
                const descricao = el.descricao.length > 200 ?
                    el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
                rows.push(
                    <tr key={`objeto${index}`}>
                        <td style={estilo.td}>{el.objeto}</td>
                        <td style={estilo.td}>{descricao}</td>
                        <td style={estilo.td}>{el.und}</td>
                        <td className="tdDfd">{el.regionais[indexRegional].ano1}</td>
                        <td className="tdDfd">{el.regionais[indexRegional].ano2}</td>
                        <td className="tdDfd">{el.regionais[indexRegional].ano3}</td>
                        <td style={estilo.td}>{el.regionais[indexRegional].qtdEstimada}</td>
                        <td style={estilo.td}>{formatCurrency(el.valorEstimado)}</td>
                        <td style={estilo.td}>{formatCurrency(valorTotal)}</td>
                    </tr>
                )
            } 
            // else console.log(el.objeto, (el.valorEstimado))
        })
        return rows
    }
}

export default ImpressaoDfd