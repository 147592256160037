import { useState } from 'react'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import enviar from '../../Biblioteca/Funcoes/enviar'

const RegionalFonte = () => {
    const [dados, setDados] = useState({userId: localStorage.id})

    const updateField = (e) => {
        const { value, name } = e.target
        const tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }

    return (
        <>
            <div style={{ width: "100%", textAlign: "center" }}>
                <Select texto="Regional/Departamento" nome="regional"
                    funcao={updateField} widthWeb="26vw" widthMobile="80vw"
                    options={["CBMPB", "1º CRBM", "2º CRBM", "3º CRBM", "4º CRBM", "Operações com Cães", 
                    "Projetos Sociais", "DAT", "Operações Aéreas", "Diretoria de Ensino, Instrução e Pesquisa", 
                    "Tecnologia da Informação", "Mergulho Autônomo", "DEFID", "Banda de Música", 
                    "Gabinete Médico", "Ajudância", "Gabinete Odontológico", "Núcleo Estratégico"]} />
                <Select texto="Fonte" nome="fonte"
                    funcao={updateField} widthWeb="26vw" widthMobile="80vw"
                    options={["CBM", "FUNESBOM"]} />
            </div>
            <Botao texto="Salvar" marginB="1vw"
                        funcao={() => enviar('/api/pca/updateUserInfo', dados, localStorage.setItem("regionalUser", dados.regional))} />
        </>
    )
}

export default RegionalFonte