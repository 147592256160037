import React, { useState, useContext } from 'react'
import './Nav.css'

import NavInterno from './NavInterno'
import NavGeral from './NavGeral'
import NavCpl from './NavCpl'
import NavDf from './NavDf'
import NavTransitorio from './NavTransitorio'
import NavDfd from './NavDfd'
import NavEm2 from './NavEm2'
import NavDal from './NavDal'

import brasao from '../../assets/brasao.png'
import Tema from '../Biblioteca/Tema'
import { MyContext } from "../Biblioteca/MyContext"

const Nav = () => {
  const mediaWeb = window.matchMedia('(min-width:600px)').matches
  const [display, setDisplay] = useState('none')

  const { myState } = useContext(MyContext)

  // Estilos para telas acima de 600px
  const navbarWebStyle = {
    width: '100%',
    height: '8vh',
    backgroundColor: myState === "light" ? '#F9F9F9' : "black",
    top: 0,
    left: 0,
    zIndex: 999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  // Estilos para telas abaixo de 600px
  const navbarMobileStyle = {
    width: '100%',
    height: '6vh',
    backgroundColor: myState === "light" ? '#F9F9F9' : "black",
    top: 0,
    left: 0,
    zIndex: 999,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem',
  }

  const brasaoStyle = {
    marginLeft: '0.5rem',
    maxHeight: mediaWeb ? '80%' : '90%',
    flex: '0 0 auto',
  }
  const logoutStyle = {
    color: myState === "light" ? 'black' : "#F9F9F9",
    marginLeft: '2.5rem',
    marginRight: '0.5rem',
    cursor: 'pointer',
    fontSize: '11px',
    fontWeight: 550,
    flex: '0 0 auto',
  }

  const logout = () => {
    localStorage.clear()
    window.location = '/'
  }

  let navTipo = null
  if (localStorage.tipo === 'interno') {
    navTipo = <NavInterno />
  } if (localStorage.tipo === 'geral') {
    navTipo = <NavGeral />
  } else if (localStorage.tipo === 'cpl') {
    navTipo = <NavCpl />
  } else if (localStorage.tipo === 'df') {
    navTipo = <NavDf />
  } else if (localStorage.tipo === 'transitorio' || localStorage.tipo === 'centralDeCompras') {
    navTipo = <NavTransitorio />
  } else if (localStorage.tipo === 'dfd') {
    navTipo = <NavDfd />
  } else if (localStorage.tipo === 'EM2') {
    navTipo = <NavEm2 />
  } else if (localStorage.tipo === 'dal') {
    navTipo = <NavDal />
  }

  return (
    <div className='nav'>
      <div style={mediaWeb ? navbarWebStyle : navbarMobileStyle}>
        <img src={brasao} alt="Brasão" style={brasaoStyle} />
        {mediaWeb ? (
          <>
            <Tema />
            {navTipo}
            <div style={logoutStyle} onClick={logout}>
              LOGOUT
            </div>
          </>
        ) : (
          <>
            <Tema />
            <div style={{ position: 'relative' }}>
              <i
                className="bi bi-list"
                onClick={() => setDisplay(display === 'block' ? 'none' : 'block')}
                style={{
                  color: myState === "light" ? 'black' : "#F9F9F9",
                  marginLeft: '0.5rem',
                  cursor: 'pointer',
                  fontSize: '24px',
                  flex: '0 0 auto',
                }}
              ></i>
              <div
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: -200,
                  right: 0,
                  width: "60vw",
                  marginTop: "1vh",
                  backgroundColor: myState === "light" ? '#F9F9F9' : "black",
                  padding: '0.5rem',
                  display: display === 'block' ? 'flex' : 'none',
                  zIndex: 999,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {navTipo}
                <div className="mt-2">
                  <a onClick={logout} style={{
                    color: myState === "light" ? 'black' : "#F9F9F9",
                    marginLeft: 'auto',
                    marginRight: '1rem',
                    fontWeight: 550,
                    fontSize: "13px",
                    cursor: 'pointer',
                    textDecoration: 'none'
                  }}>
                    LOGOUT
                  </a>
                </div>
              </div>
            </div>
          </>
        )
        }
      </div >
    </div>
  )
}

export default Nav
