import { useState } from "react"
import axios from 'axios'

import Caixa from "../../Biblioteca/Caixa"
import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import enviar from "../../Biblioteca/Funcoes/enviar"

const NovoAditivo = () => {
    const [dados, setDados] = useState({ user: localStorage.nome })
    const [disabled, setDisabled] = useState(false)

    const updateField = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }

    return (
        <Caixa>
            <Input texto="Nº da Solicitação" nome="numeroSolicitacao" widthWeb="14vw" widthMobile="80vw"
                funcao={updateField} tipo="number" />
            <Input texto="PBDOC" nome="pbdoc" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Select texto="Andamento" nome="andamento" funcao={updateField} widthWeb="14vw" widthMobile="80vw"
                options={["Abertura", "Pesquisa", "DF-RO", "Comitê Gestor", "Nota Técnica",
                    "PGE", "Ass. CMDG", "CGE", "DF-Empenho", "SEAD", "Cancelado", "QRX"]} />
            <Input texto="Data do Andamento" nome="dataAndamento" tipo="date"
                funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Nº do Contrato" nome="numeroContrato" widthWeb="14vw" widthMobile="80vw"
                funcao={updateField} tipo="number" />
            <Input texto="Objeto" nome="objeto" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Observações" nome="obs" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Prazo PGE" nome="prazoPge" tipo="date"
                funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <div>
                <Botao texto="ENVIAR" funcao={() => enviar('/api/cpl/novoAditivo', dados, "/aditivosCpl", setDisabled())}
                    widthMobile="40vw" disabled={disabled} />
            </div>
        </Caixa>
    )
}

export default NovoAditivo