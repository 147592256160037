import { useState, useEffect, useContext } from 'react'
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios'

import loading from '../../assets/loading.gif'

const TabelasPorAreas = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabelasPorArea, setTabelas] = useState()
    const [display, setDisplay] = useState([])
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        axios.get('/api/orcamento/tabelasPorArea')
            .then(res => {
                setTabelas(res.data)
                const objeto = { display: "none" }
                const copias = Array(Object.keys(res.data).length).fill(objeto)
                setDisplay([...copias])
                setLoaded(true)
            })
            .catch(error => alert(error))
    }, [])

    const alterarDisplay = (index, novoValor) => {
        const novoArray = [...display]
        novoArray[index] = { ...novoArray[index], display: novoValor }
        setDisplay(novoArray)
    }

    const estilo = {
        table: {
            width: mediaWeb ? "98vw" : "100%", margin: "auto", marginTop: "2vh", marginBottom: "2vh",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: mediaWeb ? 'fixed' : null
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        },
        input: {
            border: "none", outline: "none", backgroundColor: "#00000000",
            color: myState === "light" ? 'black' : "#FFFF", width: "96%",
            resize: "none", overflow: "hidden"
        }
    }

    return (
        <>
            {!loaded ? (
                <div style={{textAlign: "center", marginTop: "10vh"}}>
                    <img style={{ width: "5vw" }} src={loading} />
                </div>
            ) : null}
            {RenderTabelas()}
        </>
    )

    function RenderTabelas() {
        let tabelas = []
        if (tabelasPorArea !== undefined) {
            Object.keys(tabelasPorArea).map((obj, index) => {
                tabelas.push(
                    <>
                        <div style={{
                            width: "70vw", padding: "10px",
                            marginTop: "2vh", marginLeft: "auto", marginRight: "auto",
                            backgroundColor: "#ccc", borderRadius: "8px",
                        }}>
                            <div style={{
                                width: "64vw", display: "inline-block",
                                textAlign: "center", fontSize: "18px"
                            }}>
                                {obj}
                            </div>
                            <button name="mais" style={{
                                backgroundColor: "#FFFF", color: "#ccc",
                                borderRadius: "50%", border: "none",
                                width: "2vw", height: "2vw",
                                backgroundColor: display[index].display === "none" ? "green" : "red",
                                color: "#FFFF",
                            }}
                                onClick={() => display[index].display === "none" ?
                                    alterarDisplay(index, "block") : alterarDisplay(index, "none")}
                            >
                                {display[index].display === "none" ? "+" : "-"}
                            </button>
                        </div>
                        <table style={{ ...estilo.table, display: display[index].display }}>
                            <thead>
                                <tr>
                                    <th style={{ ...estilo.th, width: "25vw" }}>OBJETO</th>
                                    <th style={estilo.th}>DESCRIÇÃO DO OBJETO</th>
                                    <th style={{ ...estilo.th, width: "10vw" }}>UNIDADE</th>
                                    <th style={{ ...estilo.th, width: "10vw" }}>VALOR ESTIMADO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {RenderItens(tabelasPorArea[obj])}
                            </tbody>
                        </table>
                    </>
                )
            })
        }
        return tabelas
    }

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderItens(array) {
        let rows = []
        array.map(el => {
            if (el.area !== "Área de Teste") {

                rows.push(
                    <tr>
                        <td style={estilo.td}>{el.objeto}</td>
                        <td style={estilo.td}>{el.descricao}</td>
                        <td style={estilo.td}>{el.und}</td>
                        <td style={estilo.td}>{formatCurrency(el.valorEstimado || '')}</td>
                    </tr>
                )
            }
        })
        return rows
    }
}

export default TabelasPorAreas