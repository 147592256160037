const calcularPrazo = (data) => {
    var hoje = new Date();
  
    var dataFornecida = new Date(data);
  
    var diferencaEmMilissegundos = dataFornecida - hoje;
  
    var diferencaEmDias = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
  
    return diferencaEmDias + " dias";
  }

  export default calcularPrazo
  