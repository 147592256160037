import { useState, useEffect } from "react"
import axios from 'axios'

import './NovoDed.css'
import Caixa from "../Biblioteca/Caixa"
import Input from "../Biblioteca/Input"
import Select from "../Biblioteca/Select"
import Textarea from "../Biblioteca/Textarea"
import Botao from "../Biblioteca/Botao"
import enviar from "../Biblioteca/Funcoes/enviar"

const NovoDed = () => {
    const [dados, setDados] = useState({
        user: localStorage.nome, qtdDfds: 0, dfds: [],
        qtdObjetivo: 0, objetivoEstrategico: [],
        qtdMeta: 0, metaPpa: [],
        qtdNatureza: 0, naturezas: [],
        qtdItens: 0, itens: [],
    })
    const [objetos, setObjetos] = useState([])
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        const url = '/api/ded/getAllObjetos'
        axios.get(url)
            .then(res => setObjetos(res.data))
            .catch(error => alert(error.response.data.message))
    }, [])

    const updateQtd = (e, object) => {
        const { name } = e.target
        const { campoQtd, campo } = object
        let tempState = { ...dados }
        if (name === "menos") {
            if (tempState[campoQtd] > 0) {
                tempState[campoQtd] -= 1
                tempState[campo].pop()
                setDados(tempState)
            }
            else return null
        }
        else if (name === "mais") {
            tempState[campoQtd] += 1
            setDados(tempState)
            let dfdsCopy = [...tempState[campo]]
            dfdsCopy.push({})
            setDados({ ...tempState, [campo]: dfdsCopy })
        }
    }
    const updateQtdItens = (e, object) => {
        const { name } = e.target
        const { campoQtd, campo } = object
        let tempState = { ...dados }
        if (name === "menos") {
            if (tempState[campoQtd] > 0) {
                tempState[campoQtd] -= 1
                tempState[campo].pop()
                setDados(tempState)
            }
            else return null
        }
        else if (name === "mais") {
            tempState[campoQtd] += 1
            setDados(tempState)
            let dfdsCopy = [...tempState[campo]]
            dfdsCopy.push({})
            setDados({ ...tempState, [campo]: dfdsCopy })
        }
    }

    const updateField = (e, n, object) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        if (object) {
            const { campo } = object
            let copy = [...tempState[campo]]
            copy[n - 1][name] = value
            setDados({ ...tempState, [campo]: copy })
        }
        else {
            tempState[name] = value
            setDados(tempState)
        }
    }
    const updateFieldItens = (e, n) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        let copy = [...tempState.itens]

        if (name === 'item') {
            const index = parseInt(value.split('-').pop(), 10)
            const selectedObj = objetos[index - 1]
            copy[n - 1] = {
                ...copy[n - 1],
                item: selectedObj.objeto,
                descricao: selectedObj.descricao,
                id: selectedObj.id,
                indexEipcp: index,
                valorEstimado: selectedObj.valorEstimado,
            }
        }
        else if (name == "valorEstimado") {
            copy[n - 1][name] = parseInt(String(value).replace("R$", "").trim().replace(/\./g, "").replace(",", ""), 10)/100
        }
        else {
            copy[n - 1][name] = value
        }

        setDados({ ...tempState, itens: copy })
    }
    console.log(dados)
    return (
        <Caixa>
            <div className="headerDed">INFORMAÇÕES DA EXECUÇÃO DA DEMANDA </div>
            <Select texto="Previsão no PCA" nome="previstoPca" funcao={updateField} widthWeb="25vw" widthMobile="80vw"
                options={["SIM, conforme DFD( ‘s) em anexo",
                    "NÃO, conforme DFD Extra e justificativa da autoridade competente em anexo"]} />
            {dados.previstoPca === "SIM, conforme DFD( ‘s) em anexo" ? (
                <>
                    <div>
                        <div className="principal">
                            DFDs
                            <div className="direita">
                                <button className="menos"
                                    name="menos"
                                    onClick={e => updateQtd(e, { campoQtd: "qtdDfds", campo: "dfds" })}>-</button>
                                <input className="quantidade" value={dados.qtdDfds} />
                                <button className="mais"
                                    name="mais"
                                    onClick={e => updateQtd(e, { campoQtd: "qtdDfds", campo: "dfds" })}>+</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {RenderDfds()}
                    </div>
                </>
            ) : (<></>)}
            <div>
                <div className="principal">
                    Área/Objetivo(s) Estratégico(s) a ser(em) atingido(s)
                    <div className="direita">
                        <button className="menos"
                            name="menos"
                            onClick={e => updateQtd(e, { campoQtd: "qtdObjetivo", campo: "objetivoEstrategico" })}>-</button>
                        <input className="quantidade" value={dados.qtdObjetivo} />
                        <button className="mais"
                            name="mais"
                            onClick={e => updateQtd(e, { campoQtd: "qtdObjetivo", campo: "objetivoEstrategico" })}>+</button>
                    </div>
                </div>
            </div>
            <div>
                {RenderObjetivos()}
            </div>
            <div>
                <div className="principal">
                    Meta(s) PPA (2024/2027)
                    <div className="direita">
                        <button className="menos"
                            name="menos"
                            onClick={e => updateQtd(e, { campoQtd: "qtdMeta", campo: "metaPpa" })}>-</button>
                        <input className="quantidade" value={dados.qtdMeta} />
                        <button className="mais"
                            name="mais"
                            onClick={e => updateQtd(e, { campoQtd: "qtdMeta", campo: "metaPpa" })}>+</button>
                    </div>
                </div>
            </div>
            <div>
                {RenderMetas()}
            </div>
            <hr />
            <div>
                <Select texto="Produto LOA/2024" nome="produtoLoa" funcao={updateField} widthWeb="25vw" widthMobile="80vw"
                    options={[
                        "0703,Despesas de Exercícios Anteriores", "0751,Indenizações e Restituições",
                        "1157,Unidade construída ou reformada", "1614,Imóvel adquirido/locado", "2143,Pessoa formada e capacitada",
                        "2216,Serviço mantido", "2217,Unidade militar mantida", "4391,Manutenção realizada",
                        "4392,Atividade realizada", "4393,Atendimento realizado", "4938,Semovente adquirido mantido",
                        "6106,Projeto desenvolvido", "4211,Seguros e Taxas de Veículos", "4216,Manutenção de Serviços Administrativo",
                        "4780,Seguros e Taxas de Aeronaves"
                    ]} />
            </div>
            <div>
                <Select texto="Estratégia de aquisição" nome="estrategiaAquisicao" funcao={updateField} widthWeb="25vw" widthMobile="80vw"
                    options={["UTILIZAÇÃO DE ATA DE REGISTRO DE PREÇO", "ADESÃO DE ATA DE REGISTRO DE PREÇO",
                        "PREGÃO ELETRÔNICO", "PREGÃO ELETRÔNICO PARA REGISTRO DE PREÇO", "CONCORRÊNCIA",
                        "INEXIGIBILIDADE - Art. 74",
                        "DISPENSA - Art. 75, caput, inciso I - obras e serviços de engenharia ou de serviços de manutenção de veículos automotores",
                        "DISPENSA - Art. 75, caput, inciso II - outros serviçoes e compras",
                        "DISPENSA - Art. 75, caput, inciso VIII - nos casos de emergência ou calamidade pública"]} />
            </div>
            <hr />
            <div className="headerDed">INFORMAÇÕES DA CONTRATAÇÃO </div>
            <div>
                <div className="principal">
                    Natureza dos Itens
                    <div className="direita">
                        <button className="menos"
                            name="menos"
                            onClick={e => updateQtd(e, { campoQtd: "qtdNatureza", campo: "naturezas" })}>-</button>
                        <input className="quantidade" value={dados.qtdNatureza} />
                        <button className="mais"
                            name="mais"
                            onClick={e => updateQtd(e, { campoQtd: "qtdNatureza", campo: "naturezas" })}>+</button>
                    </div>
                </div>
            </div>
            <div>
                {RenderNaturezas()}
            </div>
            <Textarea texto="Descrição sucinta da solicitação" nome="descricaoSolicitacao" funcao={updateField}
                default="Aquisição dos itens acima visa dar prosseguimento às formalizações das demandas constantes nos DFDs em anexo que resultaram no PCA CBMPB 2024 com vistas a aquisição de " />
            <Textarea texto="Necessidade/Justificativa técnica da contratação" nome="necessidadeContratacao" funcao={updateField}
                default="A presente demanda visa dar condições para que o Corpo de Bombeiros Militar da PB atinja suas metas e produtos constantes nos instrumentos de planejamento citados acima a partir da aquisição de " />
            <Textarea texto="Resultados Pretendidos" nome="resultadosPretendidos" funcao={updateField}
                default="Os resultados pretendidos constam na documentação em anexo que deu origem ao presente DED que de forma resumida trará " />
            <div>
                <div className="principal">
                    Itens
                    <div className="direita">
                        <button className="menos"
                            name="menos"
                            onClick={e => updateQtdItens(e, { campoQtd: "qtdItens", campo: "itens" })}>-</button>
                        <input className="quantidade" value={dados.qtdItens} />
                        <button className="mais"
                            name="mais"
                            onClick={e => updateQtdItens(e, { campoQtd: "qtdItens", campo: "itens" })}>+</button>
                    </div>
                </div>
            </div>
            <div>
                {RenderItens()}
            </div>
            <Textarea texto="Requisitos necessários para a contratação" nome="requisitosNecessarios" funcao={updateField}
                default="Conforme requisitos constantes nos DFDs em anexo, bem como " />
            <Textarea texto="Providências a serem adotadas pela administração previamente à contratação" nome="providencias" funcao={updateField}
                default="Conforme providências constantes nos DFDs em anexo, bem como " />
            <Textarea texto="Possíveis impactos ambientais e respectivas medidas de tratamento, se for o caso" nome="impactos" funcao={updateField} />

            <Botao texto="Enviar" funcao={() => enviar('/api/ded/novoDed', dados, '/novoDed', disabled)} />
        </Caixa>
    )

    function RenderDfds() {
        const options = [
            "1º CRBM", "2º CRBM", "3º CRBM", "4º CRBM", "Ajudância", "DEFID",
            "Diretoria de Ensino, Instrução e Pesquisa", "Gabinete Médico", "Gabinete Odontológico",
            "Mergulho Autônomo", "Operações Aéreas", "Operações com Cães", "Projetos Sociais",
            "Tecnologia da Informação", "DAT", "Banda de Música", "Núcleo Estratégico"
        ]
        let dfds = []
        for (let n = 1; n <= dados.qtdDfds; n++) {
            dfds.push(
                <Select texto="Regional/Setor" nome="dfd" funcao={e => updateField(e, n, { campo: "dfds" })}
                    widthWeb="25vw" widthMobile="80vw" options={options} />
            )
        }
        return dfds
    }

    function RenderObjetivos() {
        const options = [
            "PERSPECTIVA DE RECURSOS - Governança Corporativa: Otimizar a Gestão Orçamentária e Financeira com foco na eficiência dos gastos, captação de recursos e controle interno.",
            "PERSPECTIVA DA SOCIEDADE - Bem-estar da Sociedade: Aprimorar o atendimento à sociedade integralizando ações operativas e preventivas.",
            "PERSPECTIVA DA SOCIEDADE - Responsabilidade Social: Fortalecer e expandir os projetos de responsabilidade social da corporação junto à comunidade.",
            "PERSPECTIVA DO CLIENTE - Excelência em Qualidade: Garantir um alto padrão de qualidade com assertividade e resolutividade de problemas.",
            "PERSPECTIVA DO CLIENTE - Parcerias Estratégicas: Ampliar e consolidar as parcerias institucionais.",
            "PERSPECTIVA DO CLIENTE - Confiança e Credibilidade: Incrementar os padrões de atendimento técnico e humanístico.",
            "PERSPECTIVA INTERNA - TIC - Tecnologia da Informação: Aprimorar a infraestrutura e os serviços de Tecnologia da Informação e Comunicação para a corporação.",
            "PERSPECTIVA INTERNA - Comunicação Eficaz: Fortalecer, unificar, incrementar e diversificar a comunicação interna e externa.",
            "PERSPECTIVA INTERNA - Eficiência Organizacional:Aprimorar a gestão operacional e administrativa.",
            "PERSPECTIVA INTERNA - Planejamento: Aperfeiçoar os processos de consulta e programação de necessidades logísticas e de pessoal dos setores administrativos e operacionais.",
            "PERSPECTIVA DE APRENDIZADO E CRESCIMENTO - Inovação: Incorporar inovações em todas as áreas:atendimento, tecnológica, técnica e operacional, e processos internos.",
            "PERSPECTIVA DE APRENDIZADO E CRESCIMENTO - Gestão das Pessoas: Desenvolver e agregar continuamente o sistema de gestão de pessoas, buscando consolidar crescimento e um alto padrão de Qualidade de Vida no Trabalho.",
            "PERSPECTIVA DE APRENDIZADO E CRESCIMENTO - Educação e Competência:Fortalecer a base e o programa Educacional da corporação visando capacitar e desenvolver as pessoas."
        ]
        let objetivos = []
        for (let n = 1; n <= dados.qtdObjetivo; n++) {
            objetivos.push(
                <Select texto="Objetivo" nome="objetivo" funcao={e => updateField(e, n, { campo: "objetivoEstrategico" })}
                    widthWeb="25vw" widthMobile="80vw" options={options} />
            )
        }
        return objetivos
    }

    function RenderMetas() {
        const options = [
            "Quantidade de Unidades Administrativas e/ou operacionais ativadas",
            "Concursos públicos e/ou seleções internas realizados", "Número total de pessoas formadas e/ou capacitadas",
            "Número de Unidades Bombeiros Militar construídas, reformadas ou ampliadas",
            "Quantidade de Imóveis locados ou ativado", "Serviço de Resgate Aeromédico mantido",
            "Número de Unidades Bombeiros Militar mantidas",
            "Número total de Viaturas operacionais e administrativas mantidas/adquiridas/locadas",
            "Quantidade total de atividades técnicas realizadas", "Quantidade total de intervenções diretas realizadas",
            "Número total de semoventes adquiridos", "Quantidade de projetos em funcionamento"
        ]
        let metas = []
        for (let n = 1; n <= dados.qtdMeta; n++) {
            metas.push(
                <Select texto="Meta/Indicador" nome="meta" funcao={e => updateField(e, n, { campo: "metaPpa" })}
                    widthWeb="25vw" widthMobile="80vw" options={options} />
            )
        }
        return metas
    }
    function RenderNaturezas() {
        const options = [
            "Opção 1", "Opção 2"
        ]
        let metas = []
        for (let n = 1; n <= dados.qtdNatureza; n++) {
            metas.push(
                <Select texto="Natureza" nome="natureza" funcao={e => updateField(e, n, { campo: "naturezas" })}
                    widthWeb="25vw" widthMobile="80vw"
                    options={["MATERIAL DE CONSUMO", "SERVIÇO CONTINUADO", "OBRA", "FORNECIMENTO CONTÍNUO",
                        "EQUIPAMENTO/MATERIAL PERMANENTE", "SERVIÇO NÃO CONTINUADO", "SERVIÇO DE ENGENHARIA"]} />
            )
        }
        return metas
    }
    function RenderItens() {
        let itens = []

        for (let n = 1; n <= dados.qtdItens; n++) {
            const currentItem = dados.itens[n - 1]
            itens.push(
                <div key={n}>
                    <Select texto="Item" nome="item" funcao={e => updateFieldItens(e, n)}
                        widthWeb="25vw" widthMobile="80vw"
                        options={objetos.map((obj, index) => `${obj.objeto}-${index + 1}`)} />
                    <Input texto="Descrição" modo="fixed" valor={currentItem?.descricao || ''} readOnly />
                    <Input texto="Quantidade" nome="quantidade" tipo="number" funcao={e => updateFieldItens(e, n)} widthWeb="15vw" widthMobile="80vw" />
                    <Input texto="Valor" nome="valorEstimado" tipo="moeda"
                        valor={currentItem?.valorEstimado || ''} funcao={e => updateFieldItens(e, n)}
                        widthWeb="15vw" widthMobile="80vw" />
                </div>
            )
        }
        return itens
    }
}

export default NovoDed