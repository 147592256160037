import { useState, useEffect, useContext } from 'react'
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios'

const TabelaOperacionais = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabela, setTabela] = useState([])
    const [botoes, setBotoes] = useState([])
    const [areas, setAreas] = useState([])

    useEffect(() => {
        axios.get('/api/orcamento/getTipoOperacional')
            .then(res => {
                setTabela(res.data)
                const objeto = { tipoBotao: "remover" }
                const copias = Array(Object.keys(res.data).length).fill(objeto)
                setBotoes([...copias])

                const areasDistintas = []
                res.data.forEach(objeto => {
                    if (!areasDistintas.includes(objeto.area)) {
                        areasDistintas.push(objeto.area)
                    }
                })
                setAreas(areasDistintas)
            })
            .catch(error => alert(error))
    }, [])

    const alterarBotao = (index, novoValor) => {
        const novoArray = [...botoes]
        novoArray[index] = { ...novoArray[index], tipoBotao: novoValor }
        setBotoes(novoArray)
    }

    const estilo = {
        table: {
            width: mediaWeb ? "98vw" : "100%", margin: "auto", marginTop: "2vh", marginBottom: "2vh",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: mediaWeb ? 'fixed' : null
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 14, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 14 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        }
    }
    console.log(areas)
    return (
        <>
            <div style={{
                display: "inline-block", marginLeft: "0.5vw", marginTop: "1vw", padding: "10px",
                fontSize: 18, width: "40vw", color: myState === "light" ? 'black' : "#FFFF",
                border: "1px solid #ccc", borderRadius: "8px"
            }}>
                <p>Quantidade: {tabela.length}</p>
                <p>Áreas:</p>
                <ul>{areas.map((el, index) => (<li key={index}>{el}</li>))}</ul>
            </div>
            <table style={{ ...estilo.table }}>
                <thead>
                    <tr>
                        <th style={{ ...estilo.th, width: "25vw" }}>OBJETO</th>
                        <th style={{ ...estilo.th, width: "25vw" }}>ÁREA</th>
                        {/* <th style={estilo.th}>DESCRIÇÃO DO OBJETO</th> */}
                        <th style={{ ...estilo.th, width: "10vw" }}>UNIDADE</th>
                        <th style={{ ...estilo.th, width: "10vw" }}>VALOR ESTIMADO</th>
                        <th style={{ ...estilo.th, width: "8vw" }}>INSERIR/REMOVER</th>
                    </tr>
                </thead>
                <tbody>
                    {RenderItens()}
                </tbody>
            </table>
        </>
    )

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }
        return value.toLocaleString('pt-BR', options)
    }

    function RenderItens() {
        let rows = []
        tabela.map((item, index) => {
            rows.push(
                <tr>
                    <td style={estilo.td}>{item.objeto}</td>
                    <td style={estilo.td}>{item.area}</td>
                    {/* <td style={estilo.td}>{item.descricao}</td> */}
                    <td style={estilo.td}>{item.und}</td>
                    <td style={estilo.td}>{formatCurrency(item.valorEstimado || '')}</td>
                    <td style={{ ...estilo.td, textAlign: "center" }}>
                        {botoes[index].tipoBotao === "remover" ? (
                            <button style={{
                                border: "none", outline: "none",
                                backgroundColor: "#dc3545", borderRadius: "15px", color: "#FFFF"
                            }}
                                onClick={() => botoes[index].tipoBotao === "remover" ?
                                    alterarBotao(index, "adicionar") : alterarBotao(index, "remover")}>
                                Remover
                            </button>
                        ) : (
                            <button style={{
                                border: "none", outline: "none",
                                backgroundColor: "#09792d", borderRadius: "15px", color: "#FFFF"
                            }}
                                onClick={() => botoes[index].tipoBotao === "remover" ?
                                    alterarBotao(index, "adicionar") : alterarBotao(index, "remover")}>
                                Adicionar
                            </button>
                        )}
                    </td>
                </tr>
            )
        })
        return rows
    }
}

export default TabelaOperacionais