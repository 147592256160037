import { useState, useEffect } from "react"
import axios from 'axios'

import NovoAditivo from "./NovoAditivo"
import LinkComponent from "../Processos/LinkComponent"
import Movimentacoes from "../Processos/Movimentacoes"

import Caixa from "../../Biblioteca/Caixa"
import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import calcularPrazo from "../../Biblioteca/Funcoes/calculoPrazo"
import enviar from "../../Biblioteca/Funcoes/enviar"

const Aditivos = () => {
    const [dados, setDados] = useState({ editados: [] })
    const [aditivos, setAditivos] = useState([])
    const [novoAditivo, setNovoAditivo] = useState(false)
    const [tipoBusca, setTipo] = useState()
    const [contratos, setContratos] = useState([])

    useEffect(() => {
        axios("/api/cpl/getAditivos")
            .then(res => {
                setAditivos(res.data)
                setContratos(res.data.map(el => el.numeroContrato))
            })
            .catch(error => alert(error.response.data))
    }, [])

    const updateField = (e) => {
        var { name, value } = e.target
        let tempState = { ...dados }
        let object = { user: localStorage.id, campo: name, novo: value, data: new Date().toLocaleString() }
        tempState.editados.push(object)
        tempState[name] = value
        setDados(tempState)
    }

    const updateTipo = (e) => {
        const { value } = e.target
        setTipo(value)
    }

    const updateDadosFiltro = (e) => {
        const { value } = e.target
        let info = { filtro: tipoBusca, campo: value }
        const url = '/api/cpl/filtroAditivos'
        axios.post(url, info)
            .then(res => {
                setAditivos(res.data)
            })
            .catch(error => {
                alert(error.response.data)
            })
    }

    const choose = (el) => {
        let tempState = { ...dados, ...el, idProcesso: el._id }
        setDados(tempState)
    }
    
    return (
        <>
            {RenderNovoAditivo()}
            {RenderFiltro()}
            {RenderRelatorio()}
        </>
    )

    function RenderFiltro() {
        return (
            <div style={{ marginLeft: "5vw", marginTop: "3vh" }}>
                <Select texto="Buscar" nome="tipoFiltro" widthWeb="14vw" widthMobile="80vw" funcao={updateTipo}
                    options={["Andamento", "Nº do Contrato", "Responsável"]} />
                {RenderTipo()}
            </div>
        )

        function RenderTipo() {
            if (tipoBusca === "Andamento") {
                return (
                    <Select texto="Andamento" nome="andamento" widthWeb="14vw" widthMobile="80vw" funcao={updateDadosFiltro}
                        options={["Abertura", "CPL", "Pesquisa", "DF-RO", "Comitê Gestor", "Nota Técnica",
                            "PGE", "Ass. CMDG", "CGE", "DF-Empenho", "SEAD", "Cancelado", "QRX"]} />
                )
            }
            else if (tipoBusca === "Nº do Contrato") {
                return (
                    <Select texto="Nº do Contrato" nome="numeroContrato" widthWeb="14vw" widthMobile="80vw" funcao={updateDadosFiltro}
                        options={[...contratos]} />
                )
            }
            else if (tipoBusca === "Responsável") {
                return (
                    <Select texto="Responsável" nome="responsavel" widthWeb="14vw" widthMobile="80vw" funcao={updateDadosFiltro}
                        options={["Marcelo Villar", "Bruno Rennan", "Enzo Capobianco", "Talmay Pessoa",
                            "Marcos Santos", "Gustavo de Figueiredo"]} />
                )
            }
        }
    }

    function RenderNovoAditivo() {
        if (novoAditivo) return <NovoAditivo />
        else {
            return (
                <div style={{ textAlign: "center", marginTop: "3vh" }}>
                    <Botao texto="Novo Aditivo" widthWeb="10vw" widthMobile="40vw" funcao={() => setNovoAditivo(true)} />
                </div>
            )
        }
    }

    function RenderRelatorio() {
        if (aditivos.length > 0) {
            let relatorio = []
            aditivos.map(el => {
                var data = null
                if (el.prazoPge) data = el.prazoPge.slice(0, 10)
                var borda; var pad; var modo
                if (dados.idProcesso === el._id) {
                    borda = "1px solid green"
                    pad = "1vw"
                }
                dados.idProcesso !== el._id ? modo = "fixed" : modo = "edit"
                relatorio.push(
                    <Caixa>
                        <div style={{ border: borda, padding: pad }}>
                            <LinkComponent pbdoc={el.pbdoc} />
                            <div>
                                <Input texto="Nº da Solicitação" nome="numeroSolicitacao" widthWeb="14vw" widthMobile="80vw"
                                    funcao={updateField} modo={modo} valor={el.numeroSolicitacao} />
                            </div>
                            <Input texto="PBDOC" nome="pbdoc" widthWeb="14vw" widthMobile="80vw"
                                funcao={updateField} modo={modo} valor={el.pbdoc} />
                            <Select texto="Andamento" nome="andamento" widthWeb="14vw" widthMobile="80vw"
                                funcao={updateField}
                                modo="fixed" valor={el.andamento}
                                options={["Abertura", "Pesquisa", "DF-RO", "Comitê Gestor", "Nota Técnica",
                                    "PGE", "Ass. CMDG", "CGE", "DF-Empenho", "SEAD", "Cancelado", "QRX"]} />
                            <Input texto="Nº do Contrato" nome="numeroContrato" tipo="number"
                                modo={modo} valor={el.numeroContrato} widthWeb="14vw" widthMobile="80vw"
                                funcao={updateField} />
                            <Input texto="Objeto" nome="objeto" widthWeb="14vw" widthMobile="80vw"
                                funcao={updateField}
                                modo={modo} valor={el.objeto} />
                            <Input texto="Observações" nome="obs" widthWeb="14vw" widthMobile="80vw"
                                funcao={updateField}
                                modo={modo} valor={el.obs} />
                            <Input texto="Prazo PGE" nome="prazoPge" tipo="date"
                                modo={modo} valor={data.slice(0, 10)} widthWeb="14vw" widthMobile="80vw"
                                funcao={updateField} />
                            <Input texto="Dias restantes"
                                modo="fixed" valor={calcularPrazo(data)} widthWeb="14vw" widthMobile="80vw"
                                funcao={updateField} />
                            <div style={{ textAlign: "center" }}>
                                {dados.idProcesso !== el._id ? (
                                    <a onClick={() => choose(el)} style={{ cursor: "pointer", marginBottom: "15vw" }}>
                                        {el.responsavel} <i className="bi bi-pen penReport"></i>
                                    </a>
                                ) : (
                                    <>
                                        <Botao texto="EDITAR" widthMobile="40vw" funcao={() => 
                                            enviar('/api/cpl/editAditivo', dados, "/aditivosCpl")} />
                                        <div style={{
                                            width: "80%", borderBottom: "1px solid #33348F50",
                                            margin: "auto", marginTop: "2vh"
                                        }}></div>
                                        <Movimentacoes processo={el} 
                                        url="insertMovimentacaoAditivo" redirect="aditivosCpl" />
                                    </>
                                )}
                            </div>
                        </div>
                    </Caixa>
                )
            })
            return (relatorio)
        }
    }
}

export default Aditivos