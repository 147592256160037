import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "./MyContext"

const Select = (props) => {
    const { myState } = useContext(MyContext);
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const width = mediaWeb ? props.widthWeb : props.widthMobile

    const estilo = {
        divInput: {
            display: props.display || "inline-grid", textAlign: "center", width: width || "15vw",
            margin: "auto", marginBottom: props.marginB || "1vh",
            marginRight: props.marginR || "2vw", marginLeft: props.marginL
        },
        label: {
            textAlign: "left", fontWeight: 600, fontSize: props.fontS || 15,
            color: myState === "light" ? "black" : "#dbdbdb"
        },
        input: {
            width: width || "15vw", border: myState === "light" ? "1px solid #00000029" : "1px solid rgb(0,0,0, 0.35)", 
            borderRadius: "5px", color: myState === "light" ? "black" : "#dbdbdb",
            minHeight: "18px", fontSize: props.fontS || 15, backgroundColor: "#00000000"
        }
    }

    return (
        <div style={estilo.divInput}>
            <div style={estilo.label}>{props.texto}</div>
            {RenderSelect()}
        </div>
    )

    function RenderSelect() {
        const handleChange = (e) => {
            if (props.funcao) {
                props.funcao(e, props.additionalArgs)
            }
        }

        if (props.modo === "fixed") {
            return (
                <div style={{ ...estilo.input, textAlign: "left" }}>
                    {props.valor}
                </div>
            )
        } else {
            return (
                <select style={estilo.input} name={props.nome}
                    defaultValue={props.valor}
                    onChange={handleChange} >
                    {Options()}
                </select>
            )
        }
    }

    function Options() {
        let list = [<option value="" key="keySelect" style={{...estilo.input, 
            backgroundColor: myState === "light" ? "#FFFF" : "rgb(44,44,44)"}}></option>]
        props.options.map((el, index) => {
            list.push(
                <option value={el} key={`keySelect-${index}`} style={{...estilo.input, 
                    backgroundColor: myState === "light" ? "#FFFF" : "rgb(44,44,44)"}}>{el}</option>
            )
        })
        return list
    }
}

export default Select