import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { MyContext } from "../Biblioteca/MyContext"

const ItensCalculo = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabela, setTabela] = useState()

    useEffect(() => {
        const url = '/api/orcamento/getAcimaMedia'
        axios.post(url, { regional: localStorage.regionalUser })
            .then(res => setTabela(res.data))
            .catch(error => alert(error.response.message))
    }, [])

    const estilo = {
        table: {
            width: "88vw", marginTop: "2vh", marginBottom: "2vh",
            marginLeft: "auto", marginRight: "auto",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: mediaWeb ? 'fixed' : null
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        }
    }

    return (
        <>
            <table style={estilo.table}>
                <thead>
                    <tr>
                        <th rowSpan="2" style={estilo.th}>OBJETO</th>
                        <th rowSpan="2" style={estilo.th}>UND. DE MEDIDA</th>
                        <th rowSpan="2" style={estilo.th}>MÉDIA 3 ANOS</th>
                        <th rowSpan="2" style={estilo.th}>QTD. ESTIMADA</th>
                        <th rowSpan="2" style={estilo.th}>VALOR UNITÁRIO</th>
                        <th rowSpan="2" style={estilo.th}>VALOR TOTAL</th>
                        <th rowSpan="2" style={estilo.th}>ÁREA</th>
                    </tr>
                </thead>
                <tbody>
                    {RenderItens()}
                </tbody>
            </table>
        </>
    )

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderItens() {
        let rows = []
        if (tabela !== undefined && tabela.length > 0) {
            tabela.map((el, index) => {
                const indexRegional = el.regionais.findIndex(x => x.regional === localStorage.regionalUser)
                const valorTotal = el.valorEstimado * el.regionais[indexRegional].qtdEstimada
                const media = (el.regionais[indexRegional].ano1 + 
                    el.regionais[indexRegional].ano2 + 
                    el.regionais[indexRegional].ano3) / 3
                rows.push(
                    <tr key={`objeto${index}`}>
                        <td style={estilo.td}>{el.objeto}</td>
                        <td style={estilo.td}>{el.und}</td>
                        <td style={estilo.td}>{media.toFixed(2)}</td>
                        <td style={estilo.td}>{el.regionais[indexRegional].qtdEstimada}</td>
                        <td style={estilo.td}>{formatCurrency(el.valorEstimado)}</td>
                        <td style={estilo.td}>{formatCurrency(valorTotal)}</td>
                        <td style={estilo.td}>{el.area}</td>
                    </tr>
                )
            })
        }
        return rows
    }
}

export default ItensCalculo