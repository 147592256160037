import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import enviar from '../../Biblioteca/Funcoes/enviar'
import './NovoContrato.css'

function formatDateForInput(dateString) {
    const date = new Date(dateString)
    if (isNaN(date)) {
        return ''
    }
    return date.toISOString().split('T')[0]
}

const NovoContrato = () => {
    const [suggestions, setSuggestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [showSuggestions, setShowSuggestions] = useState(false)
    const [selectKey, setSelectKey] = useState(0)
    const [dados, setDados] = useState({
        pbdoc: '',
        // assunto: ''
    })
    const id = window.location.pathname.slice(14)

    const fetchContrato = async (id) => {
        try {
            const res = await axios.get(`/api/dal/getContratoById/${id}`)
            setDados(res.data)
            setSelectKey(prevKey => prevKey + 1)
        } catch (error) {
            alert(error.response?.data?.message || error.reponse.data.message)
        }
    }

    useEffect(() => {
        if (id && id.trim() !== "") {
            fetchContrato(id)
        }
    }, [id])

    useEffect(() => {
        if (dados.pbdoc.length > 2) {
            const delayDebounceFn = setTimeout(() => {
                fetch(`/api/dal/autocomplete-pbdoc?q=${dados.pbdoc}`)
                    .then(response => response.json())
                    .then(data => {
                        setSuggestions(data)
                        setShowSuggestions(true)
                        setLoading(false)
                    })
                    .catch(error => {
                        alert('Erro ao buscar pbdocs:', error.response.data.message)
                        setLoading(false)
                    })
            }, 300)

            return () => clearTimeout(delayDebounceFn)
        } else {
            setShowSuggestions(false)
        }
    }, [dados.pbdoc])

    const handlePbdocChange = (e) => {
        const selectedPbdoc = e.target.value
        setDados(prevState => ({
            ...prevState,
            pbdoc: selectedPbdoc
        }))

        const selectedItem = suggestions.find(item => item.pbdoc === selectedPbdoc)
        if (selectedItem) {
            setDados(prevState => ({
                ...prevState,
                // assunto: selectedItem.assunto
            }))
        } else {
            setDados(prevState => ({
                ...prevState,
                // assunto: ''
            }))
        }
    }

    const updateField = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }

    const handleSubmit = () => {
        if (id && id.trim() !== "") var url = '/api/dal/editContrato'
        else var url = '/api/dal/novoContrato'
        enviar(url, dados, '/BuscaContratos')
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }} key={selectKey}>
            <div style={{ margin: "2vh 0 0 2vw" }}>
                <div className="containerContrato">
                    <div className="inputGroupContrato">
                        <div className="labelsContrato">Processo(PBDOC)</div>
                        <input className="inputsContrato" style={{ width: "26vw" }}
                            type="text"
                            name="pbdoc"
                            value={dados.pbdoc}
                            onChange={handlePbdocChange}
                            onFocus={() => setShowSuggestions(true)}
                            onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
                            list="pbdoc-suggestions"
                            placeholder="Digite ou selecione o pbdoc"
                        />
                    </div>
                    {/* <div className="inputGroup">
                        <div className="labelsContrato">Assunto</div>
                        <input className="inputsContrato" style={{ width: "60vw" }}
                            type="text"
                            name="assunto"
                            value={dados.assunto}
                            onChange={handleChange}
                            placeholder="Assunto"
                        />
                    </div> */}
                </div>
                {loading && <p>Carregando...</p>}
                {showSuggestions && (
                    <ul style={{
                        width: '50vw', overflowY: 'auto', border: '1px solid #ccc',
                        borderRadius: "5px", position: 'absolute', zIndex: 1
                    }}>
                        {suggestions.map((item, index) => (
                            <li
                                key={index}
                                style={{ padding: '8px', cursor: 'pointer' }}
                                onClick={() => {
                                    setDados(prevState => ({
                                        ...prevState,
                                        pbdoc: item.pbdoc,
                                        // assunto: item.assunto
                                    }))
                                    setShowSuggestions(false)
                                }}>
                                {item.pbdoc} ({item.assunto})
                            </li>
                        ))}
                    </ul>
                )}
                <div>
                    <Input texto="Número do Contrato" nome="numeroContrato" widthWeb="26vw" widthMobile="80vw"
                        valor={dados.numeroContrato} funcao={updateField} />
                    <Input texto="Registro CGE" nome="cge" widthWeb="26vw" widthMobile="80vw"
                        valor={dados.cge} funcao={updateField} />
                </div>
                <div>
                    <Input texto="Data DOE" nome="dataDoe" tipo="date" widthWeb="26vw" widthMobile="80vw"
                        valor={dados.dataDoe ? formatDateForInput(dados.dataDoe) : ""} funcao={updateField} />
                    <Input texto="Número DOE" nome="numeroDoe" widthWeb="26vw" widthMobile="80vw"
                        valor={dados.numeroDoe} funcao={updateField} />
                </div>
                <div>
                    <Input texto="Vigência" nome="vigencia" tipo="date" widthWeb="26vw" widthMobile="80vw"
                        valor={dados.vigencia ? formatDateForInput(dados.vigencia) : ""} funcao={updateField} />
                    <Input texto="Valor do Contrato" nome="valorContrato" tipo="moeda" widthWeb="26vw" widthMobile="80vw"
                        valor={dados.valorContrato} funcao={updateField} />
                </div>
                <div>
                    <Input texto="Fornecedor" nome="fornecedor" widthWeb="26vw" widthMobile="80vw"
                        valor={dados.fornecedor} funcao={updateField} />
                    <Input texto="Matrícula do Gestor" nome="matriculaGestor" widthWeb="26vw" widthMobile="80vw"
                        valor={dados.matriculaGestor} funcao={updateField} />
                </div>
                <div>
                    <Select texto="Status" nome="statusContrato" funcao={updateField} widthWeb="26vw" widthMobile="80vw"
                        valor={dados.statusContrato} options={["VIGENTE", "ADITIVADO","CANCELADO"]} />
                    <Input texto="Link Drive" nome="linkDrive" widthWeb="26vw" widthMobile="80vw"
                        valor={dados.linkDrive} funcao={updateField} />
                </div>
                <div>
                    <Botao texto="Salvar" widthWeb="6vw"
                        funcao={handleSubmit} />
                </div>
            </div>
        </div>
    )
}

export default NovoContrato
