import React, { createContext, useState } from "react";

export const MyContext = createContext();

export const MyProvider = (props) => {
  const [myState, setMyState] = useState("");

  const updateMyState = (newValue) => {
    setMyState(newValue);
  };

  return (
    <MyContext.Provider value={{ myState, updateMyState }}>
      {props.children}
    </MyContext.Provider>
  );
};
