import { useState } from "react"
import axios from 'axios'

import Caixa from "../Biblioteca/Caixa"
import Input from '../Biblioteca/Input'
import Select from '../Biblioteca/Select'
import Botao from '../Biblioteca/Botao'
import enviar from '../Biblioteca/Funcoes/enviar'

const NovaDiaria = () => {
    const [dados, setDados] = useState({ user: localStorage.nome })
    const [disabled, setDisabled] = useState(false)

    const updateField = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }

    return (
        <Caixa>
            <Input texto="Data de Entrada" nome="dataEntrada" tipo="date"
                funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="PBDOC" nome="pbdoc" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Origem" nome="origem" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Descrição" nome="descricao" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Select texto="Classificação" nome="classificacao" funcao={updateField} widthWeb="14vw" widthMobile="80vw"
                options={["Cursos", "Administrativo", "Diária Servidor Civil", "Prevenção", "Atuação"]} />
            <Select texto="Class. SIAF" nome="classificacaoSiaf" funcao={updateField} widthWeb="14vw" widthMobile="80vw"
                options={["4008", "4009", "4017", "4036", "4040"]} />
            <Input texto="Montante" nome="montante" tipo="moeda" dado={dados.valorReserva || null}
                funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Select texto="Situação" nome="situacao" funcao={updateField} widthWeb="14vw" widthMobile="80vw"
                options={["Recebido DF1/Diárias", "Em Empenho", "Em Liquidação", "Pagamento", 
                "Ass. do Ordenador", "Aguardando Documentação", "Aguardando GD", "Arquivado Corrente"]} />
            <Input texto="Empenho" nome="empenho" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Select texto="Fonte" nome="fonte" funcao={updateField} widthWeb="14vw" widthMobile="80vw"
                options={["FUNESBOM", "CBM"]} />
            <Input texto="Liquidação" nome="liquidacao" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Autorização de Pagamento" nome="autorizacaoPagamento" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <div>
                <Botao texto="ENVIAR" funcao={() => enviar('/api/df/novoContinuado', dados, "/continuadosDf")} 
                widthMobile="40vw" disabled={disabled} />
            </div>
        </Caixa>
    )
}

export default NovaDiaria