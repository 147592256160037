import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "../../Biblioteca/MyContext"
import link from '../../../assets/link.png'

const LinkComponent = ({ pbdoc }) => {
  const { myState } = useContext(MyContext);
  const mediaWeb = window.matchMedia("(min-width:600px)").matches
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  const cor = myState === "light" ? "#33348F" : "#c4c4ea"

  return (
    <div
      style={{
        display: "inline-block",
        margin: "auto",
        marginBottom: "3vh",
        cursor: "pointer",
        transition: "transform 0.2s",
        transform: isHovered ? "scale(0.98)" : "scale(1)"
      }}
      onClick={() =>
        window.open(`https://pbdoc.pb.gov.br/sigaex/app/expediente/doc/exibir?sigla=${pbdoc}`)
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={link}
        style={{
          width: mediaWeb ? "1.6vw" : "5vw"
        }}
      />
      <span
        style={{
          fontWeight: 550,
          fontSize: 14,
          marginLeft: mediaWeb ? "0.3vw" : "2vw",
          color: isHovered ? "#dc3545" : cor
        }}
      >
        PBDOC
      </span>
    </div>
  );
};

export default LinkComponent;
