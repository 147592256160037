import { useState, useEffect } from 'react'
import axios from 'axios'
import converterData from '../../Biblioteca/Funcoes/converterData'
import Botao from '../../Biblioteca/Botao'

const GetDfdExtra = () => {
    const [dados, setDados] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1)
    const itensPorPagina = 10

    const fetchDados = (pagina) => {
        axios(`/api/dfdExtra/getDfdsExtra?page=${pagina}`)
            .then(res => setDados(res.data))
            .catch(error => alert(error.response.data.message))
    }

    useEffect(() => {
        fetchDados(paginaAtual)
    }, [paginaAtual])

    const paginaAnterior = () => {
        if (paginaAtual > 1) setPaginaAtual(paginaAtual - 1)
    }

    const proximaPagina = () => {
        if (dados.length === itensPorPagina) setPaginaAtual(paginaAtual + 1)
    }

    return (
        <>
            <div style={{ width: "90vw", margin: "4vh auto 4vh auto" }}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: "30vw", textAlign: "center" }}>Regional</th>
                            <th style={{ width: "30vw", textAlign: "center" }}>Data</th>
                            <th style={{ width: "30vw", textAlign: "center" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dados.map((el, index) => (
                            <tr key={`dfdExtra-${index}`}>
                                <td>{el.regional}</td>
                                <td>{converterData(el?.data.slice(0, 10))}</td>
                                <td>
                                    <Botao texto="Abrir" funcao={() => window.location = `/DfdExtraView/${el._id}`} widthMobile="40vw" />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Botao texto="Anterior" funcao={paginaAnterior} disabled={paginaAtual === 1 ? true : false}
                        marginT="0" marginR="0" cor="rgb(51, 52, 143)"/>
                    <span style={{ margin: '0 15px' }}>Página {paginaAtual}</span>
                    <Botao texto="Próxima" funcao={proximaPagina} disabled={dados.length < itensPorPagina ? true : false}
                        marginT="0" cor="rgb(51, 52, 143)"/>
                </div>
            </div>
        </>
    )
}

export default GetDfdExtra
