import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios';

import calcularDiasPassados from '../Biblioteca/Funcoes/calcularDiasPassados';

const TabelaAndamento = () => {
    const { myState } = useContext(MyContext);
    const [dados, setDados] = useState([]);

    useEffect(() => {
        axios("/api/cpl/getAndamentosTable")
            .then(res => {
                setDados(res.data);
            })
            .catch(error => alert(error.response.data));
    }, []);

    const estilo = {
        th: {
            color: myState === "light" ? 'black' : "#FFFF", 
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '16px',
            border: '1px solid #ddd', fontWeight: 600, fontSize: 15
        },
        td: {
            padding: '16px', backgroundColor: myState === "light" ? '#fff' : "#00000000", border: '1px solid #ddd', 
            fontSize: 14,
            color: myState === "light" ? 'black' : "#FFFF",
        }
    }

    return (
        <div
            style={{
                height: '100%',
                overflowY: 'auto',
            }}
        >
            <div style={{
                position: 'sticky',
                top: 0,
                backgroundColor: myState === "light" ? '#fff' : "#00000000",
                zIndex: 1,
                color: myState === "light" ? '#33348F' : "#c4c4ea",
                padding: '16px',
                border: '1px solid #ddd',
                fontWeight: 600,
                fontSize: 15,
                textAlign: "center"
            }}>
                PROCESSOS
            </div>
            <table
                style={{
                    width: '100%',
                    padding: '16px',
                    borderCollapse: 'collapse',
                    textAlign: "center",
                    borderRadius: '10px',
                    maxHeight: '28vh',
                    tableLayout: 'fixed',
                    wordWrap: 'break-word',
                }}
            >
                <thead>
                    <tr>
                        <th style={estilo.th}>OBJETO</th>
                        <th style={estilo.th}>ANDAMENTO</th>
                        <th style={estilo.th}>TEMPO</th>
                    </tr>
                </thead>
                <tbody>
                    {dados.map((item, index) => {
                        if (item.data) {
                            var data = calcularDiasPassados(item.data.slice(0, 10))
                        }
                        else var data = null
                        return (
                            <tr key={index}>
                                <td
                                    style={{
                                        padding: '16px', border: '1px solid #ddd', fontSize: 14,
                                        cursor: 'pointer', backgroundColor: myState === "light" ? '#fff' : "#00000000", 
                                        transition: 'background-color 0.3s ease-in-out',
                                        color: myState === "light" ? 'black' : "#FFFF"
                                    }}
                                    onClick={() => window.open(`https://pbdoc.pb.gov.br/sigaex/app/expediente/doc/exibir?sigla=${item.pbdoc}`, '_blank')}
                                    onMouseOver={(e) => {
                                        e.target.style.backgroundColor = '#33348F';
                                        e.target.style.color = 'white';
                                    }}
                                    onMouseOut={(e) => {
                                        myState === "light" ? e.target.style.backgroundColor = '#fff' : 
                                        e.target.style.backgroundColor = '#00000000';
                                        myState === "light" ? e.target.style.color = 'black' : 
                                        e.target.style.color = 'white';
                                    }}
                                >
                                    {item.objeto}
                                </td>
                                <td style={estilo.td}>{item.andamento}</td>
                                <td style={estilo.td}>{data}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TabelaAndamento;
