import { useState, useEffect } from 'react'
import axios from 'axios'

import brasao from '../../../assets/brasao.png'
import "./PrintDfdSetorial.css"
import FonteRecursos from './FonteRecursos'
import CaixaFinalDfd from './CaixaFinalDfd'

const PrintDfdSetorizado = (anoPreenchimento) => {
    const [tabelas, setTabelas] = useState([])
    const [fonte, setFonte] = useState("")
    const [cmd, setCmd] = useState()
    let numeroItem = 1

    useEffect(() => {
        const url = '/api/pca/getDfdByRegional'
        axios.post(url, { anoPreenchimento: parseInt(anoPreenchimento.ano) })
            .then(res => {
                setTabelas(res.data.tabelas)
                setFonte(res.data.fonte)
            })
            .catch(error => alert(error.response.data.message))
    }, [])

    useEffect(() => {
        const url = '/api/orcamento/getCmdAss'
        axios.post(url, { regional: localStorage.regionalUser })
            .then(res => setCmd(res.data))
            .catch(error => alert(error.response.data.message))
    }, [])

    return (
        <div>
            <div style={{ textAlign: "center", marginTop: "4vh", fontWeight: "600", marginBottom: "5vh" }}>
                <img src={brasao} style={{ width: "8vw" }} />
                <div>ESTADO DA PARAÍBA</div>
                <div>SECRETARIA DE SEGURANÇA E DEFESA SOCIAL</div>
                <div>CORPO DE BOMBEIROS MILITAR DA PARAÍBA</div>
            </div>
            <div style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", marginBottom: "4vh" }}>
                DOCUMENTO DE FORMALIZAÇÃO DE DEMANDA – DFD
            </div>
            <div style={{
                border: "1px solid black", width: "98vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    INFORMAÇÕES DO NÍVEL SETORIAL
                </div>
                <div style={{
                    borderBottom: "1px solid black",
                    padding: "5px 0 5px 5px"
                }}><b>NÍVEL SETORIAL: </b>{RenderNivelSetorial()}</div>
                <div style={{ borderBottom: "1px solid black", display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>Bombeiro Militar  reponsável pelo Planejamento e esclarecimentos da Demanda: </b>
                        {localStorage.nome}
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Matrícula CBMPB: </b> {localStorage.numeroMatricula}
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>E-mail: </b> {localStorage.email}
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Contato: </b> {localStorage.contato}
                    </div>
                </div>
            </div>
            {RenderTabelas()}
            <CaixaFinalDfd fonte={fonte} cmd={cmd} regional={localStorage.regionalUser}/>
        </div>
    )

    function RenderNivelSetorial() {
        if (localStorage.regionalUser === "1º CRBM" || localStorage.regionalUser === "2º CRBM" ||
            localStorage.regionalUser === "3º CRBM" || localStorage.regionalUser === "4º CRBM" ||
            localStorage.regionalUser === "5º CRBM") {
            return localStorage.regionalUser
        } else return `Departamento (${localStorage.regionalUser})`
    }

    function RenderTabelas() {
        let dfds = []
        if (tabelas !== undefined) {
            Object.keys(tabelas).map(obj => {
                var titulo = obj
                dfds.push(
                    <div style={{
                        border: "1px solid black", width: "98vw", fontSize: "12px",
                        marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
                    }}>
                        <div style={{
                            borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                            textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                        }}>
                            INFORMAÇÕES DA NECESSIDADE DE CONTRATAÇÃO ({titulo})
                        </div>
                        <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                            <div style={{ textAlign: "center" }}><b>Descrição sucinta da solicitação:</b></div>
                            <br />
                            Formalização da Demanda para fins de Planejamento de Contratação Anual {parseInt(anoPreenchimento.ano) + 1}, 
                            exercício financeiro {parseInt(anoPreenchimento.ano) + 1}, de objetos e serviços comuns para desenvolvimento das ações 
                            e ativividades fins e meio no âmbito da corporação.
                        </b></div>
                        <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                            <div style={{ textAlign: "center" }}><b>Necessidade / Justificativa técnica da contratação para itens:</b></div>
                            <br />
                            {tabelas[obj].justificativa}
                        </b></div>
                        <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                            <div style={{ textAlign: "center" }}><b>Resultados Pretendidos:</b></div>
                            <br />
                            {tabelas[obj].resultados}
                        </b></div>
                        <FonteRecursos fonte={fonte}
                            regional={localStorage.regionalUser}
                            areaEipcp={obj} />
                        <table className="tableDfd">
                            <thead>
                                <tr>
                                <th rowSpan="2" className="thDfdGeral" style={{ width: "3vw" }}>ITEM</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "4vw" }}>CÓDIGO</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "8vw" }}>OBJETO</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "14vw" }}>DESCRIÇÃO BASE DE REFERÊNCIA</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "5vw" }}>UND. DE MEDIDA</th>
                                    <th className="thDfdGeral" colSpan="3" style={{ width: "9vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                                    <th rowSpan="2" className="thDfdGeral"  style={{ width: "5vw" }}>QTD. ESTIMADA</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "17vw" }}>JUSTIFICATIVA DOS QUANTITATIVOS</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>VALOR ESTIMADO UNITÁRIO</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "10vw" }}>COMPROMETIMENTO ORÇAMENTÁRIO LOA 2025</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "9vw" }}>DATA PRETENDIDA / CALENDÁRIO DE CONTRATAÇÃO</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>GRAU DE PRIORIDADE</th>
                                </tr>
                                <tr>
                                    <th className="thDfd">2021</th>
                                    <th className="thDfd">2022</th>
                                    <th className="thDfd">2023</th>
                                </tr>
                            </thead>
                            <tbody>
                                {RenderRows(tabelas[obj].items)}
                            </tbody>
                            <thead>
                                <tr>
                                    <th colSpan="10" className="thDfd" style={{
                                        backgroundColor: "#f9ff06", color: "black", textAlign: "left"
                                    }}>
                                        VALOR TOTAL DO DOCUMENTO DE FORMALIZAÇÃO DA DEMANDA
                                    </th>
                                    <th colSpan="4" className="thDfd" style={{
                                        backgroundColor: "#f9ff06", color: "black", textAlign: "left"
                                    }}>
                                        {RenderSomaTotal(tabelas[obj].items, obj)}
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                )
            })
        }
        return dfds
    }

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderSomaTotal(array, obj) {
        let somaTotal = 0
        array.map(el => {
            const indexRegional = el.regionais.findIndex(x => x.regional === localStorage.regionalUser)
            if (indexRegional !== -1) somaTotal += el.valorEstimado * el.regionais[indexRegional].qtdEstimada
        })
        return formatCurrency(somaTotal)
    }

    function RenderRows(array) {
        let rows = []
        array.map((el, index) => {
            const indexRegional = el.regionais.findIndex(x => x.regional === localStorage.regionalUser)
            if (indexRegional !== -1 && el.regionais[indexRegional].qtdEstimada !== 0) {
                const valorTotal = el.valorEstimado * el.regionais[indexRegional].qtdEstimada
                const descricao = el.descricao.length > 200 ?
                    el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
                rows.push(
                    <tr key={`objeto${index}`}>
                        <td className="tdDfdGeral">{numeroItem}</td>
                        <td className="tdDfdGeral">{el?.codCentral || "Sem correspondência no SGC"}</td>
                        <td className="tdDfdGeral">{el.objeto}</td>
                        <td className="tdDfdGeral">{descricao}</td>
                        <td className="tdDfdGeral">{el.und}</td>
                        <td className="tdDfdGeral">{el.regionais[indexRegional].ano1}</td>
                        <td className="tdDfdGeral">{el.regionais[indexRegional].ano2}</td>
                        <td className="tdDfdGeral">{el.regionais[indexRegional].ano3}</td>
                        <td className="tdDfdGeral">{el.regionais[indexRegional].qtdEstimada}</td>
                        <td className="tdDfdGeral">{el.regionais[indexRegional].justificativa}</td>
                        <td className="tdDfdGeral">{formatCurrency(el.valorEstimado)}</td>
                        <td className="tdDfdGeral">{formatCurrency(valorTotal)}</td>
                        <td className="tdDfdGeral">{el.regionais[indexRegional].entrega}</td>
                        <td className="tdDfdGeral">{el.regionais[indexRegional].prioridade}</td>
                    </tr>
                )
                numeroItem++
            }
            // else console.log(el.objeto, (el.valorEstimado))
        })
        return rows
    }
}

export default PrintDfdSetorizado