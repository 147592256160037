import { useState, useEffect } from 'react'
import axios from 'axios'

import Caixa from '../Biblioteca/Caixa'
import Input from '../Biblioteca/Input'
import Select from '../Biblioteca/Select'
import Botao from '../Biblioteca/Botao'
import Textarea from '../Biblioteca/Textarea'
import enviar from '../Biblioteca/Funcoes/enviar'

const Tarefas = () => {
    const [newTask, setNewTask] = useState({})
    const [cleanKey, setCleanKey] = useState(0)
    const [tasks, setTasks] = useState([])
    const [modificacoes, setModificacoes] = useState({})
    const [filtro, setFiltro] = useState("")

    const updateNewTask = (e) => {
        const { name, value } = e.target
        let tempNewTask = { ...newTask }
        tempNewTask[name] = value
        setNewTask(tempNewTask)
    }

    const cleanNewTask = () => {
        setNewTask({})
        setCleanKey(prevState => prevState + 1)
    }

    useEffect(() => {
        axios.get('/api/taskManager/')
            .then(res => setTasks(res.data))
            .catch(error => alert(error.response.data))
    }, [cleanKey])

    const handleInputChange = (e, id) => {
        var { name, value } = e.target
        setModificacoes(prevState => {
            const existingEntry = prevState[id]
            if (existingEntry) {
                return { ...prevState, [id]: { ...existingEntry, [name]: value } }
            } else {
                return { ...prevState, [id]: { id: id, [name]: value } }
            }
        })
    }

    const updateFiltro = (e) => {
        const { value } = e.target
        setFiltro(value)
    }

    const filteredTasks = filtro ? tasks.filter(task => task.responsavel === filtro) : tasks

    return (
        <div>
            <Caixa key={cleanKey}>
                <div style={{ textAlign: "center", color: "rgb(51, 52, 143)", fontSize: "1.1rem" }}><b>Nova Tarefa</b></div>
                <Input texto="Tarefa" nome="tarefa" valor={newTask?.tarefa} widthWeb="15vw" widthMobile="80vw"
                    funcao={updateNewTask} />
                <Select texto="Responsável" nome="responsavel" valor={newTask?.responsavel} widthWeb="15vw" widthMobile="80vw"
                    options={["Simone", "Thaís", "Bruno", "Gouveia", "Amorim", "Maciel", "Martins"]} funcao={updateNewTask} />
                <Input texto="Prazo" nome="prazo" tipo="date" valor={newTask?.prazo} widthWeb="15vw" widthMobile="80vw"
                    funcao={updateNewTask} />
                <Select texto="Urgência" nome="urgencia" valor={newTask?.urgencia} widthWeb="15vw" widthMobile="80vw"
                    options={["Baixo", "Média", "Alta"]} funcao={updateNewTask} />
                <Select texto="Importância" nome="importancia" valor={newTask?.importancia} widthWeb="15vw" widthMobile="80vw"
                    options={["Baixo", "Média", "Alta"]} funcao={updateNewTask} />
                <Botao texto="Salvar" widthWeb="6vw"
                    funcao={() => enviar('/api/taskManager/', newTask, cleanNewTask())} />
            </Caixa>
            <div style={{margin: "2vh 0 0 5vw"}}>
                <Select texto="Filtro" nome="filtro" valor={filtro}
                    funcao={updateFiltro} widthWeb="26vw" widthMobile="80vw"
                    options={["Simone", "Thaís", "Bruno", "Gouveia", "Amorim", "Maciel", "Martins"]} />
            </div>
            <table style={{ margin: "4vh auto 4vh auto", width: "98vw" }}>
                <thead>
                    <th>Tarefa</th>
                    <th style={{width: "8vw"}}>Responsável</th>
                    <th>Prazo</th>
                    <th>Urgência</th>
                    <th>Importância</th>
                    <th style={{width: "20vw"}}>Andamento</th>
                    <th>Editar</th>
                    <th>Finalizar</th>
                </thead>
                <tbody>
                    {filteredTasks.length > 0 ? (
                        filteredTasks.map((item, indexItem) => {
                            return (
                                <tr style={{ backgroundColor: indexItem % 2 === 0 ? "#FFF" : "#ddd" }}>
                                    <td><Input key={`tarefa-${item._id}`} nome="tarefa" widthWeb="100%"
                                        valor={modificacoes[item._id] ? modificacoes[item._id].tarefa : item.tarefa}
                                        funcao={handleInputChange} additionalArgs={item._id} />
                                    </td>
                                    <td>
                                        <Select key={`responsavel-${item._id}`} nome="responsavel" widthWeb="100%"
                                            funcao={handleInputChange} additionalArgs={item._id}
                                            valor={modificacoes[item._id] ? modificacoes[item._id].responsavel : item.responsavel}
                                            options={["Simone", "Thaís", "Bruno", "Gouveia", "Amorim", "Maciel", "Martins"]} />
                                    </td>
                                    <td>
                                        <Input key={`prazo-${item._id}`} nome="prazo" tipo="date" widthWeb="100%"
                                            valor={modificacoes[item._id] ? modificacoes[item._id].prazo : item.prazo.slice(0, 10)}
                                            funcao={handleInputChange} additionalArgs={item._id} />
                                    </td>
                                    <td>
                                        <Select key={`urgencia-${item._id}`} nome="urgencia" widthWeb="100%"
                                            funcao={handleInputChange} additionalArgs={item._id}
                                            valor={modificacoes[item._id] ? modificacoes[item._id].urgencia : item.urgencia}
                                            options={["Baixo", "Média", "Alta"]} />
                                    </td>
                                    <td>
                                        <Select key={`importancia-${item._id}`} nome="importancia" widthWeb="100%"
                                            funcao={handleInputChange} additionalArgs={item._id}
                                            valor={modificacoes[item._id] ? modificacoes[item._id].importancia : item.importancia}
                                            options={["Baixo", "Média", "Alta"]} />
                                    </td>
                                    <td>
                                        <Textarea key={`andamento-${item._id}`} nome="andamento" widthWeb="100%"
                                            funcao={handleInputChange} additionalArgs={item._id}
                                            default={modificacoes[item._id] ? modificacoes[item._id].andamento : item.andamento}
                                             />
                                    </td>
                                    <td>
                                        <Botao texto="Editar" widthWeb="6vw" marginT="0vw"
                                            funcao={() => enviar('/api/taskManager/editTask', modificacoes[item._id], "/tarefas")} />
                                    </td>
                                    <td>
                                        <Botao texto="Finalizar" widthWeb="6vw" marginT="0vw"
                                            funcao={() => enviar('/api/taskManager/markComplete', {id: item._id}, "/tarefas")} />
                                    </td>
                                </tr>
                            )
                        })
                    ) : (<></>)}
                </tbody>
            </table>
        </div>
    )
}

export default Tarefas