import { useState } from "react"
import axios from 'axios'

import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import Textarea from '../../Biblioteca/Textarea'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'

const currentYear = new Date().getFullYear()

const SolicitarItem = () => {
    const [dados, setDados] = useState({ user: localStorage.nome, regional: localStorage.regionalUser })
    const [search, setSearch] = useState()
    const [searchResults, setSearchResults] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [keyDiv, setKey] = useState(0)
    const [infoDfd, setInfoDfd] = useState({})
    const [qtdItens, setQtdItens] = useState(0)
    const [itensTabela, setItensTabela] = useState([])

    const updateField = (index, e) => {
        const { name, value } = e.target
        setItensTabela((prevItens) => {
            const novosItens = [...prevItens]
            novosItens[index] = { ...novosItens[index], [name]: value }
            return novosItens
        })
    }


    const updateSearch = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setSearch(tempState)
    }

    const buscaItem = () => {
        axios.post('/api/pca/buscarItem', search)
            .then(res => setSearchResults(res.data))
            .catch(error => {
                alert(error.response.data.message)
                setSearchResults([])
            })
    }

    const addQtdItens = (e) => {
        const { name, value } = e.target
        if (name === "mais") {
            setQtdItens(prev => prev + 1)
            setItensTabela(prevItens => [...prevItens, {}])
        }
        else {
            if (qtdItens > 0) setQtdItens(prev => prev - 1)
            setItensTabela(prevItens => prevItens.slice(0, -1))
        }
    }

    const handleInfoDfd = (e) => {
        const { value, name } = e.target
        const tempState = { ...infoDfd }
        tempState[name] = value
        setInfoDfd(tempState)
    }

    const inserirInformacoesNaLinha = (index, el) => {
        setItensTabela((prevItens) => {
            const novosItens = [...prevItens]
            novosItens[index] = el
            return novosItens
        })
        setSearchResults([])
        setKey(prev => prev + 1)
    }

    const enviarDados = () => {
        // if (id && id.trim() !== "") var url = '/api/pca/editDfdSead'
        var url = '/api/dfdExtra/novoDfdExtra'
        axios.post(url, { infoDfd, itensTabela })
            .then(res => {
                alert(res.data.message)
                window.location = `/DfdExtraView/${res.data.id}`
            })
            .catch(error => {
                alert(error.response.data.message)
            })
    }

    return (
        <>
            <div style={{ margin: "2vh 0 0 4vw" }}>
                <Textarea texto="Justificativa" nome="justificativa" widthWeb="90vw"
                    funcao={handleInfoDfd} />
                <Textarea texto="Resultados Pretendidos" nome="resultados" widthWeb="90vw"
                    funcao={handleInfoDfd} />
                <Select texto="Vinculação ou dependência com outro objeto?" nome="boolVinculacao"
                    funcao={handleInfoDfd} widthWeb="26vw" widthMobile="80vw"
                    options={["Sim", "Não"]} valor={""} />
                {infoDfd.boolVinculacao === "Sim" ? (
                    <Textarea nome="vinculacao" widthWeb="90vw"
                        funcao={handleInfoDfd} />
                ) : null}
                <Textarea texto="Justificativa da não inclusão no PCA" nome="justificativaInclusao" widthWeb="90vw"
                    funcao={handleInfoDfd} />
                <Textarea texto="Sugestão de item do demandante a ser substituído" nome="sugestaoSubstituido" widthWeb="90vw"
                    funcao={handleInfoDfd} />
            </div>
            <div style={{ margin: "2vh 0 2vh 4vw" }}>
                <div style={{
                    borderBottom: "1px solid #00000029", width: "20vw",
                    margin: "auto", marginTop: "2vh", marginBottom: "2vh",
                    textAlign: "left", color: "#000000", display: "inline-block"
                }}>
                    <div style={{ display: "inline-block" }}>
                        Itens
                    </div>
                    <div style={{ float: "right", position: "relative", top: "-3px" }}>
                        <button style={{
                            width: "4vw",
                            backgroundColor: "#E90303", color: "#FFFF",
                            border: "none", borderRadius: "5px"
                        }}
                            name="menos"
                            onClick={(e) => addQtdItens(e)}>-</button>
                        <input style={{
                            marginLeft: "0.5vw", marginRight: "0.5vw", width: "4vw",
                            borderRadius: "5px", backgroundColor: "#00000000", textAlign: "center"
                        }} value={qtdItens} disabled />
                        <button style={{
                            width: "4vw", marginRight: "0.5vw",
                            backgroundColor: "#42A908", color: "#FFFF",
                            border: "none", borderRadius: "5px"
                        }}
                            name="mais"
                            onClick={(e) => addQtdItens(e)}>+</button>
                    </div>
                </div>
            </div>
            <div style={{ marginLeft: "4vw", display: "inline-block" }}>
                <Input texto="Buscar Item" nome="item" widthWeb="30vw" widthMobile="80vw" funcao={updateSearch} />
                <div>
                    <Botao texto="BUSCAR" funcao={buscaItem}
                        align="left" widthMobile="40vw" display="inline" />
                    <Botao texto="LIMPAR" funcao={() => setSearchResults([])}
                        align="left" widthMobile="40vw" display="inline" />
                </div>
            </div>
            {searchResults.length > 0 ? (
                <div className="scroll-tabela" style={{ width: "90vw", margin: "2vh auto 2vh auto" }}>
                    <table className="tabelaNovoDfd">
                        <thead>
                            <tr>
                                <th className="thNovoDfd" style={{ width: "15vw" }}>OBJETO</th>
                                <th className="thNovoDfd" style={{ width: "25vw" }}>DESCRIÇÃO</th>
                                <th className="thNovoDfd">UNIDADE</th>
                                <th className="thNovoDfd">VALOR ESTIMADO</th>
                                <th className="thNovoDfd">ÁREA</th>
                                <th className="thNovoDfd">ADICIONAR NA LINHA DA TABELA</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchResults.map((el, index) => (
                                <tr key={index}>
                                    <td className="tdNovoDfd">{el.objeto}</td>
                                    <td className="tdNovoDfd">{el.descricao}</td>
                                    <td className="tdNovoDfd">{el.und}</td>
                                    <td className="tdNovoDfd">{formatCurrency(el.valorEstimado)}</td>
                                    <td className="tdNovoDfd">{el.areaEipcp}</td>
                                    <td className="tdNovoDfd">
                                        <Select
                                            funcao={(e) => inserirInformacoesNaLinha(parseInt(e.target.value) - 1, el)}
                                            options={Array.from({ length: qtdItens }, (_, i) => (i + 1).toString())}
                                            widthWeb="10vw"
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : null}
            <div key={keyDiv}
                style={{ width: "98vw", margin: "2vw auto 0 auto", overflowX: "auto" }}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ width: "15vw" }}>Cód. Central de Compras</th>
                            <th style={{ width: "25vw" }}>Descrição</th>
                            <th style={{ width: "17vw" }}>Medida(und, caixa, L, kg etc)</th>
                            <th style={{ width: "14vw" }}>Valor Estimado</th>
                            <th style={{ width: "15vw" }}>Nome do Item</th>
                            <th style={{ width: "25vw" }}>Tipo do CAT</th>
                            <th style={{ width: "17vw" }}>CATMAT/SER</th>
                            <th style={{ width: "15vw" }}>Ação</th>
                            <th style={{ width: "15vw" }}>Natureza</th>
                            <th style={{ width: "25vw" }}>Área</th>
                            <th style={{ width: "15vw" }}>{currentYear - 2}</th>
                            <th style={{ width: "15vw" }}>{currentYear - 1}</th>
                            <th style={{ width: "15vw" }}>{currentYear - 0}</th>
                            <th style={{ width: "15vw" }}>QTD Estimada</th>
                            <th style={{ width: "15vw" }}>Data Pretendida / Calendário de Contratação</th>
                            <th style={{ width: "15vw" }}>Grau de Prioridade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itensTabela.map((el, index) => (
                            <tr key={index}>
                                <td>
                                    <Input nome="codCentral"
                                        valor={el?.codCentral} modo={el._id ? "fixed" : null}
                                        tipo="number" funcao={(e) => updateField(index, e)} widthWeb="14vw" widthMobile="80vw" />
                                </td>
                                <td>
                                    <Input nome="descricao" funcao={(e) => updateField(index, e)}
                                        valor={el?.descricao} modo={el._id ? "fixed" : null}
                                        widthWeb="24vw" widthMobile="80vw" />
                                </td>
                                <td>
                                    <Input nome="und"
                                        valor={el?.und} modo={el._id ? "fixed" : null}
                                        funcao={(e) => updateField(index, e)} widthWeb="16vw" widthMobile="80vw" />
                                </td>
                                <td>
                                    <Input nome="valorEstimado" tipo="moeda" dado={el?.valorEstimado || null}
                                        funcao={(e) => updateField(index, e)} widthWeb="14vw" widthMobile="80vw"
                                        valor={el?.valorEstimado} modo={el._id ? "fixed" : null} />
                                </td>
                                <td>
                                    <Input nome="objeto"
                                        valor={el?.objeto} modo={el._id ? "fixed" : null}
                                        widthWeb="14vw" widthMobile="80vw" funcao={(e) => updateField(index, e)} />
                                </td>
                                <td>
                                    <Select nome="tipoCat" funcao={(e) => updateField(index, e)} widthWeb="24vw" widthMobile="80vw"
                                        options={["Material", "Serviço"]} valor={el?.tipoCat} modo={el._id ? "fixed" : null} />
                                </td>
                                <td>
                                    <Input nome="catMatSer" tipo="number"
                                        valor={el?.catMatSer} modo={el._id ? "fixed" : null}
                                        funcao={(e) => updateField(index, e)} widthWeb="16vw" widthMobile="80vw" />
                                </td>
                                <td>
                                    <Select nome="acao" funcao={(e) => updateField(index, e)} widthWeb="14vw" widthMobile="80vw"
                                        options={["4211", "4216", "2143", "1157", "1614", "2216", "2217", "4392", "4393",
                                            "4938", "6106", "0703", "751", "4391", "4780"]} valor={el?.acao} modo={el._id ? "fixed" : null} />
                                </td>
                                <td>
                                    <Select nome="natureza" funcao={(e) => updateField(index, e)} widthWeb="14vw" widthMobile="80vw"
                                        options={["339030", "339033", "339039", "339139", "449039", "449051", "449052"]}
                                        valor={el?.natureza} modo={el._id ? "fixed" : null} />
                                </td>
                                <td>
                                    <Select nome="areaEipcp" funcao={(e) => updateField(index, e)} widthWeb="24vw" widthMobile="80vw"
                                        valor={el?.areaEipcp} modo={el._id ? "fixed" : null}
                                        options={["Atendimento Pré-Hospitalar", "Atividades Técnicas", "Combate a Incêndio Florestal",
                                            "Combate a Incêndio Urbano", "Materiais de Construção", "Decoração e Eventos", "Educação Física",
                                            "Embarcações, Motores e Reboques", "Ensino, Instrução e Pesquisa", "Equipamento de Som e afins",
                                            "Ferramentas e Equipamentos Gerais de Busca e Salvamento", "Gabinete Médico e Odontológico",
                                            "Gêneros Alimentícios", "Locação de Imóvel", "Materiais de Cozinha", "Material Bélico",
                                            "Material de Limpeza", "Mergulho", "Mobiliário, eletrodomésticos e afins", "Obras, serviços de engenharia ou de manutenção predial",
                                            "Operações Aquáticas", "Operações Aéreas", "Operações com Cães", "Operações em Altura",
                                            "Outras Ferramentas e Equipamentos", "Outros Serviços", "Papelaria e Escritório", "Projetos Sociais",
                                            "Serviços terceirizados com ou sem regime de dedicação exclusiva de mão de obra",
                                            "Seleções internas e Concursos Público",
                                            "Tecnologia da Informação", "Uniformes e acessórios", "Viaturas - Locação",
                                            "Viaturas - Manutenção", "Viaturas Administrativas - Aquisição", "Viaturas Operacionais - Aquisição"]} />
                                </td>
                                <td>
                                    <Input nome="ano1" tipo="number" funcao={(e) => updateField(index, e)}
                                        widthWeb="14vw" widthMobile="80vw" />
                                </td>
                                <td>
                                    <Input nome="ano2" tipo="number" funcao={(e) => updateField(index, e)}
                                        widthWeb="14vw" widthMobile="80vw" />
                                </td>
                                <td>
                                    <Input nome="ano3" tipo="number" funcao={(e) => updateField(index, e)}
                                        widthWeb="14vw" widthMobile="80vw" />
                                </td>
                                <td>
                                    <Input nome="qtdEstimada" tipo="number" funcao={(e) => updateField(index, e)}
                                        widthWeb="14vw" widthMobile="80vw" />
                                </td>
                                <td>
                                    <Select nome="entrega" funcao={(e) => updateField(index, e)} widthWeb="14vw" widthMobile="80vw"
                                        options={[
                                            "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                                            "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
                                        ]} />
                                </td>
                                <td>
                                    <Select nome="prioridade" funcao={(e) => updateField(index, e)} widthWeb="14vw" widthMobile="80vw"
                                        options={["Baixo", "Médio", "Alto"]} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Botao texto="Enviar" funcao={enviarDados} widthMobile="40vw" marginB="2vh" />
        </>
    )
}

export default SolicitarItem
