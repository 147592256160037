import {useState, useEffect} from 'react'
import axios from 'axios'

import Select from '../../Biblioteca/Select'

let today = new Date()
let year = today.getFullYear()

const BloquearLiberarDfd = () => {
    const [ano, setAno] = useState("")
    const [situacaoPreenchimento, setSituacao] = useState("")


    useEffect(() => {
        axios.get('/api/pca/getSituacaoPreenchimento')
            .then(res => setSituacao(res.data.status))
            .catch(error => alert(error.response.data.message))
    }, [])

    const updateAno = (e) => {
        setAno(e.target.value)
    }

    const updateSituacao = (e) => {
        const { value } = e.target
        if(ano !== "") {
            setSituacao(value)
            axios.post('/api/pca/situacaoPreenchimento', {situacaoPreenchimento: value, ano: parseInt(ano)})
            .then(res => alert(res.data))
            .catch(error => alert(error.response.data.message))
        } else {
            alert("É necessário inserir o ano!")
            window.location = '/bloquearliberardfd'
        }
    }

    return (
        <>
            <div style={{ margin: "1vw auto 0 auto" }}>
                <Select key={ano} texto="Ano de preenchimento do PCA(PCA menos 1)" nome="anoPreenchimento" valor={ano}
                    funcao={updateAno} widthWeb="26vw" widthMobile="80vw" display="block"
                    options={[year, parseInt(year + 1)]} />
                <Select key={situacaoPreenchimento} texto="Preenchimento do DFD" nome="situacaoPreenchimetno" valor={situacaoPreenchimento}
                    funcao={updateSituacao} widthWeb="26vw" widthMobile="80vw"
                    options={["Bloqueado", "Liberado"]} />
            </div >
        </>
    )
}

export default BloquearLiberarDfd