import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { MyContext } from "../Biblioteca/MyContext"

import brasao from '../../assets/brasao.png'

const DedView = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [dados, setDados] = useState({})
    const [origemItens, setOrigemItens] = useState([])

    useEffect(() => {
        const id = window.location.pathname.slice(9)
        axios(`/api/ded/getDed/${id}`)
            .then(res => {
                setDados(res.data)
                axios.post('/api/ded/itensRequisitados', res.data.itens)
                    .then(res => setOrigemItens(res.data))
                    .catch(error => alert(error.response.message))
            })
            .catch(error => alert(error.response.message))
    }, [])

    const estilo = {
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11,
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        }
    }
    // console.log(dados)
    return (
        <>
            <div style={{ textAlign: "center", marginTop: "4vh", fontWeight: "600", marginBottom: "5vh" }}>
                <img src={brasao} style={{ width: "8vw" }} />
                <div>ESTADO DA PARAÍBA</div>
                <div>SECRETARIA DE SEGURANÇA E DEFESA SOCIAL</div>
                <div>CORPO DE BOMBEIROS MILITAR DA PARAÍBA</div>
            </div>
            <div style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", marginBottom: "4vh" }}>
                DOCUMENTO DE EXECUÇÃO DE DEMANDA – DED
            </div>
            <div style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", marginBottom: "4vh" }}>
                Nº {dados.numeroDed}/2024/FUNESBOM
            </div>

            <div className="caixa">
                <div className="headerCaixa">
                    INFORMAÇÕES DA EXECUÇÃO DA DEMANDA
                </div>
                <div className="header1"><b>ESTÁ PREVISTA DO PCA/2024? </b>
                    ( X )  SIM, conforme DFD( ‘s) em anexo; <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    (&nbsp;&nbsp;&nbsp;)  NÃO, conforme DFD Extra e justificativa da autoridade competente em anexo.
                </div>
                <div className="header1"><b>Área / Objetivo(s) Estratégico(s) a ser(em) atingido(s):  </b>
                    {dados.objetivoEstrategico && dados.objetivoEstrategico.map((el, index) => {
                        if (index === 0) return el.objetivo
                        else return `, ${el.objetivo}`
                    })}
                </div>
                <div className="header1"><b>Meta(s) PPA (2024/2027): </b>
                    {dados.metaPpa && dados.metaPpa.map((el, index) => {
                        if (index === 0) return el.meta
                        else return `, ${el.meta}`
                    })}
                </div>
                <div className="header1"><b>Produto LOA/2024: </b>{dados.produtoLoa}</div>
                <div className="header1"><b>Área do PCA/CBMPB: </b>{RenderAreas()}</div>
                <div className="header1">
                    <div><b>Ação orçamentária: </b>
                        {dados.itens && dados.itens.map((el, index) => {
                            if (index === 0) return el.acao
                            else return `, ${el.acao}`
                        })}
                    </div>
                    <div><b>Natureza de Despesa: </b>
                        {dados.itens && dados.itens.map((el, index) => {
                            if (index === 0) return el.natureza
                            else return `, ${el.natureza}`
                        })}
                    </div>
                </div>
                <div className="header1"><b>Estratégia de aquisição: </b>{dados.estrategiaAquisicao}</div>
                <div className="header1"><b>Unidades Requisitantes: </b>
                    {dados.dfds && dados.dfds.map((el, index) => {
                        if (index === 0) return el.dfd
                        else return `, ${el.dfd}`
                    })}
                </div>
                <div className="header1"><b>Responsável pelo Planejamento: </b>
                    Equipe Integrada de Planejamento de Compras e Projetos – EIPCP, com base nos DFD’s que geraram o PCA/2024 constante no 
                    Processo PBDOC nº CBM-PRC-2023/00336
                </div>
            </div>

            <div className="caixa">
                <div className="headerCaixa">
                    INFORMAÇÕES DA CONTRATAÇÃO
                </div>
                <div className="headerSemCor">NATUREZA DOS ITENS</div>
                <div className="divLeft">MATERIAL DE CONSUMO {CheckNatureza("MATERIAL DE CONSUMO")}</div>
                <div className="divRight">EQUIPAMENTO/MATERIAL PERMANENTE {CheckNatureza("EQUIPAMENTO/MATERIAL PERMANENTE")}</div>
                <div className="divLeft">SERVIÇO CONTINUADO {CheckNatureza("SERVIÇO CONTINUADO")}</div>
                <div className="divRight">SERVIÇO NÃO CONTINUADO {CheckNatureza("SERVIÇO NÃO CONTINUADO")}</div>
                <div className="divLeft">OBRA {CheckNatureza("OBRA")}</div>
                <div className="divRight">SERVIÇO DE ENGENHARIA {CheckNatureza("SERVIÇO DE ENGENHARIA")}</div>
                <div className="divLeft">FORNECIMENTO CONTÍNUO {CheckNatureza("FORNECIMENTO CONTÍNUO")}</div>
                <div className="divRight">&nbsp;</div>

                <div className="header1"><b>Descrição sucinta da solicitação: </b><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {dados.descricaoSolicitacao}
                </div>
                <div className="header1"><b>Necessidade / Justificativa técnica da contratação: </b><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {dados.necessidadeContratacao}
                </div>
                <div className="header1"><b>Resultados Pretendidos: </b><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {dados.resultadosPretendidos}
                </div>
                <table className="tableDed">
                    <thead>
                        <tr>
                            <th style={{ ...estilo.th, width: "5vw" }}>Item</th>
                            <th style={{ ...estilo.th, width: "10vw" }}>CATMAT/<br />CATSER</th>
                            <th style={{ ...estilo.th, width: "15vw" }}> Classe CATMAT/CATSER</th>
                            <th style={{ ...estilo.th, width: "30vw" }}>Descrição</th>
                            <th style={estilo.th}>UND</th>
                            <th style={estilo.th}>QTD</th>
                            <th style={estilo.th}>Valor Unitário</th>
                            <th style={estilo.th}>Valor Total</th>
                        </tr>
                        {RenderRows()}
                    </thead>
                    <thead>
                        <tr>
                            <th colSpan="4" style={{
                                ...estilo.th, backgroundColor: "#d5ccac", color: "black", textAlign: "left"
                            }}>
                                VALOR TOTAL DO DOCUMENTO DE EXECUÇÃO DA DEMANDA
                            </th>
                            <th colSpan="4" style={{
                                ...estilo.th, backgroundColor: "#d5ccac", color: "black", textAlign: "left"
                            }}>
                                {RenderSoma()}
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className="header1"><b>Requisitos necessários para a contratação: </b><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {dados.requisitosNecessarios}
                </div>
                <div className="header1"><b>Providências a serem adotadas pela administração previamente à contratação: </b><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {dados.providencias}
                </div>
                <div className="header1"><b>Possíveis impactos ambientais e respectivas medidas de tratamento, se for o caso: </b><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {dados.impactos}
                </div>
            </div>

            <div className="caixa">
                <div className="headerCaixa">
                    RESPONSABILIDADE PELA ABERTURA DA EXECUÇÃO DA DEMANDA E CONTEÚDO DO DOCUMENTO
                </div>
                <div style={{ textAlign: "justify", padding: "5px" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Assumo que os itens acima estão enquadrados dentro do planejamento de contratações constante no PCA CBMPB 2024, ficando à disposição para dirimir eventuais dúvidas sobre esta requisição, bem como para auxiliar no que for da competência deste signatário todo o procedimento de contratação, fornecendo todas as informações técnicas necessárias junto ao agente de contratação, pregoeiro e suas equipes de apoio dos setores internos e externos envolvidos.
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Certifico que a execução da demanda acima identificada se faz necessária pelos motivos expostos nas justificativas da contratação do presente documento e de seus anexos.
                </div>
            </div>
            
            <div className="caixa">
                <div className="headerCaixa">
                    ORIGEM DAS SOLICITAÇÕES DOS ITENS (DFDs)
                </div>
                <table className="tableDed">
                    <thead>
                        <tr>
                            <th style={estilo.th}>OBJETO</th>
                            {/* <th style={estilo.th}>DESCRIÇÃO</th> */}
                            <th style={estilo.th}>QTD SOLICITADA</th>
                            <th style={estilo.th}>REGIONAL/DEPARTAMENTO</th>
                        </tr>
                        {RenderListaRetornada()}
                    </thead>
                </table>
            </div>

            <div style={{ textAlign: "center", marginTop: "8vh" }}>
                ______________________________________________________<br />
                CEL QOBM <b>LUCAS</b> SEVERIANO DE LIMA MEDEIROS
                <br />
                <b>Cmt /  Chefe da EIPCP</b>
            </div>
        </>
    )

    function CheckNatureza(natureza) {
        if (dados.naturezas && dados.naturezas.some(el => el.natureza === natureza)) return "( X )"
        else return <>(&nbsp;&nbsp;&nbsp;)</>
    }

    function RenderRows() {
        let rows = []
        dados.itens && dados.itens.map((el, index) => {
            rows.push(
                <tr key={`objeto${index}`}>
                    <td style={estilo.td}>{index + 1}</td>
                    <td style={estilo.td}>{el.catMatSer}</td>
                    <td style={estilo.td}>{el.descricaoCatMatSer}</td>
                    <td style={estilo.td}>{el.descricao}</td>
                    <td style={estilo.td}>{el.undMedida}</td>
                    <td style={estilo.td}>{el.quantidade}</td>
                    <td style={estilo.td}>{formatCurrency(el.valorEstimado)}</td>
                    <td style={estilo.td}>{formatCurrency(el.valorEstimado * el.quantidade)}</td>
                </tr>
            )
        })
        return rows
    }
    
    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderSoma() {

        let soma = 0
        dados.itens && dados.itens.map(el => {
            soma = soma + (el.valorEstimado * el.quantidade)
        })
        return formatCurrency(soma)
    }

    function RenderAreas() {
        let areas = []
        dados.itens && dados.itens.map((el, index) => {
            if (index === 0) areas.push(el.areaEipcp)
            else areas.push(`, ${el.areaEipcp}`)
        })
        return areas
    }

    function RenderListaRetornada() {
        let listaRetornada = []
        origemItens.length > 0 && origemItens.map((el, index) => {
            listaRetornada.push(
                <tr key={`objeto${index}`}>
                    <td style={estilo.td}>{el["Objeto"]}</td>
                    {/* <td style={estilo.td}>{el["Descrição"]}</td> */}
                    <td style={estilo.td}>{el["Qtd Solicitada"]}</td>
                    <td style={estilo.td}>{el["Regional/Departamento"]}</td>
                </tr>
            )
        })
        return listaRetornada
    }
}


export default DedView