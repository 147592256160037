import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "../../Biblioteca/MyContext"
import axios from 'axios'

import NovoProcesso from "./NovoProcessos"
import Movimentacoes from "./Movimentacoes"
import Tags from './Tags';

import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import cnae from './cnae'
import enviar from '../../Biblioteca/Funcoes/enviar'
import converterData from '../../Biblioteca/Funcoes/converterData'

const Processos = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext);
    const [dados, setDados] = useState({ editados: [] })
    const [processos, setProcessos] = useState([])
    const [novoProcesso, setNovoProcesso] = useState(false)
    const [tipoBusca, setTipo] = useState()

    const updateTipo = (e) => {
        const { value } = e.target
        setTipo(value)
    }
    const updateOrdem = (e) => {
        const { value } = e.target
        let tempState = [...processos]
        if (value === "Nº da Solicitação") {
            tempState.sort((a, b) => a.numeroSolicitacao - b.numeroSolicitacao)
            setProcessos(tempState)
        } else {
            tempState.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            setProcessos(tempState)
        }
    }

    const updateDadosFiltro = (e) => {
        const { value } = e.target
        let info = { filtro: tipoBusca, campo: value }
        const url = '/api/cpl/filtro'
        axios.post(url, info)
            .then(res => {
                setProcessos(res.data)
            })
            .catch(error => {
                alert(error.response.data)
            })
    }

    useEffect(() => {
        axios("/api/cpl/getProcessos")
            .then(res => {
                setProcessos(res.data)
            })
            .catch(error => alert(error.response.data))
    }, [])

    const choose = (el) => {
        let tempState = { ...dados, ...el, idProcesso: el._id }
        setDados(tempState)
    }

    const updateField = (e) => {
        var { name, value } = e.target
        let tempState = { ...dados }
        if (name === "codCnae") {
            let index = cnae.codigos.indexOf(value)
            tempState["descricaoCnae"] = cnae.descricoes[index]
        }
        let object = { user: localStorage.id, campo: name, novo: value, data: new Date().toLocaleString() }
        tempState.editados.push(object)
        tempState[name] = value
        setDados(tempState)
    }

    const estilo = {
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: "#00000000",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF"
        }
    }

    return (
        <>
            {RenderNovoProcesso()}
            {RenderOrganizacao()}
            {RenderFiltro()}
            {RenderRelatorio()}
        </>
    )

    function RenderOrganizacao() {
        return (
            <div style={{ marginLeft: "5vw", marginTop: "3vh" }}>
                <Select texto="Ordenar" nome="ordem" widthWeb="14vw" widthMobile="80vw" funcao={updateOrdem}
                    options={["Mais Recentes", "Nº da Solicitação"]} valor="Mais Recentes" />
            </div>
        )
    }

    function RenderFiltro() {
        return (
            <div style={{ marginLeft: "5vw", marginTop: "3vh" }}>
                <Select texto="Buscar" nome="tipoFiltro" widthWeb="14vw" widthMobile="80vw" funcao={updateTipo}
                    options={["Andamento", "Modalidade", "Responsável"]} />
                {RenderTipo()}
            </div>
        )

        function RenderTipo() {
            if (tipoBusca === "Andamento") {
                return (
                    <Select texto="Andamento" nome="andamento" widthWeb="14vw" widthMobile="80vw" funcao={updateDadosFiltro}
                        options={["Abertura", "CPL", "Pesquisa", "DF-RO", "Comitê Gestor", "Nota Técnica",
                            "PGE", "Ass. CMDG", "CGE", "DF-Empenho", "SEAD", "Cancelado", "QRX"]} />
                )
            }
            else if (tipoBusca === "Modalidade") {
                return (
                    <Select texto="Modalidade" nome="modalidade" widthWeb="14vw" widthMobile="80vw" funcao={updateDadosFiltro}
                        options={["Adesão de Ata", "Compra Direta", "Dispensa de Licitação", "Dispensa Art. 75",
                            "Inexigibilidade de Licitação", "Pregão", "Registro de Preços", "Contrato",
                            "TED/Convênios", "Utilização de Ata"]} />
                )
            }
            else if (tipoBusca === "Responsável") {
                return (
                    <Select texto="Responsável" nome="responsavel" widthWeb="14vw" widthMobile="80vw" funcao={updateDadosFiltro}
                        options={["Marcelo Villar", "Bruno Rennan", "Enzo Capobianco", "Talmay Pessoa",
                            "Marcos Santos", "Gustavo de Figueiredo"]} />
                )
            }
        }
    }

    function RenderNovoProcesso() {
        if (novoProcesso) return <NovoProcesso />
        else {
            return (
                <div style={{ textAlign: "center", marginTop: "3vh" }}>
                    <Botao texto="Novo Processo" widthWeb="10vw" widthMobile="40vw" funcao={() => setNovoProcesso(true)} />
                </div>
            )
        }
    }

    function RenderRelatorio() {
        return (
            <div style={{
                maxWidth: mediaWeb ? "100%" : "200vw",
                overflowX: mediaWeb ? null : "auto",
            }}>
                <table
                    style={{
                        width: mediaWeb ? "98vw" : "100%",
                        margin: "auto",
                        marginTop: "2vh",
                        marginBottom: "2vh",
                        padding: '16px',
                        borderCollapse: 'collapse',
                        textAlign: "center",
                        borderRadius: '10px',
                        maxHeight: '70vh',
                        overflowY: 'auto',
                        tableLayout: mediaWeb ? 'fixed' : null
                    }}
                >
                    <thead>
                        <tr>
                            <th style={estilo.th}>Nº DA SOLICITAÇÃO</th>
                            <th style={{ ...estilo.th, width: mediaWeb ? "10vw" : "40vw" }}>PBDOC</th>
                            <th style={estilo.th}>ANDAMENTO</th>
                            <th style={estilo.th}>MODALIDADE</th>
                            <th style={estilo.th}>Nº DA MODALIDADE</th>
                            <th style={estilo.th}>CÓDIGO CNAE</th>
                            <th style={{ ...estilo.th, width: mediaWeb ? "10vw" : "40vw" }}>DESCRIÇAO</th>
                            <th style={estilo.th}>VALOR RESERVADO</th>
                            <th style={estilo.th}>DATA DA RESERVA</th>
                            <th style={{ ...estilo.th, width: mediaWeb ? "16vw" : "40vw" }}>OBJETO</th>
                            <th style={estilo.th}>OBSERVAÇÕES</th>
                            <th style={{
                                ...estilo.th, width: mediaWeb ? "10vw" : "40vw"
                            }}>EDITAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {processos.map((el, index) => {
                            var data = null
                            if (el.dataReserva) data = el.dataReserva.slice(0, 10)
                            var borda = '1px solid #ddd'; var pad; var modo; var cor = myState === "light" ? '#fff' : "#00000000"
                            if (dados.idProcesso === el._id) {
                                borda = "1px solid green"
                                pad = "1vw"
                            }
                            dados.idProcesso !== el._id ? modo = "fixed" : modo = "edit"
                            if (el.andamento === "DF-Empenho") cor = "green"
                            if (el.andamento === "Cancelado") cor = "red"
                            return (
                                <>
                                    <tr key={index} style={{ backgroundColor: cor }}>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Nº da Solicitação" nome="numeroSolicitacao" widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} modo={modo} valor={el.numeroSolicitacao}
                                                    fontS={11} marginB="0vh" />
                                            ) : (el.numeroSolicitacao)}
                                        </td>
                                        {modo === "edit" ? (
                                            <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                                <Input texto="PBDOC" nome="pbdoc" widthWeb="9vw" widthMobile="80vw"
                                                    funcao={updateField} modo={modo} valor={el.pbdoc}
                                                    fontS={11} marginB="0vh" />
                                            </td>
                                        ) : (
                                            <td style={{ ...estilo.td, border: borda, cursor: 'pointer' }}
                                                onClick={() => window.open(`https://pbdoc.pb.gov.br/sigaex/app/expediente/doc/exibir?sigla=${el.pbdoc}`, '_blank')}
                                                onMouseOver={(e) => {
                                                    e.target.style.backgroundColor = '#33348F';
                                                    e.target.style.color = 'white';
                                                }}
                                                onMouseOut={(e) => {
                                                    myState === "light" ? e.target.style.backgroundColor = cor :
                                                        e.target.style.backgroundColor = cor;
                                                    myState === "light" ? e.target.style.color = 'black' :
                                                        e.target.style.color = 'white';
                                                }}>
                                                {el.pbdoc}
                                            </td>
                                        )}
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {el.andamento}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Select texto="Modalidade" nome="modalidade" widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh"
                                                    modo={modo} valor={el.modalidade}
                                                    options={["Adesão de Ata", "Compra Direta", "Dispensa de Licitação", "Dispensa Art. 75",
                                                        "Inexigibilidade de Licitação", "Pregão", "Registro de Preços", "Contrato",
                                                        "TED/Convênios", "Utilização de Ata"]} />
                                            ) : (el.modalidade)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Nº da Modalidade" nome="numeroModalidade" tipo="number"
                                                    modo={modo} valor={el.numeroModalidade} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh" />
                                            ) : (el.numeroModalidade)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Select texto="Código CNAE" nome="codCnae" widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh"
                                                    modo={modo} valor={el.codCnae} options={cnae.codigos} />
                                            ) : (el.codCnae || null)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {el.descricaoCnae || null}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Valor Reservado" nome="valorReserva" tipo="moeda"
                                                    dado={el.valorReserva || null} fontS={11} marginB="0vh"
                                                    modo={modo} valor={el.valorReserva} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} />
                                            ) : (el.valorReserva || null)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Data da Reserva" nome="dataReserva" tipo="date"
                                                    modo={modo} valor={data.slice(0, 10)} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh" />
                                            ) : (converterData(data.slice(0, 10)))}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Objeto" nome="objeto" widthWeb="15vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh"
                                                    modo={modo} valor={el.objeto} />
                                            ) : (el.objeto || null)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Observações" nome="obs" widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh"
                                                    modo={modo} valor={el.obs} />
                                            ) : (el.obs || null)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {dados.idProcesso !== el._id ? (
                                                <a onClick={() => choose(el)} style={{ cursor: "pointer", marginBottom: "15vw" }}>
                                                    <i className="bi bi-pen penReport"></i>
                                                </a>
                                            ) : (
                                                <>
                                                    <Botao texto="EDITAR" widthMobile="40vw"
                                                        funcao={() => enviar('/api/cpl/editProcesso', dados, "/processosCpl")} />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                    {dados.idProcesso === el._id && (
                                        <tr>
                                            <td colspan="8" style={{ border: borda, paddingLeft: "1vw" }}>
                                                <Movimentacoes processo={el}
                                                    url="insertMovimentacao" redirect="processosCpl" />
                                            </td>
                                            <td colspan="4" style={{ border: borda, paddingLeft: "1vw" }}>
                                                <Tags processo={el}
                                                    url="insertTags" redirect="processosCpl" />
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Processos