import { useState, useEffect } from 'react'
import axios from 'axios'

import './BuscaContratos.css'
import converterData from '../../Biblioteca/Funcoes/converterData'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'

const BuscaContratos = () => {
    const [contratos, setContratos] = useState([])

    useEffect(() => {
        axios.get('/api/dal/getContratos')
            .then(res => setContratos(res.data))
            .catch(error => alert(error.response.data.message))
    }, [])
    return (
        <>
            <table style={{ margin: "1vh auto 1vh auto", width: "98vw" }}>
                <thead>
                    <tr>
                        <th className="thBuscaContratos" style={{ textAlign: "center", width: "10vw" }}>Nº PROCESSO</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center", width: "12vw" }}>OBJETO</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center" }}>Nº CONTRATO</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center" }}>Nº REGISTRO CGE</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center" }}>DATA DOE</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center" }}>Nº DOE</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center" }}>VIGÊNCIA</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center" }}>VALOR(R$)</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center" }}>FORNECEDOR</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center" }}>MATRÍCULA DO GESTOR</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center" }}>GESTOR</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center" }}>STATUS</th>
                        <th className="thBuscaContratos" style={{ textAlign: "center" }}>DRIVE</th>
                    </tr>
                </thead>
                <tbody>
                    {contratos.map(el => {
                        return (
                            <tr onDoubleClick={() => window.location = `/NovoContrato/${el._id}`}>
                                <td className="tdBuscaContratos">
                                    <a className='linkBuscaContratos' href={`https://pbdoc.pb.gov.br/sigaex/app/expediente/doc/exibir?sigla=${el.pbdoc}`} target="_blank" rel="noopener noreferrer">{el.pbdoc}</a>
                                </td>
                                <td className="tdBuscaContratos">{el.assunto}</td>
                                <td className="tdBuscaContratos">{el.numeroContrato}</td>
                                <td className="tdBuscaContratos">{el.cge}</td>
                                <td className="tdBuscaContratos">{el.dataDoe ? converterData(el.dataDoe.slice(0, 10)) : ''}</td>
                                <td className="tdBuscaContratos">{el.numeroDoe}</td>
                                <td className="tdBuscaContratos">{el.vigencia ? converterData(el.vigencia.slice(0, 10)) : ''}</td>
                                <td className="tdBuscaContratos">{el.valorContrato ? formatCurrency(el.valorContrato) : ''}</td>
                                <td className="tdBuscaContratos">{el.fornecedor}</td>
                                <td className="tdBuscaContratos">{el.matriculaGestor}</td>
                                <td className="tdBuscaContratos">{el.gestor}</td>
                                <td className="tdBuscaContratos">{el.statusContrato}</td>
                                <td className="tdBuscaContratos">
                                    <a className='linkBuscaContratos' href={el.linkDrive} target="_blank" rel="noopener noreferrer">{el.linkDrive}</a>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

export default BuscaContratos