const Botao = (props) => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const width = mediaWeb ? props.widthWeb : props.widthMobile

    const estilo = {
        botao: {
            backgroundColor: props.cor || "#09792d", border: "none", borderRadius: "40px", cursor: "pointer",
            width: width || "8vw", color: "#FFFF", fontSize: 14, fontWeight: 500,
            paddingTop: "0.8vh", paddingBottom: "0.8vh", 
            marginTop: props.marginT || "2vh", marginRight: props.marginR || "1vw", marginBottom: props.marginB
        }
    }

    return (
        <div style={{ textAlign: props.align || "center", display: props.display }}>
            <button style={estilo.botao} disabled={props.disabled} onClick={props.funcao}>{props.texto}</button>
        </div>
    )
}

export default Botao