import { useState, useEffect } from 'react'
import axios from 'axios'

import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'

const TabelaEtp = () => {
    const [anoPreenchimento, setAnoPreenchimento] = useState("")
    const [fonte, setFonte] = useState("")
    const [indices, setIndices] = useState([])
    const [indiceSelecionado, setIndiceSelecionado] = useState()
    const [itens, setItens] = useState([])

    useEffect(() => {
        if (fonte !== "" && anoPreenchimento !== "")
            axios.post('/api/pca/getIndices', { fonte: fonte, anoPreenchimentoPca: anoPreenchimento })
                .then(res => setIndices(res.data))
                .catch(error => alert(error.response.data.message))
    }, [anoPreenchimento])

    const updateAno = (e) => {
        const { value } = e.target
        setAnoPreenchimento(value)
    }
    const updateFonte = (e) => {
        const { value } = e.target
        setFonte(value)
    }

    const updatedIndice = (e) => {
        const { value } = e.target
        setIndiceSelecionado(value)
    }

    const getItem = () => {
        axios.post('/api/pca/getItemByIndex', { indice: indiceSelecionado, fonte: fonte, anoPreenchimentoPca: anoPreenchimento })
            .then(res => setItens(prevState => [...prevState, res.data]))
            .catch(error => alert(error.response.data.message))
    }

    console.log(itens)
    return (
        <>
            <div style={{ margin: "1vw 0 0 4vw" }}>
                <Select texto="Fonte" nome="fonte" valor={fonte}
                    funcao={updateFonte} widthWeb="26vw" widthMobile="80vw"
                    options={["CBM", "FUNESBOM"]} />
                <Select texto="Ano de Preenchimento" tipo="number" nome="anoPreenchimento" valor={anoPreenchimento}
                    funcao={updateAno} widthWeb="26vw" widthMobile="80vw"
                    options={[2023, 2024]} />
            </div>
            <div style={{
                margin: "1vw 0 0 4vw", display: "grid",
                gridTemplateColumns: "26vw auto", alignItems: "flex-end", justifyContent: "flex-start",
                height: "100%", width: "auto", columnGap: "2vw"
            }}>
                <Select texto="Índice" nome="indice" valor={fonte}
                    funcao={updatedIndice} widthWeb="26vw" widthMobile="80vw" marginR="1vw"
                    options={[...indices]} />
                <Botao texto="Incluir" marginB="0.5vw" marginT="0vw" martginL display="inline-grid"
                    funcao={getItem} />
            </div>
            {itens.length > 0 ? (
                <table className="tableDfd">
                    <thead>
                        <tr>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "3vw" }}>ITEM</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "4vw" }}>CÓDIGO</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "8vw" }}>OBJETO</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "14vw" }}>DESCRIÇÃO BASE DE REFERÊNCIA</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "5vw" }}>UND. DE MEDIDA</th>
                            <th className="thDfdGeral" colSpan="3" style={{ width: "9vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "5vw" }}>QTD. ESTIMADA</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "17vw" }}>JUSTIFICATIVA DOS QUANTITATIVOS</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>VALOR ESTIMADO UNITÁRIO</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "10vw" }}>COMPROMETIMENTO ORÇAMENTÁRIO LOA 2025</th>
                            {anoPreenchimento.ano !== "2023" ? (<>
                                <th rowSpan="2" className="thDfdGeral" style={{ width: "9vw" }}>DATA PRETENDIDA / CALENDÁRIO DE CONTRATAÇÃO</th>
                                <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>GRAU DE PRIORIDADE</th>
                            </>) : (<></>)}
                        </tr>
                        <tr>
                            <th className="thDfdGeral">2021</th>
                            <th className="thDfdGeral">2022</th>
                            <th className="thDfdGeral">2023</th>
                        </tr>
                    </thead>
                    <tbody>
                        {RenderRows()}
                    </tbody>
                </table>
            ) : (<></>)}
        </>
    )

    function RenderRows() {
        let rows = []
        itens.map((el, index) => {
            const descricao = el.descricao.length > 200 ?
                el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
            const media = (el.totalAno1 + el.totalAno2 + el.totalAno3) / 3
            var justificativa
            if (media === 0) justificativa = "Item que não teve compra nos últimos três anos, mas apresentaram demandas de acordo com as quantidades referentes ao somatório dos DFDs Setoriais dos setores listados na justificativa da área conforme todas as JUSTIFICATIVAS DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo"
            else if (media <= el.totalQtdEstimada) justificativa = "Quantidades referentes ao somatório dos DFDs Setoriais dos setores listados na justificativa da área conforme todas as JUSTIFICATIVAS DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo."
            else justificativa = "Conforme JUSTIFICATIVA DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo."
            if (el.totalQtdEstimada > 0) {
                rows.push(
                    <tr key={`objeto${index}`}>
                        <td className="tdDfdGeral">{el.indexPCA}</td>
                        <td className="tdDfdGeral">{el?.codCentral || "Sem correspondência no SGC"}</td>
                        <td className="tdDfdGeral">{el.objeto}</td>
                        <td className="tdDfdGeral">{descricao}</td>
                        <td className="tdDfdGeral">{el.und}</td>
                        <td className="tdDfdGeral">{el.totalAno1}</td>
                        <td className="tdDfdGeral">{el.totalAno2}</td>
                        <td className="tdDfdGeral">{el.totalAno3}</td>
                        <td className="tdDfdGeral">{el.totalQtdEstimada}</td>
                        <td className="tdDfdGeral">
                            {justificativa}
                            <br /><br />
                            Setores que solicitaram o item: <br />
                            {el.regionais.map((item, index) => {
                                if (index !== 0) return `, ${item.regional}(${item.qtdEstimada})`
                                else return `${item.regional}(${item.qtdEstimada})`
                            })}
                        </td>
                        <td className="tdDfdGeral">{formatCurrency(el.valorEstimado)}</td>
                        <td className="tdDfdGeral">{formatCurrency(el.totalQtdEstimada * el.valorEstimado)}</td>
                        {anoPreenchimento.ano !== "2023" ? (<>
                            <td className="tdDfdGeral">{el.closestMonth}</td>
                            <td className="tdDfdGeral">{el.highestPriority}</td>
                        </>) : (<></>)}
                    </tr>
                )
            }
        }
        )
        return rows
    }
}

export default TabelaEtp