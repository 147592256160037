import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios';
import { VictoryChart, VictoryBar, VictoryAxis, VictoryTheme, VictoryLabel } from 'victory';

const Ranking = () => {
  const { myState } = useContext(MyContext);
  const mediaWeb = window.matchMedia("(min-width:600px)").matches
  const [data, setData] = useState([]);

  useEffect(() => {
    axios("/api/cpl/getCount")
      .then(res => {
        setData(res.data);
      })
      .catch(error => alert(error.response.data));
  }, []);

  const colorScale = ['#4285F4', '#DB4437', '#F4B400', '#0F9D58', '#4285F4'];

  const generateUniqueColor = () => {
    const color = '#' + Math.floor(Math.random() * 16777215).toString(16);
    if (colorScale.includes(color)) {
      return generateUniqueColor();
    }
    return color;
  }

  return (
    <div>
      <div style={{
        textAlign: "center", fontSize: 15, fontWeight: 600,
        paddingTop: "2vh", color: myState === "light" ? "#33348F" : "#c4c4ea",
      }}>RANKING DE PROCESSOS</div>
      <VictoryChart
        theme={VictoryTheme.material}
        domainPadding={40}
        width={1100}
        height={mediaWeb ? 150: 1100}
      >
        <VictoryAxis
          tickFormat={(tick) => tick}
          style={{
            tickLabels: { fontSize: mediaWeb ? 12 : 45, padding: 5, fill: myState === "light" ? "black" : "#f3f3f3" }
          }}
        />
        <VictoryAxis
          dependentAxis
          tickFormat={(tick) => tick}
          style={{
            tickLabels: { fontSize: mediaWeb ? 10 : 45, padding: 5, fill: myState === "light" ? "black" : "#f3f3f3" }
          }}
        />
        <VictoryBar
          data={data}
          x="nome"
          y="processos"
          style={{
            data: {
              fill: ({ index }) => colorScale[index % colorScale.length] || generateUniqueColor(),
              width: mediaWeb ? 50 : 70,
            },
            labels: { fontSize: mediaWeb ? 15 : 45, fill: myState === "light" ? "black" : "#f3f3f3" }
          }}
          labels={({ datum }) => `${datum.processos}`}
          labelComponent={<VictoryLabel dy={-20} />}
        />
      </VictoryChart>
    </div>
  );
}

export default Ranking;
