import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "../../Biblioteca/MyContext"

import Input from '../../Biblioteca/Input'
import Botao from '../../Biblioteca/Botao'
import enviar from '../../Biblioteca/Funcoes/enviar'

const Tags = (props) => {
    const { myState } = useContext(MyContext);
    const [insert, setInsert] = useState(false)
    const [dados, setDados] = useState({ idProcesso: props.processo._id, user: localStorage.nome })

    const updateField = (e) => {
        var { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }

    return (
        <>
            <div style={{
                color: myState === "light" ? "#33348F" : "#c4c4ea", marginTop: "3vh",
                marginBottom: "2vh", textAlign: "left"
            }}>
                <b>Tags </b>
                {!insert ? (
                    <button onClick={() => setInsert(true)} style={{
                        border: "none", backgroundColor: "#42a908", marginLeft: "1vw",
                        color: "#FFFF", borderRadius: "5px", paddingTop: "0.5px", paddingBottom: "0.5px"
                    }}>+</button>
                ) : (
                    <>
                        <Botao texto="Enviar" display="inline-block" widthWeb="5vw" widthMobile="20vw" marginR="1vw"
                            funcao={() => enviar(`/api/cpl/${props.url}`, dados, `/${props.redirect}`)} />
                        <Botao texto="Cancelar" display="inline-block" widthWeb="5vw" widthMobile="20vw" cor="#E71313"
                            funcao={() => setInsert(false)} />
                    </>
                )}
            </div >
            {insert && (
                <div style={{ textAlign: "left" }}>
                    <Input texto="Tag" nome="tag" widthWeb="14vw" widthMobile="80vw" funcao={updateField} />
                </div>
            )
            }
            {
                props.processo.tags.reverse().map(x => {
                    return (
                        <div style={{ textAlign: "left" }}>
                            <Input texto="Tag" widthWeb="14vw" widthMobile="80vw" funcao={() => console.log()} modo="fixed" valor={x.tag} />
                        </div>
                    )
                })
            }
        </>
    )
}

export default Tags