import { useState, useContext, useEffect } from 'react'
import { MyContext } from "../Biblioteca/MyContext"

const FormEM6regionais = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [saldo, setSaldo] = useState(7600000.00)
    const [items, setItems] = useState([])

    const [display, setDisplay] = useState([
        { display: "none" }, { display: "none" }, { display: "none" }
    ])

    const alterarDisplay = (index, novoValor) => {
        setDisplay((prevDisplay) => {
            const novoDisplay = [...prevDisplay]
            novoDisplay[index].display = novoValor
            return novoDisplay
        })
    }

    const formatarMoeda = (valor) => {
        return valor.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        })
    }

    const [tabelas, setTabelas] = useState([
        {
            tabela: [
                {
                    objeto: "Cabo 10mm", descricao: " 1,00m de Cabo de 10mm de tecido sintético da WonderHeights",
                    valor: 35.00, quantidade: ""
                },
                {
                    objeto: "Cabo 15mm", descricao: " 1,00m de Cabo de 15mm de tecido sintético da WonderHeights",
                    valor: 45.00, quantidade: ""
                },
                {
                    objeto: "Cabo 18mm", descricao: " 1,00m de Cabo de 18mm de tecido sintético da WonderHeights",
                    valor: 55.00, quantidade: ""
                },
                {
                    objeto: "Cabo 20mm", descricao: " 1,00m de Cabo de 20mm de tecido sintético da WonderHeights",
                    valor: 65.00, quantidade: ""
                },
                {
                    objeto: "Mosquetão", descricao: "Mosquetão em aço inox da WonderHeights",
                    valor: 40.00, quantidade: ""
                },
                {
                    objeto: "Mosquetão UltraSlim", descricao: "Mosquetão ultra leve em tungstênio da WonderHeights",
                    valor: 55.00, quantidade: ""
                },
            ],
        },
        {
            tabela: [
                {
                    objeto: "Motosserra 170", descricao: "Motosserra STIHL MS 170",
                    valor: 1000.00, quantidade: ""
                },
                {
                    objeto: "Motosserra 210", descricao: "Motosserra STIHL MS 210 Compacta",
                    valor: 2300.00, quantidade: ""
                },
                {
                    objeto: "Desencarcerador Hidráulico", descricao: `Desencarcerador F130N da Edilgrappa`,
                    valor: 22000.00, quantidade: ""
                },
                {
                    objeto: "Desencarcerador Elétrico", descricao: `Desencarcerador E200N da Edilgrappa`,
                    valor: 17500.00, quantidade: ""
                },
            ],
        },
        {
            tabela: [
                {
                    objeto: "Mesa Individual", descricao: "Mesa individuial para escritório",
                    valor: 1000.00, quantidade: ""
                },
                {
                    objeto: "Mesa Master", descricao: "Mesa em L para escritório",
                    valor: 1800.00, quantidade: ""
                },
                {
                    objeto: `Notebook 14"" `, descricao: "Notebook Asus 8gB de RAM e HD de 1Tb",
                    valor: 4500.00, quantidade: ""
                },
                {
                    objeto: `Notebook 16"" `, descricao: "Notebook Asus 8gB de RAM, HD de 1Tb e 250Mb de SSD",
                    valor: 1800.00, quantidade: ""
                },
            ]
        }
    ])

    const estilo = {
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 13 : 18, color: myState === "light" ? 'black' : "#FFFF"
        },
        input: {
            border: "none", outline: "none", backgroundColor: "#00000000",
            color: myState === "light" ? 'black' : "#FFFF", resize: "none"
        }
    }

    const numbersOnly = (e, index, obj, indexObj) => {
        const inputValue = e.target.value.replace(/\D/g, "")

        setTabelas((prevState) => {
            const newState = [...prevState]
            newState[index].tabela[indexObj].quantidade = inputValue
            return newState
        })

        setItems((prevItems) => [...prevItems, {
            objeto: obj.objeto, valor: obj.valor, qtd: obj.quantidade
        }])
    }

    useEffect(() => {
        let valor = 0
        tabelas.map(el => {
            el.tabela.map(obj => {
                if (obj.valor && obj.quantidade !== "") {
                    valor = parseFloat(valor) + parseFloat(obj.valor * obj.quantidade)
                }
            })
        })
        setSaldo((prevSaldo) => 7600000.00 - (valor))
    }, [tabelas])

    return (
        <>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "5vh",
                width: "30vw",
                height: "10vw",
                border: "1px solid #ddd",
                borderRadius: "8px",
            }}>
                <div style={{
                    fontSize: "2rem",
                    color: myState === "light" ? 'black' : "#FFFF"
                }}>
                    <div style={{
                        fontSize: "1.5rem", textAlign: "center",
                        color: myState === "light" ? 'black' : "#FFFF"
                    }}>
                        1º CRBM
                    </div>
                    {formatarMoeda(saldo)}
                </div>
            </div>
            {/* Componentizar isso */}
            <div style={{
                width: "70vw", padding: "10px",
                marginTop: "2vh", marginLeft: "auto", marginRight: "auto",
                backgroundColor: "#ccc", borderRadius: "8px",
            }}>
                <div style={{
                    width: "64vw", display: "inline-block"
                }}>
                    Material de Salvamento em Altura
                </div>
                <button name="mais" style={{
                    backgroundColor: "#FFFF", color: "#ccc",
                    borderRadius: "50%", border: "none",
                    width: "2vw", height: "2vw",
                    backgroundColor: display[0].display === "none" ? "green" : "red", color: "#FFFF",
                }} onClick={() => display[0].display === "none" ? alterarDisplay(0, "block") : alterarDisplay(0, "none")} >
                    {display[0].display === "none" ? "+" : "-"}
                </button>
            </div>
            <div style={{ display: display[0].display }}>
                <table style={{
                    width: mediaWeb ? "98vw" : "100%",
                    margin: "auto",
                    marginTop: "2vh",
                    marginBottom: "2vh",
                    padding: '16px',
                    borderCollapse: 'collapse',
                    textAlign: "center",
                    borderRadius: '10px',
                    maxHeight: '70vh',
                    overflowY: 'auto',
                    tableLayout: mediaWeb ? 'fixed' : null
                }}>
                    <thead>
                        <tr>
                            <th style={estilo.th}>OBJETO</th>
                            <th style={estilo.th}>DESCRIÇÃO DO OBJETO</th>
                            <th style={estilo.th}>VALOR ESTIMADO</th>
                            <th style={estilo.th}>QUANTIDADE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {RenderTabela(tabelas[0].tabela, 0)}
                    </tbody>
                </table>
            </div>
            {/* Até aqui */}
            <div style={{
                width: "70vw", padding: "10px",
                marginTop: "2vh", marginLeft: "auto", marginRight: "auto",
                backgroundColor: "#ccc", borderRadius: "8px",
            }}>
                <div style={{
                    width: "64vw", display: "inline-block"
                }}>
                    Material de Salvamento Terrestre
                </div>
                <button name="mais" style={{
                    borderRadius: "50%", border: "none",
                    width: "2vw", height: "2vw",
                    backgroundColor: display[1].display === "none" ? "green" : "red", color: "#FFFF",
                }} onClick={() => display[1].display === "none" ? alterarDisplay(1, "block") : alterarDisplay(1, "none")} >
                    {display[1].display === "none" ? "+" : "-"}
                </button>
            </div>
            <div style={{ display: display[1].display }}>
                <table style={{
                    width: mediaWeb ? "98vw" : "100%",
                    margin: "auto",
                    marginTop: "2vh",
                    marginBottom: "2vh",
                    padding: '16px',
                    borderCollapse: 'collapse',
                    textAlign: "center",
                    borderRadius: '10px',
                    maxHeight: '70vh',
                    overflowY: 'auto',
                    tableLayout: mediaWeb ? 'fixed' : null
                }}>
                    <thead>
                        <tr>
                            <th style={estilo.th}>OBJETO</th>
                            <th style={estilo.th}>DESCRIÇÃO DO OBJETO</th>
                            <th style={estilo.th}>VALOR ESTIMADO</th>
                            <th style={estilo.th}>QUANTIDADE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {RenderTabela(tabelas[1].tabela, 1)}
                    </tbody>
                </table>
            </div>

            <div style={{
                width: "70vw", padding: "10px",
                marginTop: "2vh", marginLeft: "auto", marginRight: "auto",
                backgroundColor: "#ccc", borderRadius: "8px",
            }}>
                <div style={{
                    width: "64vw", display: "inline-block"
                }}>
                    Material de Escritório
                </div>
                <button name="mais" style={{
                    borderRadius: "50%", border: "none",
                    width: "2vw", height: "2vw",
                    backgroundColor: display[2].display === "none" ? "green" : "red", color: "#FFFF",
                }} onClick={() => display[2].display === "none" ? alterarDisplay(2, "block") : alterarDisplay(2, "none")} >
                    {display[2].display === "none" ? "+" : "-"}
                </button>
            </div>
            <div style={{ display: display[2].display }}>
                <table style={{
                    width: mediaWeb ? "98vw" : "100%",
                    margin: "auto",
                    marginTop: "2vh",
                    marginBottom: "2vh",
                    padding: '16px',
                    borderCollapse: 'collapse',
                    textAlign: "center",
                    borderRadius: '10px',
                    maxHeight: '70vh',
                    overflowY: 'auto',
                    tableLayout: mediaWeb ? 'fixed' : null
                }}>
                    <thead>
                        <tr>
                            <th style={estilo.th}>OBJETO</th>
                            <th style={estilo.th}>DESCRIÇÃO DO OBJETO</th>
                            <th style={estilo.th}>VALOR ESTIMADO</th>
                            <th style={estilo.th}>QUANTIDADE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {RenderTabela(tabelas[2].tabela, 2)}
                    </tbody>
                </table>
            </div>
        </>
    )

    function RenderTabela(tabela, index) {
        let rows = []
        tabela.map((obj, indexObj) => {
            rows.push(
                < tr >
                    <td style={estilo.td}>{obj.objeto}</td>
                    <td style={estilo.td}>{obj.descricao}</td>
                    <td style={estilo.td}>{formatarMoeda(obj.valor)}</td>
                    <td style={estilo.td}>
                        <input type="number" style={{
                            border: "1px solid #ccc", borderRadius: "5px", backgroundColor: "#00000000",
                            color: myState === "light" ? 'black' : "#FFFF"
                        }}
                            onChange={(e) => numbersOnly(e, index, obj, indexObj)}
                            value={obj.quantidade} />
                    </td>
                </tr >
            )
        })
        return rows
    }
}

export default FormEM6regionais