import { useState, useEffect } from 'react'
import axios from 'axios'

import Botao from '../../Biblioteca/Botao'
import LinkComponent from "../../Cpl/Processos/LinkComponent"
import converterData from '../../Biblioteca/Funcoes/converterData'

const BuscaDfdSead = () => {
    const [dfds, setDfds] = useState([])

    useEffect(() => {
        axios.get('/api/pca/getDfdsSead')
            .then(res => setDfds(res.data))
            .catch(error => alert(error.responde.data.message))
    }, [])

    const excluir = (id) => {
        if (window.confirm("Tem certeza de que deseja excluir este item?")) {
            axios.post('/api/pca/deleteDfdsSead', { id: id })
                .then(res => {
                    alert(res.data)
                    window.location = '/BuscaDfdSead'
                })
                .catch(error => alert(error.response.data.message))
        } else {
            alert("A exclusão foi cancelada.")
        }
    }
    return (
        <>
            <table style={{ margin: "4vh auto 4vh auto", width: "80vw" }}>
                <thead>
                    <tr>
                        <th style={{ textAlign: "center" }}>DATA</th>
                        <th style={{ textAlign: "center", width: "10vw" }}>MODELO</th>
                        <th style={{ textAlign: "center", width: "20vw" }}>USUÁRIO</th>
                        <th style={{ textAlign: "center" }}>NÚMERO</th>
                        <th style={{ textAlign: "center" }}>PBDOC</th>
                        <th style={{ textAlign: "center" }}></th>
                        <th style={{ textAlign: "center" }}></th>
                        <th style={{ textAlign: "center" }}></th>
                    </tr>
                </thead>
                <tbody>
                    {dfds.length > 0 ? (<>
                        {dfds.slice().sort((a, b) => {
                            const dataA = new Date(a.data)
                            const dataB = new Date(b.data)
                            return dataB - dataA
                        }).map(el => {
                            return (

                                <tr>
                                    <td>{el.data ? converterData(el.data.slice(0, 10)) : ''}</td>
                                    <td>{el.modelo}</td>
                                    <td>{el.usuário}</td>
                                    <td>{el.numeroDfd}</td>
                                    <td><LinkComponent pbdoc={el.pbdoc} /></td>
                                    <td>
                                        <Botao texto="Abrir" widthWeb="5vw"
                                            funcao={() => window.location = `/DfdSeadView/${el._id}`} />
                                    </td>
                                    <td>
                                        <Botao texto="Editar" widthWeb="5vw" cor="rgb(51, 52, 143)"
                                            funcao={() => window.location = `/SelecionarModelo/${el._id}`} />
                                    </td>
                                    <td>
                                        <Botao texto="Excluir" cor="red" widthWeb="5vw"
                                            funcao={() => excluir(el._id)} />
                                    </td>
                                </tr>
                            )
                        })}
                    </>) : (<></>)}
                </tbody>
            </table>
        </>
    )
}

export default BuscaDfdSead