import React, { useEffect, useState } from 'react'
import axios from 'axios'

const contratoDescricao = {
    "pbdoc": "Processo(PBDOC)",
    "assunto": "Assunto",
    "numeroContrato": "Número do Contrato",
    "cge": "Registro CGE",
    "dataDoe": "Data DOE",
    "numeroDoe": "Número DOE",
    "vigencia": "Vigência",
    "valorContrato": "Valor do Contrato",
    "fornecedor": "Fornecedor",
    "matriculaGestor": "Matrícula do Gestor",
    "gestor": "Nome do Gestor",
    "statusContrato": "Status",
    "linkDrive": "Link Drive"
  }

const LogEdicoes = () => {
  const [edicoes, setEdicoes] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchEdicoes = async () => {
    try {
      const response = await axios.get('/api/dal/listarTodasEdicoes')
      setEdicoes(response.data)
      setLoading(false)
    } catch (error) {
      setError('Erro ao buscar as edições.')
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchEdicoes()
  }, [])

  if (loading) {
    return <p>Carregando...</p>
  }

  if (error) {
    return <p>{error}</p>
  }

  return (
    <div>
      <h2>Alterações em Contartos Registradas</h2>
      <table>
        <thead>
          <tr>
            <th>Contrato</th>
            <th>Número do Contrato</th>
            <th>Usuário</th>
            <th>Campo Alterado</th>
            <th>Valor Antigo</th>
            <th>Valor Novo</th>
            <th>Data de Alteração</th>
          </tr>
        </thead>
        <tbody>
          {edicoes.map((edicao) => (
            <React.Fragment key={edicao._id}>
              {edicao.alteracoes.map((alteracao, index) => (
                <tr key={index}>
                  <td>{[edicao.contratoId.pbdoc]}</td>
                  <td>{edicao.contratoId.numeroContrato}</td>
                  <td>{edicao.usuarioId.nome}</td>
                  <td>{contratoDescricao[alteracao.campoAlterado]}</td>
                  <td>{alteracao.valorAntigo}</td>
                  <td>{alteracao.valorNovo}</td>
                  <td>{new Date(edicao.dataAlteracao).toLocaleString()}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LogEdicoes
