import axios from 'axios'

const enviar = (url, dados, redirect, disable) => {
    if (disable) disable(true)
    axios.post(url, dados)
        .then(res => {
            alert(res.data)
            if (redirect) window.location = redirect
        })
        .catch(error => {
            alert(error.response.data.message)
            if (disable) disable(false)
        })
}

export default enviar