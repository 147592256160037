const moeda = (e, funcao) => {
    var valor = e.target.value;

    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g,''));
    valor = valor + '';
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
        valor = valor.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

    e.target.value = "R$ " + valor
    if (valor == 'NaN') e.target.value = ''

    return funcao(e)
}

export default moeda