import { useState, useEffect, useContext } from 'react'
import { MyContext } from "../../Biblioteca/MyContext"
import axios from 'axios'

import Select from '../../Biblioteca/Select'

const DetalhamentoDespesaPca = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabelas, setTabelas] = useState([])
    const [anoPreenchimento, setAnoPreenchimento] = useState("")
    const [fonte, setFonte] = useState("")

    useEffect(() => {
        if (fonte !== "" && anoPreenchimento !== "") {
            axios.post('/api/pca/detalhamentoDespesa', { fonte: fonte, anoPreenchimento: anoPreenchimento })
                .then(res => setTabelas(res.data))
                .catch(error => alert(error.response.data.message))
        }
    }, [anoPreenchimento])

    const updateAno = (e) => {
        const { value } = e.target
        setAnoPreenchimento(value)
    }
    const updateFonte = (e) => {
        const { value } = e.target
        setFonte(value)
    }

    const estilo = {
        table: {
            width: mediaWeb ? "98vw" : "100%", marginTop: "2vh", marginBottom: "2vh",
            marginLeft: "auto", marginRight: "auto",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: mediaWeb ? 'fixed' : null
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 16, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: 14, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        }
    }
    console.log(tabelas)
    return (
        <>
            <div style={{ margin: "1vw 0 0 4vw" }}>
                <Select texto="Fonte" nome="fonte" valor={fonte}
                    funcao={updateFonte} widthWeb="26vw" widthMobile="80vw"
                    options={["CBM", "FUNESBOM"]} />
                <Select texto="Ano de Preenchimento PCA" nome="anoPreenchimentoPca" valor={anoPreenchimento}
                    funcao={updateAno} widthWeb="26vw" widthMobile="80vw"
                    options={[2024]} />
            </div>
            <div style={{
                textAlign: "center",
                marginTop: "5vh", marginBottom: "2vh"
            }}><b>QUADRO DE DETALHAMENTOD DE DESPESAS</b></div>
            <table style={estilo.table}>
                <thead>
                    <tr>
                        <th style={estilo.th}>Ação</th>
                        <th style={estilo.th}>Natureza</th>
                        <th style={estilo.th}>Valor Total</th>
                    </tr>
                </thead>
                <tbody>
                    {RenderRows()}
                </tbody>
            </table>
        </>
    )

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderRows() {
        let table = []
        let soma = 0
        if (tabelas !== undefined) {
            tabelas.map((obj) => {
                obj.naturezas.map((el, index) => {
                    soma = soma + el.totalValorEstimado
                    const acaoMapping = {
                        "1º CRBM": "4855 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 1º Comando Regional Bombeiro Militar / Unidade Financeira  - 23.102",
                        "2º CRBM": "4856 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 2º Comando Regional Bombeiro Militar  / Unidade Financeira  - 23.103",
                        "3º CRBM": "4857 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 3º Comando Regional Bombeiro Militar / Unidade Financeira  - 23.104",
                        "4º CRBM": "6009 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 4º Comando Regional Bombeiro Militar / Unidade Financeira  - 23.106",
                        "4211": "SEGUROS E TAXAS DE VEÍCULOS",
                        "4216": "MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
                        "2143": "FORMAÇÃO, ESPECIALIZAÇÃO E CAPACITAÇÃO DE BOMBEIRO MILITAR",
                        "1157": "CONSTRUÇÕES E REFORMAS DE IMÓVEIS DO CBMPB",
                        "1614": "AQUISICÃO E LOCAÇÃO DE IMÓVEIS PARA O CBMPB",
                        "2216": "AQUISIÇÃO, LOCAÇÃO E MANUTENÇÃO DE AERONAVE",
                        "2217": "AQUISIÇÃO E MANUTENÇÃO DE MATERIAL BELICO",
                        "4392": "PREVENÇÃO, FISCALIZAÇÃO E PERÍCIA DE INCÊNDIO",
                        "4393": "COMBATE A INCENDIO, SALVAMENTO E APH",
                        "4938": "AQUISIÇÃO DE SEMOVENTES E EQUIPAMENTOS DE ADESTRAMENTO",
                        "1511": "DESENVOLVIMENTO DE PROGRAMAS E PROJETOS SOCIAIS EDUCATIVOS E DE PREVENÇÃO",
                        "0703": "DESPESAS DE EXERCÍCIOS ANTERIORES",
                        "751": "Indenizações e restituições",
                        "4391": "AQUISIÇÃO DE VIATURAS, PEÇAS, ACESSÓRIOS E SERVIÇOS",
                        "4780": "SEGUROS E TAXAS DE AERONAVES",
                        "6106": "DESENVOLVIMENTO DE PROGRAMAS E PROJETOS DE RESPONSABILIDADE SOCIAL PARA OS PÚBLICOS INTERNO E EXTERNO "
                    }
                    const acao = acaoMapping[obj.acao] || "Ação não definida"

                    const naturezaMapping = {
                        "339030": "Material de Consumo",
                        "339033": "Passgens e despesas com locomoção",
                        "339039": "Outros Serviços de Terceiros - Pessoa Jurídica",
                        "339139": "Outros Serviços de Terceiros - Pessoa Jurídica",
                        "449039": "Outros Serviços de Terceiros - Pessoa Jurídica - Capital",
                        "449051": "Obras e Instalações",
                        "449052": "Equipamentos e Material Permanente",
                        "449092": "Despesas de Exercícios Anteriores - Capital"
                    }
                    const natureza = naturezaMapping[el.natureza] || "Natureza não definida"
                    table.push(
                        <tr key={el.natureza + "-" + obj.acao}>
                            <td style={estilo.td}>
                                <div>
                                    <b>{obj.acao}</b>
                                </div>
                                ({acao})
                            </td>
                            <td style={estilo.td}>
                                <div>
                                    <b>{el.natureza}</b>
                                </div>
                                ({natureza})
                            </td>
                            <td style={estilo.td}>{formatCurrency(el.totalValorEstimado)}</td>
                        </tr>
                    )
                })
            })
        }
        table.push(
            <tr key="somaTotal">
                <td style={estilo.td}></td>
                <td style={estilo.td}><b>SOMA TOTAL</b></td>
                <td style={{ ...estilo.td, color: "red" }}>{formatCurrency(soma)}</td>
            </tr>
        )
        return table
    }
}

export default DetalhamentoDespesaPca