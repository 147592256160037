const calcularDiasPassados = (data) => {
    var hoje = new Date();
  
    var dataFornecida = new Date(data);
  
    var diferencaEmMilissegundos = hoje - dataFornecida;
  
    var diferencaEmDias = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
  
    return Math.abs(diferencaEmDias) + " dias";
  }
  
  export default calcularDiasPassados;
  