import React, { useState, useContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { MyProvider } from "./components/Biblioteca/MyContext"
import './App.css'
import setAuthToken from './utils/setAuthToken'
import checkAuth from './utils/checkAuth'

import Wrapper from './Wrapper'
import Login from './components/Login/Login'
import Nav from './components/Nav/Nav'
import Content from './components/Content/Content'

if (localStorage.token) {
  setAuthToken(localStorage.token)
  checkAuth()
}

function App() {
  if (!localStorage.token) {
    return (
      <MyProvider>
        <Login />
      </MyProvider>
    )
  }

  return (
    <Router>
      <MyProvider>
        <Wrapper>
          <Nav />
          <Content />
        </Wrapper>
      </MyProvider >
    </Router>
  )
}

export default App
