import React from 'react'

const Dashboard = () => {
    return (
        <div style={{ width: "100%", height: "91vh", backgroundColor: '#212529' }}>
            <iframe
                src="https://bravoplan-dash-265e677c26ea.herokuapp.com/dashboard1/"
                // src="http://localhost:5001/dashboard1/"
                style={{ width: "100%", height: "100%" }}
                frameBorder="0"
            ></iframe>
        </div>
    )
}

export default Dashboard
