import { useState } from "react"
import axios from 'axios'

import Caixa from "../Biblioteca/Caixa"
import Input from '../Biblioteca/Input'
import Select from '../Biblioteca/Select'
import Botao from '../Biblioteca/Botao'
import enviar from '../Biblioteca/Funcoes/enviar'

const Lacerda = () => {
    const [dados, setDados] = useState({ user: localStorage.nome })
    const [disabled, setDisabled] = useState(false)

    const updateField = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }

    return (
        <Caixa>
            <Input texto="PBDOC" nome="pbdoc" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Empresa" nome="empresa" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Processo" nome="processo" funcao={updateField} tipo="number" widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Vigência" nome="vigencia" tipo="date"
                funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Credor" nome="credor" funcao={updateField} tipo="number" widthWeb="14vw" widthMobile="80vw" />
            <Input texto="CNPJ" nome="cnpj" funcao={updateField} tipo="cnpj" widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Reserva 2023" nome="valorReserva2023" tipo="moeda" dado={dados.valorReserva || null}
                funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Número da Reserva" nome="numeroReserva" funcao={updateField} tipo="number" widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Reserva 2024" nome="valorReserva2024" tipo="moeda" dado={dados.valorReserva || null}
                funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
                {/* //Inserir soma das reservas */}
            <Input texto="Classificação" nome="classificacao" funcao={updateField} tipo="number" widthWeb="14vw" widthMobile="80vw" />
            <Input texto="Objeto" nome="objeto" funcao={updateField} widthWeb="14vw" widthMobile="80vw" />
            <div>
                <Botao texto="ENVIAR" funcao={() => enviar('/api/df/novoContinuado', dados, "/continuadosDf")} 
                widthMobile="40vw" disabled={disabled} />
            </div>
        </Caixa>
    )
}

export default Lacerda