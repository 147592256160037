import { useState, useEffect } from "react"
import axios from 'axios'

import Caixa from "../../Biblioteca/Caixa"
import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import enviar from '../../Biblioteca/Funcoes/enviar'

const AprovarItem = () => {
    const [dados, setDados] = useState([])
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        axios(`/api/pca/getItensProvisorios`)
            .then(res => {
                const dadosComModo = res.data.map(item => ({
                    ...item,
                    modo: "fixed"
                }))
                setDados(dadosComModo)
            })
            .catch(error => alert(error.response.data.message))
    }, [])
    
    const updateField = (e, index) => {
        const { name, value } = e.target
        let tempDados = [...dados]
        tempDados[index] = {
            ...tempDados[index],
            [name]: value,
        }
        setDados(tempDados)
    }
    

    const alterarModo = (index) => {
        let tempDados = [...dados]
        const novoModo = tempDados[index].modo === "fixed" ? "" : "fixed"
        tempDados[index] = {
            ...tempDados[index],
            modo: novoModo,
        }
        setDados(tempDados)
    }
    
    return (
        <>
            {dados.map((item, index) => {
                return (
                    <Caixa>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Botao texto="Editar" funcao={() => alterarModo(index)} cor="rgb(51, 52, 143)" />
                            <Botao texto="Reprovar" funcao={() => enviar('/api/pca/reprovarItem', item, "/aprovarItem")} cor="red" />
                        </div>
                        <Input texto="Cód. Central de Compras" nome="codCentral" tipo="number" funcao={(e) => updateField(e, index)} widthWeb="14vw" widthMobile="80vw"
                            valor={item.codCentral} />
                        <Input texto="Descrição" nome="descricao" funcao={(e) => updateField(e, index)} widthWeb="24vw" widthMobile="80vw"
                            valor={item.descricao} modo={item.modo} />
                        <Input texto="Medida(und, caixa, L, kg etc)" nome="und" funcao={(e) => updateField(e, index)} widthWeb="16vw" widthMobile="80vw"
                            valor={item.und} />
                        <Input texto="Valor Estimado" nome="valorEstimado" tipo="moeda" dado={dados.valorEstimado || null}
                            funcao={(e) => updateField(e, index)} widthWeb="14vw" widthMobile="80vw"
                            valor={item.valorEstimado} />
                        <Input texto="Nome do Item" nome="objeto" widthWeb="14vw" widthMobile="80vw"
                            valor={item.objeto} funcao={(e) => updateField(e, index)} modo={item.modo} />
                        <Select texto="Tipo do CAT" nome="tipoCat" funcao={(e) => updateField(e, index)} widthWeb="24vw" widthMobile="80vw"
                            valor={item.tipoCat}
                            options={["Material", "Serviço"]} />
                        <Input texto="CATMAT/SER" nome="catMatSer" funcao={(e) => updateField(e, index)} widthWeb="16vw" widthMobile="80vw"
                            valor={item.catMatSer} />
                        <Select texto="Ação" nome="acao" funcao={(e) => updateField(e, index)} widthWeb="14vw" widthMobile="80vw"
                            valor={item.acao}
                            options={["4211", "4216", "2143", "1157", "1614", "2216", "2217", "4392", "4393",
                                "4938", "6106", "0703", "751", "4391", "4780"]} />
                        <Select texto="Natureza" nome="natureza" funcao={(e) => updateField(e, index)} widthWeb="14vw" widthMobile="80vw"
                            valor={item.natureza}
                            options={["339030", "339033", "339039", "339139", "449039", "449051", "449052"]} />
                        <Select texto="Área" nome="areaEipcp" funcao={(e) => updateField(e, index)} widthWeb="24vw" widthMobile="80vw"
                            valor={item.areaEipcp}
                            options={["Atendimento Pré-Hospitalar", "Atividades Técnicas", "Combate a Incêndio Florestal",
                                "Combate a Incêndio Urbano", "Materiais de Construção", "Decoração e Eventos", "Educação Física",
                                "Embarcações, Motores e Reboques", "Ensino, Instrução e Pesquisa", "Equipamento de Som e afins",
                                "Ferramentas e Equipamentos Gerais de Busca e Salvamento", "Gabinete Médico e Odontológico",
                                "Gêneros Alimentícios", "Locação de Imóvel", "Materiais de Cozinha", "Material Bélico",
                                "Material de Limpeza", "Mergulho", "Mobiliário, eletrodomésticos e afins", "Obras, serviços de engenharia ou de manutenção predial",
                                "Operações Aquáticas", "Operações Aéreas", "Operações com Cães", "Operações em Altura",
                                "Outras Ferramentas e Equipamentos", "Outros Serviços", "Papelaria e Escritório", "Projetos Sociais", 
                                "Serviços terceirizados com ou sem regime de dedicação exclusiva de mão de obra",, 
                                "Seleções internas e Concursos Público", 
                                "Tecnologia da Informação", "Uniformes e acessórios", "Viaturas - Locação",
                                "Viaturas - Manutenção", "Viaturas Administrativas - Aquisição", "Viaturas Operacionais - Aquisição"]} />
                        <div style={{marginTop: "2vh"}}><b>Solicitante:</b> {item.autor}</div>
                        {item.modo == "" &&
                            <div>
                                <Botao texto="ENVIAR" funcao={() => enviar('/api/pca/aprovarItem', item, "/aprovarItem")}
                                    widthMobile="40vw" disabled={false} />
                            </div>
                        }
                    </Caixa>
                )
            })}
        </>
    )
}

export default AprovarItem