import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { MyContext } from "../Biblioteca/MyContext"

const TabelaPreenchimentoCentral = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabelas, setTabelas] = useState([])
    const [filtro, setFiltro] = useState(null)

    const estilo = {
        table: {
            width: "98vw", marginTop: "2vh", marginBottom: "2vh",
            marginLeft: "auto", marginRight: "auto",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: "fixed"
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 16,
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: 16, color: myState === "light" ? 'black' : "#FFFF", textAlign: "center"
        }
    }

    useEffect(() => {
        const url = '/api/orcamento/getAllItens'
        axios(url)
            .then(res => setTabelas(res.data))
            .catch(error => alert(error.response.message))
    }, [])

    const saveItem = (item, indexState) => {
        const url = '/api/orcamento/inseridoNaCentral'
        axios.post(url, item)
            .then(res => {
                let newArray = [...tabelas]
                // newArray.splice(indexState, 1)
                newArray[indexState].inseridoNaCentral = "sim"
                setTabelas(newArray)
                alert(res.data)
            })
            .catch(error => alert(error.response.data))
    }

    const changeFiltro = (value) => {
        setFiltro(value)
    }

    return (
        <>
            <div>
                <select style={{ margin: "2vw" }} onChange={e => changeFiltro(e.target.value)}
                    className='nav'>
                    <option value="Todos os Itens">Todos os Itens</option>
                    <option value="sim">Apenas os não salvos</option>
                    <option value="Itens sem código">Itens sem código</option>
                </select>
            </div>
            <table style={estilo.table}>
                <thead>
                    <tr>
                        <th style={estilo.th}></th>
                        <th style={estilo.th}>OBJETO</th>
                        <th style={{ ...estilo.th, width: "40vw" }}>DESCRIÇÃO</th>
                        <th style={estilo.th}>CÓDIGO ITEM</th>
                        <th style={estilo.th}>QTD TOTAL</th>
                        <th style={estilo.th}>VALOR TOTAL</th>
                        <th style={estilo.th}>REMOVER</th>
                    </tr>
                </thead>
                <tbody>
                    {RenderRows()}
                </tbody>
            </table>
        </>
    )

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderRows() {
        let rows = []
        if (tabelas.length > 0) {
            let numero = 0
            tabelas.map((item, index) => {
                let ano1 = 0
                let ano2 = 0
                let ano3 = 0
                let qtdEstimada = 0
                item.regionais.map(el => {
                    ano1 += el.ano1
                    ano2 += el.ano2
                    ano3 += el.ano3
                    qtdEstimada += el.qtdEstimada
                })
                let valorTotal = item.valorEstimado * qtdEstimada

                // if (qtdEstimada > 0 && item.inseridoNaCentral !== "sim") {
                if(filtro === "sim") {
                if (qtdEstimada > 0 && item.inseridoNaCentral !== "sim") {
                    numero += 1
                    rows.push(
                        <tr key={`objeto${index}`}
                            style={{ backgroundColor: numero % 2 === 0 ? "#ccc" : null }}>
                            <td style={estilo.td}>{numero}</td>
                            <td style={estilo.td}>{item.objeto}</td>
                            <td style={estilo.td}>{item.descricao}</td>
                            <td style={estilo.td}>{item.codCentral}</td>
                            <td style={estilo.td}>{qtdEstimada}</td>
                            <td style={estilo.td}>{formatCurrency(valorTotal)}</td>
                            <td style={estilo.td}>
                                <button style={{
                                    border: "none", outline: "none", borderRadius: '8px', width: "4vw",
                                    backgroundColor: item.inseridoNaCentral === "sim" ? "red" : "#09792d", color: "black", padding: "5px"
                                }}
                                    onClick={e => saveItem(item, index)} >Salvar</button>
                            </td>
                        </tr>
                    )
                }
            } 
            else if(filtro === "Itens sem código") {
                if (qtdEstimada > 0 && !item.codCentral) {
                    numero += 1
                    rows.push(
                        <tr key={`objeto${index}`}
                            style={{ backgroundColor: numero % 2 === 0 ? "#ccc" : null }}>
                            <td style={estilo.td}>{numero}</td>
                            <td style={estilo.td}>{item.objeto}</td>
                            <td style={estilo.td}>{item.descricao}</td>
                            <td style={estilo.td}>{item.codCentral}</td>
                            <td style={estilo.td}>{qtdEstimada}</td>
                            <td style={estilo.td}>{formatCurrency(valorTotal)}</td>
                            <td style={estilo.td}>
                                <button style={{
                                    border: "none", outline: "none", borderRadius: '8px', width: "4vw",
                                    backgroundColor: item.inseridoNaCentral === "sim" ? "red" : "#09792d", color: "black", padding: "5px"
                                }}
                                    onClick={e => saveItem(item, index)} >Salvar</button>
                            </td>
                        </tr>
                    )
                }
            }
            else {
                if (qtdEstimada > 0) {
                    numero += 1
                    rows.push(
                        <tr key={`objeto${index}`}
                            style={{ backgroundColor: numero % 2 === 0 ? "#ccc" : null }}>
                            <td style={estilo.td}>{numero}</td>
                            <td style={estilo.td}>{item.objeto}</td>
                            <td style={estilo.td}>{item.descricao}</td>
                            <td style={estilo.td}>{item.codCentral}</td>
                            <td style={estilo.td}>{qtdEstimada}</td>
                            <td style={estilo.td}>{formatCurrency(valorTotal)}</td>
                            <td style={estilo.td}>
                                <button style={{
                                    border: "none", outline: "none", borderRadius: '8px', width: "4vw",
                                    backgroundColor: item.inseridoNaCentral === "sim" ? "red" : "#09792d", color: "black", padding: "5px"
                                }}
                                    onClick={e => saveItem(item, index)} >Salvar</button>
                            </td>
                        </tr>
                    )
                }
            }
            })
        }
        return rows
    }
}

export default TabelaPreenchimentoCentral