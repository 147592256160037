import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { MyContext } from "../Biblioteca/MyContext"

const TabelaCod = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabelas, setTabelas] = useState([])
    const [filtro, setFiltro] = useState(null)

    const estilo = {
        table: {
            width: "98vw", marginTop: "2vh", marginBottom: "2vh",
            marginLeft: "auto", marginRight: "auto",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: "fixed"
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11,
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        }
    }

    useEffect(() => {
        const url = '/api/orcamento/getAllItens'
        axios(url)
            .then(res => setTabelas(res.data))
            .catch(error => alert(error.response.message))
    }, [])

    const updateField = (e, item) => {
        const { name, value } = e.target
        const index = tabelas.findIndex(el => el.objeto === item.objeto && el.descricao === item.descricao)
        setTabelas(prevState => {
            const newArray = [...prevState]
            newArray[index][name] = value
            return newArray
        })
    }

    const saveItem = (item) => {
        const url = '/api/orcamento/addCentralDeCompras'
        axios.post(url, item)
            .then(res => alert(res.data))
            .catch(error => alert(error.response.data))
    }

    const changeFiltro = (value) => {
        setFiltro(value)
    }

    return (
        <>
            <div>
                <select style={{ margin: "2vw" }} onChange={e => changeFiltro(e.target.value)}
                    className='nav'>
                    <option value="Todos os Itens">Todos os Itens</option>
                    <option value="Itens sem código">Itens sem código</option>
                </select>
            </div>
            <table style={estilo.table}>
                <thead>
                    <tr>
                        <th style={{ ...estilo.th, width: "3vw" }}></th>
                        <th style={estilo.th}>OBJETO</th>
                        <th style={{ ...estilo.th, width: "20vw" }}>DESCRIÇÃO DO OBJETO</th>
                        <th style={estilo.th}>COMPROMETIMENTO ORÇAMENTÁRIO LOA 2024</th>
                        <th style={estilo.th}> CLASSE DO CATMAT/CATSER</th>
                        <th style={estilo.th}>CÓD. CENTRA DE COMPRAS</th>
                        <th style={estilo.th}>AÇÃO ORÇAMENTÁRIA</th>
                        <th style={estilo.th}>NATUREZA DE DESPESA</th>
                        <th style={estilo.th}>DESCRIÇÃO DA AÇÃO ORÇAMENTÁRIA</th>
                        <th style={estilo.th}>DESCRIÇÃO DA NATUREZA DE DESPESA</th>
                        <th style={estilo.th}>SALVAR</th>
                    </tr>
                </thead>
                <tbody>
                    {RenderRows()}
                </tbody>
            </table>
        </>
    )

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderRows() {
        let rows = []
        if (tabelas.length > 0) {
            let numero = 0
            tabelas.map((item, index) => {
                let ano1 = 0
                let ano2 = 0
                let ano3 = 0
                let qtdEstimada = 0
                item.regionais.map(el => {
                    ano1 += el.ano1
                    ano2 += el.ano2
                    ano3 += el.ano3
                    qtdEstimada += el.qtdEstimada
                })
                let valorTotal = item.valorEstimado * qtdEstimada
                const acaoMapping = {
                    "4211": "SEGUROS E TAXAS DE VEÍCULOS",
                    "4216": "MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
                    "2143": "FORMAÇÃO, ESPECIALIZAÇÃO E CAPACITAÇÃO DE BOMBEIRO MILITAR",
                    "1157": "CONSTRUÇÕES E REFORMAS DE IMÓVEIS DO CBMPB",
                    "1614": "AQUISICÃO E LOCAÇÃO DE IMÓVEIS PARA O CBMPB",
                    "2216": "AQUISIÇÃO, LOCAÇÃO E MANUTENÇÃO DE AERONAVE",
                    "2217": "AQUISIÇÃO E MANUTENÇÃO DE MATERIAL BELICO",
                    "4392": "PREVENÇÃO, FISCALIZAÇÃO E PERÍCIA DE INCÊNDIO",
                    "4393": "COMBATE A INCENDIO, SALVAMENTO E APH",
                    "4938": "AQUISIÇÃO DE SEMOVENTES E EQUIPAMENTOS DE ADESTRAMENTO",
                    "1511": "DESENVOLVIMENTO DE PROGRAMAS E PROJETOS SOCIAIS EDUCATIVOS E DE PREVENÇÃO",
                    "0703": "DESPESAS DE EXERCÍCIOS ANTERIORES",
                    "751": "Indenizações e restituições",
                    "4391": "AQUISIÇÃO DE VIATURAS, PEÇAS, ACESSÓRIOS E SERVIÇOS",
                    "4780": "SEGUROS E TAXAS DE AERONAVES"
                }
                const acao = acaoMapping[item.acao] || "Ação não definida"

                const naturezaMapping = {
                    "339030": "Material de Consumo",
                    "339033": "Passgens e despesas com locomoção",
                    "339039": "Outros Serviços de Terceiros - Pessoa Jurídica",
                    "339139": "Outros Serviços de Terceiros - Pessoa Jurídica",
                    "449039": "Outros Serviços de Terceiros - Pessoa Jurídica - Capital",
                    "449051": "Obras e Instalações",
                    "449052": "Equipamentos e Material Permanente",
                    "449092": "Despesas de Exercícios Anteriores - Capital"
                }
                const natureza = naturezaMapping[item.natureza] || "Natureza não definida"

                if (filtro === "Itens sem código") {
                    if (qtdEstimada > 0 && item.codCentral === undefined) {
                        numero += 1
                        rows.push(
                            <tr key={`objeto${index}`}>
                                <td style={{ ...estilo.td, textAlign: "center" }}>{numero}</td>
                                <td style={estilo.td}>{item.objeto}</td>
                                <td style={estilo.td}>{item.descricao.slice(0, 200)}</td>
                                <td style={estilo.td}>{formatCurrency(valorTotal)}</td>
                                <td style={{ ...estilo.td, textAlign: "center" }}>
                                    <input type="number" style={{ width: "5vw" }}
                                        name="catMatSer" onChange={e => updateField(e, item)}
                                        defaultValue={item.catMatSer || ""} />
                                </td>
                                <td style={{ ...estilo.td, textAlign: "center" }}>
                                    <input type="number" style={{ width: "5vw" }}
                                        name="codCentral" onChange={e => updateField(e, item)}
                                        defaultValue={item.codCentral || ""} />
                                </td>
                                <td style={{ ...estilo.td, textAlign: "center" }}>
                                    <select name="acao" onChange={e => updateField(e, item)}
                                        defaultValue={item.acao || ""} >
                                        <option value=""></option>
                                        <option value="4211">4211</option>
                                        <option value="4216">4216</option>
                                        <option value="2143">2143</option>
                                        <option value="1157">1157</option>
                                        <option value="1614">1614</option>
                                        <option value="2216">2216</option>
                                        <option value="2217">2217</option>
                                        <option value="4392">4392</option>
                                        <option value="4393">4393</option>
                                        <option value="4938">4938</option>
                                        <option value="1511">1511</option>
                                        <option value="0703">0703</option>
                                        <option value="751">751</option>
                                        <option value="4391">4391</option>
                                        <option value="4780">4780</option>
                                    </select>
                                </td>
                                <td style={{ ...estilo.td, textAlign: "center" }}>
                                    <select name="natureza" onChange={e => updateField(e, item)}
                                        defaultValue={item.natureza || ""} >
                                        <option value=""></option>
                                        <option value="339030">339030</option>
                                        <option value="339033">339033</option>
                                        <option value="339039">339039</option>
                                        <option value="339139">339139</option>
                                        <option value="449039">449039</option>
                                        <option value="449051">449051</option>
                                        <option value="449052">449052</option>
                                        <option value="449092">449092</option>
                                    </select>
                                </td>
                                <td style={estilo.td}>{acao}</td>
                                <td style={estilo.td}>{natureza}</td>
                                <td style={{ ...estilo.td, textAlign: "center" }}>
                                    <button style={{
                                        border: "none", outline: "none", borderRadius: '8px', width: "4vw",
                                        backgroundColor: "#09792d", color: "#FFFF", padding: "5px"
                                    }}
                                        onClick={e => saveItem(item)} >Salvar</button>
                                </td>
                            </tr>
                        )
                    }
                }
                else {
                    if (qtdEstimada > 0) {
                        numero += 1
                        var borda = "1px solid #ccc"
                        if (item.acao !== undefined && item.codCentral === undefined) var borda = "3px solidred"
                        rows.push(
                            <tr key={`objeto${index}`}>
                                <td style={{ ...estilo.td, border: borda, textAlign: "center" }}>{numero}</td>
                                <td style={estilo.td}>{item.objeto}</td>
                                <td style={estilo.td}>{item.descricao.slice(0, 200)}</td>
                                <td style={estilo.td}>{formatCurrency(valorTotal)}</td>
                                <td style={{ ...estilo.td, textAlign: "center" }}>
                                    <input type="number" style={{ width: "5vw" }}
                                        name="catMatSer" onChange={e => updateField(e, item)}
                                        defaultValue={item.catMatSer || ""} />
                                </td>
                                <td style={{ ...estilo.td, textAlign: "center" }}>
                                    <input type="number" style={{ width: "5vw" }}
                                        name="codCentral" onChange={e => updateField(e, item)}
                                        defaultValue={item.codCentral || ""} />
                                </td>
                                <td style={{ ...estilo.td, textAlign: "center" }}>
                                    <select name="acao" onChange={e => updateField(e, item)}
                                        defaultValue={item.acao || ""} >
                                        <option value=""></option>
                                        <option value="4211">4211</option>
                                        <option value="4216">4216</option>
                                        <option value="2143">2143</option>
                                        <option value="1157">1157</option>
                                        <option value="1614">1614</option>
                                        <option value="2216">2216</option>
                                        <option value="2217">2217</option>
                                        <option value="4392">4392</option>
                                        <option value="4393">4393</option>
                                        <option value="4938">4938</option>
                                        <option value="1511">1511</option>
                                        <option value="0703">0703</option>
                                        <option value="751">751</option>
                                        <option value="4391">4391</option>
                                        <option value="4780">4780</option>
                                    </select>
                                </td>
                                <td style={{ ...estilo.td, textAlign: "center" }}>
                                    <select name="natureza" onChange={e => updateField(e, item)}
                                        defaultValue={item.natureza || ""} >
                                        <option value=""></option>
                                        <option value="339030">339030</option>
                                        <option value="339033">339033</option>
                                        <option value="339039">339039</option>
                                        <option value="339139">339139</option>
                                        <option value="449039">449039</option>
                                        <option value="449051">449051</option>
                                        <option value="449052">449052</option>
                                        <option value="449092">449092</option>
                                    </select>
                                </td>
                                <td style={estilo.td}>{acao}</td>
                                <td style={estilo.td}>{natureza}</td>
                                <td style={{ ...estilo.td, textAlign: "center" }}>
                                    <button style={{
                                        border: "none", outline: "none", borderRadius: '8px', width: "4vw",
                                        backgroundColor: "#09792d", color: "#FFFF", padding: "5px"
                                    }}
                                        onClick={e => saveItem(item)} >Salvar</button>
                                </td>
                            </tr>
                        )
                    }
                }
            })
        }
        return rows
    }
}

export default TabelaCod