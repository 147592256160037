import React, { useState, useContext } from 'react';
import { MyContext } from "../Biblioteca/MyContext"

import NavCpl from './NavCpl';
import NavDf from './NavDf';
import NavGeral from './NavGeral';
import NavEm2 from './NavEm2';
import NavDal from './NavDal';

const NavInterno = (props) => {
    const [tipoNav, setTipo] = useState("interno")
    const { myState } = useContext(MyContext);
    const [display, setDisplay] = useState("none")

    const linkStyle = {
        marginLeft: 'auto',
        marginRight: '1rem',
        color: myState === "light" ? 'black' : "#F9F9F9",
        fontWeight: 550,
        fontSize: "13px",
        cursor: 'pointer',
        textDecoration: 'none',
    };

    if (window.matchMedia("(min-width:600px)").matches) {
        if (tipoNav === "cpl") return <NavCpl />
        if (tipoNav === "df") return <NavDf />
        if (tipoNav === "geral") return <NavGeral />
        if (tipoNav === "em2") return <NavEm2 />
        if (tipoNav === "dal") return <NavDal />
        else {
            return (
                <div style={{ textAlign: "center", width: '100%' }}>
                    <a onClick={() => setTipo("cpl")} style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                        CPL
                    </a>
                    <a onClick={() => setTipo("df")} style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                        DF
                    </a>
                    <a onClick={() => setTipo("geral")} style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                        EIPCP
                    </a>
                    <a onClick={() => setTipo("em2")} style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                        EM2
                    </a>
                    <a onClick={() => setTipo("dal")} style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                        DAL
                    </a>
                    <a href="/senha" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                        SENHA
                    </a>
                    <a href="/tabelasPorAreas" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                        ORÇAMENTO
                    </a>
                    <a href="/dfd" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                        DFD
                    </a>
                </div>
            )
        }
    }
    else {
        if (tipoNav === "cpl") return <NavCpl />
        if (tipoNav === "df") return <NavDf />
        else {
            return (
                <>
                    <div className="mt-2">
                        <a onClick={() => setTipo("cpl")} style={{ ...props.estilo, ...linkStyle }}>
                            CPL
                        </a>
                    </div>
                    <div className="mt-2">
                        <a onClick={() => setTipo("df")} style={{ ...props.estilo, ...linkStyle }}>
                            DF
                        </a>
                    </div>
                    <div className="mt-2">
                        <a href="/senha" style={{ ...props.estilo, ...linkStyle }}>
                            SENHA
                        </a>
                    </div>
                    <div className="mt-2">
                        <a style={{ ...props.estilo, ...linkStyle }}
                            onClick={() => display === "none" ? setDisplay("block") : setDisplay("none")}>
                            MEU CAMARADA
                        </a>
                    </div>
                    <div className="mt-2 ms-2" style={{ display: display }}>
                        <div>
                            <a href="/chatAI" style={{ ...props.estilo, ...linkStyle }}>
                                CHAT
                            </a>
                        </div>
                        <div className='mt-2'>
                            <a href="/conversationsHistory" style={{ ...props.estilo, ...linkStyle }}>
                                HISTÓRICO
                            </a>
                        </div>
                    </div>
                    <div className="mt-2">
                        <a href="/tabelaPorAreas" style={{ ...props.estilo, ...linkStyle }}>
                            ORÇAMENTO
                        </a>
                    </div>
                    <div className="mt-2">
                        <a href="/dfd" style={{ ...props.estilo, ...linkStyle }}>
                            DFD
                        </a>
                    </div>
                </>
            )
        }
    }
}

export default NavInterno