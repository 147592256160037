import { useState, useEffect } from 'react'
import axios from 'axios'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'
import loading from '../../../assets/loading.gif'

const CatsAgrupados = () => {
    const [dados, setDados] = useState([])

    useEffect(() => {
        axios.post('/api/pca/catsAgrupados', {})
            .then(res => {
                const sortedData = res.data.sort((a, b) => {
                    const descA = a.itens[0]?.descricaoCatMatSer?.toLowerCase() || ''
                    const descB = b.itens[0]?.descricaoCatMatSer?.toLowerCase() || ''
                    if (descA < descB) return -1
                    if (descA > descB) return 1
                    return 0
                })
                setDados(sortedData)
            })
            .catch(error => alert(error.response.data.message))
    }, [])

    return (
        <>
            {dados.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>CATMAT/SER</th>
                            <th>DESCRIÇÃO</th>
                            <th>1º CRBM</th>
                            <th>2º CRBM</th>
                            <th>3º CRBM</th>
                            <th>4º CRBM</th>
                            <th>TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dados.map((cat, index) => {
                            let crbm1 = 0
                            let crbm2 = 0
                            let crbm3 = 0
                            let crbm4 = 0
                            cat.itens.forEach(el => {
                                crbm1 += el["1º CRBM"]
                                crbm2 += el["2º CRBM"]
                                crbm3 += el["3º CRBM"]
                                crbm4 += el["4º CRBM"]
                            })
                            let total = crbm1 + crbm2 + crbm3 + crbm4

                            var cor1, cor2, cor3, cor4
                            if(crbm1 > 16130.55) var cor1 = "red"
                            if(crbm2 > 14571.25) var cor2 = "red"
                            if(crbm3 > 18071.46) var cor3 = "red"
                            if(crbm4 > 11132.75) var cor4 = "red"
                            if(total > 59906.02) var corTotal = "red"
                            return (
                                <tr key={index}>
                                    <td>{cat?.categoria || "ITENS SEM CAT DEFINIDO"}</td>
                                    <td>{cat.itens[0]?.descricaoCatMatSer || "ITENS SEM CAT DEFINIDO"}</td>
                                    <td style={{backgroundColor: cor1}}>{formatCurrency(crbm1)}</td>
                                    <td style={{backgroundColor: cor2}}>{formatCurrency(crbm2)}</td>
                                    <td style={{backgroundColor: cor3}}>{formatCurrency(crbm3)}</td>
                                    <td style={{backgroundColor: cor4}}>{formatCurrency(crbm4)}</td>
                                    <td style={{backgroundColor: corTotal}}>{formatCurrency(total)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            ) : <div style={{textAlign: "center"}}><img src={loading} style={{width: "5vw"}}></img></div>}
        </>
    )
}

export default CatsAgrupados