const formatarCNPJ = (e, funcao) => {
  var valor = e.target.value

  valor = valor.replace(/\D/g, ''); // Remove caracteres não numéricos
  valor = valor.substring(0, 14)
  valor = valor.replace(/^(\d{2})(\d)/, '$1.$2'); // Insere o primeiro ponto após os primeiros dois dígitos
  valor = valor.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Insere o segundo ponto após os próximos três dígitos
  valor = valor.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Insere a barra após os próximos três dígitos
  valor = valor.replace(/(\d{4})(\d)/, '$1-$2'); // Insere o traço após os próximos quatro dígitos

  e.target.value = valor
  return funcao(e)
};

export default formatarCNPJ;
