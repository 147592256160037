import { useState, useEffect, useContext } from 'react'
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios'

import Botao from '../Biblioteca/Botao'

const PesquisaDeds = () => {
    const { myState } = useContext(MyContext)
    const [deds, setDeds] = useState([])
    const [page, setPage] = useState(1)

    useEffect(() => {
        const fetchDeds = async () => {
            try {
                const response = await axios.get(`/api/ded/getAllDeds?page=${page}`)
                setDeds(response.data)
            } catch (error) {
                alert("Erro ao buscar DEDs:", error)
            }
        }

        fetchDeds()
    }, [page])

    const estilo = {
        th: {
            color: myState === "light" ? 'black' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '16px',
            border: '1px solid #ddd',
            fontWeight: 600, fontSize: 15
        },
        td: {
            padding: '16px', backgroundColor: myState === "light" ? '#fff' : "#00000000",
            border: '1px solid #ddd', fontSize: 14, color: myState === "light" ? 'black' : "#FFFF",
        }
    }

    return (
        <div>
            <table style={{
                width: '100%',
                padding: '16px',
                borderCollapse: 'collapse',
                textAlign: "center",
                borderRadius: '10px',
                maxHeight: '28vh',
                tableLayout: 'fixed',
                wordWrap: 'break-word',
            }}>
                <thead>
                    <tr>
                        <th style={estilo.th}>NÚMERO</th>
                        <th style={estilo.th}>DFDS</th>
                        <th style={estilo.th}></th>
                    </tr>
                </thead>
                <tbody>
                    {deds.map((ded) => (
                        <tr key={ded._id}>
                            <td style={estilo.td}>{ded.numeroDed}</td>
                            <td style={estilo.td}>{ded.dfds.map(dfd => dfd.dfd).join(', ')}</td>
                            <td style={estilo.td}>
                                <Botao texto="Abrir" funcao={() => window.location.href = `/dedView/${ded._id}`} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{textAlign: "right"}}>
                <Botao texto="Anterior" funcao={() => setPage(page - 1)} cor="#33348f"
                    disabled={page === 1 ? true : false} display="inline-grid" />
                <Botao texto="Próximo" funcao={() => setPage(page + 1)} cor="#33348f" display="inline-grid" />
            </div>
        </div>
    )
}

export default PesquisaDeds
