import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios'

import NovoContinuado from "./NovoContinuado"

import Input from '../Biblioteca/Input'
import Select from '../Biblioteca/Select'
import Botao from '../Biblioteca/Botao'
import enviar from '../Biblioteca/Funcoes/enviar'
import converterData from '../Biblioteca/Funcoes/converterData'

const Continuados = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext);
    const [dados, setDados] = useState({ editados: [] })
    const [processos, setProcessos] = useState([])
    const [novoProcesso, setNovoProcesso] = useState(false)
    const [tipoBusca, setTipo] = useState()

    const updateTipo = (e) => {
        const { value } = e.target
        setTipo(value)
    }

    const updateDadosFiltro = (e) => {
        const { value } = e.target
        let info = { filtro: tipoBusca, campo: value }
        const url = '/api/cpl/filtro'
        axios.post(url, info)
            .then(res => {
                setProcessos(res.data)
            })
            .catch(error => {
                alert(error.response.data)
            })
    }

    useEffect(() => {
        axios("/api/df/getContinuados")
            .then(res => {
                setProcessos(res.data)
            })
            .catch(error => alert(error.response.data))
    }, [])

    const choose = (el) => {
        let tempState = { ...dados, ...el, idProcesso: el._id }
        setDados(tempState)
    }

    const updateField = (e) => {
        var { name, value } = e.target
        let tempState = { ...dados }
        let object = { user: localStorage.id, campo: name, novo: value, data: new Date().toLocaleString() }
        tempState.editados.push(object)
        tempState[name] = value
        setDados(tempState)
    }

    const estilo = {
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: "#00000000",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF"
        }
    }

    return (
        <>
            {RenderNovoProcesso()}
            {RenderRelatorio()}
        </>
    )

    function RenderNovoProcesso() {
        if (novoProcesso) return <NovoContinuado />
        else {
            return (
                <div style={{ textAlign: "center", marginTop: "3vh" }}>
                    <Botao texto="Novo Processo" widthWeb="10vw" widthMobile="40vw" funcao={() => setNovoProcesso(true)} />
                </div>
            )
        }
    }

    function RenderRelatorio() {
        return (
            <div style={{
                maxWidth: mediaWeb ? "100%" : "200vw",
                overflowX: mediaWeb ? null : "auto",
            }}>
                <table
                    style={{
                        width: mediaWeb ? "98vw" : "100%",
                        margin: "auto",
                        marginTop: "2vh",
                        marginBottom: "2vh",
                        padding: '16px',
                        borderCollapse: 'collapse',
                        textAlign: "center",
                        borderRadius: '10px',
                        maxHeight: '70vh',
                        overflowY: 'auto',
                        tableLayout: mediaWeb ? 'fixed' : null
                    }}
                >
                    <thead>
                        <tr>
                            <th style={{ ...estilo.th, width: mediaWeb ? "10vw" : "40vw" }}>PBDOC</th>
                            <th style={{ ...estilo.th, width: mediaWeb ? "10vw" : "40vw" }}>EMPRESA</th>
                            <th style={{ ...estilo.th, width: mediaWeb ? "10vw" : "40vw" }}>PROCESSO</th>
                            <th style={estilo.th}>VIGÊNCIA</th>
                            <th style={estilo.th}>CREDOR</th>
                            <th style={{ ...estilo.th, width: mediaWeb ? "8vw" : "40vw" }}>CNPJ</th>
                            <th style={estilo.th}>RESERVA 2023</th>
                            <th style={estilo.th}>Nº DA RESERVA</th>
                            <th style={estilo.th}>RESERVA 2024</th>
                            <th style={{ ...estilo.th, width: mediaWeb ? "8vw" : "40vw" }}>CLASSIFICAÇÃO</th>
                            <th style={{ ...estilo.th, width: mediaWeb ? "16vw" : "40vw" }}>OBJETO</th>
                            <th style={{
                                ...estilo.th, width: mediaWeb ? "10vw" : "40vw"
                            }}>EDITAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {processos.map((el, index) => {
                            var data = null
                            if (el.vigencia) data = el.vigencia.slice(0, 10)
                            var borda = '1px solid #ddd'; var pad; var modo; var cor = myState === "light" ? '#fff' : "#00000000"
                            if (dados.idProcesso === el._id) {
                                borda = "1px solid green"
                                pad = "1vw"
                            }
                            dados.idProcesso !== el._id ? modo = "fixed" : modo = "edit"
                            return (
                                <>
                                    <tr key={index} style={{ backgroundColor: cor }}>
                                        {modo === "edit" ? (
                                            <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                                <Input texto="PBDOC" nome="pbdoc" widthWeb="9vw" widthMobile="80vw"
                                                    funcao={updateField} modo={modo} valor={el.pbdoc}
                                                    fontS={11} marginB="0vh" />
                                            </td>
                                        ) : (
                                            <td style={{ ...estilo.td, border: borda, cursor: 'pointer' }}
                                                onClick={() => window.open(`https://pbdoc.pb.gov.br/sigaex/app/expediente/doc/exibir?sigla=${el.pbdoc}`, '_blank')}
                                                onMouseOver={(e) => {
                                                    e.target.style.backgroundColor = '#33348F';
                                                    e.target.style.color = 'white';
                                                }}
                                                onMouseOut={(e) => {
                                                    myState === "light" ? e.target.style.backgroundColor = cor :
                                                        e.target.style.backgroundColor = cor;
                                                    myState === "light" ? e.target.style.color = 'black' :
                                                        e.target.style.color = 'white';
                                                }}>
                                                {el.pbdoc}
                                            </td>
                                        )}
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Empresa" nome="empresa"
                                                    modo={modo} valor={el.empresa} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh" />
                                            ) : (el.empresa)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Processo" nome="processo" tipo="number"
                                                    modo={modo} valor={el.processo} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh" />
                                            ) : (el.processo)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Vigência" nome="vigencia" tipo="date"
                                                    modo={modo} valor={data.slice(0, 10)} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh" />
                                            ) : (converterData(data.slice(0, 10)))}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Credor" nome="credor" tipo="number"
                                                    modo={modo} valor={el.credor} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh" />
                                            ) : (el.credor)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="CNPJ" nome="cnpj" tipo="cnpj"
                                                    modo={modo} valor={el.cnpj} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh" />
                                            ) : (el.cnpj)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Reserva 2023" nome="valorReserva2023" tipo="moeda"
                                                    dado={el.valorReserva2023 || null} fontS={11} marginB="0vh"
                                                    modo={modo} valor={el.valorReserva2023} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} />
                                            ) : (el.valorReserva2023 || null)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Nº da Reserva" nome="numeroReserva" tipo="number"
                                                    modo={modo} valor={el.numeroReserva} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh" />
                                            ) : (el.numeroReserva)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Reserva 2024" nome="valorReserva2024" tipo="moeda"
                                                    dado={el.valorReserva2024 || null} fontS={11} marginB="0vh"
                                                    modo={modo} valor={el.valorReserva2024} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} />
                                            ) : (el.valorReserva2024 || null)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Classificação" nome="classificacao" tipo="number"
                                                    modo={modo} valor={el.classificacao} widthWeb="5vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh" />
                                            ) : (el.classificacao)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {modo === "edit" ? (
                                                <Input texto="Objeto" nome="objeto" widthWeb="15vw" widthMobile="80vw"
                                                    funcao={updateField} fontS={11} marginB="0vh"
                                                    modo={modo} valor={el.objeto} />
                                            ) : (el.objeto || null)}
                                        </td>
                                        <td style={{ ...estilo.td, border: borda, verticalAlign: modo === "edit" ? "bottom" : "middle" }}>
                                            {dados.idProcesso !== el._id ? (
                                                <a onClick={() => choose(el)} style={{ cursor: "pointer", marginBottom: "15vw" }}>
                                                    <i className="bi bi-pen penReport"></i>
                                                </a>
                                            ) : (
                                                <>
                                                    <Botao texto="EDITAR" widthMobile="40vw"
                                                        funcao={() => enviar('/api/df/editContinuado', dados, "/continuadosDf")} />
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Continuados