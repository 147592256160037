import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import cytoscape from 'cytoscape';
import coseBilkent from 'cytoscape-cose-bilkent';

cytoscape.use(coseBilkent);

const SnaPcaGraph = () => {
  const cyRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/sna/snaPca');
        const { nodes, edges } = response.data;

        cyRef.current = cytoscape({
          container: document.getElementById('cy'),
          elements: {
            nodes: nodes.map(node => ({ data: node.data })),
            edges: edges.map(edge => ({ data: edge.data })),
          },
          style: [
            {
              selector: 'node',
              style: {
                'background-color': node => {
                  const category = node.data('label').split(' ')[0];
                  if (category === 'Fonte') return '#FF6347';
                  if (category === 'Regional') return '#4682B4';
                  return '#3CB371'; // Default for areaEipcp
                },
                'label': 'data(label)',
                'font-size': '10px',
                'text-valign': 'center',
                'width': '20px',
                'height': '20px',
              }
            },
            {
              selector: 'edge',
              style: {
                'width': 2,
                'line-color': '#ccc',
                'target-arrow-color': '#ccc',
                'target-arrow-shape': 'triangle',
                'curve-style': 'bezier',
                'label': 'data(weight)',
                'font-size': '8px',
              }
            }
          ],
          layout: {
            name: 'concentric',
            concentric: function (node) {
              const label = node.data('label');
              if (label.startsWith('Fonte')) {
                return 3;
              } else if (label.startsWith('Regional')) {
                return 2;
              } else {
                return 1;
              }
            },
            levelWidth: function (nodes) { return nodes.maxDegree() / 2; },
            minNodeSpacing: 100,  // Espaçamento mínimo entre os nós
            padding: 30,
            startAngle: 3 / 2 * Math.PI,
            clockwise: true,
            avoidOverlap: true,
          },
          zoom: 1,
          pan: { x: 0, y: 0 },
          wheelSensitivity: 0.1,
        });

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      if (cyRef.current) {
        cyRef.current.destroy();
      }
    };
  }, []);

  return <div id="cy" style={{ width: '100%', height: '600px' }} />;
};

export default SnaPcaGraph;
