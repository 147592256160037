import { useState, useEffect } from 'react'
import axios from 'axios'

function formatarDataBrasileira(dataISO) {
    const data = new Date(dataISO)
    const dia = data.getDate().toString().padStart(2, '0')
    const mes = (data.getMonth() + 1).toString().padStart(2, '0')
    const ano = data.getFullYear()
    
    return `${dia}/${mes}/${ano}`
}

const chaves = {
    "objeto": "Objeto",
    "descricao": "Descrição",
    "und": "Unidade de Medida",
    "codCentral": "Código Central",
    "catMatSer": "Catmat/ser",
    "valorEstimado": "Valor Estimado",
    "acao": "Ação",
    "natureza": "Natureza",
    "areaEipcp": "Área"
}

const UpdatedItens = () => {
    const [itens, setItens] = useState([])
    const [visibilidade, setVisibilidade] = useState({})

    useEffect(() => {
        axios('/api/pca/getUpdatedItens')
            .then(res => {
                setItens(res.data)
                const initialVisibility = res.data.reduce((acc, item) => {
                    acc[item._id] = false
                    return acc
                }, {})
                setVisibilidade(initialVisibility)
            })
            .catch(error => alert(error.response.data.message))
    }, [])

    const toggleVisibilidade = (id) => {
        setVisibilidade(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }))
    }

    const estilo = {
        caixaUpdatedItens: {
            border: "1px solid #ccc", borderRadius: "5px", width: "90vw", margin: "1vh auto"
        },
        headerContainer: {
            display: "flex", minHeight: "5vh"
        },
        headerUpdatedItens: {
            display: "flex", alignItems: "center", width: "25vw", borderRight: "1px solid #ccc",
            paddingLeft: "0.5vw", boxSizing: "border-box"
        },
        buttonWraperUpdatedItens: {
            display: "flex", alignItems: "center", justifyContent: "center", width: "14vw"
        },
        buttonUpdatedItens: {
            width: "3vw", margin: "0 auto", borderRadius: "40px", border: "none",
            color: "#FFF"
        },
        lineUpdatedItens: {
            width: "100%", borderBottom: "1px solid #ccc"
        },
        bodyUpdatedItensLeft: {
            display: "inline-grid", borderRight: "1px solid #ccc", width: "50%", paddingLeft: "0.5vw"
        },
        bodysUpdatedItens: {
            display: 'none'
        },
        bodyUpdatedItensRigth: {
            display: "inline-grid", width: "49%", paddingLeft: "0.5vw"
        },
        bodyHeaderUpdatedItens: {
            textAlign: "center", fontWeight: "bold"
        }
    }

    return (
        <>
            {itens.length > 0 ? (itens.map(el => {
                const isVisible = visibilidade[el._id]
                return (
                    <div key={el._id} style={estilo.caixaUpdatedItens}>
                        <div style={estilo.headerContainer}>
                            <div style={estilo.headerUpdatedItens}>
                                <b>Objeto: </b><span style={{ paddingLeft: "0.5vw" }}>{el.versaoNova.objeto}</span>
                            </div>
                            <div style={estilo.headerUpdatedItens}>
                                <b>Editor:</b><span style={{ paddingLeft: "0.5vw" }}>{el.autorEdicao}</span>
                            </div>
                            <div style={estilo.headerUpdatedItens}>
                                <b>Data Edição:</b><span style={{ paddingLeft: "0.5vw" }}>{formatarDataBrasileira(el.dataEdicao)}</span>
                            </div>
                            <div style={estilo.buttonWraperUpdatedItens}>
                                <button 
                                style={{...estilo.buttonUpdatedItens, backgroundColor: isVisible ? 'red' : 'rgb(9, 121, 45)'}} 
                                onClick={() => toggleVisibilidade(el._id)}>{isVisible ? "-" : "+"}</button>
                            </div>
                        </div>
                        <div style={estilo.lineUpdatedItens}></div>
                        <div style={{ ...estilo.bodysUpdatedItens, display: isVisible ? 'block' : 'none' }}>
                            <div style={estilo.bodyUpdatedItensLeft}>
                                <div style={estilo.bodyHeaderUpdatedItens}>Versão Original</div>
                                {Object.keys(el.versaoAntiga).map(item => {
                                    const campoEncontrado = el.camposEditados.some(campoEditado => campoEditado.campo === item)
                                    if (campoEncontrado) return (
                                        <div key={item}><b>{chaves[item]}:</b><span style={{ paddingLeft: "0.5vw" }}>{el.versaoAntiga[item]}</span></div>
                                    )
                                    return null
                                })}
                            </div>
                            <div style={estilo.bodyUpdatedItensRigth}>
                                <div style={estilo.bodyHeaderUpdatedItens}>Versão Editada</div>
                                {Object.keys(el.versaoNova).map(item => {
                                    const campoEncontrado = el.camposEditados.some(campoEditado => campoEditado.campo === item)
                                    if (campoEncontrado) return (
                                        <div key={item}><b>{chaves[item]}:</b><span style={{ paddingLeft: "0.5vw" }}>{el.versaoNova[item]}</span></div>
                                    )
                                    return null
                                })}
                            </div>
                        </div>
                    </div>
                )
            })) : (<></>)}
        </>
    )
}

export default UpdatedItens
