import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "../../Biblioteca/MyContext"
import axios from 'axios'

import NovoDocumento from "./NovoDocumento"
import LinkComponent from "../Processos/LinkComponent"

import Input from '../../Biblioteca/Input'
import Select from '../../Biblioteca/Select'
import Botao from '../../Biblioteca/Botao'
import enviar from '../../Biblioteca/Funcoes/enviar'

const Documentos = () => {
    const { myState } = useContext(MyContext);
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const [dados, setDados] = useState({ editados: [] })
    const [novoDocumento, setNovoDocumento] = useState(false)
    const [documentos, setDocumentos] = useState([])
    const [tipoBusca, setTipo] = useState()

    useEffect(() => {
        axios("/api/cpl/getDocumento")
            .then(res => {
                setDocumentos(res.data)
            })
            .catch(error => alert(error.response.data))
    }, [])

    const updateTipo = (e) => {
        const { value } = e.target
        setTipo(value)
    }

    const updateDadosFiltro = (e) => {
        const { value } = e.target
        let info = { filtro: tipoBusca, campo: value }
        const url = '/api/cpl/filtroDocumentos'
        axios.post(url, info)
            .then(res => {
                setDocumentos(res.data)
            })
            .catch(error => {
                alert(error.response.data)
            })
    }

    const choose = (el) => {
        let tempState = { ...dados, ...el, idDocumento: el._id }
        setDados(tempState)
    }

    const updateField = (e) => {
        var { name, value } = e.target
        let tempState = { ...dados }
        let object = { user: localStorage.nome, campo: name, novo: value, data: new Date().toLocaleString() }
        tempState.editados.push(object)
        tempState[name] = value
        setDados(tempState)
    }

    const estilo = {
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '16px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 15, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: myState === "light" ? '#fff' : "#00000000",
            fontSize: mediaWeb ? 12 : 18, color: myState === "light" ? 'black' : "#FFFF"
        }
    }

    return (
        <>
            {RenderNovoDocumento()}
            {RenderFiltro()}
            <div style={{
                maxWidth: mediaWeb ? "100%" : "200vw",
                overflowX: mediaWeb ? null : "auto",
            }}>
                <table
                    style={{
                        width: mediaWeb ? "96vw" : "100%",
                        margin: "auto",
                        marginTop: "2vh",
                        marginBottom: "2vh",
                        padding: '16px',
                        borderCollapse: 'collapse',
                        textAlign: "center",
                        borderRadius: '10px',
                        maxHeight: '70vh',
                        overflowY: 'auto',
                        tableLayout: mediaWeb ? 'fixed' : null
                    }}
                >
                    <thead>
                        <tr>
                            <th style={estilo.th}>PBDOC</th>
                            <th style={estilo.th}>DESCRIÇÃO</th>
                            <th style={estilo.th}>REMETENTE</th>
                            <th style={estilo.th}>DESTINATÁRIO</th>
                            <th style={estilo.th}>OBSERVAÇÕES</th>
                            <th style={estilo.th}>CONCLUÍDO</th>
                            <th style={{
                                ...estilo.th, width: mediaWeb ? "10vw" : "40vw"
                            }}>EDITAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentos.map((el, index) => {
                            var borda = '1px solid #ddd'; var pad; var modo
                            if (dados.idDocumento === el._id) {
                                borda = "1px solid green"
                                pad = "1vw"
                            }
                            dados.idDocumento !== el._id ? modo = "fixed" : modo = "edit"
                            return (
                                <tr key={index}>
                                    <td style={{ ...estilo.td, border: borda }}>
                                        {modo === "edit" ? (
                                            <Input texto="PBDOC" nome="pbdoc" widthWeb="10vw" widthMobile="80vw"
                                                fontS={13} marginB="0vh" marginL="1vw"
                                                funcao={updateField} modo={modo} valor={el.pbdoc} />
                                        ) : (<LinkComponent pbdoc={el.pbdoc} />)}
                                    </td>
                                    <td style={{ ...estilo.td, border: borda }}>
                                        {modo === "edit" ? (
                                            <Input texto="Descrição" nome="descricao" widthWeb="10vw" widthMobile="80vw"
                                                fontS={13} marginB="0vh"
                                                funcao={updateField} modo={modo} valor={el.descricao} />
                                        ) : (el.descricao)}
                                    </td>
                                    <td style={{ ...estilo.td, border: borda }}>
                                        {modo === "edit" ? (
                                            <Select texto="Remetente" nome="remetente" widthWeb="10vw" widthMobile="80vw"
                                                fontS={13} marginB="0vh"
                                                funcao={updateField} modo={modo} valor={el.remetente}
                                                options={["AJG", "DAL", "DF", "DP", "GABCMT", "CPL", "DAT", "EIPCP"]} />
                                        ) : (el.remetente)}
                                    </td>
                                    <td style={{ ...estilo.td, border: borda }}>
                                        {modo === "edit" ? (
                                            <Select texto="Destinatário" nome="destinatario" widthWeb="10vw" widthMobile="80vw"
                                                fontS={13} marginB="0vh"
                                                funcao={updateField} modo={modo} valor={el.destinatario}
                                                options={["AJG", "DAL", "DF", "DP", "GABCMT", "CPL", "DAT", "PAULINO"]} />
                                        ) : (el.destinatario)}
                                    </td>
                                    <td style={{ ...estilo.td, border: borda }}>
                                        {modo === "edit" ? (
                                            <Input texto="Observações" nome="obs" widthWeb="10vw" widthMobile="80vw"
                                                fontS={13} marginB="0vh"
                                                funcao={updateField}
                                                modo={modo} valor={el.obs} />
                                        ) : (el.obs)}
                                    </td>
                                    <td style={{ ...estilo.td, border: borda }}>
                                        {modo === "edit" ? (
                                            <Select texto="Concluído" nome="concluido" widthWeb="10vw" widthMobile="80vw"
                                                fontS={13} marginB="0vh"
                                                funcao={updateField} modo={modo} valor={el.concluido}
                                                options={["Sim", "Não"]} />
                                        ) : (el.concluido)}
                                    </td>
                                    <td style={{ ...estilo.td, border: borda }}>
                                        {dados.idDocumento !== el._id ? (
                                            <a onClick={() => choose(el)} style={{ cursor: "pointer", marginBottom: "15vw" }}>
                                                <i className="bi bi-pen penReport"></i>
                                            </a>
                                        ) : (
                                            <>
                                                <Botao texto="EDITAR" widthMobile="40vw"
                                                    funcao={() => enviar('/api/cpl/editDocumento', dados, "/documentosCpl")} />
                                            </>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )

    function RenderFiltro() {
        return (
            <div style={{ marginLeft: "2vw", marginTop: "3vh" }}>
                <Select texto="Buscar" nome="tipoFiltro" widthWeb="14vw" widthMobile="80vw" funcao={updateTipo}
                    options={["Remetente", "Destinatário", "Concluído"]} />
                {RenderTipo()}
            </div>
        )

        function RenderTipo() {
            if (tipoBusca === "Remetente") {
                return (
                    <Select texto="Remetente" nome="remetente" widthWeb="14vw" widthMobile="80vw" funcao={updateDadosFiltro}
                        options={["AJG", "DAL", "DF", "DP", "GABCMT", "CPL", "DAT", "EIPCP"]} />
                )
            }
            else if (tipoBusca === "Destinatário") {
                return (
                    <Select texto="Destinatário" nome="destinatario" widthWeb="14vw" widthMobile="80vw" funcao={updateDadosFiltro}
                        options={["AJG", "DAL", "DF", "DP", "GABCMT", "CPL", "DAT", "PAULINO"]} />
                )
            }
            else if (tipoBusca === "Concluído") {
                return (
                    <Select texto="Concluído" nome="concluido" widthWeb="14vw" widthMobile="80vw" funcao={updateDadosFiltro}
                        options={["Sim", "Não"]} />
                )
            }
        }
    }

    function RenderNovoDocumento() {
        if (novoDocumento) return <NovoDocumento />
        else {
            return (
                <div style={{ textAlign: "center", marginTop: "3vh" }}>
                    <Botao texto="Novo Documento" widthWeb="10vw" widthMobile="40vw" funcao={() => setNovoDocumento(true)} />
                </div>
            )
        }
    }
}

export default Documentos