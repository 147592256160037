import { useState, useEffect, useContext } from 'react'
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios'

const DFDsSelecionaveis = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabelas, setTabelas] = useState([])

    const estilo = {
        table: {
            width: mediaWeb ? "98vw" : "100%", marginTop: "2vh", marginBottom: "2vh",
            marginLeft: "auto", marginRight: "auto",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: mediaWeb ? 'fixed' : null
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        }
    }

    useEffect(() => {
        if (localStorage.regionalUser !== "undefined") {
            if (localStorage.regionalUser === "CBMPB/Funesbom") var url = '/api/orcamento/getAllItens'
            else var url = `/api/orcamento/getItensByArea/${localStorage.regionalUser}`
            axios(url)
                .then(res => setTabelas(res.data))
                .catch(error => alert(error.response.message))
        }
    }, [])

    const changeArea = (regionalUser) => {
        localStorage.setItem("regionalUser", regionalUser)
        window.location = '/DFDsSelecionaveis'
    }

    return (
        <>
            <div>
                <select style={{ margin: "2vw" }} onChange={e => changeArea(e.target.value)}
                    className='nav'>
                    {localStorage.regionalUser !== "undefined" ? (
                        <option value={localStorage.regionalUser}>{localStorage.regionalUser}</option>
                    ) : (
                        <option value="">Escolha um Regional/Departamento</option>
                    )}
                    <option value="1º CRBM">1º CRBM</option>
                    <option value="2º CRBM">2º CRBM</option>
                    <option value="3º CRBM">3º CRBM</option>
                    <option value="4º CRBM">4º CRBM</option>
                    <option value="Ajudância">Ajudância</option>
                    <option value="DEFID">DEFID</option>
                    <option value="Diretoria de Ensino, Instrução e Pesquisa">Diretoria de Ensino, Instrução e Pesquisa</option>
                    <option value="Gabinete Médico">Gabinete Médico</option>
                    <option value="Gabinete Odontológico">Gabinete Odontológico</option>
                    <option value="Mergulho Autônomo">Mergulho Autônomo</option>
                    <option value="Operações Aéreas">Operações Aéreas</option>
                    <option value="Operações com Cães">Operações com Cães</option>
                    <option value="Projetos Sociais">Projetos Sociais</option>
                    <option value="Tecnologia da Informação">Tecnologia da Informação</option>
                    <option value="DAT">DAT</option>
                    <option value="Banda de Música">Banda de Música</option>
                    <option value="Núcleo Estratégico">Núcleo Estratégico</option>
                    <option value="CBMPB/Funesbom">CBMPB/Funesbom</option>
                </select>
            </div>
            <div style={{ textAlign: "center" }}><b>JUSTIFICATIVA DE QUANTIDADE DOS ITENS</b></div>
            <div style={{ textAlign: "center" }}>{localStorage.regionalUser}</div>
            <table style={estilo.table}>
                <thead>
                    <tr>
                        <th rowSpan="2" style={{ ...estilo.th, width: "12vw" }}>OBJETO</th>
                        <th rowSpan="2" style={{ ...estilo.th, width: "20vw" }}>DESCRIÇÃO</th>
                        <th rowSpan="2" style={{ ...estilo.th, width: "10vw" }}>UNIDADE</th>
                        <th rowSpan="2" style={{ ...estilo.th, width: "10vw" }}>VALOR ESTIMADO</th>
                        <th colSpan="3" style={{ ...estilo.th, width: "12vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                        <th rowSpan="2" style={{ ...estilo.th, width: "10vw" }}>QTD ESTIMADA</th>
                        <th rowSpan="2" style={{ ...estilo.th, width: "10vw" }}>VALOR TOTAL</th>
                        <th rowSpan="2" style={{ ...estilo.th, width: "15vw" }}>ÁREA</th>
                    </tr>
                    <tr>
                        <th style={{ ...estilo.th, width: "5vw" }}>2021</th>
                        <th style={{ ...estilo.th, width: "5vw" }}>2022</th>
                        <th style={{ ...estilo.th, width: "5vw" }}>2023</th>
                    </tr>
                </thead>
                <tbody>
                    {RenderRows()}
                </tbody>
            </table>
        </>
    )

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderRows() {
        let rows = []
        let valorTotalItens = 0
        tabelas.map((el, index) => {
            const areaMapping = {
                "Ajudância": "Material Administrativo",
                "DEFID": "Material Esportivo",
            }
            const area = areaMapping[el.area] || el.area
            if (localStorage.regionalUser !== "CBMPB/Funesbom") {
                const indexRegional = el.regionais.findIndex(x => x.regional === localStorage.regionalUser)
                const valorTotal = el.valorEstimado * el.regionais[indexRegional].qtdEstimada
                valorTotalItens += valorTotal
                if (el.regionais[indexRegional].qtdEstimada !== 0) {
                    rows.push(
                        <tr key={`objeto${index}`}>
                            <td style={estilo.td}>{el.objeto}</td>
                            <td style={estilo.td}>{el.descricao}</td>
                            <td style={estilo.td}>{el.und}</td>
                            <td style={estilo.td}>{formatCurrency(el.valorEstimado)}</td>
                            <td style={estilo.td}>{el.regionais[indexRegional].ano1}</td>
                            <td style={estilo.td}>{el.regionais[indexRegional].ano2}</td>
                            <td style={estilo.td}>{el.regionais[indexRegional].ano3}</td>
                            <td style={estilo.td}>{el.regionais[indexRegional].qtdEstimada}</td>
                            <td style={estilo.td}>{formatCurrency(valorTotal)}</td>
                            <td style={estilo.td}>{area}</td>
                        </tr>
                    )
                }
            }
            else {
                let ano1 = 0
                let ano2 = 0
                let ano3 = 0
                let qtdEstimada = 0
                el.regionais.map(el => {
                    ano1 += el.ano1
                    ano2 += el.ano2
                    ano3 += el.ano3
                    qtdEstimada += el.qtdEstimada
                })
                let valorTotal = el.valorEstimado * qtdEstimada
                valorTotalItens += valorTotal
                if (qtdEstimada > 0) {
                    rows.push(
                        <tr key={`objeto${index}`}>
                            <td style={estilo.td}>{el.objeto}</td>
                            <td style={estilo.td}>{el.descricao}</td>
                            <td style={estilo.td}>{el.und}</td>
                            <td style={estilo.td}>{formatCurrency(el.valorEstimado)}</td>
                            <td style={estilo.td}>{ano1}</td>
                            <td style={estilo.td}>{ano2}</td>
                            <td style={estilo.td}>{ano3}</td>
                            <td style={estilo.td}>{qtdEstimada}</td>
                            <td style={estilo.td}>{formatCurrency(valorTotal)}</td>
                            <td style={estilo.td}>{area}</td>
                        </tr>
                    )
                }
            }
        })
        return rows
    }
}

export default DFDsSelecionaveis