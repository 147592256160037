import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { MyContext } from "../Biblioteca/MyContext"

const TabelaFinal = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabelas, setTabelas] = useState([])
    const [filtro, setFiltro] = useState(null)

    const estilo = {
        table: {
            width: "98vw", marginTop: "2vh", marginBottom: "2vh",
            marginLeft: "auto", marginRight: "auto",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: "fixed"
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11,
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        }
    }

    useEffect(() => {
        const url = '/api/orcamento/getAllItens'
        axios(url)
            .then(res => setTabelas(res.data))
            .catch(error => alert(error.response.message))
    }, [])

    const updateField = (e, item) => {
        const { name, value } = e.target
        const index = tabelas.findIndex(el => el.objeto === item.objeto && el.descricao === item.descricao)
        setTabelas(prevState => {
            const newArray = [...prevState]
            newArray[index][name] = value
            return newArray
        })
    }

    const saveItem = (item) => {
        const url = '/api/orcamento/addCentralDeCompras'
        axios.post(url, item)
            .then(res => alert(res.data))
            .catch(error => alert(error.response.data))
    }

    const changeFiltro = (value) => {
        setFiltro(value)
    }

    return (
        <>
            <table style={estilo.table}>
                <thead>
                    <tr>
                        <th rowSpan="2" style={{ ...estilo.th, width: "3vw" }}>ITEM</th>
                        <th rowSpan="2" style={estilo.th}>CÓDIGO</th>
                        <th rowSpan="2" style={estilo.th}>OBJETO</th>
                        <th rowSpan="2" style={{ ...estilo.th, width: "20vw" }}>DESCRIÇÃO</th>
                        <th rowSpan="2" style={estilo.th}>UNIDADE</th>
                        <th rowSpan="2" style={estilo.th}>AÇÃO</th>
                        <th rowSpan="2" style={estilo.th}>NATUREZA</th>
                        <th colSpan="3" style={{ ...estilo.th, width: "15vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                        <th rowSpan="2" style={{ ...estilo.th, width: "5vw" }}>QTD ESTIMADA</th>
                        <th rowSpan="2" style={estilo.th}>VALOR UNITÁRIO ESTIMADO</th>
                        <th rowSpan="2" style={estilo.th}>COMPROMETIMENTO ORÇAMENTÁRIO LOA 2024</th>
                        <th rowSpan="2" style={estilo.th}>GRAU DE PRIORIDADE DA AQUISIÇÃO</th>
                    </tr>
                    <tr>
                        <th style={{ ...estilo.th, width: "5vw" }}>2021</th>
                        <th style={{ ...estilo.th, width: "5vw" }}>2022</th>
                        <th style={{ ...estilo.th, width: "5vw" }}>2023</th>
                    </tr>
                </thead>
                <tbody>
                    {RenderRows()}
                </tbody>
            </table>
        </>
    )

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderRows() {
        let rows = []
        if (tabelas.length > 0) {
            let numero = 0
            tabelas.map((item, index) => {
                let ano1 = 0
                let ano2 = 0
                let ano3 = 0
                let qtdEstimada = 0
                item.regionais.map(el => {
                    ano1 += el.ano1
                    ano2 += el.ano2
                    ano3 += el.ano3
                    qtdEstimada += el.qtdEstimada
                })
                let valorTotal = item.valorEstimado * qtdEstimada
                // if (qtdEstimada > 0 && !item.codCentral) {
                if (qtdEstimada > 0) {
                    numero += 1
                    var borda = "1px solid #ccc"
                    if (item.acao !== undefined && item.codCentral === undefined) var borda = "3px solidred"
                    rows.push(
                        <tr key={`objeto${index}`}>
                            <td style={estilo.td}>{numero}</td>
                            <td style={estilo.td}>{item.codCentral}</td>
                            <td style={estilo.td}>{item.objeto}</td>
                            <td style={estilo.td}>{item.descricao}</td>
                            <td style={estilo.td}>{item.und}</td>
                            <td style={estilo.td}>{item.acao}</td>
                            <td style={estilo.td}>{item.natureza}</td>
                            <td style={estilo.td}>{ano1}</td>
                            <td style={estilo.td}>{ano2}</td>
                            <td style={estilo.td}>{ano3}</td>
                            <td style={estilo.td}>{qtdEstimada}</td>
                            <td style={estilo.td}>{formatCurrency(item.valorEstimado)}</td>
                            <td style={estilo.td}>{formatCurrency(valorTotal)}</td>
                            <td style={estilo.td}>ALTO</td>
                        </tr>
                    )
                }
            })
        }
        return rows
    }
}

export default TabelaFinal