import React, { useState } from 'react'
import axios from 'axios'

import loadGif from '../../assets/loading.gif'

const EisenhowerMatrix = () => {
  const mediaWeb = window.matchMedia("(min-width:600px)").matches
  const [dados, setDados] = useState({
    query: "", qtdCaracteres: 0,
    idConversation: "", user: localStorage.nome
  })
  const [analise, setAnalise] = useState({ analise: "" })
  const [loading, setLoading] = useState(false)
  const [messages, setMessage] = useState([])

  const updateField = (e) => {
    const { name, value } = e.target
    let tempState = { ...dados }
    tempState[name] = value
    setDados(tempState)
  }

  const enviar = () => {
    setLoading(true)
    const url = "http://localhost:5001/memoryTest/camarada/testComLang"
    axios.post(url, dados)
      .then(res => {
        let tempState = { ...dados }
        tempState.idConversation = res.data.idConversation
        setDados(tempState)
        setAnalise({ analise: res.data.analise })
        setLoading(false)

        let tempMessage = [...messages]
        tempMessage.push({ tipo: "humano", message: dados.query }, { tipo: "ai", message: res.data.analise })
        setMessage(tempMessage)
      })
      .catch(error => {
        alert(error)
        setLoading(false)
      })
    console.log(messages)
  }

  const styles = {
    containerTelas: {
      display: 'flex',
      width: "90vw", margin: "auto", marginTop: "1vh"
    },
    containerTelasMobile: {
      width: "98vw", margin: "auto", marginTop: "1vh", overflow: "scroll"
    },
    telas: {
      border: '1px solid #ddd', borderRadius: "5px", display: "inline-block",
      margin: "auto", width: "90vw", height: "60vh", marginTop: "2vh",
      paddingLeft: "2vw", paddingRight: "2vw", paddingBottom: "1vh", paddingTop: "1vh",
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
    },
    telasMobile: {
      border: '1px solid #ddd', borderRadius: "5px", display: "block",
      margin: "auto", width: "92vw", height: "80vh", marginTop: "2vh",
      paddingLeft: "2vw", paddingRight: "2vw", paddingBottom: "1vh", paddingTop: "1vh",
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)'
    },
    containerInput: {
      border: '1px solid #ddd', borderRadius: "5px",
      margin: "auto", width: "90vw", marginTop: "2vh", marginBottom: "8vh",
      paddingLeft: "2vw", paddingRight: "2vw", paddingBottom: "1vh", paddingTop: "1vh",
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      display: 'flex', justifyContent: "space-between"
    },
    containerInputMobile: {
      border: '1px solid #ddd', borderRadius: "5px",
      margin: "auto", width: "92vw", marginTop: "2vh", marginBottom: "1vh",
      paddingLeft: "2vw", paddingRight: "2vw", paddingBottom: "1vh", paddingTop: "1vh",
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      display: 'flex', justifyContent: "space-between"
    },
    input: {
      border: "none",
      backgroundColor: "#00000000",
      width: "75vw",
      outline: "none"
    },
    botao: {
      border: "none",
      color: "#FFFF",
      backgroundColor: "#09792d",
      borderRadius: "8px",
      fontSize: "13px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    gif: {
      width: mediaWeb ? "25vw" : "60vw"
    }
  }
  return (
    <>
      <div style={mediaWeb ? styles.containerTelas : styles.containerTelasMobile}>
        <div style={mediaWeb ? styles.telas : styles.telasMobile}>
          {/* {analise.analise} */}
          {RenderMessages()}
        </div>
      </div>
      <div style={mediaWeb ? styles.containerInput : styles.containerInputMobile}>
        <input type="text" name="query" onChange={e => updateField(e)} value={dados.query}
          style={styles.input} />
        <button onClick={e => enviar()}
          style={styles.botao}>Enviar</button>
      </div>
    </>
  )

  function RenderMessages() {
    let mensagens = []
    messages.map(el => {
      mensagens.push(
        <div style={{
          backgroundColor: el.tipo === "humano" ? "green" : "red",
          // float: el.tipo === "humano" ? "right" : "left",
          width: mediaWeb ? "20vw" : "70vw",
          color: "#FFFF", borderRadius: "10px",
          padding: "10px", marginTop: "1vh", marginBottom: "1vh"
        }}>{el.message}</div>
      )
    })
    return mensagens
  }
}

export default EisenhowerMatrix;
