import { useState, useEffect } from 'react'
import axios from 'axios'

const SetoresQueEscolheram = (props) => {
    const [setores, setSetores] = useState([])
    const [tipoUser, setTipoUser] = useState("")
    const [keyDiv, setKey] = useState(0)

    useEffect(() => {
        if (props.area) {
            axios.post('/api/pca/setoresQueEscolheram', { areaEipcp: props.area, ano: parseInt(props.ano) })
                .then(res => {
                    setSetores(res.data.setores)
                    setTipoUser(res.data.tipoUser)
                    setKey(prevstate => prevstate + 1)
                })
                .catch(error => alert(error.response.data.message))
        }
    }, [])

    return (
        <div key={keyDiv}>
            {(tipoUser === "geral" || tipoUser === "interno") ? (<></>) : (
                props.area !== "" && (
                    <div style={{ margin: "1vw 0 0 4vw" }}>
                        <b>Setores que escolheram itens nesta área:</b> {
                            setores.map((setor, index) => {
                                if (index !== 0) return `, ${setor}`
                                else return setor
                            })
                        }
                    </div>
                )
            )}
        </div>
    )
}

export default SetoresQueEscolheram