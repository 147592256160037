import { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { MyContext } from "../Biblioteca/MyContext"

const ConversationsHistory = () => {
    const { myState } = useContext(MyContext)
    const [history, setHistory] = useState([])

    useEffect(() => {
        const url = "api/conversation/getUserConversations"
        const user = localStorage.nome
        axios.post(url, { user })
            .then(res => {
                setHistory(res.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])
    return (
        <>
            {RenderHistory()}
        </>
    )

    function RenderHistory() {
        let historico = []
        history.map(el => {
            historico.push(
                <>
                    <div style={{
                        textAlign: "center", border: "1px solid #ccc", borderRadius: "8px", width: "96vw",
                        marginLeft: "auto", marginRight: "auto", marginTop: "1vh", marginBottom: "1vh",
                        backgroundColor: "#0000000", color: myState === "light" ? 'black' : "#FFFF",
                        paddingTop: "1vh", paddingBottom: "1vh"
                    }}>
                        {el.data}
                    </div>
                    {GetConversations()}
                </>
            )

            function GetConversations() {
                let conversas = []
                el.conversas.map(x => {
                    conversas.push(
                        <div style={{
                            width: "92vw", marginLeft: "auto", marginRight: "auto",
                            padding: "3vw", color: myState === "light" ? 'black' : "#FFFF",
                            transition: "background-color 0.3s"
                        }}
                            onMouseOver={(e) => e.target.style.backgroundColor = "gray"}
                            onMouseOut={(e) => e.target.style.backgroundColor = "transparent"}
                            onClick={(e) => window.location = `/chatAI/${x._id}`}>
                            <i className="bi bi-chat-left-text" style={{ marginRight: "2vw" }}></i>
                            {x.header}
                        </div>
                    )
                })
                return conversas
            }
        })
        return historico
    }
}

export default ConversationsHistory