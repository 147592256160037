import { useState, useEffect, useContext } from 'react'
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios'

const DfdRegional = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabelaOperacionais, setTabelaOperacionais] = useState()
    const [display, setDisplay] = useState([])
    const [justificativas, setJustificativas] = useState([])
    const [saldo, setSaldo] = useState(0)
    const [rowsValues, setRowsValues] = useState([])
    const [rowsValuesAdm, setRowsValuesAdm] = useState([])

    useEffect(() => {
        axios.get('/api/orcamento/getOperacional')
            .then(res => {
                setTabelaOperacionais(res.data)
                const objeto = { display: "none" }
                const copias = Array(Object.keys(res.data).length).fill(objeto)
                setDisplay([...copias])
            })
            .catch(error => alert(error))
    }, [])

    useEffect(() => {
        axios.post('/api/orcamento/getJustificativas', { regional: localStorage.regionalUser })
            .then(res => {
                setJustificativas(res.data)
            })
            .catch(error => alert(error))

        axios.post('/api/orcamento/getSaldo', { regional: localStorage.regionalUser })
            .then(res => {
                setSaldo(res.data.saldo)
            })
            .catch(error => alert(error))
    }, [])

    const alterarDisplay = (index, novoValor) => {
        setDisplay(prevDisplay => {
            const novoArray = [...prevDisplay]
            novoArray[index] = { ...novoArray[index], display: novoValor }
            return novoArray
        })
    }

    const updateField = (e, indexEl, area, objeto) => {
        const { name, value } = e.target
        const index = rowsValues.findIndex(el => el.index === indexEl && el.area === area && el.objeto === objeto)
        const newObj = { [name]: value, index: indexEl, area: area, regional: localStorage.regionalUser, objeto: objeto }
        if (index !== -1) {
            setRowsValues(prevState => {
                const newArray = [...prevState]
                newArray[index] = { ...newArray[index], ...newObj }
                return newArray
            })
        }
        else setRowsValues(prevState => [...prevState, newObj])
    }

    const saveItem = (indexEl, area, objeto) => {
        const index = rowsValues.findIndex(el => el.index === indexEl && el.area === area && el.objeto === objeto)
        if (index === -1) return null
        else {
            axios.post("/api/orcamento/addCampoRegionais", rowsValues[index])
                .then(res => {
                    if (res.data.saldo) setSaldo(res.data.saldo)
                    if (res.data.aviso) alert(res.data.aviso)
                })
                .catch(error => alert(error.response.data))
        }
    }

    const updateJustificativa = (e, area, regional) => {
        const { name, value } = e.target
        let index = justificativas.findIndex(el => el.area === area)
        if (index === -1) {
            setJustificativas(prevState => [...prevState, { area: area, regional: regional, [name]: value }])
        }
        else {
            const updatedJustificativa = [...justificativas]
            updatedJustificativa[index][name] = value
            setJustificativas(updatedJustificativa)
        }
    }

    const sendJustificativa = (area) => {
        const url = "/api/orcamento/addJustificativas"
        let index = justificativas.findIndex(el => el.area === area)
        const obj = justificativas[index]
        axios.post(url, obj)
            .then(res => alert(res.data))
            .catch(error => alert(error))
    }

    const estilo = {
        table: {
            width: mediaWeb ? "98vw" : "100%", marginTop: "2vh", marginBottom: "2vh",
            marginLeft: "auto", marginRight: "auto",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: mediaWeb ? 'fixed' : null
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        },
        input: {
            border: "none", outline: "none", backgroundColor: "#00000000",
            color: myState === "light" ? 'black' : "#FFFF", width: "96%",
            resize: "none", overflow: "hidden"
        },
        textArea: {
            width: "90vw", outline: "none", minHeight: "15vh",
            backgroundColor: "#00000000", color: myState === "light" ? 'black' : "#FFFF",
            border: "1px solid #ccc", borderRadius: "8px"
        }
    }

    return (
        <>
            <div style={{
                position: "fixed", top: "16vh", left: "50%", transform: "translate(-50%, -50%)",
                display: "flex", alignItems: "center", justifyContent: "center",
                marginLeft: "auto", marginRight: "auto", width: "22vw", height: "6vw",
                border: "1px solid #ddd", borderRadius: "8px",
                backgroundColor: myState === "light" ? "#FFFF" : "#2c2c2c",
                color: myState === "light" ? 'black' : "#FFFF",
            }}>
                <div style={{
                    fontSize: "1.5rem", color: myState === "light" ? 'black' : "#FFFF", textAlign: "center"
                }}>
                    <div style={{
                        fontSize: "1.3rem", textAlign: "center",
                        color: myState === "light" ? 'black' : "#FFFF"
                    }}>
                        {localStorage.regionalUser}
                    </div>
                    {formatCurrency(saldo)}
                </div>
            </div>
            <div style={{marginTop: "20vh"}}></div>
            {RenderTabelasOperacionais()}
        </>
    )

    function RenderTabelasOperacionais() {
        let tabelas = []
        if (localStorage.tipo === "geral" || localStorage.tipo === "interno" || localStorage.tipo === "dfd") {
            if (tabelaOperacionais !== undefined) {
                Object.keys(tabelaOperacionais).map((obj, index) => {
                    let indexJustificativa = justificativas.findIndex(el => el.area === obj)
                    if (indexJustificativa === -1) {
                        var justificativaArea = ""
                        var resultadoArea = ""
                    } else {
                        var justificativaArea = justificativas[indexJustificativa]?.justificativa
                        var resultadoArea = justificativas[indexJustificativa]?.resultados
                    }
                    var titulo = obj
                    if(obj === "Ajudância") titulo = "Material Administrativo"
                    if(obj === "DEFID") titulo = "Material Esportivo"
                    tabelas.push(
                        <>
                            <div style={{
                                width: "70vw", padding: "10px",
                                marginTop: "2vh", marginLeft: "auto", marginRight: "auto",
                                backgroundColor: "#ccc", borderRadius: "8px",
                            }}>
                                <div style={{
                                    width: "64vw", display: "inline-block",
                                    textAlign: "center", fontSize: "18px"
                                }}>
                                    {titulo}
                                </div>
                                <button name="mais" style={{
                                    backgroundColor: "#FFFF", color: "#ccc",
                                    borderRadius: "50%", border: "none",
                                    width: "2vw", height: "2vw",
                                    backgroundColor: display[index].display === "none" ? "green" : "red",
                                    color: "#FFFF",
                                }}
                                    onClick={() => display[index].display === "none" ?
                                        alterarDisplay(index, "block") : alterarDisplay(index, "none")}
                                >
                                    {display[index].display === "none" ? "+" : "-"}
                                </button>
                            </div>
                            <div style={{
                                display: display[index].display, marginTop: "4vh", marginLeft: "2vw",
                                color: myState === "light" ? 'black' : "#FFFF"
                            }}>
                                <div style={{ color: myState === "light" ? '#33348F' : "#FFFF" }}>Justificativa</div>
                                <textarea id={`justificativa-${index}`} style={estilo.textArea}
                                    name="justificativa"
                                    defaultValue={justificativaArea}
                                    onChange={e => updateJustificativa(e, obj, localStorage.regionalUser)} />
                                <div style={{ color: myState === "light" ? '#33348F' : "#FFFF" }}>Resultados Pretendidos</div>
                                <textarea id={`resultados-${index}`} style={estilo.textArea}
                                    name="resultados"
                                    defaultValue={resultadoArea}
                                    onChange={e => updateJustificativa(e, obj, localStorage.regionalUser)} />
                                <div style={{ textAlign: "center", marginTop: "2vh" }}>
                                    <button style={{
                                        border: "none", outline: "none", borderRadius: '8px',
                                        backgroundColor: "#09792d", color: "#FFFF", padding: "5px"
                                    }}
                                        onClick={e => sendJustificativa(obj)} >Enviar Justificativa/Resultados</button>
                                </div>
                            </div>
                            <table style={{ ...estilo.table, display: display[index].display }}>
                                <thead>
                                    <tr>
                                        <th rowSpan="2" style={{ ...estilo.th, minWidth: "10vw" }}>OBJETO</th>
                                        {obj === "Ajudância" ? (
                                            <th rowSpan="2" style={{ ...estilo.th, minWidth: "10vw" }}>DESCRIÇÃO</th>
                                        ) : (<></>)}
                                        <th rowSpan="2" style={{ ...estilo.th, minWidth: "10vw" }}>UNIDADE</th>
                                        <th rowSpan="2" style={{ ...estilo.th, minWidth: "10vw" }}>VALOR ESTIMADO</th>
                                        <th colSpan="3" style={{ ...estilo.th, minWidth: "20vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                                        <th rowSpan="2" style={{ ...estilo.th, minWidth: "10vw" }}>QTD ESTIMADA</th>
                                        <th rowSpan="2" style={{ ...estilo.th, minWidth: "10vw" }}>SALVAR</th>
                                    </tr>
                                    <tr>
                                        <th style={{ ...estilo.th, minWidth: "10vw" }}>2021</th>
                                        <th style={{ ...estilo.th, minWidth: "10vw" }}>2022</th>
                                        <th style={{ ...estilo.th, minWidth: "10vw" }}>2023</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {RenderItensOperacionais(tabelaOperacionais[obj], obj)}
                                </tbody>
                            </table>
                        </>
                    )
                })
            }
        }
        return tabelas
    }

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderItensOperacionais(array, obj) {
        let rows = []
        array.map((el, index) => {
            const indexRegional = el.regionais.findIndex(x => x.regional === localStorage.regionalUser)
            rows.push(
                <tr key={`objeto${index}`}>
                    <td style={estilo.td}>{el.objeto}</td>
                    {obj === "Ajudância" ? (
                        <th style={estilo.td}>{el.descricao}</th>
                    ) : (<></>)}
                    <td style={estilo.td}>{el.und}</td>
                    <td style={estilo.td}>{formatCurrency(el.valorEstimado || '')}</td>
                    <td style={estilo.td}>
                        <input type="number" style={estilo.input}
                            name="ano1" onChange={e => updateField(e, el.index, el.area, el.objeto)}
                            defaultValue={el.regionais[indexRegional]?.ano1 || ''} />
                    </td>
                    <td style={estilo.td}>
                        <input type="number" style={estilo.input}
                            name="ano2" onChange={e => updateField(e, el.index, el.area, el.objeto)}
                            defaultValue={el.regionais[indexRegional]?.ano2 || ''} />
                    </td>
                    <td style={estilo.td}>
                        <input type="number" style={estilo.input}
                            name="ano3" onChange={e => updateField(e, el.index, el.area, el.objeto)}
                            defaultValue={el.regionais[indexRegional]?.ano3 || ''} />
                    </td>
                    <td style={estilo.td}>
                        <input type="number" style={estilo.input}
                            name="qtdEstimada" onChange={e => updateField(e, el.index, el.area, el.objeto)}
                            defaultValue={el.regionais[indexRegional]?.qtdEstimada || ''} />
                    </td>
                    <td style={{ ...estilo.td, textAlign: "center" }}>
                        <button style={{
                            border: "none", outline: "none", borderRadius: '8px', width: "4vw",
                            backgroundColor: "#09792d", color: "#FFFF", padding: "5px"
                        }}
                            onClick={e => saveItem(el.index, el.area, el.objeto)} >Salvar</button>
                    </td>
                </tr>
            )
        })
        return rows
    }
}

export default DfdRegional