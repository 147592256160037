import axios from 'axios'

const checkAuth = () => {
    const url = `/api/auth/checkAuth`
    axios(url)
        .then(resp => { })
        .catch(async error => {
            alert(error.response.data)
            localStorage.clear()
            window.location = '/'
        }
        )
}

export default checkAuth