import { useState, useEffect } from 'react'
import axios from 'axios'

import brasaoPB from '../../../assets/BrasaoPB.png'

import converterData from '../../Biblioteca/Funcoes/converterData'

const dataAtual = new Date()
const currentYear = dataAtual.getFullYear()

const formatarData = (data) => {
    const meses = [
        "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ]

    const [dia, mes, ano] = data.split('/')
    return `${dia} de ${meses[parseInt(mes, 10) - 1]} de ${ano}`
}

const quadro = {
    "Soldado": "", "Cabo": "", "3º Sargento": "", "2º Sargento": "", "1º Sargento": "", "Subtenente": "",
    "Aspirante": "QOEM", "2º Tenente": "QOEM", "1º Tenente": "QOEM", "Capitão": "QOEM", "Major": "QOEM",
    "Tenente Coronel": "QOEM", "Coronel": "QOEM"
}

const DfdSeadView = () => {
    const [dados, setDados] = useState([])
    const [departamentos, setDepartamentos] = useState([])

    useEffect(() => {
        const id = window.location.pathname.slice(13)
        axios(`/api/pca/getDfdSead/${id}`)
            .then(res => {
                const regionais = res.data.pontosFocais.map(pf => pf.regional)
                const regionaisUnicas = [...new Set(regionais)]
                setDepartamentos(regionaisUnicas)
                setDados(res.data)
            })
            .catch(error => {
                alert(error.response.data.message)
            })
    }, [])

    const estilo = {
        cabecalhoCinza: {
            backgroundColor: "#bfbfbf", fontSize: "13px",
            textAlign: "center", padding: "2px 0 2px 0", fontWeight: "550",
            width: "80vw", margin: "0 auto 0 auto"
        },
        caixaBorda: {
            border: "1px solid black", width: "80vw", margin: "4vh auto 0 auto", fontSize: "12px"
        },
        headerCaixa: {
            fontSize: "13px", fontWeight: "550", backgroundColor: "#bfbfbf", borderBottom: "1px solid black",
            borderTop: "1px solid black"
        },
        celulaCheia: {
            borderBottom: "1px solid black", fontSize: "12px"
        },
        linha: {
            display: "flex", width: "100%"
        },
        celulaEsquerda: {
            borderBottom: "1px solid black", borderRight: "1px solid black", width: "80%", display: "flex",
            alignItems: "center", boxSizing: "border-box", fontSize: "12px"
        },
        celulaDireita: {
            borderBottom: "1px solid black", width: "20%", display: "flex",
            alignItems: "center", justifyContent: "center", boxSizing: "border-box", fontSize: "12px"
        }
    }
    return (
        <>
            <div style={{ textAlign: "center" }}>
                <img src={brasaoPB} alt="brasao" style={{ width: "30vw" }} />
            </div>
            <div style={estilo.cabecalhoCinza}>
                DOCUMENTO DE FORMALIZAÇÃO DA DEMANDA – DFD
            </div>
            <div style={{ ...estilo.cabecalhoCinza, marginTop: "4vh" }}>
                PREENCHIMENTO PELA ÁREA REQUISITANTE
            </div>
            <div style={estilo.caixaBorda}>
                <div style={estilo.headerCaixa}>
                    1. ÁREA REQUISITANTE DA DEMANDA
                </div>
                <div style={estilo.celulaCheia}>
                    Órgão: Corpo de Bombeiros Militar da Paraíba – FUNESBOM
                </div>
                <div style={estilo.celulaCheia}>
                    Setor Requisitante (Unidade/Setor/Depto): {departamentos.join('; ')}
                </div>
                {
                    dados.pontosFocais && dados.pontosFocais.map((pontoFocal, index) => (
                        <div style={estilo.linha} key={index}>
                            <div style={estilo.celulaEsquerda}>
                                Responsável pela Demanda - {pontoFocal.regional}: {pontoFocal.nome}
                            </div>
                            <div style={estilo.celulaDireita}>
                                {pontoFocal.numeroMatricula}
                            </div>
                        </div>
                    ))
                }
                <div style={estilo.linha}>
                    <div style={{ ...estilo.celulaEsquerda, borderBottom: "none" }}>
                        E-mail: eipcpcbmpb@gmail.com
                    </div>
                    <div style={{ ...estilo.celulaDireita, borderBottom: "none" }}>
                        Telefone: (82) 99686-9956
                    </div>
                </div>
            </div>
            <div style={estilo.caixaBorda}>
                <div style={estilo.headerCaixa}>
                    2. IDENTIFICAÇÃO DA DEMANDA
                </div>
                <div style={{ textAlign: 'justify' }}>Descrição: {dados.dfd && dados.dfd.identificacao ? (<>{dados.dfd.identificacao}</>) : (<></>)}</div>
            </div>
            <div style={estilo.caixaBorda}>
                <div style={estilo.headerCaixa}>
                    3. JUSTIFICATIVA DA NECESSIDADE DA CONTRATAÇÃO, CONSIDERANDO O PLANEJAMENTO ESTRATÉGICO, AS DIRETRIZES DE PLANEJAMENTO, SE FOR O CASO.
                </div>
                <div>
                    {dados.dfd && dados.dfd.justificativa ? (
                        <>
                            {dados.dfd.justificativa.split('\n').map((line, index) => (
                                <div key={index} style={{ textIndent: index === 0 || line.trim() ? '2em' : '0', marginBottom: line.trim() ? '1em' : '0', textAlign: 'justify' }}>
                                    {line.trim()}
                                </div>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div style={estilo.caixaBorda}>
                <div style={estilo.headerCaixa}>
                    4. QUANTIDADE A SER CONTRATADA
                </div>
                <table style={{ marginTop: "2vh", width: "100%", fontSize: "12px" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center", width: "20%" }}>ITEM</th>
                            <th style={{ textAlign: "center", width: "10%" }}>{currentYear - 3}</th>
                            <th style={{ textAlign: "center", width: "10%" }}>{currentYear - 2}</th>
                            <th style={{ textAlign: "center", width: "10%" }}>{currentYear - 1}</th>
                            {dados.dfd && dados.dfd.modelo === "Processo Interno" ? (
                                <th style={{ textAlign: "center", width: "20%" }}>QUANTIDADE SOLICITADA</th>
                            ) : (<></>)}
                            <th style={{ textAlign: "center", width: "30%" }}>JUSTIFICATIVA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dados.dfd && dados.dfd.itensEscolhidos.map((linha, index) => {
                            const somaAno1 = linha.itens.reduce((acc, curr) => acc + (curr.ano1 || 0), 0)
                            const somaAno2 = linha.itens.reduce((acc, curr) => acc + (curr.ano2 || 0), 0)
                            const somaAno3 = linha.itens.reduce((acc, curr) => acc + (curr.ano3 || 0), 0)

                            return (
                                <tr key={index}>
                                    <td style={{ textAlign: "center", width: "20%" }}>{linha.objetoItem}</td>
                                    <td style={{ textAlign: "center", width: "10%" }}>{somaAno3}</td>
                                    <td style={{ textAlign: "center", width: "10%" }}>{somaAno2}</td>
                                    <td style={{ textAlign: "center", width: "10%" }}>{somaAno1}</td>
                                    {dados.dfd && dados.dfd.modelo === "Processo Interno" ? (
                                        <td style={{ textAlign: "center", width: "20%" }}>{linha.qtdSolicitadaItem}</td>
                                    ) : (<></>)}
                                    <td style={{ textAlign: "justify", width: "30%", whiteSpace: "pre-wrap" }}>
                                        {linha.justificativaItem}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div>
                    A quantidade solicitada para o presente procedimento licitatório se encontra no RELATÓRIO DE CONFERÊNCIA DE LANÇAMENTO DOS QUANTITATIVOS, o qual segue anexo aos autos
                </div>
            </div>
            <div style={estilo.caixaBorda}>
                <div style={estilo.headerCaixa}>
                    5. PREVISÃO DE DATA EM QUE DEVEM SER ENTREGUES OS BENS OU QUE OS SERVIÇOS SERÃO INICIADOS
                </div>
                <div style={{ ...estilo.celulaCheia, textAlign: "justify" }}>
                    Notas:<br />
                    1) Deve-se levar em conta o tempo ordinariamente gasto com um processo de contratação para se ponderar quando razoavelmente se espera a entrega dos bens, precavendo-se, assim, de possível solução de continuidade ou então demora que comprometa o funcionamento do órgão e/ou da entidade;
                    <br />
                    2) Utilizar-se de experiências anteriores para avaliar o lapso temporal necessário para se iniciar o procedimento no âmbito da requisitante.
                </div>
                <div>
                    Previsão de entrega ou Previsão que os serviços serão iniciados:
                    {dados.dfd && dados.dfd.mes ? (<> {dados.dfd.mes}/{dados.dfd.ano}</>) : (<></>)}
                </div>
            </div>
            {dados.dfd && dados.dfd.modelo === "Processo Interno" ? (<>
                <div style={estilo.caixaBorda}>
                    <div style={estilo.headerCaixa}>
                        6. FONTE DE RECURSOS
                    </div>
                    <div style={estilo.linha}>
                        <div style={{ ...estilo.celulaEsquerda, width: "50%" }}>
                            Gestão/Unidade
                        </div>
                        <div style={{ ...estilo.celulaDireita, width: "50%", justifyContent: "flex-start" }}>
                            {dados.dfd.unidadeGestora}
                        </div>
                    </div>
                    <div style={estilo.linha}>
                        <div style={{ ...estilo.celulaEsquerda, width: "50%" }}>
                            Fonte de Recursos
                        </div>
                        <div style={{ ...estilo.celulaDireita, width: "50%", justifyContent: "flex-start" }}>
                            {dados.dfd.fonteRecursos}
                        </div>
                    </div>
                    <div style={estilo.linha}>
                        <div style={{ ...estilo.celulaEsquerda, width: "50%" }}>
                            Programa de Trabalho
                        </div>
                        <div style={{ ...estilo.celulaDireita, width: "50%", justifyContent: "flex-start" }}>
                            {dados.dfd.programaTrabalho}
                        </div>
                    </div>
                    <div style={estilo.linha}>
                        <div style={{ ...estilo.celulaEsquerda, width: "50%" }}>
                            Elemento de Despesa
                        </div>
                        <div style={{ ...estilo.celulaDireita, width: "50%", justifyContent: "flex-start" }}>
                            {dados.dfd.elementoDespesa}
                        </div>
                    </div>
                </div>
            </>) : (<></>)}
            <div style={estilo.caixaBorda}>
                <div style={estilo.headerCaixa}>
                    {dados.dfd && dados.dfd.modelo === "Processo Interno" ? (7) : (6)}. PLANO DE CONTRATAÇÕES ANUAL - PCA
                </div>
                Declaramos que todos os itens indicados neste documento de formalização da demanda:
                <br />
                {dados.dfd &&
                    dados.dfd.constaPca !== "Não constam no Plano de Contratações Anual - PCA" ? (<>(X)</>) : (<>(    )</>)}
                Constam no Plano de Contratações Anual – PCA.
                <br />
                {dados.dfd &&
                    dados.dfd.constaPca === "Não constam no Plano de Contratações Anual - PCA" ? (<>(X)</>) : (<>(    )</>)}
                Não constam no Plano de Contratações Anual - PCA.
                <br /><br />
                {dados.dfd &&
                    dados.dfd.constaPca === "Não constam no Plano de Contratações Anual - PCA" ?
                    (<div style={{ textAlign: 'justify' }}>Justificativa: {dados.dfd.justificativaPca}</div>) : (<></>)}
            </div>
            <div style={estilo.caixaBorda}>
                <div style={estilo.headerCaixa}>
                    {dados.dfd && dados.dfd.modelo === "Processo Interno" ? (8) : (7)}. INDICAÇÃO DO MEMBRO RESPONSÁVEL PELA FORMALIZAÇÃO DA DEMANDA
                </div>
                JOÃO PESSOA, {dados.dfd && dados.dfd.data ? (<>{formatarData(converterData(dados.dfd.data.slice(0,10)))}</>) : (<></>)}
                <div style={{ textAlign: "center", padding: "5px" }}><br />
                    <div style={{ textAlign: "center" }}>
                        <br />
                        {dados.dfd && dados.dfd.usuário} - <b>{dados.dfd && dados.dfd.posto} {dados.dfd && quadro[dados.dfd.posto]}</b><br />
                        Responsável pela Formalização da Demanda<br />
                        Matrícula {dados.dfd && dados.dfd.numeroMatricula}
                    </div>
                    Assinado e datado via PBDOC
                </div>
            </div>
            <div style={estilo.caixaBorda}>
                <div style={estilo.headerCaixa}>
                    {dados.dfd && dados.dfd.modelo === "Processo Interno" ? (9) : (8)}. ANUÊNCIA DO ORDENADOR DE DESPESAS
                </div>
                JOÃO PESSOA, {dados.dfd && dados.dfd.data ? (<>{formatarData(converterData(dados.dfd.data.slice(0,10)))}</>) : (<></>)}
                <div style={{ textAlign: "center", padding: "5px" }}><br />
                    <div style={{ textAlign: "center" }}>
                        <br />
                        MARCELO AUGUSTO DE <b>ARAÚJO</b> BEZERRA – <b>CEL QOEM</b><br />
                        Matrícula - 516.508-3<br />
                        Comandante Geral do CBMPB<br />
                    </div>
                    Assinado e datado via PBDOC
                </div>
            </div>
        </>
    )
}

export default DfdSeadView