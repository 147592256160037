import React, { useContext } from 'react'
import { MyContext } from "./components/Biblioteca/MyContext"

const Wrapper = (props) => {
    const { myState } = useContext(MyContext)

    return (
        <div style={{ backgroundColor: myState === "light" ? '#FFFF' : "rgb(44,44,44)", 
        display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {props.children}
        </div>
    )
}

export default Wrapper