import { useState, useEffect } from 'react'
import axios from 'axios'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'
import loading from '../../../assets/loading.gif'

import Select from '../../Biblioteca/Select'
import Input from '../../Biblioteca/Input'

const TodosItens = () => {
    const [dados, setDados] = useState([])
    const [filtro, setFiltro] = useState("")

    useEffect(() => {
        axios.post('/api/pca/todosItens', {})
            .then(res => setDados(res.data))
            .catch(error => alert(error.response.data.message))
    }, [])

    const updateFiltro = (e) => {
        const { value } = e.target
        setFiltro(value)
    }
    const aplicarFiltro = (dados) => {
        if (!filtro || filtro === "Sem filtro") return dados

        const filtros = {
            "Cód. Central Vazio": (item) => !item.codCentral,
            "CATMAT/SER Vazio": (item) => !item.catMatSer,
            "Ação Vazia": (item) => !item.acao,
            "Natureza Vazia": (item) => !item.natureza,
        }

        return dados.filter(filtros[filtro])
    }
    const dadosFiltrados = aplicarFiltro(dados)

    return (
        <>
            <div style={{ margin: "1vw" }}>

                <Select texto="Filtro" nome="filtro" valor={filtro}
                    funcao={updateFiltro} widthWeb="26vw" widthMobile="80vw"
                    options={["Sem filtro", "Cód. Central Vazio", "CATMAT/SER Vazio", "Ação Vazia", "Natureza Vazia"]} />
                <Input texto="Quantidade de Itens" nome="quantidade" widthWeb="26vw" widthMobile="80vw"
                    valor={dadosFiltrados.length} />
                {dados.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th style={{ fontSize: "11px", width: "4vw" }}></th>
                                <th style={{ fontSize: "11px", width: "10vw" }}>OBJETO</th>
                                <th style={{ fontSize: "11px", width: "20vw" }}>DESCRIÇÃO</th>
                                <th style={{ fontSize: "11px" }}>UNIDADE</th>
                                <th style={{ fontSize: "11px" }}>QUANTIDADE</th>
                                <th style={{ fontSize: "11px" }}>VALOR ESTIMADO</th>
                                <th style={{ fontSize: "11px" }}>VALOR TOTAL</th>
                                <th style={{ fontSize: "11px" }}>CÓD. CENTRAL</th>
                                {/* <th style={{ fontSize: "11px" }}>AÇÃO</th>
                                <th style={{ fontSize: "11px" }}>NATUREZA</th> */}
                                <th style={{ fontSize: "11px" }}>CATMAT/SER</th>
                                {/* <th style={{ fontSize: "11px" }}>DESCRIÇÃO CAT</th>
                                <th style={{ fontSize: "11px" }}>ÁREA</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {(() => {
                                let contador = 1
                                return dadosFiltrados.map((item, index) => {
                                    const descricao = item.descricao.length > 200 ?
                                        item.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : item.descricao
                                    var qtdTotal = 0
                                    item.regionais.map(el => qtdTotal += el.qtdEstimada)
                                    if (qtdTotal > 0) {
                                        const linha = (
                                            <tr key={index}>
                                                <td style={{ fontSize: "11px" }}>{contador}</td>
                                                <td style={{ fontSize: "11px" }}>{item.objeto}</td>
                                                <td style={{ fontSize: "11px" }}>{descricao}</td>
                                                <td style={{ fontSize: "11px" }}>{item.und}</td>
                                                <td style={{ fontSize: "11px" }}>{qtdTotal}</td>
                                                <td style={{ fontSize: "11px" }}>{formatCurrency(item.valorEstimado)}</td>
                                                <td style={{ fontSize: "11px" }}>{formatCurrency(item.valorEstimado * qtdTotal)}</td>
                                                <td style={{ fontSize: "11px" }}>{item.codCentral}</td>
                                                <td style={{ fontSize: "11px" }}>{item.catMatSer}</td>
                                            </tr>
                                        )
                                        contador++
                                        return linha
                                    }
                                    return null 
                                })
                            })()}
                        </tbody>
                    </table>
                ) : <div style={{ textAlign: "center" }}><img src={loading} style={{ width: "5vw" }}></img></div>}
            </div>
        </>
    )
}

export default TodosItens