import React, { useContext } from 'react';
import { MyContext } from "../Biblioteca/MyContext"

const NavCpl = (props) => {
    const { myState } = useContext(MyContext);

    const linkStyle = {
        marginLeft: 'auto',
        marginRight: '1rem',
        color: myState === "light" ? 'black' : "#F9F9F9",
        fontWeight: 550,
        fontSize: "13px",
        cursor: 'pointer',
        textDecoration: 'none',
    };

    if (window.matchMedia('(min-width:600px)').matches) {
        return (
            <div style={{ textAlign: "center", width: '100%' }}>
                {/* <a href="/" style={{ ...props.estilo, ...linkStyle }}>
                    INÍCIO
                </a>
                <a href="/dashboard" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw'  }}>
                    DASHBOARD
                </a>
                <a href="/processosCpl" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    PROCESSOS
                </a>
                <a href="/aditivosCpl" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    ADITIVOS
                </a> */}
                {/* <a href="/tabelaCatMat" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    CATMAT/CATSER
                </a> */}
                <a href="/cpldal" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    EDITAR ITENS
                </a>
                {/* <a href="/documentosCpl" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    DOCUMENTOS
                </a> */}
                <a href="/senha" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    SENHA
                </a>
            </div>
        );
    } else {
        return (
            <>
                {/* <div className="mt-2">
                    <a href="/" style={{ ...props.estilo, ...linkStyle }}>
                        INÍCIO
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/dashboard" style={{ ...props.estilo, ...linkStyle }}>
                        DASHBOARD
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/processosCpl" style={{ ...props.estilo, ...linkStyle }}>
                        PROCESSOS
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/aditivosCpl" style={{ ...props.estilo, ...linkStyle }}>
                        ADITIVOS
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/tabelaCatMat" style={{ ...props.estilo, ...linkStyle }}>
                        CATMAT/CATSER
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/documentosCpl" style={{ ...props.estilo, ...linkStyle }}>
                        DOCUMENTOS
                    </a>
                </div> */}
                <div className="mt-2">
                    <a href="/cpldal" style={{ ...props.estilo, ...linkStyle }}>
                        EDITAR ITENS
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/senha" style={{ ...props.estilo, ...linkStyle }}>
                        SENHA
                    </a>
                </div>
            </>
        );
    }
};

export default NavCpl;
