import { useState, useEffect } from 'react'
import axios from 'axios'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'
import loading from '../../../assets/loading.gif'

import Select from '../../Biblioteca/Select'
import Input from '../../Biblioteca/Input'

const TabelaSead = () => {
    const [anoPreenchimento, setAnoPreenchimento] = useState("")
    const [fonte, setFonte] = useState("")
    const [tabelas, setTabelas] = useState([])
    let numeroItem = 1

    const updateAno = (e) => {
        const { value } = e.target
        setAnoPreenchimento(value)
    }
    const updateFonte = (e) => {
        const { value } = e.target
        setFonte(value)
    }

    useEffect(() => {
        const url = '/api/pca/getDfdGeral'
        if (anoPreenchimento !== "") {
            axios.post(url, { fonte: fonte, anoPreenchimento: parseInt(anoPreenchimento) })
                .then(res => {
                    setTabelas(res.data.tabelas)
                })
                .catch(error => alert(error.response.data.message))
        }
    }, [anoPreenchimento])

    return (
        <>
            {anoPreenchimento === "" ? (
                <div style={{ margin: "1vw 0 0 4vw" }}>
                    <Select texto="Fonte" nome="fonte" valor={fonte}
                        funcao={updateFonte} widthWeb="26vw" widthMobile="80vw"
                        options={["CBM", "FUNESBOM"]} />
                    <Select texto="Ano de Preenchimento" tipo="number" nome="anoPreenchimento" valor={anoPreenchimento}
                        funcao={updateAno} widthWeb="26vw" widthMobile="80vw"
                        options={[2024]} />
                </div>
            ) : (
                <>
                    <div style={{ margin: "1vw" }}>
                        <table className="tableDfd">
                            <thead>
                                <tr>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "3vw" }}>ITEM</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "4vw" }}>CÓDIGO</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "8vw" }}>OBJETO</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "14vw" }}>DESCRIÇÃO BASE DE REFERÊNCIA</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "5vw" }}>UND. DE MEDIDA</th>
                                    <th className="thDfdGeral" colSpan="3" style={{ width: "9vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "5vw" }}>QTD. ESTIMADA</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "17vw" }}>JUSTIFICATIVA DOS QUANTITATIVOS</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>VALOR ESTIMADO UNITÁRIO</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "10vw" }}>COMPROMETIMENTO ORÇAMENTÁRIO LOA 2025</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "9vw" }}>DATA PRETENDIDA / CALENDÁRIO DE CONTRATAÇÃO</th>
                                    <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>GRAU DE PRIORIDADE</th>
                                </tr>
                                <tr>
                                    <th className="thDfdGeral">2021</th>
                                    <th className="thDfdGeral">2022</th>
                                    <th className="thDfdGeral">2023</th>
                                </tr>
                            </thead>
                            <tbody>
                                {RenderRows(tabelas)}
                            </tbody>
                            <thead>
                                <tr>
                                    <th colSpan="10" className="thDfdGeral" style={{
                                        backgroundColor: "#f9ff06", color: "black", textAlign: "left"
                                    }}>
                                        VALOR TOTAL DO DOCUMENTO DE FORMALIZAÇÃO DA DEMANDA
                                    </th>
                                    <th colSpan="4" className="thDfdGeral" style={{
                                        backgroundColor: "#f9ff06", color: "black", textAlign: "left"
                                    }}>
                                        {RenderSomaTotal(tabelas)}
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </>)}
        </>
    )

    function RenderSomaTotal(array, obj) {
        let somaTotal = 0
        Object.keys(array).map(area => {
            tabelas[area].items.map(el => somaTotal = somaTotal + (el.totalQtdEstimada * el.valorEstimado))
        })
        return formatCurrency(somaTotal)
    }

    function RenderRows(array) {
        let rows = []
        Object.keys(array).map(area => {
            tabelas[area].items.map((el, index) => {
                const descricao = el.descricao.length > 200 ?
                    el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
                const media = (el.totalAno1 + el.totalAno2 + el.totalAno3) / 3
                var justificativa
                if (media === 0) justificativa = "Item que não teve compra nos últimos três anos, mas apresentaram demandas de acordo com as quantidades referentes ao somatório dos DFDs Setoriais dos setores listados na justificativa da área conforme todas as JUSTIFICATIVAS DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo"
                else if (media <= el.totalQtdEstimada) justificativa = "Quantidades referentes ao somatório dos DFDs Setoriais dos setores listados na justificativa da área conforme todas as JUSTIFICATIVAS DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo."
                else justificativa = "Conforme JUSTIFICATIVA DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo."
                if (el.totalQtdEstimada > 0) {
                    rows.push(
                        <tr key={`objeto${index}`}>
                            <td className="tdDfdGeral">{numeroItem}</td>
                            <td className="tdDfdGeral">{el?.codCentral || "Sem correspondência no SGC"}</td>
                            <td className="tdDfdGeral">{el.objeto}</td>
                            <td className="tdDfdGeral">{descricao}</td>
                            <td className="tdDfdGeral">{el.und}</td>
                            <td className="tdDfdGeral">{el.totalAno1}</td>
                            <td className="tdDfdGeral">{el.totalAno2}</td>
                            <td className="tdDfdGeral">{el.totalAno3}</td>
                            <td className="tdDfdGeral">{el.totalQtdEstimada}</td>
                            <td className="tdDfdGeral">
                                {justificativa}
                                <br /><br />
                                Setores que solicitaram o item: <br />
                                {el.regionais.map((item, index) => {
                                    if (index !== 0) return `, ${item.regional}(${item.qtdEstimada})`
                                    else return `${item.regional}(${item.qtdEstimada})`
                                })}
                            </td>
                            <td className="tdDfdGeral">{formatCurrency(el.valorEstimado)}</td>
                            <td className="tdDfdGeral">{formatCurrency(el.totalQtdEstimada * el.valorEstimado)}</td>
                            <td className="tdDfdGeral">{el.closestMonth}</td>
                            <td className="tdDfdGeral">{el.highestPriority}</td>
                        </tr>
                    )
                    numeroItem++
                }
            })
        })
        return rows
    }
}

export default TabelaSead