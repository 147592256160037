import { useState } from 'react'
import axios from 'axios'

import Input from '../Biblioteca/Input'
import Botao from '../Biblioteca/Botao'

const Senha = () => {
    const [dados, setDados] = useState({ user: localStorage.id })

    const updateField = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }

    const updateSenha = () => {
        const url = '/api/user/senha'
        axios.put(url, dados)
            .then(res => alert(res.data))
            .catch(error => alert(error.response.data))
    }

    const estilo = {
        container: {
            width: "40vw", margin: "auto", marginTop: "10vh", textAlign: "center"
        }
    }

    const handleSwipe = (direction) => {
        console.log(`Swiped to the ${direction}`);
      };

    return (
        <div style={estilo.container}>
            <div>
                <Input texto="Senha Atual" tipo="password" nome="antiga" funcao={updateField}
                    widthWeb="15vw" widthMobile="40vw" />
            </div>
            <div>
                <Input texto="Senha Nova" tipo="password" nome="nova" funcao={updateField}
                    widthWeb="15vw" widthMobile="40vw" />
            </div>
            <div>
                <Input texto="Repetir Senha Nova" tipo="password" nome="repetida" funcao={updateField}
                    widthWeb="15vw" widthMobile="40vw" />
            </div>
            <Botao texto="EDITAR" widthMobile="20vw" funcao={updateSenha} />
        </div>
    )
}

export default Senha