import { useState, useEffect, useContext } from 'react'
import { MyContext } from "../Biblioteca/MyContext"
import axios from 'axios'

const TabelasPorAreas = () => {
    const mediaWeb = window.matchMedia("(min-width:600px)").matches
    const { myState } = useContext(MyContext)
    const [tabelasPorArea, setTabelas] = useState()
    const [regionalUser, setRegional] = useState(window.location.pathname.slice(18))
    const [display, setDisplay] = useState([])
    const [saldo, setSaldo] = useState(7600000.00)

    useEffect(() => {
        axios.get('/api/orcamento/tabelasPorArea')
            .then(res => {
                setTabelas(res.data)
                const objeto = { display: "none" }
                const copias = Array(Object.keys(res.data).length).fill(objeto)
                setDisplay([...copias])
            })
            .catch(error => alert(error))
    }, [])

    const updateField = (e, index, area, qtdEstimada) => {
        const { name, value } = e.target
        let obj = { campo: name, valor: value, index: index, area: area, regional: regionalUser }
        // axios.post("/api/orcamento/addCampoRegionais", obj)
        //     .then()
        //     .catch(error => alert("O item não pôde ser salvo, contate o suporte."))

        if (name === "qtdEstimada") {
            setSaldo((prevSaldo) => 7600000.00 - (qtdEstimada * value))
        }
    }

    const updateJustificativa = (e, area) => {
        const { value } = e.target
    }

    const alterarDisplay = (index, novoValor) => {
        setDisplay(prevDisplay => {
            const novoArray = [...prevDisplay]
            novoArray[index] = { ...novoArray[index], display: novoValor }
            return novoArray
        })
    }

    const formatarMoeda = (valor) => {
        return valor.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        })
    }

    useEffect(() => {

    }, [saldo])

    const estilo = {
        table: {
            width: mediaWeb ? "98vw" : "100%", marginTop: "2vh", marginBottom: "2vh",
            marginLeft: "auto", marginRight: "auto",
            padding: '16px', borderCollapse: 'collapse', textAlign: "center", borderRadius: '10px',
            tableLayout: mediaWeb ? 'fixed' : null
        },
        th: {
            color: myState === "light" ? '#33348F' : "#FFFF",
            backgroundColor: myState === "light" ? '#fff' : "#00000000", padding: '5px', border: '1px solid #ddd',
            fontWeight: 600, fontSize: 11, width: mediaWeb ? null : "40vw"
        },
        td: {
            padding: '5px', backgroundColor: "#00000000", border: "1px solid #ddd",
            fontSize: mediaWeb ? 11 : 18, color: myState === "light" ? 'black' : "#FFFF", textAlign: "left"
        },
        input: {
            border: "none", outline: "none", backgroundColor: "#00000000",
            color: myState === "light" ? 'black' : "#FFFF", width: "96%",
            resize: "none", overflow: "hidden"
        },
        textArea: {
            width: "90vw", outline: "none", minHeight: "15vh",
            backgroundColor: "#00000000", color: myState === "light" ? 'black' : "#FFFF",
            border: "1px solid #ccc", borderRadius: "8px"
        }
    }

    return (
        <>
            <div style={{
                position: "fixed",
                top: "16vh",
                left: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                width: "18vw",
                height: "6vw",
                border: "1px solid #ddd",
                borderRadius: "8px",
                backgroundColor: myState === "light" ? "#FFFF" : "#2c2c2c",
                color: myState === "light" ? 'black' : "#FFFF",
            }}>
                <div style={{
                    fontSize: "1.5rem",
                    color: myState === "light" ? 'black' : "#FFFF"
                }}>
                    <div style={{
                        fontSize: "1.3rem", textAlign: "center",
                        color: myState === "light" ? 'black' : "#FFFF"
                    }}>
                        1º CRBM
                    </div>
                    {formatarMoeda(saldo)}
                </div>
            </div>
            <div style={{ marginTop: "45vh" }}>
                {RenderTabelas()}
            </div>
        </>
    )

    function RenderTabelas() {
        let tabelas = []
        if (localStorage.regionalUser === regionalUser ||
            localStorage.tipo === "geral" || localStorage.tipo === "interno") {
            if (tabelasPorArea !== undefined) {
                Object.keys(tabelasPorArea).map((obj, index) => {
                    tabelas.push(
                        <>
                            <div style={{
                                width: "70vw", padding: "10px",
                                marginTop: "2vh", marginLeft: "auto", marginRight: "auto",
                                backgroundColor: "#ccc", borderRadius: "8px",
                            }}>
                                <div style={{
                                    width: "64vw", display: "inline-block",
                                    textAlign: "center", fontSize: "18px"
                                }}>
                                    {obj}
                                </div>
                                <button name="mais" style={{
                                    backgroundColor: "#FFFF", color: "#ccc",
                                    borderRadius: "50%", border: "none",
                                    width: "2vw", height: "2vw",
                                    backgroundColor: display[index].display === "none" ? "green" : "red",
                                    color: "#FFFF",
                                }}
                                    onClick={() => display[index].display === "none" ?
                                        alterarDisplay(index, "block") : alterarDisplay(index, "none")}
                                >
                                    {display[index].display === "none" ? "+" : "-"}
                                </button>
                            </div>
                            <div style={{
                                display: display[index].display, marginTop: "4vh", marginLeft: "2vw",
                                color: myState === "light" ? 'black' : "#FFFF"
                            }}>
                                <div>Justificativa</div>
                                <textarea id={`justificativa-${index}`} style={estilo.textArea}
                                    name="und"
                                    onChange={e => updateJustificativa(e, obj)} />
                                <div>Resultados Pretendidos</div>
                                <textarea id={`resultados-${index}`} style={estilo.textArea}
                                    name="und"
                                    onChange={e => updateJustificativa(e, obj)} />
                            </div>
                            <table style={{ ...estilo.table, display: display[index].display }}>
                                <thead>
                                    <tr>
                                        <th rowspan="2" style={{ ...estilo.th, minWidth: "10vw" }}>OBJETO</th>
                                        <th rowspan="2" style={{ ...estilo.th, minWidth: "10vw" }}>UNIDADE</th>
                                        <th rowspan="2" style={{ ...estilo.th, minWidth: "10vw" }}>VALOR ESTIMADO</th>
                                        <th colspan="3" style={{ ...estilo.th, minWidth: "20vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                                        <th rowspan="2" style={{ ...estilo.th, minWidth: "10vw" }}>QTD ESTIMADA</th>
                                        {/* <th rowspan="2" style={{ ...estilo.th, minWidth: "10vw" }}>JUSTIFICATIVA</th> */}
                                    </tr>
                                    <tr>
                                        <th style={{ ...estilo.th, minWidth: "10vw" }}>2021</th>
                                        <th style={{ ...estilo.th, minWidth: "10vw" }}>2022</th>
                                        <th style={{ ...estilo.th, minWidth: "10vw" }}>2023</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {RenderItens(tabelasPorArea[obj])}
                                </tbody>
                            </table>
                        </>
                    )
                })
            }
        }
        return tabelas
    }

    function formatCurrency(value) {
        const options = {
            style: 'currency',
            currency: 'BRL'
        }

        return value.toLocaleString('pt-BR', options)
    }

    function RenderItens(array) {
        let rows = []
        array.map((el, index) => {
            const indexRegional = el.regionais.findIndex(x => x.regional === regionalUser)
            rows.push(
                <tr key={`objeto${index}`}>
                    <td style={estilo.td}>{el.objeto}</td>
                    <td style={estilo.td}>{el.und}</td>
                    <td style={estilo.td}>{formatCurrency(el.valorEstimado || '')}</td>
                    <td style={estilo.td}>
                        <input type="number" style={estilo.input}
                            name="ano1" onChange={e => updateField(e, el.index, el.area)}
                            defaultValue={el.regionais[indexRegional]?.ano1 || ''} />
                    </td>
                    <td style={estilo.td}>
                        <input type="number" style={estilo.input}
                            name="ano2" onChange={e => updateField(e, el.index, el.area)}
                            defaultValue={el.regionais[indexRegional]?.ano2 || ''} />
                    </td>
                    <td style={estilo.td}>
                        <input type="number" style={estilo.input}
                            name="ano3" onChange={e => updateField(e, el.index, el.area)}
                            defaultValue={el.regionais[indexRegional]?.ano3 || ''} />
                    </td>
                    <td style={estilo.td}>
                        <input type="number" style={estilo.input}
                            name="qtdEstimada" onChange={e => updateField(e, el.index, el.area, el.valorEstimado)}
                            defaultValue={el.regionais[indexRegional]?.qtdEstimada || ''} />
                    </td>
                    {/* <td style={estilo.td}>
                        <input type="text" style={estilo.input}
                            name="justificativa" onChange={e => updateField(e, el.index, el.area)}
                            defaultValue={el.regionais[indexRegional]?.justificativa || ''} />
                    </td> */}
                </tr>
            )
        })
        return rows
    }
}

export default TabelasPorAreas